import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import qs from 'query-string';
import axios from 'axios';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {URL, URL_WEBSOCKET} from "../../constants";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {addStoreComet} from "../../store/reducers/StoreSlice";
import {alert} from "../../store/reducers/AlertSlice";
import {convertCometData} from "../../utils/utils";
import {logout} from "../../store/reducers/AuthSlice";
import {useTranslate} from "../../hooks/useTranslate";
import {setDebug} from "../../store/reducers/DebugSlice";
import { reloadAllList, loadLossArticle } from '../../store/reducers/LSlice';

const WebSocket:FC  = () => {
    const { user, list } = useAppSelector(state => state.auth);
    const [connect, setConnect] = useState(true);
    const [isReconnect, setIsReconnect] = useState(false);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const isDisconnect = useRef(false);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [socketUrl, setSocketUrl] = useState<string | null>(null);
    const [date, setDate] = useState<string | null>(null);

    const getSocketUrl = useCallback(async () => {
        try {
          const { data } = await axios.get<{ ticket: string }>(URL.GET_TICKET, { withCredentials: true });
          return URL_WEBSOCKET + data?.ticket;
        } catch (error) {
          console.error('Error fetching WebSocket ticket:', error);
          return null;
        }
      }, []);

    const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
        shouldReconnect: () => false,
        onClose: () => {
            if (!isDisconnect.current) {
                scheduleReconnect();
            }
        },
        onOpen: () => {
            if (isReconnect && date) {
                // dispatch(reloadAllList());
                dispatch(loadLossArticle(date));
            }
            setIsReconnect(true);
        }
    }, connect);


    const stopWebSocket = useCallback(() => {
        console.log('STOP');
        const ws = getWebSocket();
        if (ws && !isDisconnect.current) {
            ws.close();  // Закрываем соединение
        }
        isDisconnect.current = true;  // Устанавливаем флаг ручного отключения
        setConnect(false);  // Отключаем подключение
        clearReconnectInterval(); // Очищаем интервал переподключения
    }, [getWebSocket]);

    const startWebSocket = useCallback(async () => {
        console.log('START');
        if (readyState === ReadyState.CLOSED || readyState === ReadyState.UNINSTANTIATED) {
            isDisconnect.current = false;  // Сбрасываем флаг при автоматическом переподключении
            const newSocketUrl = await getSocketUrl();  // Получаем новый URL
            setSocketUrl(newSocketUrl);  // Устанавливаем новый URL
            setConnect(true);  // Включаем подключение
        }
    }, [readyState]);

    const clearReconnectInterval = useCallback(() => {
        if (reconnectIntervalRef.current) {
          clearInterval(reconnectIntervalRef.current);
          reconnectIntervalRef.current = null;
        }
    }, []);

    const scheduleReconnect = useCallback(() => {
        if (reconnectIntervalRef.current) {
          clearInterval(reconnectIntervalRef.current);
        }
        reconnectIntervalRef.current = setInterval(() => {
          if (readyState === ReadyState.UNINSTANTIATED || (readyState === ReadyState.CLOSED && !isDisconnect.current)) {
            startWebSocket();
          }
        }, 3000); // Попытка переподключения каждые 30 секунд
    }, [startWebSocket, readyState]);
      

    const connectionStatus = {
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
    }[readyState];

    useEffect(() => {
        if (lastMessage !== null) {
            const { data } = lastMessage;
            if(data) {
                const convert = convertCometData([JSON.parse(data)], list);

                if(convert.logout){
                    dispatch(logout());
                    dispatch(alert({
                        title: translate.multiSession.disconnect,
                        description: translate.multiSession.disconnect,
                        button: translate.multiSession.close,
                    }));
                    stopWebSocket();
                }
                else if(convert.subscriptions_expired && translate.subscriptions_expired.title){
                    dispatch(logout());
                    dispatch(alert({
                        title: translate.subscriptions_expired.title,
                        description: translate.subscriptions_expired.description,
                        button: translate.subscriptions_expired.close,
                    }));
                    stopWebSocket();
                }

                if(user.debug && convert.articles.length){
                    dispatch(setDebug(convert.articles));
                }
                dispatch(addStoreComet(convert.articles));
                if(convert.articles.length){
                    setDate(convert.articles[0].article_date);
                }
            }
        }
    }, [lastMessage]);
    
    useEffect(() => {
        if(readyState === ReadyState.UNINSTANTIATED){
            startWebSocket();
        }
        if(readyState === ReadyState.CLOSED){
            scheduleReconnect(); // Запускаем расписание при монтировании компонента
        }
        return () => {
            clearReconnectInterval(); // Очищаем интервал при размонтировании компонента
        }
    }, [scheduleReconnect, clearReconnectInterval]);

    useEffect(() => {
        return () => {
            stopWebSocket(); // Останавливаем WebSocket при размонтировании компонента
        }
    },[]);

    // console.log(connectionStatus, date);

    // useEffect(() => {
    //     if(date){
    //         dispatch(loadLossArticle(date));
    //     }
    // },[date]);

    return null
};

export default WebSocket