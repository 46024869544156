import React, {FC} from 'react';
import image1 from '../../../assets/images/faq/image1.png';
import image23 from '../../../assets/images/faq/image23.png';

export const STRUCTURE_AUTH = () => {
    return (
        <div>
            <section id="registration" name="registration">
                <h3>Регистрация</h3>
                <p>Для оформления доступа к полному функционалу Терминала и подписке на коммерческие ленты, необходимо обратиться в отдел продаж, нажав кнопку на главной странице Терминала (tr.ria.ru) “Оформить подписку”, расположенную в верхней части страницы, и далее выбрать демо-доступ с последующим коммерческим подключением выбранного продукта.</p>
                <img src={image1} width="1999" height="1373" alt="Кнопка оформления подписки на странице авторизации."/>
                <p>После заполнения формы, на указанные пользователем контакты приходит уведомление о дальнейших действиях.</p>
            </section>
            <section id="auth" name="auth">
                <h3>Авторизация</h3>
                <p>Доступ к Терминалу осуществляется путем нажатия кнопки «Авторизации» (иконка пользователя) на Главной странице, вызывающее модальное окно авторизации. </p>
                <img src={image23} width="1999" height="691" alt="Кнопка и форма для авторизации через социальный логин или данные подписчика."/>
                <p>Для доступа к Информационному мультимедийному терминалу РИА Новости необходимо заполнить поля Логин / Пароль, полученными от Вашего менеджера.</p>
                <p>Необходимо учитывать, что для одного аккаунта возможна только 1 сессия. Для доступа более чем одного пользователя, необходимо обратиться к аккаунт менеджеру, за приобретением дополнительных учетных данных для пользователей.</p>
                <p>Для запроса демо-доступа необходимо заполнить форму на странице <a href="https://xn--c1acbl2abdlkab1og.xn--p1ai/web_terminal/" target="_blank">https://россиясегодня.рф/web_terminal/</a></p>
            </section>
        </div>
    )
};