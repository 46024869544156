import React, { FC, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Icon from '../../../component/Icon'
import css from './Empty.module.scss';
import {useTranslate} from "../../../hooks/useTranslate";
import {ROUTE} from "../../../constants";
import {ICON_VARIANT} from "../../../assets/svg";
import {useAppSelector} from "../../../hooks/redux";
import {LANG} from "../../../types/IUser";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import ru_sm from '../../../assets/images/feed/ru/sm.jpg'
import ru_md from '../../../assets/images/feed/ru/md.jpg'
import ru_lg from '../../../assets/images/feed/ru/lg.jpg'

import en_sm from '../../../assets/images/feed/en/sm.jpg'
import en_md from '../../../assets/images/feed/en/md.jpg'
import en_lg from '../../../assets/images/feed/en/lg.jpg'

import es_sm from '../../../assets/images/feed/es/sm.jpg'
import es_md from '../../../assets/images/feed/es/md.jpg'
import es_lg from '../../../assets/images/feed/es/lg.jpg'

import cn_sm from '../../../assets/images/feed/cn/sm.jpg'
import cn_md from '../../../assets/images/feed/cn/md.jpg'
import cn_lg from '../../../assets/images/feed/cn/lg.jpg'

import eg_sm from '../../../assets/images/feed/eg/sm.jpg'
import eg_md from '../../../assets/images/feed/eg/md.jpg'
import eg_lg from '../../../assets/images/feed/eg/lg.jpg'

import fa_sm from '../../../assets/images/feed/fa/sm.jpg'
import fa_md from '../../../assets/images/feed/fa/md.jpg'
import fa_lg from '../../../assets/images/feed/fa/lg.jpg'

const IMAGE = {
    [LANG.RU]: {
        [SCREEN_VARIANT.MOBILE]: ru_sm,
        [SCREEN_VARIANT.TABLET]: ru_md,
        [SCREEN_VARIANT.DESKTOP]: ru_lg,
    },
    [LANG.EN]: {
        [SCREEN_VARIANT.MOBILE]: en_sm,
        [SCREEN_VARIANT.TABLET]: en_md,
        [SCREEN_VARIANT.DESKTOP]: en_lg,
    },
    [LANG.ES]: {
        [SCREEN_VARIANT.MOBILE]: es_sm,
        [SCREEN_VARIANT.TABLET]: es_md,
        [SCREEN_VARIANT.DESKTOP]: es_lg,
    },
    [LANG.CN]: {
        [SCREEN_VARIANT.MOBILE]: cn_sm,
        [SCREEN_VARIANT.TABLET]: cn_md,
        [SCREEN_VARIANT.DESKTOP]: cn_lg,
    },
    [LANG.EG]: {
        [SCREEN_VARIANT.MOBILE]: eg_sm,
        [SCREEN_VARIANT.TABLET]: eg_md,
        [SCREEN_VARIANT.DESKTOP]: eg_lg,
    },
    [LANG.FA]: {
        [SCREEN_VARIANT.MOBILE]: fa_sm,
        [SCREEN_VARIANT.TABLET]: fa_md,
        [SCREEN_VARIANT.DESKTOP]: fa_lg,
    }
}

const Empty:FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const { screen } = useAppSelector(state => state.screen);
    const translate = useTranslate();

    return (
        <div className={css.container}>
            <div className={css.text}>
                <div className={css.title}>{translate.pages.index.empty.title}</div>
                <div className={css.description}>
                    {translate.pages.index.empty.description1}
                    <Link to={ROUTE.FEED}>{translate.pages.index.empty.link} <span className={css.icon}><Icon name={ICON_VARIANT.SETTINGS}/></span></Link>
                    {translate.pages.index.empty.description2}
                </div>
            </div>
            <div className={css.image}>
                <img src={IMAGE[lang][screen]} alt=""/>
            </div>
        </div>
    )
};

export default Empty