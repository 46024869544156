import React, { FC } from 'react';
import cn from 'classnames';
import { TooltipProfile } from '../Tooltip';
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants";
import { useTranslate } from "../../hooks/useTranslate";
import { analyticsButtonAnalytic } from "../../utils/analytics";
import css from './Sidebar.module.scss';
import {PropsSidebar} from "./index";


const Analytics: FC<PropsSidebar> = ({active}) => {
    const translate = useTranslate();

    const onClick = () => {
        analyticsButtonAnalytic();
    }

    return(
        <div className={cn(css.box, {[css.active]: active})} onClick={onClick}>
            <div className={css.caption}>
                <Link to={ROUTE.ANALYTICS}>{translate.pages.analytics.title}</Link>
            </div>
        </div>
    )
};

export default Analytics