const locale_fa = {
	name: "persian_fa",
	months: [
		["فروردین", "فر"],
		["اردیبهشت", "ار"],
		["خرداد", "خرد"],
		["تیر", "تیر"],
		["مرداد", "مر"],
		["شهریور", "شه"],
		["مهر", "مه"],
		["آبان", "آبا"],
		["آذر", "آذ"],
		["دی", "دی"],
		["بهمن", "بهم"],
		["اسفند", "اسف"],
	],
	weekDays: [
		["شنبه", "شن"],
		["یکشنبه", "یک"],
		["دوشنبه", "دو"],
		["سه شنبه", "سه"],
		["چهارشنبه", "چهار"],
		["پنجشنبه", "پنج"],
		["جمعه", "جم"],
	],
	digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	meridiems: [
		["قبل از ظهر", "ق.ظ"],
		["بعد از ظهر", "ب.ظ"],
	],
};

export default locale_fa

