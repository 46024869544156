import React, { FC, useState, MouseEvent, FormEvent, useRef, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSearchParams } from "react-router-dom";
import cn from 'classnames';
import Modal from '../index';
import Icon from '../../Icon';
import Input from '../../Input/Input/Input';
import Button, {ButtonGroup, BUTTON_GROUP_ALIGN, BUTTON_VARIANT} from '../../Button';
import {ICON_VARIANT} from "../../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {login as loginAuth, registration, checkCredentials, loginSocial, SOCIAL_AUTH} from "../../../store/reducers/AuthSlice";
import { LOGIN_FIELD, setLoginField, setLoginOpen, setLoginRecoveryOpen} from '../../../store/reducers/ModalSlice';
import {OnInputChange} from "../../../types/IInput";
import {AUTH_STATUS, ILogin, THEME} from "../../../types/IUser";
import {useTranslate} from "../../../hooks/useTranslate";
import {analyticsFreeRegistration} from "../../../utils/analytics";
import css from './Login.module.scss'

const DATA: {id: number, name: string}[] = [
    {
        id: 1,
        name: 'login'
    },
    {
        id: 2,
        name: 'registration'
    }
];

const Login: FC = () => {
    const { auth, error, errorRegistration, theme } = useAppSelector(state => state.auth);
    const { isOpen, isLoading } = useAppSelector(state => state.modal.login);
    const [ loginForm, setLoginForm ] = useState({login: '', pwd: ''});
    const [ registrationForm, setRegistrationForm ] = useState({login: '', password: '', password2: ''});
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [tab,  setTab] = useState<number>(1);

    const onChange = ({name, value}: OnInputChange) => {
        setLoginForm(prev => ({...prev, ...{[name]: value}}));
    };

    const onChangeRegistration = ({name, value}: OnInputChange) => {
        setRegistrationForm(prev => ({...prev, ...{[name]: value}}));
    };

    const getToken = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
        } else {
            try {
                const token = await executeRecaptcha('load');
                return token;
            } catch (e) {
                getToken()
            }
        }
    };

    const onSubmit = (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(loginForm.login && loginForm.pwd){
            if(auth === AUTH_STATUS.LOGIN){
                dispatch(checkCredentials({
                    login: loginForm.login,
                    pwd: loginForm.pwd
                }))
            } else {
                dispatch(loginAuth({
                    login: loginForm.login,
                    pwd: loginForm.pwd
                }))
            }
        }
    };

    const onRegistration = async (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        analyticsFreeRegistration();
        const token =  await getToken();

        if(registrationForm.login && registrationForm.password && registrationForm.password2 && token){
            dispatch(registration({
                params: {
                    ...registrationForm,
                    ...{'g-recaptcha-response': token}
                },
                success: {
                    title: translate.auth.registration,
                    description: translate.auth.registrationAnnotate,
                    button: translate.auth.close
                }
            }));
        }
    };

    useEffect(() => {
        if(isOpen){
            setRegistrationForm({login: '', password: '', password2: ''});
        }
    },[isOpen]);

    return <Modal
        isOpen={isOpen}
        close={() => dispatch(setLoginOpen(false))}
    >
        <div className={css.tab}>
            {DATA.map(item =>
                <div className={cn(css.tabItem, {[css.active]: item.id === tab})} key={item.id} onClick={()=> setTab(item.id)}>
                    {item.id === 1 ? translate.auth.login : null}
                    {item.id === 2 ? translate.auth.registration : null}
                </div>
            )}
        </div>

        {tab === 1
            ? <div className={css.content}>
                    <form onSubmit={onSubmit}>
                        <Input name={LOGIN_FIELD.LOGIN} value={loginForm.login} title={translate.auth.placeholderLogin} onChange={onChange}/>
                        <Input name={LOGIN_FIELD.PWD} value={loginForm.pwd} type="password" title={translate.auth.placeholderPassword} onChange={onChange}/>
                        <button hidden/>
                        {error === AUTH_STATUS.AUTH_ERROR
                            ? <div className={css.error}>{translate.auth.error}</div>
                            : null
                        }
                        {error === AUTH_STATUS.ANOTHER_SESSION
                            ? <div className={css.error}>{translate.auth.anotherSession}</div>
                            : null
                        }
                        {error === AUTH_STATUS.NO_WEB_SUBSCRIPTION && theme === THEME.RIA
                            ? <div className={css.error} dangerouslySetInnerHTML={{__html: translate.auth.errorNoWEb}}/>
                            : null
                        }
                        {error === AUTH_STATUS.NO_WEB_SUBSCRIPTION && theme === THEME.SPUNTIK
                            ? <div className={css.error} dangerouslySetInnerHTML={{__html: translate.auth.errorNoWEb2}}/>
                            : null
                        }
                        <div className={css.recovery} onClick={() => {dispatch(setLoginOpen(false)); dispatch(setLoginRecoveryOpen(true))}}>{translate.auth.recover}</div>
                    </form>
                    <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                        {/*<Button variant={BUTTON_VARIANT.TEXT} onClick={(e) => e.preventDefault()}>{translate.auth.recover}</Button>*/}
                        <Button onClick={onSubmit} disabled={!Boolean(loginForm.login && loginForm.pwd)} isLoading={isLoading}>{translate.auth.login}</Button>
                    </ButtonGroup>
                </div>
            : <>
                <div className={css.content}>
                    <form onSubmit={onRegistration}>
                        <Input name={'login'} value={registrationForm.login} title={translate.auth.email} onChange={onChangeRegistration}/>
                        <Input name={'password'} value={registrationForm.password} type="password" title={translate.auth.placeholderPassword} onChange={onChangeRegistration}/>
                        <Input name={'password2'} value={registrationForm.password2} type="password" title={translate.auth.placeholderPassword2} onChange={onChangeRegistration}/>
                        {errorRegistration
                            ? <div className={css.error}>
                                {errorRegistration === 'Login is not email' ? translate.auth.error1 : null}
                                {errorRegistration === 'Passwords are not the same' ? translate.auth.error2 : null}
                                {errorRegistration === 'Captcha check failed' ? translate.auth.error3 : null}
                                {errorRegistration === 'Login already exists' ? translate.auth.error5 : null}
                            </div>
                            : null
                        }
                    </form>
                    <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                        <Button onClick={onRegistration} disabled={!Boolean(registrationForm.login && registrationForm.password && registrationForm.password2)} isLoading={isLoading}>{translate.auth.registration}</Button>
                    </ButtonGroup>
                </div>
            </>
        }

        <div className={css.footer}>
            <div className={css.beetwen}>
                <div className={css.socialTitle}>{translate.auth.loginSocial}</div>
                <ButtonGroup>
                    <Button variant={BUTTON_VARIANT.TEXT} width={'32px'} padding={'0'} onClick={() => dispatch(loginSocial(SOCIAL_AUTH.VK))}>
                        <div className={cn(css.social, css.vk)}>
                            <Icon name={ICON_VARIANT.VK}/>
                        </div>
                    </Button>
                    {/*<Button width={'32px'} padding={'0'} onClick={() => dispatch(loginSocial(SOCIAL_AUTH.VK))}>*/}
                    {/*<CSSModalSocialFacebook>*/}
                    {/*<Icon name={ICON_VARIANT.FACEBOOK}/>*/}
                    {/*</CSSModalSocialFacebook>*/}
                    {/*</Button>*/}
                    <Button variant={BUTTON_VARIANT.TEXT} width={'32px'} padding={'0'} onClick={() => dispatch(loginSocial(SOCIAL_AUTH.OK))}>
                        <div className={css.social}>
                            <Icon name={ICON_VARIANT.OK}/>
                        </div>
                    </Button>
                    {/*<Button variant={BUTTON_VARIANT.TEXT} width={'32px'} padding={'0'} onClick={() => dispatch(loginSocial(SOCIAL_AUTH.APPLE))}>*/}
                    {/*    <div className={css.social}>*/}
                    {/*        <Icon name={ICON_VARIANT.APPLE}/>*/}
                    {/*    </div>*/}
                    {/*</Button>*/}
                    {/*<Button variant={BUTTON_VARIANT.TEXT} width={'32px'} padding={'0'} onClick={() => dispatch(loginSocial(SOCIAL_AUTH.GOOGLE))}>*/}
                    {/*    <div className={css.social}>*/}
                    {/*        <Icon name={ICON_VARIANT.GOOGLE}/>*/}
                    {/*    </div>*/}
                    {/*</Button>*/}
                </ButtonGroup>
            </div>
            <div className={css.text} dangerouslySetInnerHTML={{__html: translate.auth.annotate}}/>
        </div>
    </Modal>
};

export default Login