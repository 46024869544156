import React, { FC } from 'react';
import ArticleMedia from '../../Article/ArticleMedia'
import ArticleKeywords from '../../Article/ArticleKeywords';
import ListMenu from '../ListItem/ListMenu';
import ListShare from '../ListItem/ListShare';
import {containsSids, dateListFormat} from "../../../utils/utils";
import {IArticle} from "../../../types/IArticle";
import css from './ListItemPreview.module.scss'
import {SID_EXCLUSIVE} from "../../../constants";
import {useTranslate} from "../../../hooks/useTranslate";

interface Props {
    article: IArticle,
    onClick?: () => void
}

const ListItemPreview: FC<Props>= ({article, onClick}) => {
    const translate = useTranslate();
    // const { article, isLoading } = useAppSelector(state => state.article);

    if(!article){
        return null
    }

    const {article_date, article_title, article_body, medias, article_keywords} = article;

    return (
	    <div className={css.preview}>
		    <div className={css.header}>
			    <div className={css.date}>{dateListFormat(article_date)}</div>
			    <div className={css.info}>
				    <div className={css.title} onClick={onClick}>
                        <span dangerouslySetInnerHTML={{ __html: article_title}} onClick={onClick}/>
                        {containsSids([article], [SID_EXCLUSIVE]).length ? <div className={css.exclusive}>{translate.list.exclusive}</div> : null}
                    </div>
                    <div>
                        <div className={css.controls}>
                            <ListMenu article={article}/>
                            <ListShare article={article}/>
                        </div>
                    </div>
			    </div>
		    </div>

		    <div className={css.body}>
                <ArticleKeywords keywords={article.article_keywords}/>
                {medias?.length ? <ArticleMedia media={medias}/> : null}
                <div className={css.text} dangerouslySetInnerHTML={{ __html: article_body }}/>
		    </div>
	    </div>
        
    )
};

export default ListItemPreview