import React, { FC, useState, MouseEvent, FormEvent, useEffect, useRef } from 'react';
import Dropdown, { DropdownItem, DropDownHeader } from '../../../../component/Dropdown';
import Input from "../../../../component/Input/Input/Input";
import TextArea from "../../../../component/Input/TextArea/TextArea";
import {Row} from "../../../../component/Input/Form";
import {ICON_VARIANT} from "../../../../assets/svg";
import Button, {BUTTON_GROUP_ALIGN, BUTTON_VARIANT, ButtonGroup} from "../../../../component/Button";
import {INPUT_VARINAT, OnInputChange} from "../../../../types/IInput";
import {createNewsletter, deleteNewsletter, updateNewsletter} from "../../../../store/reducers/AuthSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {IMailingList} from "../../../../types/IUser";
import {useTranslate} from "../../../../hooks/useTranslate";
import {DROPDOWN_ITEM_VARIANT} from "../../../../component/Dropdown/DropdownItem";
import {DROPDOWN_VARIANT} from "../../../../component/Dropdown/DropdownMenu";
import {DROPDOWN_HEADER_SIZE} from "../../../../component/Dropdown/DropdownHeader";
import {FORM_ROW_JUSTIFY} from "../../../../component/Input/Form";

interface Props {
    callback?: (arg: IMailingList) => void
}

const initialState: Omit<IMailingList, 'type'> = {
    id: '',
    name: '',
    value: {
        list: [],
        description: ''
    }
};

const ExportForm: FC<Props> = ({callback}) => {
    const {isLoading, mailing_lists} = useAppSelector(state => state.auth);
    const [mail, setMail] = useState<Omit<IMailingList, 'type'>>(initialState);
    const filterMailList = mailing_lists || [];
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onSelectMail = (item: IMailingList) => {
        setMail(item);
    };

    const onChangeName = ({name, value}: OnInputChange) => {
        setMail({...mail, ...{[name]: value}})
    };
    const onChangeList = ({name, value}: OnInputChange) => {
        const list = value.split(',').map(item => item.trim()).filter(item => item);
        if((value[value.length - 1] === ' ' || value[value.length - 1] === ',') && mail.value.list.join(', ').length < value.length){
            list.push('')
        }
        setMail({...mail, ...{value: {...mail.value, ...{[name]: list}}}})
    };

    const onChangeDescription = ({name, value}: OnInputChange) => {
        setMail({...mail, ...{value: {...mail.value, ...{[name]: value}}}})
    };

    const onClean = (e: MouseEvent) => {
        e.stopPropagation();
        setMail(initialState);
    };

    const onClickHeaderDropdown = (e: MouseEvent) => {
        // if(Boolean(mail.id)){
        //     e.stopPropagation();
        // }
    };

    const remove = async () => {
        const action: any = await dispatch(deleteNewsletter(mail));
        if(action?.payload?.data.deleted_records > 0){
            setMail(initialState);
        }
    };

    const create = async () => {
        const action: any = await dispatch(createNewsletter(mail));
        if(action?.payload?.id) {
            setMail(action?.payload);
            callback && callback(action?.payload);
        }
    };

    const update = async () => {
        const action: any = await dispatch(updateNewsletter(mail));
        if(action?.payload?.id) {
            setMail(action?.payload);
            callback && callback(action?.payload);
        }
    };

    return (
        <>
            <Row>
                <Dropdown
                    variant={DROPDOWN_VARIANT.WHITE}
                    header={
                        <>
                            <DropDownHeader
                                value={mail.name}
                                title={translate.pages.export.mailList}
                                // tooltip={translate.tooltip.export.mailList}
                                size={DROPDOWN_HEADER_SIZE.LARGE}
                            />
                        </>
                    }
                >
                    {filterMailList.map(item =>
                        <DropdownItem
                            key={item.id}
                            active={item.id === mail.id}
                            varinat={DROPDOWN_ITEM_VARIANT.WHITE}
                            icon={item.id === mail.id ? ICON_VARIANT.CHECK : null}
                            onClick={() => onSelectMail(item)}>
                            {item.name}
                        </DropdownItem>
                    )}
                </Dropdown>
            </Row>
            <Row>
                <Input
                    variant={INPUT_VARINAT.GREY}
                    name={'name'}
                    value={mail.name}
                    title={translate.pages.export.mailName}
                    tooltip={translate.tooltip.export.mailName}
                    onChange={onChangeName}/>
            </Row>
            <Row>
                <Input
                    variant={INPUT_VARINAT.GREY}
                    name={'list'}
                    value={mail.value.list.join(', ')}
                    title={translate.pages.export.mailAddress}
                    tooltip={translate.tooltip.export.mailAddress}
                    onChange={onChangeList}/>
            </Row>
            <Row>
                <TextArea
                    variant={INPUT_VARINAT.GREY}
                    name={'description'}
                    value={mail.value.description}
                    title={translate.pages.export.mailAnnounce}
                    tooltip={translate.tooltip.export.mailAnnounce}
                    onChange={onChangeDescription}/>
            </Row>
            <Row>
                 <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                     {Boolean(mail.id)
                         ? <>
                             <Button
                                 variant={BUTTON_VARIANT.DELETE}
                                 height={'40px'}
                                 onClick={remove}
                                 disabled={!Boolean(mail.id)}>
                                 {translate.pages.export.delete}
                             </Button>
                             <Button
                                 isLoading={isLoading}
                                 height={'40px'}
                                 onClick={update}>
                                 {translate.pages.export.update}
                             </Button>
                         </>
                         : <Button
                             isLoading={isLoading}
                             height={'40px'}
                             onClick={create}>
                             {translate.pages.export.send}
                         </Button>
                     }
                 </ButtonGroup>
            </Row>
        </>
    )
};

export default ExportForm;