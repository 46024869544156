import React, { FC, useState, MouseEvent, FormEvent } from 'react';
import Input from '../../../component/Input/Input/Input';
import TextArea from '../../../component/Input/TextArea/TextArea';
import Checkbox  from '../../../component/Input/Checkbox/Checkbox';
import Button from '../../../component/Button';
import Modal from '../index';
import { OnCheckboxChange, OnInputChange } from "../../../types/IInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { closeDemoModal } from '../../../store/reducers/AuthSlice';
import {useTranslate} from "../../../hooks/useTranslate";
import css from '../Modal.module.scss';

const initialState = {
    menu: '',
    organization: '',
    email: '',
    phone: '',
    position: '',
    lenta: '',
    agree: false
};


const DemoAuth: FC = () => {
    const [form, setForm] = useState(initialState);
    const { isOpenDemo } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onChange = ({name, value}: OnInputChange | OnCheckboxChange) => {
        setForm(state => ({...state, ...{[name]: value}}));
    };

    const onClick = (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    };

    return(
        <Modal
            isOpen={isOpenDemo}
            close={() => dispatch(closeDemoModal())}
            title={'Демо-доступ'}
        >
            <div className={css.content}>
                <Input name={'menu'} title={translate.auth.menu} value={form.menu} onChange={onChange}/>
                <Input name={'organization'} title={translate.auth.organization} value={form.organization} onChange={onChange}/>
                <Input name={'email'} title={translate.auth.email} value={form.email} onChange={onChange}/>
                <Input name={'phone'} title={translate.auth.phone} value={form.phone} onChange={onChange}/>
                <Input name={'position'} title={translate.auth.position} value={form.position} onChange={onChange}/>
                <TextArea name={'lenta'} title={translate.auth.lenta} value={form.lenta} onChange={onChange}/>
                <div className={css.demo}>
                    <Checkbox name={'agree'} title={translate.auth.agree} value={form.agree} onChange={onChange}/>
                    <Button onClick={onClick}>{translate.auth.send}</Button>
                </div>
            </div>
        </Modal>
    )
};

export default DemoAuth;