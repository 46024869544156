import {useCallback, useRef} from 'react';
import {useAppSelector} from "./redux";
import {LANG} from "../types/IUser";
import {COLOR, COLOR_SPUTNIK} from "../types/IColor";

export const useGetColor = () => {
    const { lang } = useAppSelector(state => state.auth);

    if(lang === LANG.RU) {
        return COLOR
    } else{
        return COLOR_SPUTNIK
    }
};