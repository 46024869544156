import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from 'query-string';
import axios from "axios";
import {IArticle} from "../../types/IArticle";
import {TYPE_PREFERENCES, URL} from "../../constants";
import {addStoreArticles} from "./StoreSlice";

interface IArticleSlice {
    isLoading: boolean,
    articleId: number | null,
    article: IArticle | null
}

interface IGetArticle {
    id: number,
    query?: string | null | undefined
}

const initialState: IArticleSlice = {
    isLoading: false,
    articleId: null,
    article: null
};


export const getArticle = createAsyncThunk(
    'article/getArticle',
    async (params: IGetArticle, { rejectWithValue, dispatch, getState }) => {
        try {
            const {id, query } = params;

            dispatch(setId(id));
            const response = await axios.post(URL.GET_ARTICLE, qs.stringify({
                type: TYPE_PREFERENCES.QUERY,
                id: id,
                query: query
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            const article = response?.data?.data?.paginationSolrArticles?.articles[0]

            if(article){
                dispatch(addStoreArticles([article]))
            }
            return article
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        setId(state, action: PayloadAction<number>){
            state.articleId = action.payload;
        },
        unloadArticle(state){
            state.isLoading = false;
            state.article = null;
        }
    },
    extraReducers: {
        [getArticle.pending.type]: (state: IArticleSlice) => {
            state.isLoading = true;
            state.article = null;
        },
        [getArticle.fulfilled.type]: (state: IArticleSlice, action: PayloadAction<IArticle>) => {
            const acticle = action.payload;

            state.isLoading = false;
            state.articleId = acticle?.article_id || null;
            state.article = acticle || null
        },
    }
});

export const { unloadArticle, setId } = articleSlice.actions;
export default articleSlice.reducer;