import React, { FC  } from 'react';
import {useTranslate} from "../../../hooks/useTranslate";
import css from './HistoryEmpty.module.scss';


const HistoryHeader: FC = () => {
    const translate = useTranslate();

    return(
        <div className={css.empty}>
            {translate.history.empty}
        </div>
    )
};

export default HistoryHeader;