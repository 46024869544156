import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL, TYPE_PREFERENCES } from "../../constants";
import { setGroups, addQueris } from './AuthSlice';
import axios from "axios";

interface Props {
    isLoading: boolean,
}

const initialState: Props = {
    isLoading: false
};

export const setSidGroup = createAsyncThunk(
    'sid/setSidGroup',
    async (data: string[], { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.GROUP,
                name: TYPE_PREFERENCES.GROUP,
                value: data
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            const result = response.data.setPreferences.result;
            dispatch(setGroups(result));
            return result
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const sidSlice = createSlice({
    name: 'sid',
    initialState,
    reducers: {
    },
    extraReducers: {
        [setSidGroup.pending.type]: (state: Props) => {
            state.isLoading = true;
        },
        [setSidGroup.fulfilled.type]: (state: Props) => {
            state.isLoading = false;
        },
    }
});

export const {} = sidSlice.actions;
export default sidSlice.reducer;

