import React, { FC, useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content'
import {analyticsBanner} from "../../utils/analytics";
import {useGetBanner} from "../../hooks/useGetBanner";
import {useUserPremium} from "../../hooks/useUserPremium";

const BannerSidebar: FC = () => {
    const banner = useGetBanner();
    const premium = useUserPremium();

    const onClick = () => {
        analyticsBanner();
    };

    return (
        <div style={{marginBottom:'4px', overflow: 'hidden', maxWidth: '100%'}} onClick={onClick}>
             {premium
                ? <InnerHTML html={banner.BANNER_SIDEBARE_GS3} />
                : <InnerHTML html={banner.BANNER_SIDEBARE_GS2} />
             }
        </div>
    )
};

export default BannerSidebar;