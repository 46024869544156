import {useCallback, useRef} from 'react';
import {useAppSelector} from "./redux";
import {ISid, LANG} from "../types/IUser";
import {unique} from "../utils/utils";

const LANG_SIDS = {
  [LANG.RU]: 'rus',
  [LANG.EN]: 'eng',
  [LANG.ES]: 'spa',
  [LANG.CN]: 'zho',
  [LANG.FA]: 'fas',
  [LANG.EG]: 'ara'
};

export function useUserSids():ISid[]{
    const { list, groups, lang } = useAppSelector(state => state.auth);
    const value = groups?.length ? groups[0].value : [];
    const langSids = LANG_SIDS[lang];

    if(list.length) {
        if (value.length && value.map(sid => list.find(item => item.sid === sid && item.is_active)).filter(e => e).length) {
            return (value.map(sid => list.find(item => item.sid === sid && item.is_active)) as ISid[]).filter(e => e);
        } else {
            return list.filter(item => item.is_active && item.lang === langSids);
        }
    } else {
        return []
    }
}

export function useUserAllSids():ISid[] {
    const { list } = useAppSelector(state => state.auth);
    const userSids = useUserSids();

    if(list.length) {
        return unique([...userSids, ...list.filter(item => item.is_active)],'id')
    }

    return []
}