import React, { FC } from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import {SUBSCRIBE} from "../../constants";
import {useTranslate} from "../../hooks/useTranslate";
import {ICON_VARIANT} from "../../assets/svg";
import css from './Sidebar.module.scss';
import {useAppSelector} from "../../hooks/redux";
import {THEME} from "../../types/IUser";

const Subscribe: FC = () => {
    const { lang, theme } = useAppSelector(state => state.auth);
    const translate = useTranslate();
    const link = SUBSCRIBE[lang].link;

    return (
        <a className={cn(css.box, css.subscribe, css[theme])} href={link} target="_blank" rel="noreferrer">
            <span>{translate.header.subcribe}</span>
            <span className={css.subscribeIcon}>
                <Icon name={theme === THEME.SPUNTIK ? ICON_VARIANT.SUBSCRIBE : ICON_VARIANT.SUBSCRIBE}/>
            </span>
        </a>
    )
};

export default Subscribe;