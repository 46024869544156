import React, { FC } from 'react';
import DropdownMenu, {PropsDropDown} from './DropdownMenu'
import { DropdownProvider, DropdownContext } from './Provider'

const Dropdown:FC<PropsDropDown> = (props) => {
    return(
        <DropdownProvider>
            <DropdownMenu {...props}>
                {props.children}
            </DropdownMenu>
        </DropdownProvider>
    )
};

export default Dropdown