import React, { FC  } from 'react';
import cn from 'classnames';
import Icon from '../../Icon';
import Dropdown, { DropdownItem } from '../../Dropdown';
import Input from '../../Input/Input/Input';
import {ICON_VARIANT} from "../../../assets/svg";
import {pagination} from "../../../utils/utils";
import {useTranslate} from "../../../hooks/useTranslate";
import {INPUT_VARINAT} from "../../../types/IInput";
import {DROPDOWN_VARIANT} from "../../Dropdown/DropdownMenu";
import css from './HistoryPagination.module.scss';

interface Props {
    onpage: number
    total: number
    page: number
    onPage: (offset: number) => void
    onLimit: (limit: number) => void
}

const LIMIT = [5, 20, 50, 100];

const HistoryPagination: FC<Props> = ({onpage, page, total, onPage, onLimit}) => {
    const maxOffset = (Math.ceil(total/onpage) - 1) * onpage || 0;
    const maxPage = Math.ceil(total/onpage) - 1 || 0;
    const pag = pagination(page, maxPage, 1);
    const translate = useTranslate();

    return(
        <div className={css.pagination}>
            <div className={css.controls}>
                <div className={css.button} onClick={() => onPage(0)}>
                    <Icon name={ICON_VARIANT.ARROW_DOUBLE_LEFT}/>
                </div>
                <div className={css.button} onClick={() => onPage(page ? (page - 1) * onpage : 0)}>
                    <Icon name={ICON_VARIANT.ARROW_LEFT}/>
                </div>
                {pag.map((item, i) =>
                    item === '...'
                        ? <div className={cn(css.link, {[css.active]: false, [css.pointer]: false})} key={i}>
                            {item}
                        </div>
                        : <div className={cn(css.link, {[css.active]: item === page, [css.pointer]: true})} key={i} onClick={() => onPage((item as number) * onpage)}>
                            {item}
                        </div>
                )}
                <div className={css.button} onClick={() => onPage(page === maxPage || maxPage === 0 ? maxOffset : (page + 1) * onpage)}>
                    <Icon name={ICON_VARIANT.ARROW_RIGHT}/>
                </div>
                <div className={css.button} onClick={() => onPage(maxOffset)}>
                    <Icon name={ICON_VARIANT.ARROW_DOUBLE_RIGHT}/>
                </div>
            </div>
            <div className={css.limit}>
                <div className={css.text}>{translate.history.limit1}</div>
                <div className={css.field}>
                    <Dropdown
                        variant={DROPDOWN_VARIANT.WHITE}
                        header={
                            <div className={css.header}>
                                <Input
                                    variant={INPUT_VARINAT.GREY}
                                    value={onpage.toString()}
                                    readonly={true}
                                    onChange={() => {}}/>
                                <div className={css.arrow}><Icon name={ICON_VARIANT.ARROW_DOWN}/></div>
                            </div>
                        }
                    >
                        {LIMIT.map(limit =>
                            <DropdownItem key={limit} onClick={() => onLimit(limit)} active={Boolean(limit === onpage)}>
                                {limit}
                            </DropdownItem>
                        )}
                    </Dropdown>
                </div>
                <div className={css.text}>{translate.history.limit2}</div>
            </div>
        </div>
    )
};

export default HistoryPagination;