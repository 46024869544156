import React, { FC, useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content'
import {analyticsBanner} from "../../utils/analytics";
import {useGetBanner} from "../../hooks/useGetBanner";

interface Props {
    id: number
}

const BannerHeader: FC<Props> = ({id}) => {
    const banner = useGetBanner();

    const onClick = () => {
        analyticsBanner();
    };

    return (
        <div style={{marginBottom:'4px', overflow: 'hidden', maxWidth: '100%'}} onClick={onClick}>
            <InnerHTML html={banner.BANNER_FEED?.replaceAll('bannerId', id.toString())} />
        </div>
    )
};

export default BannerHeader;