import React, { FC, useRef, useState, useEffect, KeyboardEvent} from 'react';
import moment from 'moment';
import cn from 'classnames';
import Scrollbar, {IScrollBarUpdate} from '../Scrollbar';
import Spinner from "../Spinner";
import Title, {ITitleMenu} from '../Title';
import Warning from '../Warning';
import ListItem, {LIST_VARIANT} from './ListItem';
import ListMain from './ListMain';
import ListEmpty from './ListEmpty';
import ListBar from './ListBar/index';
import ListCount from './ListCount';
import Empty from './Empty';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useGetArticles, useGetPinndedIds, useGetPinned} from "../../hooks/useGetArticles";
import {IArticle} from "../../types/IArticle";
import {arrayExcludeIds, diff, getUnique, sortedArticles} from "../../utils/utils";
import {useUserSettings} from "../../hooks/useUserSettings";
import {OnCheckboxChange} from "../../types/IInput";
import {
    IListFilterSlice,
    init,
    setChecked,
    setParams,
    setCheckedIds,
    setPin,
    getMain,
    getList,
    loadMore,
    getPin,
    destroy,
    addShowIds,
    LIST_NAME,
    initialState
} from '../../store/reducers/LSlice';
import {OnChangeDatePicker} from "../DatePicker";
import {useGetFavoriteIds} from "../../hooks/useGetFavorite";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {alert} from "../../store/reducers/AlertSlice";
import {useUserPremium} from "../../hooks/useUserPremium";
import {useTranslate} from "../../hooks/useTranslate";
import {analyticsBreaking, analyticsPin} from "../../utils/analytics";
import css from './List.module.scss';
import {LIST_FIELDS_DIFF, SIDS} from "../../constants";
import {LANG} from "../../types/IUser";

interface Props {
    tour?: string,

    name: LIST_NAME,
    filterParams?: IListFilterSlice | null,
    withAuth?: boolean,
    variant?: LIST_VARIANT,
    preview?: boolean,
    priority?: boolean,
    onArticle: (article: IArticle) => void,

    title?: string,
    backScreen?: SCREEN_VARIANT[],
    titleMenu?: ITitleMenu[] | [],
    menu?: ITitleMenu[]

    main?: boolean
    pined?: boolean
    isCount?: boolean
    isChecked?: boolean
    isBreaking?: boolean
    isWarning?: boolean
    datepicker?: boolean
    keyboard?: boolean

    bannerBefore?: React.ReactNode | null,
    banner?: any | null,
}

const COUNT_BANNER_PLACE = 10;

const List: FC<Props> = ({tour, name, filterParams = null, withAuth = true, variant = LIST_VARIANT.STANDART, isCount = false, main = false, pined = false, isChecked = false, isBreaking, preview = false, priority = false, datepicker = true, keyboard = false, isWarning = false, onArticle, title = '', titleMenu = [], backScreen = [], menu = [], bannerBefore = null, banner = null}) => {
    const { isLoading, isLoadingMain,  articlesIds, mainIds, filter, checked, showIds, resultsCount} = useAppSelector(state => state.l[name]);
    const { screen } = useAppSelector(state => state.screen);
    const { lang } = useAppSelector(state => state.auth);
    const { articleId, isLoading: isLoadingArticle } = useAppSelector(state => state.article);


    const dispatch = useAppDispatch();
    const articlesMain = useGetArticles(main ? mainIds : []);
    const sortMain = sortedArticles(articlesMain);

    const articles = useGetArticles(arrayExcludeIds(articlesIds, main ? mainIds : []));
    const list = sortedArticles(articles);
    const favoriteIds =  useGetFavoriteIds() ;

    const pinedIds = useGetPinndedIds();
    const pinArticles = useGetPinned({name: name, ids: pined ? pinedIds : []});

    const sortPinnded = sortedArticles(pinArticles);
    const { lightingFavoriteColor, lightingFavorite } = useUserSettings();
    const premium = useUserPremium();
    const translate = useTranslate();
    const { scrollFixed } = useUserSettings();
    const refObserver = useRef<IntersectionObserver | null>(null);
    const refObserverMore = useRef<IntersectionObserver | null>(null);
    const refMore = useRef<HTMLDivElement | null>(null);
    const refTitle = useRef<HTMLDivElement | null>(null);

    const diffMainDate = moment(articles[0]?.article_date, 'YYYY-MM-DD').diff(moment(articlesMain[0]?.article_date, 'YYYY-MM-DD'), 'days');
    const Banner = banner;

    const hasWarning = Boolean(isWarning && lang === LANG.RU && (!filter.ids?.length && !filter.id) && diff(initialState[name].filter, filter, LIST_FIELDS_DIFF).length);

    const onCheckedAll = ({ ids, value } : {ids: number[], value: boolean}) => {
        dispatch(setCheckedIds({
            name: name,
            ids: getUnique(ids),
            value: value
        }));
    };

    const onChangeDate = ({startDate, endDate, startTime, endTime, label}: OnChangeDatePicker) => {
        dispatch(setParams({
            name: name,
            params: {
                date_start: startDate,
                date_end: endDate,
                startTime: startTime,
                endTime: endTime,
                label: label
            }
        }));
        dispatch(getList(name));
    };

    const onChecked = ({name: id}: OnCheckboxChange) => {
        dispatch(setChecked({
            name: name,
            id: parseInt(id)
        }));
    };

    const onPined = (article: IArticle) => {
        const id = article.article_id;
        const ids: number[] = pinedIds.includes(id)
            ? pinedIds.filter(i => i !== id)
            : pinedIds.concat(id);

        dispatch(setPin(ids));

        analyticsPin();
    };

    const onClickBreaking = () => {
        if(premium){
            dispatch(setParams({
                name: name,
                params: {
                    priority: filter.priority.includes(1) && filter.priority.includes(2) ? [] : [1, 2]
                }
            }));
            dispatch(getList(name));
        } else{
            dispatch(alert({
                title: translate.list.subscribe,
                description:translate.list.subscrieBreaking,
                button: ''
            }))
        }

        analyticsBreaking();
    };

    const onScrollEnd = () => {

        if(articles?.length > filter.offset && !isLoading && !filter.ids?.length){
            dispatch(loadMore({
                name: name,
                params: {
                    offset: articles?.length || 0
                }
            }))
        }
    };

    const createObserver = () => {
        refObserver.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const isShow = entry.target?.getAttribute('data-show');
                        const id = entry.target?.getAttribute('data-id');

                        if(id && isShow === 'false'){
                            dispatch(addShowIds({
                                name: name,
                                id: parseInt(id)
                            }));
                        }
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        const sections = document.querySelectorAll("div[data-target='observer'][data-show='false']");
        sections?.forEach((section) => refObserver?.current?.observe(section));
    };

    const destroyObserver = () => {
        if (refObserver.current) {
            refObserver.current.disconnect()
        }
    };

    const createObserverMore = () => {
        refObserverMore.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isLoading) {
                    onScrollEnd();
                }
            },
            {
                rootMargin: '-20px',
                threshold: 0.1,
            }
        );
    };

    const destroyObserverMore = () => {
        if (refObserverMore.current) {
            refObserverMore.current.disconnect()
        }
    };

    useEffect(() => {
        if(filterParams) {
            dispatch(init({
                name: name,
                filter: filterParams,
                withAuth: withAuth
            }));
        }

        dispatch(getList(name));

        if(main){
            dispatch(getMain({
                name: name,
                params: {
                    sid_list: [SIDS[lang].MEDIA],
                    offset: 0,
                    limit: 1
                }
            }));
        }

        if(pined){
            dispatch(getPin());
        }
    },[]);

    useEffect(() => {
        if(articlesIds.length){
            destroyObserver();
            createObserver();
        }
        if(refMore.current && [SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET].includes(screen)){
            destroyObserverMore();
            createObserverMore();

            if(refObserverMore?.current && refMore?.current){
                refObserverMore?.current?.observe(refMore.current);
            }
        }
    },[articlesIds.length]);

    useEffect(() => {
        return () => {
            destroyObserverMore();
            destroyObserver();
            dispatch(destroy(name));
        }
    },[]);

    useEffect(() => {
        const onKeydown: any = (e: KeyboardEvent) => {
            if(isLoadingArticle){
                return
            }
            const index = articlesIds.indexOf(articleId || 0);
            const prevIndex = index - 1 > 0 ? index - 1 : 0;
            const nextIndex = index + 1 > articlesIds.length ? articlesIds.length : index + 1;

            if (e.keyCode === 38) { //prev
                e.preventDefault();
                onArticle(articles[prevIndex])
            } else if(e.keyCode === 40){ //next
                e.preventDefault();
                onArticle(articles[nextIndex])
            }

            if((articlesIds.length - 1) === nextIndex){
                dispatch(loadMore({
                    name: name,
                    params: {
                        offset: articles?.length || 0
                    }
                }))
            }
        };

        if(keyboard) {
            document.addEventListener('keydown', onKeydown);
        }
        return () => {
            document.removeEventListener('keydown', onKeydown);
        };
    }, [keyboard, articlesIds, articleId, isLoadingArticle]);


    if(!filter.sid_list.length && !filter.ids.length && !filter.id) {
        return (
            <Empty/>
        )
    }

    return (
        <div className={css.list} data-tour={tour}>
            <Scrollbar scrollFixed={scrollFixed}
                       isUp={true}
                       countUnread={arrayExcludeIds(articlesIds, showIds).length}
                       onScrollEnd={onScrollEnd}
                       screenVariant={[SCREEN_VARIANT.DESKTOP]}>
                <div className={css.title} data-tour={`${tour}-title`} ref={refTitle}>
                    {Boolean(title)
                        ? <Title
                            title={title}
                            titleMenu={titleMenu}
                            menu={menu}
                            backScreen={backScreen}
                            listVariant={variant}
                            isBreaking={isBreaking ? Boolean(filter.priority.includes(1) && filter.priority.includes(2)) : null}
                            onClickFlash={onClickBreaking}/>
                        : null
                    }
                    {isCount
                        ? <ListCount
                            filter={filter}
                            hasTitle={Boolean(title)}
                            resultsCount={resultsCount}
                        />
                        : null
                    }
                </div>
                {(isLoading || isLoadingMain) && !articles.length
                    ? <Spinner/>
                    : <>
                        {hasWarning
                            ? <div className={css.inner}><Warning/></div>
                            : null
                        }
                        {bannerBefore
                            ? <div className={css.inner}>{bannerBefore}</div>
                            : null
                        }
                        {diffMainDate !== 0 && (Object.keys(sortMain) as Array<keyof typeof sortMain>).reverse().map(day =>
                            <div className={css.inner} key={day}>
                                <ListBar
                                    name={name}
                                    top={refTitle.current?.getBoundingClientRect().height || 0}
                                    key={day}
                                    day={day}
                                    ids={sortMain[day].map(article => article.article_id)}
                                    isChecked={isChecked}
                                    onCheckedAll={onCheckedAll}
                                />
                                {(sortMain[day] as IArticle[]).reverse().map(item =>
                                    <ListMain
                                        key={item.article_id}
                                        articleId={item.article_id}
                                        pined={pinedIds.includes(item.article_id)}
                                        checked={checked.includes(item.article_id)}
                                        backgoundFavorite={favoriteIds.includes(item.article_id) && lightingFavorite ? lightingFavoriteColor : null}
                                        variant={variant}
                                        onChecked={onChecked}
                                        onPin={onPined}
                                        onClick={onArticle}
                                        preview={preview}
                                        priority={priority}
                                        isChecked={isChecked}
                                    />
                                )}
                            </div>
                        )}

                        {(Object.keys(sortPinnded) as Array<keyof typeof sortPinnded>).reverse().map(day =>
                            <div className={css.inner} key={day}>
                                <ListBar
                                    name={name}
                                    top={refTitle.current?.getBoundingClientRect().height || 0}
                                    key={day}
                                    day={day}
                                    ids={sortPinnded[day].map(article => article.article_id)}
                                    isChecked={isChecked}
                                    onCheckedAll={onCheckedAll}
                                />
                                {(sortPinnded[day] as IArticle[]).reverse().map(item =>
                                    <ListItem
                                        key={item.article_id}
                                        articleId={item.article_id}
                                        pined={pinedIds.includes(item.article_id)}
                                        checked={checked.includes(item.article_id)}
                                        backgoundFavorite={favoriteIds.includes(item.article_id) && lightingFavorite ? lightingFavoriteColor : null}
                                        variant={variant}
                                        onChecked={onChecked}
                                        onPin={onPined}
                                        onClick={onArticle}
                                        preview={preview}
                                        priority={priority}
                                        isChecked={isChecked}
                                    />
                                )}
                            </div>
                        )}

                        {!resultsCount && !articles.length
                            ? <div className={css.inner}>
                                {/*<ListCount*/}
                                    {/*filter={filter}*/}
                                    {/*hasTitle={Boolean(title)}*/}
                                    {/*resultsCount={resultsCount}*/}
                                {/*/>*/}
                                <ListBar
                                    name={name}
                                    top={refTitle.current?.getBoundingClientRect().height || 0}
                                    day={filter.date_end}
                                    isChecked={isChecked}
                                    ids={[]}
                                    datepicker={datepicker && !filter.id && !filter.name}
                                    onChangeDate={onChangeDate}
                                />
                                <ListEmpty/>
                            </div>
                            : null
                        }



                        {(Object.keys(list) as Array<keyof typeof list>).reverse().map((day, i) =>
                            <div className={css.inner} key={day}>
                                {/*{isCount && i === 0*/}
                                    {/*? <ListCount*/}
                                        {/*filter={filter}*/}
                                        {/*hasTitle={Boolean(title)}*/}
                                        {/*resultsCount={resultsCount}*/}
                                    {/*/>*/}
                                    {/*: null*/}
                                {/*}*/}
                                <ListBar
                                    name={name}
                                    top={refTitle.current?.getBoundingClientRect().height || 0}
                                    key={day}
                                    day={day}
                                    ids={list[day].map(article => article.article_id)}
                                    isChecked={isChecked}
                                    datepicker={datepicker && !filter.id && !filter.name}
                                    onCheckedAll={onCheckedAll}
                                    onChangeDate={onChangeDate}
                                />
                                {diffMainDate === 0 && i === 0
                                    ? articlesMain.reverse().map(item =>
                                        <ListMain
                                            key={item.article_id}
                                            articleId={item.article_id}
                                            pined={pinedIds.includes(item.article_id)}
                                            checked={checked.includes(item.article_id)}
                                            backgoundFavorite={favoriteIds.includes(item.article_id) && lightingFavorite ? lightingFavoriteColor : null}
                                            variant={variant}
                                            onChecked={onChecked}
                                            onPin={onPined}
                                            onClick={onArticle}
                                            preview={preview}
                                            priority={priority}
                                            isChecked={isChecked}
                                        />
                                    )
                                    : null
                                }
                                {(list[day] as IArticle[]).sort().map((item, j) => {
                                    const keys = Object.keys(list).reverse();
                                    const index = keys.indexOf(day);
                                    const prevKeys = keys.slice(0, index);
                                    const prevCount = prevKeys.reduce((prev, current) => prev + list[current].length, 0);
                                    const count = prevCount + j + 1;

                                    // console.log('item', item.article_date, moment(item.article_date, 'YYYY-MM-DD HH:mm:ss').unix());
                                    return (
                                        <div key={item.article_id} data-tour={`${tour}-${i}-${j}`} data-target="observer" data-show={showIds.includes(item.article_id)} data-id={item.article_id}>
                                            <ListItem
                                                articleId={item.article_id}
                                                pined={pinedIds.includes(item.article_id)}
                                                checked={checked.includes(item.article_id)}
                                                backgoundFavorite={favoriteIds.includes(item.article_id) && lightingFavorite ? lightingFavoriteColor : null}
                                                variant={variant}
                                                onChecked={onChecked}
                                                onPin={onPined}
                                                onClick={onArticle}
                                                preview={preview}
                                                priority={priority}
                                                isChecked={isChecked}
                                            />
                                            {banner && ((count % COUNT_BANNER_PLACE) === 0)
                                                ? <Banner id={count}/>
                                                : null
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <div className={cn(css.spinner, {[css.isLoading]: isLoading})} ref={refMore}>
                            <Spinner height={'80px'} spinner={'40px'}/>
                        </div>
                    </>
                }
            </Scrollbar>
        </div>
    )
};


export default List