import React, { FC, useEffect, useState } from 'react';
import List from '../../../component/List';
import BannerFeed from '../../../component/Banner/BannerFeed';
import BannerSubscribe from '../../../component/Banner/BannerSubscribe';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {IArticle} from "../../../types/IArticle";
import {getArticle} from "../../../store/reducers/ArticleSlice";
import {LIST_VARIANT} from "../../../component/List/ListItem";
import {ITitleMenu} from "../../../component/Title";
import {LIST_NAME, initialStateList} from "../../../store/reducers/LSlice";
import {SIDS} from "../../../constants";
import {useUserPremium} from "../../../hooks/useUserPremium";
import {LANG} from "../../../types/IUser";
import {alert} from "../../../store/reducers/AlertSlice";
import {useTranslate} from "../../../hooks/useTranslate";

interface Props {
    title: string
    variant: LIST_VARIANT,
    menu: ITitleMenu[] | [],
    titleMenu: ITitleMenu[] | []
}

const Lenta: FC<Props>  = ({title, variant, menu, titleMenu}) => {
    const { queries, lang } = useAppSelector(state => state.auth);
    const { filter } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]);
    const dispatch = useAppDispatch();
    const premium = useUserPremium();
    const translate = useTranslate();

    const onArticle = (article: IArticle) => {
        const { article_id } = article;
        let query = '';

        if(filter.id && filter.id !== ''){
            query = (queries.find(item => item.id === filter.id)?.value as any)?.query || '';
        } else if (!(filter.ids && filter.ids.length)){
            query = filter.query;
        }


        dispatch(getArticle({
            id: article_id,
            query: query
        }));
    };

    return (
        <>
            <List
                tour={'lenta'}
                name={LIST_NAME.OPINION}
                title={title}
                titleMenu={titleMenu}
                onArticle={onArticle}
                preview={true}
                bannerBefore={!premium ? <BannerSubscribe/> : null}
                banner={BannerFeed}
                variant={variant}
                menu={menu}
                pined={true}
                filterParams={{
                    ...initialStateList.filter,
                    ...{sid_list: [SIDS[lang].OPINION, SIDS[lang].EXPORT, SIDS[lang].SPIEF]}
                }}
            />
        </>
    );
};

export default Lenta;