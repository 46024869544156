import React, { useEffect} from 'react';
import moment from 'moment';
import momentTimezone from "moment-timezone";
import {ThemeProvider, StyledTheme} from 'styled-components';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './component/Layout';
import Auth from './component/Auth';
import Index from './pages/index/Index';
import Articles from './pages/articles/Articles';
import Feed from './pages/feed';
import Queries from './pages/queries';
import Favorite from './pages/favorite';
import Export from './pages/settings/export';
import Settings from './pages/settings/settings';
import History from './pages/settings/history';
import Newsletter from './pages/settings/newsletter';
import Print from './component/Print';
import Analytics from './pages/analytics';
import FAQ from './pages/faq';
import FAQ_ITEM from './pages/faq/faqItem';
import FAQ_INDEX from './pages/faq/faqIndex';
import NotFound from './pages/notFound/NotFound';
import Tour from './component/Tour';
import Alert from './component/Modal/Alert';
import {getUserInfo} from "./store/reducers/AuthSlice";
import {resize} from "./store/reducers/ScreenSlice";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {AUTH_STATUS, LANG} from "./types/IUser";
import { ROUTE } from './constants'
import {useUserPremium} from "./hooks/useUserPremium";
import {STRUCTURE} from "./data/faq";
import {changeRtl, changeTheme} from "./utils/utils";

momentTimezone.tz.setDefault("Europe/Moscow");

const LOCALE = {
    [LANG.RU]: 'ru',
    [LANG.EN]: 'en',
    [LANG.CN]: 'zh-cn',
    [LANG.ES]: 'es',
    [LANG.EG]: 'ar',
    [LANG.FA]: 'fa'
};

function App() {
    const { auth, rtl, lang, theme } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const premium = useUserPremium();

    useEffect(() => {
        const windowResize = () => {
            dispatch(resize())
        };
        window.addEventListener("resize", windowResize, false);
        return () => window.removeEventListener("resize", windowResize);
    }, [dispatch]);

    useEffect(()=>{
        dispatch(getUserInfo());
    }, [dispatch]);

    useEffect(()=>{
        changeRtl(rtl);
    }, [rtl]);

    useEffect(() => {
        changeTheme(theme);
    },[theme]);

    useEffect(()=> {
        if(lang){
            moment.locale(LOCALE[lang]);
        }
    },[lang]);

    if(!auth) {
        return null;
    }

    return (
        <BrowserRouter>
            {auth === AUTH_STATUS.LOGIN
                ?
                <Routes>
                    <Route element={<Layout/>}>
                        <Route index element={<Index/>}/>
                        <Route path={ROUTE.FEED} element={<Feed/>}/>
                        <Route path={ROUTE.QUERIES} element={<Queries/>}/>
                        <Route path={ROUTE.QUERIES + ":id"} element={<Queries/>}/>
                        <Route path={ROUTE.FAVORITE} element={<Favorite/>}/>
                        <Route path={ROUTE.FAVORITE + ":id"} element={<Favorite/>}/>
                        <Route path={ROUTE.ADJUSTMENT} element={<Export/>}/>
                        <Route path={ROUTE.LENTA} element={<Settings/>}/>
                        <Route path={ROUTE.NEWSLETTER} element={<Newsletter/>}/>
                        <Route path={ROUTE.HISTORY} element={<History/>}/>
                        {premium
                            ? <Route path={ROUTE.ANALYTICS} element={<Analytics/>}/>
                            : null
                        }
                    </Route>
                    <Route element={<Layout sidebar={false}/>}>
                        <Route path={ROUTE.ARTICLE + ":id"} element={<Articles/>}/>
                    </Route>
                    <Route element={<Layout sidebar={false}/>}>
                        <Route path={ROUTE.FAQ} element={<FAQ />}>
                            <Route path={ROUTE.FAQ} element={<FAQ_INDEX />}/>
                            {STRUCTURE.map(structure =>
                                structure.route
                                    ? <Route key={structure.id} path={ROUTE.FAQ + structure.route} element={<FAQ_ITEM id={structure.id}/>} />
                                    : null
                            )}
                        </Route>
                    </Route>
                    <Route element={<Layout sidebar={false} services={true}/>}>
                        <Route path='*' element={<NotFound />} />
                    </Route>
                    <Route path={ROUTE.PRINT} element={<Print/>}/>
                </Routes>
                : <Auth/>
            }
            <Tour/>
            <Alert/>
            
        </BrowserRouter>
    )
}

export default App;