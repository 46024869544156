import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import Select, { components, IndicatorsContainerProps } from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import {IQueriesFilter, ISid, LANG} from "../../../types/IUser";
import { Form, Row, Cell, Devide, Title as FormTitle } from '../../../component/Input/Form/index';
import Input from '../../../component/Input/Input/Input';
import RadioGroup from '../../../component/Input/Radio/RadioGroup';
import Radio from '../../../component/Input/Radio/Radio';
import Checkbox from '../../../component/Input/Checkbox/Checkbox';
import Toggle from '../../../component/Input/Toggle/index';
import {TooltipProfile} from '../../../component/Tooltip/index';
import Button from '../../../component/Button/index';
import Dropdown, { DropdownItem, DropDownHeader } from '../../../component/Dropdown';
import Title, {ITitleMenu} from '../../../component/Title/index';
import {INPUT_VARINAT, OnCheckboxChange, OnInputChange} from "../../../types/IInput";
import {BUTTON_VARIANT} from "../../../component/Button/index";
import {customStylesBlue, customStylesBlueSputnik} from "../../../utils/styled-utils";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    saveQueries,
    setName,
    setFilterParams,
    CREATE_QUERIES_PLACEMENT
} from "../../../store/reducers/CreateLentaSlice";
import { IndicatorsContainer, MultiValueContainer, MultiValueRemove} from "../../../utils/styled-utils";
import {QUERY_LENGTH, QUERY_LENGTH_RU, ROUTE} from "../../../constants";
import {useTranslate} from "../../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {analyticsSaveLenta} from "../../../utils/analytics";
import cssL from '../../../component/Layout/Layout.module.scss';
import {
    FORM_CELL_VARINAT,
    FORM_ROW_ALIGN,
    FORM_ROW_JUSTIFY, FORM_TITLE_MARGIN,
    FORM_TITLE_VARINAT
} from "../../../component/Input/Form/index";
import {ICON_VARIANT} from "../../../assets/svg";
import {DROPDOWN_HEADER_SIZE} from "../../../component/Dropdown/DropdownHeader";
import {DROPDOWN_ITEM_VARIANT} from "../../../component/Dropdown/DropdownItem";
import {DROPDOWN_VARIANT} from "../../../component/Dropdown/DropdownMenu";
import {isRussian} from "../../../utils/utils";

interface Props {
    title?: string
    titleMenu?: ITitleMenu[],
    placement?: CREATE_QUERIES_PLACEMENT
}

const QueriesForm: FC<Props> = ({title, titleMenu = [], placement = CREATE_QUERIES_PLACEMENT.CREATE}) => {
    const { list, queries, user, lang } = useAppSelector(state => state.auth);
    const { name, filter, id, isLoading } = useAppSelector(state => state.lentaCreate[placement]);
    const [sidFilter, setSidFilter] = useState<string>('');
    const [error, setError] = useState<{query: string, stopwords: string}>({
        query: '',
        stopwords: ''
    });
    const dispath = useAppDispatch();
    const translate = useTranslate();
    const navigate = useNavigate();
    const { sid_list, stopwords, fields, advanced_search, match, priority, media_type, query, send_to_telegram } = filter;

    const onChangeName = ({name, value}: OnInputChange) => {
        dispath(setName({placement: placement, name: value}));
    };

    const onChangeCheckbox = ({name, value}: OnCheckboxChange ) => {
        dispath(setFilterParams({placement: placement, params: {[name]: value}}));
    };

    const onChangeAdvanced = ({name, value}: OnCheckboxChange ) => {
        dispath(setFilterParams({placement: placement, params: {[name]: value}}));
    };

    const onChangeFields = ({name, value}: OnCheckboxChange) => {
        const newFields = value ? fields.concat(name) : fields.filter(e => e !== name);
        dispath(setFilterParams({placement: placement, params: {fields: newFields}}));
    };

    const onSelect1 = (item: ReadonlyArray<ISid>) => {
        dispath(setFilterParams({placement: placement, params: {sid_list: item.map(i => i.sid)}}));
    };

    const onSelect = (item: ISid) => {
        const sid = sid_list.includes(item.sid) ? sid_list.filter(sid => sid !== item.sid) : sid_list.concat(item.sid);
        dispath(setFilterParams({placement: placement, params: {sid_list: sid}}));
        setSidFilter('');
    };

    const onSelectFilter = ({value}: OnInputChange) => {
        setSidFilter(value)
    };

    const onChangeInput = ({name, value}: OnInputChange) => {
        const strArr = value.split(' ').filter(e => e);
        const newValue = strArr.slice(0, QUERY_LENGTH).join(' ').concat((value[value.length - 1] === ' ' || strArr.length > QUERY_LENGTH) ? ' ' : '');

        setError(prev => ({...prev,...{[name]: strArr.length > QUERY_LENGTH ? translate.pages.query.error : ''}}));
        dispath(setFilterParams({placement: placement, params: {[name]: newValue}}));
    };

    const onChangePriority = ({name, value}: OnCheckboxChange) => {
        const newFields = value ? priority.concat(parseInt(name)) : priority.filter(e => e !== parseInt(name));
        dispath(setFilterParams({placement: placement, params: {priority: newFields}}));
    };

    const onChangeMedia = ({name, value}: OnCheckboxChange) => {
        const newFields = value ? media_type.concat(name) : media_type.filter(e => e !== name);
        dispath(setFilterParams({placement: placement, params: {media_type: newFields}}));
    };

    const save = async (e: MouseEvent) => {
        dispath(saveQueries({
            id: id,
            name: name,
            filter: filter
        }));

        analyticsSaveLenta();
    };

    return (
        <>
            {title
                ? <div className={cssL.sticky}>
                    <Title title={title} titleMenu={titleMenu}
                           backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}/>
                </div>
                : null
            }
            <Form>
                <Row align={FORM_ROW_ALIGN.CENTER}>
                    <FormTitle varinat={FORM_TITLE_VARINAT.H1} margin={FORM_TITLE_MARGIN.NO_MARGIN}>
                        {translate.pages.query.name}
                    </FormTitle>
                    <Cell varinat={FORM_CELL_VARINAT.FULL}>
                        <Input
                            variant={INPUT_VARINAT.BLUE}
                            name={'name'}
                            value={name}
                            onChange={onChangeName}/>
                    </Cell>
                </Row>
                {translate.filter.advanced
                    ? <Row>
                        <Cell>
                            <Toggle name={'advanced_search'} title={translate.filter.advanced} value={Boolean(advanced_search)} onChange={onChangeAdvanced}/>
                        </Cell>
                        <Cell>
                        </Cell>
                    </Row>
                    : null
                }
                <FormTitle varinat={FORM_TITLE_VARINAT.H1}>
                    {translate.pages.query.settings}
                </FormTitle>
                <Row>
                    <Cell>
                        <Input
                            variant={INPUT_VARINAT.BLUE}
                            title={translate.filter.query}
                            name={'query'}
                            value={query}
                            error={error.query}
                            alert={error.query && translate.pages.query.alert ? <span dangerouslySetInnerHTML={{__html: translate.pages.query.alert}}/> : ''}
                            onChange={onChangeInput}/>
                    </Cell>
                    <Cell>
                        <Input
                            variant={INPUT_VARINAT.BLUE}
                            title={translate.filter.stopwords}
                            name={'stopwords'}
                            value={stopwords}
                            error={error.stopwords}
                            alert={error.stopwords && translate.pages.query.alert ? <span dangerouslySetInnerHTML={{__html: translate.pages.query.alert}}/> : ''}
                            onChange={onChangeInput}/>
                    </Cell>
                </Row>
                <FormTitle>
                    {translate.filter.titleWords}
                </FormTitle>
                <RadioGroup name={'match'} value={match} onChange={onChangeCheckbox}>
                    <Row>
                        <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                            <Radio
                                variant={INPUT_VARINAT.BLUE}
                                value={'all'}
                                title={translate.filter.allwords}/>
                        </Cell>
                        <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                            <Radio
                                variant={INPUT_VARINAT.BLUE}
                                value={'phrase'}
                                title={translate.filter.phrasewords}/>
                        </Cell>
                        <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                            <Radio
                                variant={INPUT_VARINAT.BLUE}
                                value={'any'}
                                title={translate.filter.anywords}/>
                        </Cell>
                    </Row>
                </RadioGroup>
                <FormTitle>
                    {translate.filter.titleFilter}
                </FormTitle>
                <Row>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name={'title'}
                            value={fields.includes('title')}
                            title={translate.filter.filterTitle}
                            onChange={onChangeFields}/>
                    </Cell>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name={'body'}
                            value={fields.includes('body')}
                            title={translate.filter.filterBody}
                            onChange={onChangeFields}/>
                    </Cell>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name={'keywords'}
                            value={fields.includes('keywords')}
                            title={translate.filter.filterKeywords}
                            onChange={onChangeFields}/>
                    </Cell>
                </Row>
                <FormTitle>
                    {translate.filter.typeTitle}
                </FormTitle>
                <Row>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name='1'
                            value={Boolean((priority as number[]).includes(1))}
                            title={translate.filter.breakingNews}
                            onChange={onChangePriority}/>
                    </Cell>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name='2'
                            value={Boolean((priority as number[]).includes(2))}
                            title={translate.filter.express}
                            onChange={onChangePriority}/>
                    </Cell>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name='photo'
                            value={media_type.includes('photo')}
                            title={translate.filter.withPhoto}
                            onChange={onChangeMedia}/>
                    </Cell>
                    <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                        <Checkbox
                            variant={INPUT_VARINAT.BLUE}
                            name='video'
                            value={media_type.includes('video')}
                            title={translate.filter.withVideo}
                            onChange={onChangeMedia}/>
                    </Cell>
                </Row>
                <FormTitle varinat={FORM_TITLE_VARINAT.H6}>
                    {translate.filter.lenta}
                </FormTitle>
                <Row>
                    <Dropdown
                        header={<>
                            <DropDownHeader
                                value={list.filter(item => sid_list.includes(item.sid)).map(item => item.title).join(', ')}
                                size={DROPDOWN_HEADER_SIZE.LARGE}
                                onChange={onSelectFilter}
                            />
                        </>
                        }
                        variant={DROPDOWN_VARIANT.WHITE}
                        height={200}
                    >
                        {list.filter(item => item.is_active && (item.title.toLowerCase().indexOf(sidFilter.toLowerCase()) > -1))
                            .sort((item) => sid_list.includes(item.sid) ? -1 : 1)
                            .map(item =>
                            <DropdownItem
                                key={item.id}
                                active={sid_list.includes(item.sid)}
                                icon={sid_list.includes(item.sid) ? ICON_VARIANT.CHECK : null}
                                onClick={() => onSelect(item)}>
                                {item.title}
                            </DropdownItem>
                        )}
                    </Dropdown>
                </Row>
                {/*<FormTitle varinat={FORM_TITLE_VARINAT.H6}>*/}
                    {/*{translate.filter.lenta}*/}
                {/*</FormTitle>*/}
                {/*<Row>*/}
                    {/*<Select*/}
                        {/*isMulti={true}*/}
                        {/*placeholder={null}*/}
                        {/*styles={lang === LANG.RU ? customStylesBlue : customStylesBlueSputnik}*/}
                        {/*options={list.filter(item => item.is_active)}*/}
                        {/*getOptionLabel={(item: ISid) => item.title}*/}
                        {/*getOptionValue={(item: ISid) => item.sid}*/}
                        {/*value={list.filter(i => sid_list.includes(i.sid))}*/}
                        {/*hideSelectedOptions={false}*/}
                        {/*menuPosition={'fixed'}*/}
                        {/*menuPlacement={'auto'}*/}
                        {/*components={{IndicatorsContainer, MultiValueRemove, MultiValueContainer}}*/}
                        {/*onChange={onSelect}*/}
                    {/*/>*/}
                {/*</Row>*/}
                {user?.telegram_linked
                    ? <>
                        <Devide/>
                        <FormTitle>
                            {translate.filter.export}
                            <TooltipProfile
                                title={<>
                                    <p><b>{translate.tooltip.query.telegram}</b></p>
                                    <p>{translate.tooltip.query.telegramImport}</p>
                                </>}
                            />
                        </FormTitle>
                        <Row>
                            <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                <Checkbox
                                    variant={INPUT_VARINAT.BLUE}
                                    name='send_to_telegram'
                                    value={send_to_telegram}
                                    title={translate.filter.exportTelegram}
                                    onChange={onChangeCheckbox}/>
                            </Cell>
                        </Row>
                    </>
                    : null
                }
                <Row justify={FORM_ROW_JUSTIFY.END}>
                    <Button
                        disabled={!name}
                        isLoading={isLoading}
                        height={'40px'}
                        onClick={save}>
                            {id ? translate.filter.update : translate.filter.create}
                    </Button>
                </Row>
            </Form>
        </>
    )
};

export default QueriesForm;