import React, {FC, useState} from 'react';
import Icon from '../Icon'
import cn from 'classnames';
import RSC from "react-scrollbars-custom";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {ICON_VARIANT} from "../../assets/svg";
import {analyticsScrollUp} from "../../utils/analytics";
import css from './Scrollbar.module.scss';

export interface IScrollBarUpdate {
    scrollValues: any,
    prevScrollValues: any
}

const DELTA = 500;

interface IScrollBar {
    scrollFixed?: boolean,
    screenVariant?: SCREEN_VARIANT[],
    onUpdate?: ({scrollValues, prevScrollValues}: IScrollBarUpdate) => void,
    scrollTo?: number
    onScrollEnd?: () => void
    delta?: number,
    isUp?: boolean,
    countUnread?: number
}


const Scrollbar:FC<IScrollBar> = ({children, onUpdate, onScrollEnd, isUp = false, countUnread = 0, scrollFixed = false, delta = DELTA, screenVariant = [SCREEN_VARIANT.DESKTOP]}) => {
    const { screen } = useAppSelector(state => state.screen);
    const { rtl } = useAppSelector(state => state.auth);
    const { run } = useAppSelector(state => state.tour);
    const [top, setTop] = useState<number>(0);
    const [count, setCount] = useState<number>(countUnread);

    const onHandlerUpdate = (scrollValues: any, prevScrollValues: any) => {
        if(onUpdate) {
            onUpdate({scrollValues, prevScrollValues});
        }

        if(scrollValues.scrollTop + scrollValues.clientHeight + delta > scrollValues.contentScrollHeight && scrollValues.clientHeight !== scrollValues.contentScrollHeight){
            onScrollEnd && onScrollEnd();
        }

        if(scrollFixed
            && !(prevScrollValues.scrollTop + prevScrollValues.clientHeight + delta > prevScrollValues.contentScrollHeight)
            && scrollValues.scrollTop > 10
            && scrollValues.contentScrollHeight > scrollValues.clientHeight
            && scrollValues.contentScrollWidth === prevScrollValues.contentScrollWidth
            && scrollValues.contentScrollHeight !== prevScrollValues.contentScrollHeight
            && count !== countUnread){

            const to = prevScrollValues.scrollTop + (scrollValues.contentScrollHeight - prevScrollValues.contentScrollHeight);
            setTop(to);
            setCount(countUnread);
        } else {
            setTop(scrollValues.scrollTop);
        }
    };

    const onUp = () => {
        setTop(0);

        analyticsScrollUp();
    };

    if(!screenVariant.includes(screen)){
        return (
            <>{children}</>
        );
    }

    return(
        <div className={css.container}>
            <RSC
                noScroll={run}
                noDefaultStyles={false}
                minimalThumbSize={50}
                trackYProps={{
                    renderer: (props: any) => {
                        const { elementRef, style, ...restProps } = props;
                        return <div {...restProps} className={css.trackY} ref={elementRef}/>;
                    }
                }}
                scrollTop={(scrollFixed || isUp) ? top : undefined}
                rtl={rtl}
                onUpdate={onHandlerUpdate}
            >
                <div className={css.inner}>
                    {children}
                </div>
            </RSC>
            {isUp
                ? <div className={cn(css.scrollUp, {[css.active]: Boolean(top > 300)})} onClick={onUp}>
                    {countUnread > 0
                        ? <div className={css.count}>{countUnread}</div>
                        : null
                    }
                    <Icon name={ICON_VARIANT.ARROW_UP}/>
                </div>
                : null
            }
        </div>
    )
};

export default Scrollbar