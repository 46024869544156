import React, { FC, useState, useEffect, MouseEvent } from 'react';
import {PropsListItem} from "../ListItem";
import ArticleMedia from '../../Article/ArticleMedia';
import Icon from '../../Icon';
import {dateListFormat, getKeywords} from "../../../utils/utils";
import {useTranslate} from "../../../hooks/useTranslate";
import {ICON_VARIANT} from "../../../assets/svg";
import {useGetArticle} from "../../../hooks/useGetArticle";
import css from './ListMain.module.scss';

const ListMain:FC<PropsListItem> = ({articleId, pined, checked, backgoundFavorite = null, variant, preview, onPin, onChecked, onClick}) => {
    const [showLead, setShowLead] = useState<boolean>(false);
    const article = useGetArticle(articleId);
    const translate = useTranslate();

    if(!article){
        return null
    }
    const {article_date, article_title, article_body, medias, article_keywords} = article;
    const keywords = getKeywords(article_keywords);

    const onShowLead = (e: MouseEvent) => {
        e.stopPropagation();
        setShowLead(!showLead);
    };

    const handleClick = () => {
        onClick(article);

    };

    return (
        <div className={css.item}>
            <div className={css.header}>
                <div className={css.date}>{dateListFormat(article_date)}</div>
                <div className={css.info}>
                    <div className={css.title} dangerouslySetInnerHTML={{ __html: article_title}}  onClick={handleClick}/>
                </div>
            </div>

            <div className={css.body}>
                {medias?.length ? <ArticleMedia media={medias}/> : null}
                <div className={css.toggle} onClick={onShowLead}>
                    {translate.list.showLead}
                    <div className={css.icon}>
                        <Icon name={showLead ? ICON_VARIANT.ARROW_UP : ICON_VARIANT.ARROW_DOWN}/>
                    </div>
                </div>
                {showLead
                    ? <div className={css.text} dangerouslySetInnerHTML={{ __html: article_body }}/>
                    : null
                }
            </div>

        </div>
    )
};

export default ListMain