import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import qs from 'query-string';
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {addStoreArticles, addStoreComet} from "./StoreSlice";
import {IArticle} from "../../types/IArticle";
import {LIST_FIELDS_DIFF, SIDS, TYPE_PREFERENCES, URL} from "../../constants";
import {containsSids, diff, getUnique, objKeys, serialize, setSearchParams} from "../../utils/utils";
import {IFavorite, IPinnedArticles, LANG} from "../../types/IUser";
import {setFavorite, setPinnedArticles} from "./AuthSlice";
import {DatePickerLabel} from "../../component/DatePicker/DatePickerHeader";

export enum LIST_NAME {
    INDEX = 'index',
    ALL_NEWS = 'allNews',
    OPINION = 'opinion',
    TOP_NEWS = 'topNews',
    ARTICLE_LIST = 'articleList',
    LENTA = 'lenta'
}

export interface IListFilterSlice {
    date_start: Date | null,
    date_end: Date | null,
    sid_list: string[],
    advanced_search: number | null,
    case_sensitive: number | null,
    media_type:  string[],
    fields: string[],
    stopwords: string,
    query: string,
    match: string,
    offset: number,
    priority: number[],
    limit: number

    id?: string
    name?: string
    ids?: string[]
    parentId?: string

    label: DatePickerLabel | null
    startTime: string | null,
    endTime: string | null
}

interface IListStoreSlice {
    filter: IListFilterSlice,
    init: boolean,
    requestId: string,
    isLoading: boolean,
    isLoadingMain: boolean
    withAuth: boolean
    checked: number[],
    showIds: number[],
    articlesIds: number[],
    mainIds: number[],
    resultsCount: number,
}

interface IListSlice {
    [key: string]: IListStoreSlice
}

export const initialStateList: IListStoreSlice = {
    filter: {
        // date_start: moment().set({hour: 0, minute: 0, second: 0}).subtract(7, 'days').toDate(),
        date_start: null,
        // date_end: moment().set({hour: 0, minute: 0, second: 0}).toDate(),
        date_end: null,
        advanced_search: null,
        sid_list: [],
        fields: [],
        priority: [],
        case_sensitive: null,
        media_type: [],
        stopwords: '',
        query: '',
        match: 'all',
        offset: 0,
        limit: 20,

        id: '',
        name: '',
        ids: [],
        parentId: '',


        label: null,
        startTime: null,
        endTime: null
    },
    init: false,
    requestId: '',
    isLoading: false,
    isLoadingMain: false,
    withAuth: true,
    checked: [],
    showIds: [],
    articlesIds: [],
    mainIds: [],
    resultsCount: 0,
};

const initialStateArticleList: IListStoreSlice = {...initialStateList};
const initialStateLenta: IListStoreSlice = {...initialStateList, ...{filter: {...initialStateList.filter, ...{date_start: null, date_end: null, match: '', limit: 40}}}};

export const initialState: IListSlice = {
    [LIST_NAME.INDEX]: initialStateList,
    [LIST_NAME.ALL_NEWS]: initialStateList,
    [LIST_NAME.TOP_NEWS]: initialStateList,
    [LIST_NAME.OPINION]: initialStateList,
    [LIST_NAME.ARTICLE_LIST]: initialStateList,
    [LIST_NAME.LENTA]: initialStateLenta,
};

export const getMain = createAsyncThunk(
    'list/getMain',
    async (data: {name: LIST_NAME, params: object}, { rejectWithValue, dispatch, getState }) => {
        try {
            const { name, params } = data;

            dispatch(setPendingMain({
                name: name,
                isLoading: true
            }));

            const response = await axios.post(
                URL.SEARCH,
                qs.stringify(serialize(params), {skipNull: true, arrayFormat: 'bracket'}),
                { withCredentials: true });

            const result = response?.data?.data?.paginationSolrArticles;
            const articles: IArticle[] = result?.articles || [];
            const resultsCount: number = result?.resultsCount || 0;
            const articlesIds: number[] = articles?.map(article => article.article_id) || [];

            dispatch(addStoreArticles(articles));

            return {
                name,
                resultsCount,
                articlesIds
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const reloadAllList = createAsyncThunk(
    'list/reloadAllList',
    async (_, { rejectWithValue, getState, dispatch }) => {
            const state = getState() as any;
            const list = state.l;
            const listNameActive = objKeys(list).filter(name => list[name].init);

            listNameActive.forEach((name: any) => {
                dispatch(getList(name));
            });
        try {
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const loadLossArticle = createAsyncThunk(
    'list/loadLossArticle',
    async (date_start: string, { rejectWithValue, getState, dispatch }) => {
            const response = await axios.post(
                URL.SEARCH,
                qs.stringify(serialize({date_start, limit: 50}), {skipNull: true, arrayFormat: 'bracket'}),
                { withCredentials: true});
            
            const result = response?.data?.data?.paginationSolrArticles;
            const articles: IArticle[] = result?.articles || [];

            dispatch(addStoreComet(articles));

            const state = getState() as any;
            const list = state.l;
            const allNews = list[LIST_NAME.ALL_NEWS];
            const id = allNews?.filter.id || 0;
            
            if(id){
                const responseFeed = await axios.post(
                    URL.ALL_MY_FEEDS,
                    qs.stringify(serialize({id, date_start, limit: 50}), {skipNull: true, arrayFormat: 'bracket'}),
                    { withCredentials: true});
                
                const resultFeed = responseFeed?.data?.data?.paginationSolrArticles;
                const articlesFeed: IArticle[] = resultFeed?.articles || [];
                dispatch(addStoreComet(articlesFeed));
            }
        try {
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const getList = createAsyncThunk(
    'list/getList',
    async (name: LIST_NAME, { rejectWithValue, signal, getState, dispatch, requestId }) => {
        try {
            const state = getState() as any;
            const { filter, isLoading, withAuth } = state.l[name];
            const lang: LANG = state.auth.lang;
            const source = axios.CancelToken.source();

            signal.addEventListener('abort', () => {
                source.cancel()
            });

            // if(!isLoading){
                dispatch(setPending({
                    name: name,
                    isLoading: true,
                    requestId: requestId
                }));


                if(name === LIST_NAME.ALL_NEWS) {
                    setSearchParams(filter);
                }

                if(!withAuth){
                    const response = await axios.post(
                        URL.INDEX_FEED,
                        qs.stringify(serialize(filter), {skipNull: true, arrayFormat: 'bracket'}),
                        {cancelToken: source.token});

                    const result = response?.data?.data?.paginationSolrArticles;
                    const articles: IArticle[] = result?.articles || [];
                    const resultsCount: number = result?.resultsCount || 0;
                    const articlesIds: number[] = articles?.map(article => article.article_id) || [];

                    dispatch(addStoreArticles(articles));

                    return {
                        name,
                        resultsCount,
                        articlesIds
                    }
                } else if (filter.name) {
                    const response = await axios.post(URL.GET_ARTICLE, qs.stringify({
                        type: TYPE_PREFERENCES.QUERY,
                        id: filter.ids.length ? filter.ids : [],
                    }, {arrayFormat: 'bracket'}), { cancelToken: source.token, withCredentials: true});

                    const result = response?.data?.data?.paginationSolrArticles;
                    const articles: IArticle[] = result?.articles || [];
                    const resultsCount: number = result?.resultsCount || 0;
                    const articlesIds: number[] = articles?.map(article => article.article_id) || [];

                    dispatch(addStoreArticles(articles));

                    return {
                        name,
                        resultsCount,
                        articlesIds
                    }
                } else if(filter.id && filter.id !== ''){
                    const response = await axios.post(
                        URL.ALL_MY_FEEDS,
                        qs.stringify(serialize({id: filter.id, offset: filter.offset, limit: filter.limit}), {skipNull: true, arrayFormat: 'bracket'}),
                        { cancelToken: source.token, withCredentials: true });

                    const result = response?.data?.data?.paginationSolrQueryAll;
                    const articles: IArticle[] = result?.articles || [];
                    const resultsCount: number = result?.resultsCount || 0;
                    const articlesIds: number[] = articles?.map(article => article.article_id) || [];

                    dispatch(addStoreArticles(articles));

                    return {
                        name,
                        resultsCount,
                        articlesIds
                    }
                } else {
                    const response = await axios.post(
                        URL.SEARCH,
                        qs.stringify(serialize({...filter, ...{sid_list: filter.sid_list.length ? filter.sid_list : [SIDS[lang].ALL_NEWS]}}), {skipNull: true, arrayFormat: 'bracket'}),
                        {cancelToken: source.token, withCredentials: true});

                    const result = response?.data?.data?.paginationSolrArticles;
                    const articles: IArticle[] = result?.articles || [];
                    const resultsCount: number = result?.resultsCount || 0;
                    const articlesIds: number[] = articles?.map(article => article.article_id) || [];

                    dispatch(addStoreArticles(articles));

                    return {
                        name,
                        resultsCount,
                        articlesIds
                    }
                }
            // }

        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const loadMore = createAsyncThunk(
    'list/loadMore',
    async (data: {name: LIST_NAME, params: any}, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState() as any;
            const { name, params } = data;
            const { filter, isLoading } = state.l[name];

            if(!isLoading) {
                dispatch(setLoading({
                    name: name,
                    isLoading: true
                }));

                if(filter.id && filter.id !== ''){
                    const response = await axios.post(
                        URL.ALL_MY_FEEDS,
                        qs.stringify(serialize({id: filter.id, limit: filter.limit, offset: params.offset}), {skipNull: true, arrayFormat: 'bracket'}),
                        {withCredentials: true});

                    const result = response?.data?.data?.paginationSolrQueryAll;
                    const articles: IArticle[] = result?.articles || [];
                    const resultsCount: number = result?.resultsCount || 0;
                    const articlesIds: number[] = articles?.map(article => article.article_id) || [];

                    dispatch(addStoreArticles(articles));

                    return {
                        name,
                        resultsCount,
                        articlesIds
                    }
                } else {
                    const response = await axios.post(
                        URL.SEARCH,
                        qs.stringify(serialize({...filter, ...{offset: params.offset}}), {
                            skipNull: true,
                            arrayFormat: 'bracket'
                        }),
                        {withCredentials: true});

                    const result = response?.data?.data?.paginationSolrArticles;
                    const articles: IArticle[] = result?.articles || [];
                    const resultsCount: number = result?.resultsCount || 0;
                    const articlesIds: number[] = articles?.map(article => article.article_id) || [];

                    dispatch(addStoreArticles(articles));

                    return {
                        name,
                        resultsCount,
                        articlesIds
                    };
                }
            } else {
                return null
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const getPin = createAsyncThunk(
    'list/getPin',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state: any = getState();
            const pinsIds = (state.auth.pinned_articles as IPinnedArticles[])?.map(item => item.value)?.flat() || [];

            if(pinsIds.length){
                const response = await axios.post(URL.GET_ARTICLE, qs.stringify({
                    type: TYPE_PREFERENCES.QUERY,
                    id: pinsIds,
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const result = response?.data?.data?.paginationSolrArticles;
                const articles: IArticle[] = result?.articles || [];
                dispatch(addStoreArticles(articles));
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const setPin = createAsyncThunk(
    'list/setPin',
    async (ids: number[], { rejectWithValue, getState, dispatch }) => {
        try {
            if(ids.length){
                const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                    type: TYPE_PREFERENCES.PINNED_ARTICLE,
                    name: TYPE_PREFERENCES.PINNED_ARTICLE,
                    value: ids
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const result = response.data.setPreferences.result;

                dispatch(setPinnedArticles([result]));

                return ids
            } else {
                const response = await axios.post(URL.DELETE_PREFERENS, qs.stringify({
                    type: TYPE_PREFERENCES.PINNED_ARTICLE,
                    name: TYPE_PREFERENCES.PINNED_ARTICLE
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                dispatch(setPinnedArticles([]));
                return ids
            }

        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const setFavorites = createAsyncThunk(
    'list/setFavorite',
    async ({name, value}: {name: string, value: number[]}, { rejectWithValue, getState, dispatch }) => {
        try {

            const state: any = getState();

            const { data, status } = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.ARTICLE,
                name: name,
                value: JSON.stringify(serialize(value))
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            if(status === 200){
                const newValue = data.setPreferences.result;
                dispatch(setFavorite(newValue));
            }

            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const setReading = createAsyncThunk(
    'list/setReading',
    async (ids: number[], { rejectWithValue, getState, dispatch }) => {
        try {
            if(ids.length){
                const response = await axios.post(URL.GET_ARTICLE, qs.stringify({
                    type: TYPE_PREFERENCES.QUERY,
                    id: ids,
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const articles = response.data.data.paginationSolrArticles.articles;
                dispatch(addStoreArticles(articles));
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        init(state: IListSlice, action: PayloadAction<{name: LIST_NAME, filter: IListFilterSlice, withAuth: boolean}>){
            const { name, filter, withAuth } = action.payload;

            if(state[name]){
                state[name].filter = filter;
                state[name].withAuth = withAuth;
            }
        },
        setLoading(state: IListSlice, action: PayloadAction<{name: LIST_NAME, isLoading: boolean}>){
            const { name, isLoading } = action.payload;

            if(state[name]) {
                state[name].isLoading = isLoading;
            }
        },
        setPending(state: IListSlice, action: PayloadAction<{name: LIST_NAME, isLoading: boolean, requestId: string}>){
            const { name, isLoading, requestId } = action.payload;

            if(state[name]) {
                state[name].isLoading = isLoading;
                state[name].requestId = requestId;
                state[name].articlesIds = [];
                state[name].resultsCount = 0;
                state[name].checked = [];
                state[name].init = false;
            }
        },
        setPendingMain(state: IListSlice, action: PayloadAction<{name: LIST_NAME, isLoading: boolean}>){
            const { name, isLoading } = action.payload;

            if(state[name]) {
                state[name].isLoadingMain = isLoading;
            }
        },
        setChecked(state, action: PayloadAction<{name: LIST_NAME, id: number}>){
            const { name, id } = action.payload;

            if(state[name]) {
                state[name].checked = state[name].checked.includes(id) ? state[name].checked.filter(e => e !== id) : state[name].checked.concat(id);
            }
        },
        setCheckedIds(state, action: PayloadAction<{name: string, ids: number[], value: boolean}>){
            const { name, ids, value } = action.payload;

            if(state[name]) {
                if(value){
                    state[name].checked = getUnique([...ids, ...state[name].checked]);
                } else {
                    state[name].checked = state[name].checked.filter(i => !ids.includes(i));
                }
            }
        },
        setParams(state, action: PayloadAction<{name: LIST_NAME, params: object}>){
            const {name, params} = action.payload;

            if(state[name]){
                (Object.keys(params) as Array<keyof typeof params>).map(p => {
                    state[name].filter[p] = params[p];
                });
            }
        },
        addListIds(state, action: PayloadAction<{articles: IArticle[], lang: LANG}>){
            const { articles, lang } = action.payload;
            const now = moment();

            (Object.keys(state) as Array<keyof typeof state>).map(name => {
                const dateEnd = state[name].filter.date_end; // Date | null
                const date = moment(dateEnd);

                if(state[name].init){
                    const id = state[name].filter.id;

                    if(id){
                        const ids = containsSids(articles, [id]).map(article => article.article_id);

                        if (ids.length) {
                            state[name].articlesIds = getUnique([...ids, ...state[name].articlesIds]);
                        }
                    } else if((now.diff(date, 'days') === 0 || !dateEnd) && !diff(initialState[name].filter, state[name].filter, LIST_FIELDS_DIFF).length) {
                        const { priority } = state[name].filter;
                        const sids = state[name].filter.sid_list.length ? state[name].filter.sid_list : [SIDS[lang].ALL_NEWS];

                        const ids = containsSids(articles, sids).filter(article => priority.includes(article.article_priority) || !priority.length).map(article => article.article_id);

                        if (ids.length) {
                            state[name].articlesIds = getUnique([...ids, ...state[name].articlesIds]);
                        }
                    }
                }
            });
        },
        addShowIds(state, action: PayloadAction<{name: LIST_NAME, id: number}>){
            const {name, id} = action.payload;

            if(state[name] && !state[name].showIds.includes(id)){
                state[name].showIds = state[name].showIds.concat(id);
            }
        },
        destroy(state, action: PayloadAction<LIST_NAME>){
            const name = action.payload;

            const initial = {...initialState[name], ...{filter: {...state[name].filter, ...{offset: 0, id: '', name: '', ids: []}}}};

            if(state[name]){

                state[name] = initial;
            }
        },
        resetFilter(state,  action: PayloadAction<{name: LIST_NAME}>){
            const { name } = action.payload;

            if(state[name]){
                state[name] = initialState[name];
            }
        },
        resetAllFilters(state){
            state[LIST_NAME.INDEX] = initialStateList;
            state[LIST_NAME.ALL_NEWS] = initialStateList;
            state[LIST_NAME.TOP_NEWS] = initialStateList;
            state[LIST_NAME.OPINION] = initialStateList;
            state[LIST_NAME.ARTICLE_LIST] = initialStateArticleList;
        }
    },
    extraReducers:{
        [getList.fulfilled.type]: (state: IListSlice, action: PayloadAction<{name: LIST_NAME, articlesIds: number[], resultsCount: number}, any, any>) => {
            const { name, articlesIds, resultsCount } = action.payload;
            const { requestId } = action.meta;

            if(state[name] && state[name].requestId === requestId) {
                state[name].articlesIds = articlesIds;
                state[name].showIds = articlesIds;
                state[name].isLoading = false;
                state[name].resultsCount = resultsCount;
                state[name].init = true;
            }
        },
        [loadMore.fulfilled.type]: (state: IListSlice, action: PayloadAction<{name: LIST_NAME, articlesIds: number[], resultsCount: number} | null>) => {
            if(action.payload) {
                const {name, articlesIds, resultsCount} = action.payload;

                if (state[name]) {
                    state[name].articlesIds = getUnique([...state[name].articlesIds, ...articlesIds]);
                    state[name].showIds = getUnique([...state[name].showIds, ...articlesIds]);
                    state[name].isLoading = false;
                    state[name].resultsCount = resultsCount;
                }
            }
        },
        [getMain.fulfilled.type]: (state: IListSlice, action: PayloadAction<{name: LIST_NAME, articlesIds: number[], resultsCount: number}, any, any>) => {
            const { name, articlesIds } = action.payload;

            if(state[name]) {
                state[name].mainIds = articlesIds;
                state[name].isLoadingMain = false;
            }
        },
    }
});


export const {init, setLoading, setPending, setPendingMain, setChecked, setCheckedIds, setParams, addListIds, destroy, resetFilter, resetAllFilters, addShowIds } = listSlice.actions;
export default listSlice.reducer;