import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import qs from 'query-string';
import {URL} from "../../constants";
import {addStoreArticles} from "./StoreSlice";
import {IArticle} from "../../types/IArticle";
import {getArticle} from "./ArticleSlice";
import {IFloor} from "../../types/IFloor";


interface Props {
    isLoading: boolean,
    floor: IArticle[] | null
}


const initialState: Props = {
    isLoading: true,
    floor: null
};

export const getFloor = createAsyncThunk(
    'floor/getFloor',
    async (params: {sid: string}, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(
                URL.GET_FLOOR,
                qs.stringify(params, {skipNull: true, arrayFormat: 'bracket'}),
                { withCredentials: true });

            return response?.data?.data?.paginationSolrArticles?.articles || null
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const floorSlice = createSlice({
    name: 'floor',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getFloor.pending.type]: (state: Props) => {
            state.isLoading = true;
        },
        [getFloor.fulfilled.type]: (state: Props, action: PayloadAction<IArticle[] | null>) => {
            const floor = action.payload;

            state.isLoading = false;
            state.floor = floor
        },
    }
});

export const { } = floorSlice.actions;
export default floorSlice.reducer;