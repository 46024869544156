import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IHeader {
    focus: boolean,
    showFilter: boolean,
    showMenu: boolean,
}

const initialState: IHeader = {
    focus: false,
    showFilter: false,
    showMenu: false
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setFocus(state: IHeader, action: PayloadAction<boolean>){
            const focus = action.payload;

            state.focus = focus;
        },
        setShowFilter(state: IHeader, action: PayloadAction<boolean>){
            const showFilter = action.payload;

            if(showFilter){
                state.showMenu = false;
            }
            state.showFilter = showFilter;
        },
        setShowMenu(state: IHeader, action: PayloadAction<boolean>){
            const showMenu = action.payload;

            if(showMenu){
                state.showFilter = false;
            }
            state.showMenu = showMenu;
        }
    }
});

export const { setFocus, setShowFilter, setShowMenu } = headerSlice.actions;
export default headerSlice.reducer;