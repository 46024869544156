import {useCallback, useRef} from 'react';
import {useAppSelector} from "./redux";
import {IArticle} from "../types/IArticle";
import {LIST_NAME} from "../store/reducers/LSlice";

export function useGetArticles(ids: number[]): IArticle[]{
    const { articles } = useAppSelector(state => state.store);
    const arr = [];

    for(let i = 0; i < ids.length; i++){
        if(articles[ids[i]]){
            arr.push(articles[ids[i]])
        }
    }

    return arr;
}

export function useGetPinndedIds(): number[] {
    const { pinned_articles } = useAppSelector(state => state.auth);

    if(pinned_articles.length){
        return pinned_articles.map(item => item.value).flat()
    }

    return []
}

export function useGetPinned({name, ids}: {name: LIST_NAME, ids: number[]}): IArticle[]{
    const { filter } = useAppSelector(state => state.l[name]);
    const articles = useGetArticles(ids);
    const { sid_list } = filter;

    return articles.filter(article => article.categories.map(list => list.list_sid).some(sid => sid_list.includes(sid))) || [];
}