import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SID_MEDIA } from "../../constants";

export enum FILTER_TYPE {
    FILTER = 'filter',
    QUERIES = 'queries',
    FAVORITE = 'favorite',
    OPINION = 'opinion'
}

export interface IFilter {
    date_start: Date | null,
    date_end: Date | null,
    sid_list: string[],
    case_sensitive: number | null,
    media_type:  string[],
    fields: string[],
    stopwords: string,
    query: string,
    match: string,
    offset: number,
    priority: number[],
    limit: number
}

export interface IFilterQueries {
    id: string,
    offset: number,
    limit: number
}

export interface IFilterFavorite {
    name: string,
    ids: number[]
}

export interface IOpinion {
    date_start: Date | null,
    date_end: Date | null,
    sid_list: string[],
    offset: number,
    limit: number
}

interface FilterState {
    open: boolean,
    type: FILTER_TYPE,
    filter: IFilter,
    queries: IFilterQueries,
    favorite: IFilterFavorite,
    opinion: IOpinion
}

const d = new Date();

export const initialStateFilter: FilterState = {
    open: false,
    type: FILTER_TYPE.FILTER,
    filter: {
        date_start: moment().set({hour: 0, minute: 0, second: 0}).subtract(7, 'days').toDate(),
        date_end: moment().set({hour: 0, minute: 0, second: 0}).toDate(),
        sid_list: [],
        fields: [],
        priority: [],
        case_sensitive: null,
        media_type: [],
        stopwords: '',
        query: '',
        match: 'all',
        offset: 0,
        limit: 20
    },
    queries: {
        id: '',
        offset: 0,
        limit: 20
    },
    favorite: {
        name: '',
        ids: [],
    },
    opinion: {
        date_start: moment().set({hour: 0, minute: 0, second: 0}).subtract(7, 'days').toDate(),
        date_end: moment().set({hour: 0, minute: 0, second: 0}).toDate(),
        sid_list: [SID_MEDIA],
        offset: 0,
        limit: 20
    }
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState: initialStateFilter,
    reducers: {
        toggle(state){
            state.open = !state.open
        },
        close(state){
            state.open = false
        },
        setFilterDate(state, action){
            const { date_start, date_end} = action.payload;
            state.filter.date_start = date_start;
            state.filter.date_end = date_end;
        },
        setFilterType(state, action: PayloadAction<FILTER_TYPE>){
            state.type = action.payload;
        },
        resetFilter(state){
            state.type = FILTER_TYPE.FILTER;
            state.filter = initialStateFilter.filter;
            state.queries = initialStateFilter.queries;
            state.favorite = initialStateFilter.favorite;
        },
        setFilterParams(state, action: PayloadAction<object>){
            const params = action.payload;

            (Object.keys(params) as Array<keyof typeof params>).map(name => {
               state.filter[name] = params[name];
            });

            state.type = FILTER_TYPE.FILTER;
            state.queries = initialStateFilter.queries;
            state.favorite = initialStateFilter.favorite;
        },
        setQueriesParams(state, action: PayloadAction<object>){
            const params = action.payload;

            (Object.keys(params) as Array<keyof typeof params>).map(name => {
                state.queries[name] = params[name];
            });

            state.type = FILTER_TYPE.QUERIES;
            state.filter = initialStateFilter.filter;
            state.favorite = initialStateFilter.favorite;
        },
        setFavoriteParams(state, action: PayloadAction<object>){
            const params = action.payload;

            (Object.keys(params) as Array<keyof typeof params>).map(name => {
                state.favorite[name] = params[name];
            });

            state.type = FILTER_TYPE.FAVORITE;
            state.queries = initialStateFilter.queries;
            state.filter = initialStateFilter.filter;
        }
    }
});

export const { toggle, close, setFilterParams, setFilterDate, setQueriesParams, resetFilter, setFavoriteParams, setFilterType } = filterSlice.actions;
export default filterSlice.reducer;
