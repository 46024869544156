import React, { FC, useState, useEffect} from 'react';
import {Range as ReactRange, getTrackBackground} from 'react-range';
import moment from 'moment';
import Icon from '../../Icon'
import {ICON_VARIANT} from "../../../assets/svg";
import {COLOR} from "../../../types/IColor";
import {TIME_MARK, TIME_MARK_MOBILE} from "../../../constants";
import {getDate, TIME_FORMAT, validTime} from "../../../utils/utils";
import {useAppSelector} from "../../../hooks/redux";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {useGetColor} from "../../../hooks/useGetColor";

export interface OnChangeRange {
    startDate: {
        hours: number,
        minutes: number
    },
    endDate: {
        hours: number,
        minutes: number
    }
}

interface IRange {
    startDate: string,
    endDate: string,
    disabled?: boolean
    onChange: (dates: OnChangeRange) => void
}

interface IMark {
    props: {
        key: string,
        style: React.CSSProperties,
        ref: React.RefObject<any>,
    },
    index: number
}

export interface ITimeMark {
    index: number,
    mark: string,
    hour: number,
    minute: number
}

const INTERVAL: number = 5; // 5 минут
const MIN: number = 0;
const DAY_MINUTES = 1440;
const MAX: number = Math.ceil(DAY_MINUTES / INTERVAL); //День разбит на 5 минутки
const STEP: number = 1;


const Range:FC<IRange>  = ({startDate, endDate, disabled = false, onChange}) => {
    const { screen } = useAppSelector(state => state.screen);
    const { rtl } = useAppSelector(state => state.auth);
    const mark = screen === SCREEN_VARIANT.MOBILE ? TIME_MARK_MOBILE : TIME_MARK;
    const [values, setValues] = useState<[number, number]>([
        Math.ceil(validTime(startDate) ? ((moment(startDate, TIME_FORMAT).get('hours') * 60 + moment(startDate, TIME_FORMAT).get('minutes')) / INTERVAL) : MIN),
        Math.ceil(validTime(endDate) ?  ((moment(endDate, TIME_FORMAT).get('hours') * 60 + moment(endDate, TIME_FORMAT).get('minutes')) / INTERVAL) : MAX)
    ]);
    const color = useGetColor();

    const onChangeRange = (values: number[]) => {
        const start = values[0] * INTERVAL === DAY_MINUTES ?  DAY_MINUTES - 1 : values[0] * INTERVAL,
            end = values[1] * INTERVAL === DAY_MINUTES ?  DAY_MINUTES - 1 : values[1] * INTERVAL;

        if(!disabled) {
            setValues([values[0], values[1]]);

            onChange({
                startDate: {
                    hours: Math.floor(start / 60),
                    minutes: start - Math.floor(start / 60) * 60
                },
                endDate: {
                    hours: Math.floor(end / 60),
                    minutes: end - Math.floor(end / 60) * 60
                }
            })
        }
    };

    useEffect(() => {
        const val1 = disabled ? MIN : Math.ceil(validTime(startDate) ? ((moment(startDate, TIME_FORMAT).get('hours') * 60 + moment(startDate, TIME_FORMAT).get('minutes')) / INTERVAL) : MIN);
        const val2 = disabled ? MAX : Math.ceil(validTime(endDate) ?  ((moment(endDate, TIME_FORMAT).get('hours') * 60 + moment(endDate, TIME_FORMAT).get('minutes')) / INTERVAL) : MAX)

        setValues([val1, val2]);
    },[startDate, endDate]);

    return (
        <ReactRange
            step={STEP}
            min={MIN}
            max={MAX}
            values={values}
            onChange={onChangeRange}
            rtl={false}
            disabled={disabled}
            renderTrack={({ props, children }: any) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        position: 'relative',
                        height: '10px',
                        width: '100%',
                        borderRadius: '100px',
                        opacity: disabled ? '.7' : '1',
                        background: getTrackBackground({
                            values: values,
                            colors: [color.LIGHT, color.BLUE2, color.LIGHT],
                            min: MIN,
                            max: MAX,
                        }),
                    }}
                >
                    {children}
                </div>
            )}
            renderThumb={({ index, props }: any) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        backgroundColor: color.BLUE2,
                        outline: 'none'
                    }}
                >
                    <Icon name={ICON_VARIANT.RANGE}/>
                    <div style={{
                        position: 'absolute',
                        top: '-20px',
                        color: '#000',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontWeight: '400',
                        fontSize: '12px',
                        zIndex: '10'
                    }}>
                        {moment(getDate()).add((INTERVAL * values[index] === DAY_MINUTES ? DAY_MINUTES - 1 : INTERVAL * values[index]), 'minutes').format(TIME_FORMAT)}
                    </div>
                </div>
            )}
            renderMark={({ props, index } : IMark) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        color: '#B8B8B8',
                        marginTop: '17px'
                    }}
                >
                    {index === MAX
                        ? '23:59'
                        : mark.find(item => item.mark === moment(getDate()).add(INTERVAL * index, 'minutes').format(TIME_FORMAT))?.mark
                    }
                </div>
            )}
        />
    )
};

export default Range
