import React, { FC, useRef, useState } from 'react';
// import moment from 'moment';
import moment from 'moment-jalaali'
import cn from 'classnames';
import Checkbox from "../../Input/Checkbox/Checkbox";
import Icon from '../../Icon/index';
import {TooltipProfile} from '../../Tooltip';
import DatePicker, {OnChangeDatePicker} from '../../DatePicker/index';
import { ICON_VARIANT } from "../../../assets/svg/index";
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import {arrayCompareCheckbox, arrayEqualIds} from "../../../utils/utils";
import {useTranslate} from "../../../hooks/useTranslate";
import {LIST_NAME} from "../../../store/reducers/LSlice";
import {useAppSelector} from "../../../hooks/redux";
import css from './ListBar.module.scss';
import {useDateFormat} from "../../../hooks/useDateFormat";

interface PropsListDate {
    name: LIST_NAME
    ids: number[],
    top?: number
    day: string | Date | null,
    datepicker?: boolean
    onCheckedAll?: (params: {ids: number[], value: boolean}) => void | undefined
    isChecked: boolean,
    onChangeDate?: (params: OnChangeDatePicker) => void
}

const ListBar: FC<PropsListDate> = ({name, day, ids, datepicker = true, top = 0, isChecked, onChangeDate, onCheckedAll}) => {
    const { filter, checked } = useAppSelector(state => state.l[name]);
    const translate = useTranslate();
    const { FORMAT_DATE } = useDateFormat();
    const now = moment().set({hour: 0, minute: 0, second: 0}).toDate();
    const barChecked = arrayEqualIds(checked, ids);
    // const date = day ? moment(day, 'YYYYMMDD').format(FORMAT_DATE) : moment(now).format(FORMAT_DATE);

    const onChange = (date: OnChangeDatePicker) => {
        onChangeDate && onChangeDate(date);
    };


    const getDate = () => {
      if(day && typeof day === 'string'){
          return moment(day, 'YYYYMMDD').format(FORMAT_DATE)
      } else if(day){
          return moment(day).format(FORMAT_DATE)
      } else {
          return moment(now).format(FORMAT_DATE);
      }
    };

    const date = getDate();

    return(
        <div className={cn(css.bar)} style={{top: top + 'px'}}>
            {onChangeDate && datepicker
                ? <DatePicker
                    header={
                        <TooltipProfile
                            title={translate.tooltip.list.date}
                        >
                            <div className={css.date}>
                                <div className={css.title}>
                                    {date}
                                </div>
                                <div className={css.arrow}>
                                    <Icon name={ICON_VARIANT.ARROW_DOWN}/>
                                </div>
                            </div>
                        </TooltipProfile>
                    }
                    startDate={filter.date_start || now}
                    endDate={filter.date_end || now}
                    startTime={filter.startTime}
                    endTime={filter.endTime}
                    label={filter.label}
                    showTime={Boolean(filter.startTime || filter.endTime || filter.label?.hours)}
                    sidStatistics={filter.sid_list}
                    onChange={onChange}/>
                : <TooltipProfile
                    title={translate.tooltip.list.date}
                >
                    <div className={css.date}>
                        <div className={css.title}>
                            {date}
                        </div>
                    </div>
                </TooltipProfile>
            }

            {onCheckedAll && isChecked
                ? <div className={css.checkbox}>
                    <Checkbox
                        variant={INPUT_VARINAT.BLUE}
                        placement={INPUT_PLACEMENT.START}
                        title={translate.list.select + ' ' + barChecked.length}
                        value={arrayCompareCheckbox(barChecked, ids)}
                        onChange={({value}: OnCheckboxChange) => onCheckedAll({ids: ids, value: value})}/>
                </div>
                : null
            }
        </div>
    )
};

export default ListBar