import React, { FC, useState, MouseEvent, FormEvent, useEffect} from 'react';
import {Helmet} from "react-helmet";
import cn from 'classnames';
import qs from 'query-string';
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from '../Slider';
import News from '../News';
import List from '../List';
import Topline from '../Topline';
import Dropdown, { DropdownItem } from '../Dropdown';
import Telegram from '../Telegram';
import BannerHeader from '../Banner/BannerHeader';
import Icon from '../Icon';
import Burger from '../Header/Burger';
import Footer from '../Footer';
import {TagList, TagLink} from '../Tags';
import Spinner from '../Spinner';
import Login from '../Modal/Login';
import MobileAuth from '../Modal/MobileAuth';
import DemoAuth from '../Modal/DemoAuth';
import Subscribe from '../Modal/Subscribe';
import MultiSession from '../Modal/MultiSession';
import PasswordRecovery from '../Modal/PasswordRecovery';
import LoginRecovery from '../Modal/LoginRecovery';
import { ICON_VARIANT } from "../../assets/svg";
import { useAppDispatch, useAppSelector} from "../../hooks/redux";
import {changeLang, confirmEmail, login, openMobileModal} from '../../store/reducers/AuthSlice';
import {setPasswordRecoveryOpen, setLoginOpen } from "../../store/reducers/ModalSlice";
import { SCREEN_VARIANT } from "../../types/IScreen";
import {useTranslate} from "../../hooks/useTranslate";
import {getFloor} from "../../store/reducers/FloorSlice";
import {ROUTE, SIDS, SUBSCRIBE} from "../../constants";
import {getCookie, setCookie} from "../../utils/utils";
import {setTour, setRun} from "../../store/reducers/TourSlice";
import {analyticsChangeLang, analyticsSubscribe, analyticsButtonNewsList} from "../../utils/analytics";
import {LANG, THEME} from "../../types/IUser";
import css from './Auth.module.scss';
import {DROPDOWN_PLACEMENT, DROPDOWN_VARIANT} from "../Dropdown/DropdownMenu";
import {initialStateList, LIST_NAME} from "../../store/reducers/LSlice";
import {LIST_VARIANT} from "../List/ListItem";


const HTML_LANG = {
    [LANG.RU]: 'ru',
    [LANG.EN]: 'en',
    [LANG.CN]: 'zh',
    [LANG.ES]: 'es',
    [LANG.EG]: 'ar',
    [LANG.FA]: 'fa'
};

const Auth: FC = () => {
    const { screen } = useAppSelector(state => state.screen);
    const { isOpenMobile, lang, theme, logout, rtl } = useAppSelector(state => state.auth);
    const { isLoading, floor } = useAppSelector(state => state.floor);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const urlParams: any = qs.parse(location.search, {arrayFormat: 'bracket'});

    const openAuth = () => {
        dispatch(setLoginOpen(true));
        dispatch(setRun(false));
    };

    const openMobile = () => {
        dispatch(openMobileModal());
        dispatch(setRun(false));
    };


    const openSubscribe = () => {
        const link = SUBSCRIBE[lang].link;
        analyticsSubscribe();
        window.open(link);
    };

    const onChangeLang = (lang: LANG) => {
        dispatch(changeLang(lang));

        analyticsChangeLang(lang);
    };

    const onFooterButton = (item: any) => {
        if(item.variant){
            analyticsButtonNewsList();
        }
    }

    useEffect(() => {
        if(urlParams?.confirm_email){
            dispatch(confirmEmail({
                hash: urlParams?.confirm_email,
                success: {
                    title: translate.auth.registration,
                    description: translate.auth.accountConfirm,
                    button: translate.auth.close
                },
                error: {
                    title: translate.auth.registration,
                    description: translate.auth.accountConfirm2,
                    button: translate.auth.close
                }
            }))
        }

        if(urlParams?.password_recovery){
            dispatch(setPasswordRecoveryOpen(true));
        }
        dispatch(getFloor({
            sid: SIDS[lang].INDEX_ARTICLE
        }));

        if((location.pathname !== ROUTE.INDEX && !logout) || (urlParams?.auth !== undefined)){
            dispatch(setLoginOpen(true));
            setCookie('tour-auth', '1', 60 * 1000 * 60 * 24 * 730 * 1000, '/');
            setCookie('tour', '1', 60 * 1000 * 60 * 24 * 730 * 1000, '/');
        } else if(!getCookie('tour-auth') && getCookie('tour-auth') !== '1'){
            setTimeout(() => {
                localStorage.clear();
                dispatch(setTour(1));
            }, 200);
        }
    },[]);


    return (
        <>
            <Helmet htmlAttributes={{ lang : HTML_LANG[lang], dir: rtl ? 'rtl' : 'ltr'}}>
                <title>{translate.title}</title>
            </Helmet>

            {/*<Topline/>*/}

            <div className={css.header}>
                <div className={css.headerWrapper}>
                    <Link to={'/'} className={css.logo}>
                        {theme === THEME.RIA
                            ? <Icon name={ICON_VARIANT.LOGO}/>
                            : <Icon name={ICON_VARIANT.LOGO_SPUTNIK}/>
                        }
                    </Link>
                    {screen === SCREEN_VARIANT.DESKTOP
                        ? <div className={css.headerButton}>
                            <button
                                className={css.button}
                                onClick={openSubscribe}>
                                    {translate.auth.subscribe}
                            </button>
                            <div>
                                <Dropdown
                                    header={<>
                                        <button className={cn(css.button, css.icon)}>
                                            {lang}
                                        </button>
                                    </>}
                                    width={'190px'}
                                    variant={DROPDOWN_VARIANT.LIST}
                                    placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
                                >
                                    {Object.values(LANG).map(l =>
                                        <DropdownItem key={l} onClick={() => onChangeLang(l)}>{l}</DropdownItem>
                                    )}
                                </Dropdown>
                            </div>
                            <button
                                data-tour="login"
                                className={cn(css.button, css.icon)}
                                onClick={openAuth}>
                                    <Icon name={ICON_VARIANT.USER}/>
                            </button>
                        </div>
                        : <Burger
                            tour={'login'}
                            active={isOpenMobile}
                            onClick={openMobile}/>
                    }
                </div>
            </div>
            <div className={css.page}>
                {isLoading
                    ? <Spinner/>
                    : <>
                        <BannerHeader/>
                        <div className={css.grid}>
                            <News articles={floor}/>
                            {SIDS[lang].INDEX
                                ? <div className={css.list}>
                                    <List
                                        name={LIST_NAME.INDEX}
                                        variant={LIST_VARIANT.MINI}
                                        onArticle={() =>{}}
                                        datepicker={false}
                                        title={translate.auth.news}
                                        withAuth={false}
                                        filterParams={{
                                            ...initialStateList.filter,
                                            ...{sid_list: [SIDS[lang].INDEX]}
                                        }}
                                    />
                                </div>
                                : <Telegram/>
                            }
                        </div>
                        <Slider/>
                        <TagList>
                            {translate.footer.tags.map((item, i) =>
                                <TagLink key={i} href={item.link} variant={item.variant} onClick={() => onFooterButton(item)}>{item.title}</TagLink>
                            )}
                        </TagList>
                        <Footer/>
                    </>
                }
            </div>

            <MobileAuth/>
            <Login/>
            <DemoAuth/>
            <Subscribe/>
            <MultiSession/>
            <PasswordRecovery/>
            <LoginRecovery/>
        </>
    );
};

export default Auth