import React, { FC, useEffect, useState } from 'react';
import Premium from './Premium';
import Default from './Default';
import {useAppSelector} from "../../hooks/redux";
import {diff, getSearchParams} from "../../utils/utils";
import {useUserPremium} from "../../hooks/useUserPremium";
import {LIST_NAME, initialStateList} from "../../store/reducers/LSlice";
import {GS_VARIANT} from "../../store/reducers/ScreenSlice";

const Index: FC  = () => {
    const { filter } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]);
    const { premiumScreen } = useAppSelector(state => state.screen);
    const premium = useUserPremium();

    // if((premiumScreen === GS_VARIANT.GS2 || !premium) && !diff(filter, initialStateList.filter, ['fields', 'priority', 'case_sensitive', 'media_type', 'stopwords', 'query',  'sid_list', 'name', 'ids', 'id']).length){
    //     return <Default/>
    // }

    if(!premium && premiumScreen === GS_VARIANT.GS2 && !diff(filter, initialStateList.filter, ['fields', 'priority', 'case_sensitive', 'media_type', 'stopwords', 'query',  'sid_list', 'name', 'ids', 'id']).length){
        return <Default/>
    }

    return <Premium/>
};

export default Index