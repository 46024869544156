import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL} from "../../constants";
import {IHistory} from "../../types/IHistory";
import { addStoreHistory } from './StoreSlice';
import {getUnique, serialize} from "../../utils/utils";

export enum LOG_FORMAT {
    VK = 'vk',
    TELEGRAM = 'telegram',
    OK = 'ok',
    MAIL = 'mail',
    COPY = 'copy',
    PRINT = 'print',
    PDF = 'pdf',
    ZIP = 'zip',
    DOC = 'doc',
    NEWSML = 'newsml'
}

interface IHistoryStore {
    isLoading: boolean,
    onpage: number,
    exportIds: number[],
    total: number,
    checked: {
        [key: string]: number[]
    },
    filter: {
        format: LOG_FORMAT | '',
        limit: number
        offset: number,
        fd: Date | null,
        td: Date | null,
    }
}

export const HISTORY_LIMIT = 5;

const initialState: IHistoryStore = {
    isLoading: false,
    onpage: 0,
    exportIds: [],
    total: 0,
    checked: {},
    filter: {
        format: '',
        limit: 5,
        offset: 0,
        fd: null,
        td: null
    }
};


export const getHistory = createAsyncThunk(
    'history/getHistory',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const state = getState() as any;
            const { filter } = state.history;

            const response = await axios.post(URL.GET_HISTORY, qs.stringify(
                serialize(filter)
            , {arrayFormat: 'bracket'}), {withCredentials: true});


            const { onpage, total, records} = response.data;
            const exportIds = getUnique(((records as IHistory[]) || []).map(article => article.export_id));

            dispatch(addStoreHistory(records));

            return {
                onpage,
                total,
                exportIds
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const logHistory = createAsyncThunk(
    'history/logHistory',
    async (params: {article_id: number[], format: LOG_FORMAT}, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await axios.post(URL.LOG_HISTORY, qs.stringify(serialize(params),{arrayFormat: 'bracket'}), {withCredentials: true});
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const historylice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setChecked(state: IHistoryStore, action: PayloadAction<{id: string, article_id: number}>){
            const {id, article_id} = action.payload;

            if(!state.checked[id]){
                state.checked[id] = [];
            }

            state.checked[id] = state.checked[id].includes(article_id) ? state.checked[id].filter(e => e !== article_id) : state.checked[id].concat(article_id);
        },
        setCheckedIds(state, action: PayloadAction<{id: string, article_ids: number[], value: boolean}>){
            const { id, article_ids, value } = action.payload;

            if(!state.checked[id]){
                state.checked[id] = [];
            }

            for(let i = 0; i < article_ids.length; i++){
                if(value && !state.checked[id].includes(article_ids[i])){
                    state.checked[id] = state.checked[id].concat(article_ids[i])
                } else if(!value && state.checked[id].includes(article_ids[i])) {
                    state.checked[id] = state.checked[id].filter(e => e !== article_ids[i])
                }
            }
        },
        setParams(state, action: PayloadAction<object>){
            const params = action.payload;

            (Object.keys(params) as Array<keyof typeof params>).map(p => {
                state.filter[p] = params[p];
            });
        }
    },
    extraReducers: {
        [getHistory.pending.type]: (state: IHistoryStore) => {
            state.isLoading = true;
        },
        [getHistory.fulfilled.type]: (state: IHistoryStore, action: PayloadAction<IHistoryStore>) => {
            const { onpage, total, exportIds } = action.payload;

            state.onpage = onpage;
            state.total = total;
            state.exportIds = exportIds;
            state.isLoading = false;
        },
    }
});

export const {setChecked, setCheckedIds, setParams } = historylice.actions;
export default historylice.reducer;
