import React, { FC, useRef, useState, MouseEvent, useEffect } from 'react';
import cn from 'classnames';
import css from './Burger.module.scss';

interface Props {
    active?: boolean,
    onClick?: () => void,
    tour?: string
}

const Burger: FC<Props>  = ({active = false, onClick, tour}) => {
    return (
        <div
            className={cn(css.burger,{[css.active]: active})}
            data-tour={tour}
            onClick={onClick}>
            <i/><i/><i/>
        </div>
    )
};

export default Burger;