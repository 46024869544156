import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import { Form, Row, Cell, Title } from '../../../../component/Input/Form';
import Input  from '../../../../component/Input/Input/Input';
import TextArea from '../../../../component/Input/TextArea/TextArea';
import InputTags from '../../../../component/Input/InputTags';
import Button from '../../../../component/Button';
import {
    FORM_CELL_VARINAT,
    FORM_ROW_ALIGN, FORM_ROW_JUSTIFY,
    FORM_TITLE_MARGIN,
    FORM_TITLE_VARINAT
} from "../../../../component/Input/Form";
import {INPUT_VARINAT, OnInputChange} from "../../../../types/IInput";
import {useTranslate} from "../../../../hooks/useTranslate";
import {useAppDispatch} from "../../../../hooks/redux";
import {BUTTON_VARIANT} from "../../../../component/Button";

interface Props {
    placement?: boolean
}

const Newsletter: FC<Props> = ({}) => {
    const translate = useTranslate();
    const dispatch = useAppDispatch();
    const [v, setV] = useState<string[]>([]);

    const onChangeName = ({name, value}: OnInputChange) => {
        // dispath(setName({placement: placement, name: value}));
    };

    const onSubmit = () => {

    };

    return(
        <>
            <Form>
                <Row align={FORM_ROW_ALIGN.CENTER}>
                    <Title varinat={FORM_TITLE_VARINAT.H1} margin={FORM_TITLE_MARGIN.NO_MARGIN}>
                        {translate.pages.export.mailName}
                    </Title>
                    <Cell varinat={FORM_CELL_VARINAT.FULL}>
                        <Input
                            variant={INPUT_VARINAT.BLUE}
                            name={'name'}
                            value={''}
                            onChange={onChangeName}/>
                    </Cell>
                </Row>
                <Title varinat={FORM_TITLE_VARINAT.H1}>
                    {translate.senMail.settings}
                </Title>
                <Row>
                    <TextArea
                        variant={INPUT_VARINAT.BLUE}
                        title={translate.senMail.annotate}
                        name={'name'}
                        value={''}
                        onChange={onChangeName}/>
                </Row>
                <Row>
                    <InputTags
                        value={v}
                        onChange={(a: string[]) => setV(a)}
                    />
                </Row>
                <Row justify={FORM_ROW_JUSTIFY.END}>
                    <Button
                        // disabled={!name}
                        // isLoading={isLoading}
                        height={'40px'}
                        onClick={onSubmit}>
                        {translate.pages.export.send}
                        {/*{id ? translate.filter.update : translate.filter.create}*/}
                    </Button>
                </Row>
            </Form>
        </>
    )
};

export default Newsletter