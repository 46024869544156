import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import {useTranslate} from "../../../hooks/useTranslate";
import {IListFilterSlice} from "../../../store/reducers/LSlice";
import {declOfNum} from "../../../utils/utils";
import {useAppSelector} from "../../../hooks/redux";
import css from './ListCount.module.scss';

interface Props {
    hasTitle?: boolean,
    filter: IListFilterSlice,
    resultsCount: number
}

const ListCount: FC<Props> = ({hasTitle = false, filter, resultsCount}) => {
    const translate = useTranslate();
    const { queries } = useAppSelector(state => state.auth);

    const getCount = () => {
        const query = queries.find(q => q.id === filter.id);
        if(filter.id && query){
            return (query.value as any).sid_list?.length
        }
        return filter.sid_list.length
    };

    const countList = getCount();
    const titleLenta = declOfNum(countList, [translate.list.lenta.nominative, translate.list.lenta.singular, translate.list.lenta.plural]);
    const titleNews = declOfNum(resultsCount, [translate.list.news.nominative, translate.list.news.singular, translate.list.news.plural]);

    return(
        <div className={css.container}>
            <div className={cn(css.count, {[css.hasTitle]: hasTitle})}>
                <div className={css.text}>
                    {translate.list.resultCount}
                    {' '}
                    {countList
                        ? translate.list.for + ' ' + countList + ' ' + titleLenta + ' '
                        : null
                    }
                    {resultsCount !== null
                        ? <b>{resultsCount} {titleNews}</b>
                        : null
                    }
                </div>
            </div>
        </div>
    )
};

export default ListCount