import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import {Helmet} from "react-helmet";
import Screen from '../../component/Screen';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Article from '../../component/Article/index';
import Split from '../../component/Split/index';
import List from '../../component/List';
import Back from '../../component/Back';
import Scrollbar from '../../component/Scrollbar/index';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {unloadArticle, getArticle} from "../../store/reducers/ArticleSlice";
import {useTranslate} from "../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {IArticle} from "../../types/IArticle";
import {LIST_VARIANT} from "../../component/List/ListItem";
import {ROUTE, SIDS} from "../../constants";
import {useUserPremium} from "../../hooks/useUserPremium";
import {alert} from "../../store/reducers/AlertSlice";
import {LIST_NAME, initialStateList, setParams} from "../../store/reducers/LSlice";
import cssL from '../../component/Layout/Layout.module.scss';
import {arrayCompareSome, objKeys} from "../../utils/utils";

interface TabProps {
    id: number,
    title: string
}

const Articles: FC  = () => {
    const { lang, user} = useAppSelector(state => state.auth);
    const { screen } = useAppSelector(state => state.screen);
    const { isLoading, article } = useAppSelector(state => state.article);
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const premium = useUserPremium();
    const navigate = useNavigate();
    const terms = objKeys(user?.terms || {});
    const sids = article?.categories.map(item => item.list_sid) || [];
    const TABS: TabProps[] = [
        {
            id: 1,
            title: translate.pages.articles.article
        },{
            id: 2,
            title: translate.pages.articles.title
        }
    ];
    const [tab, setTab] = useState<TabProps>(TABS[0]);

    const onArticle = (article: IArticle) => {

        if(premium){
            // window.open(ROUTE.ARTICLE + article.article_id);
            navigate(ROUTE.ARTICLE + article.article_id);
        } else{
            dispatch(alert({
                title: translate.list.subscribe,
                description:translate.list.subscribeAnnounce,
                button: ''
            }))
        }
    };

    useEffect(() => {
        if(id){
            dispatch(getArticle({
                id: parseInt(id)
            }));

            dispatch(setParams({
                name: LIST_NAME.ARTICLE_LIST,
                params: {
                    sid_list: SIDS[lang].ARTICLE_NEWS
                }
            }))
        }
    },[id]);

    useEffect(() => {
        return () => {
            dispatch(unloadArticle());
        }
    },[]);

    if(sids.length && !premium && !arrayCompareSome(sids, terms)){
        navigate(ROUTE.INDEX);
        dispatch(alert({
            title: translate.list.subscribe,
            description: translate.list.subscribeAnnounce,
            button: ''
        }));
    }

    return (
        <>
            <Helmet>
                <title>{!isLoading && article ? article.article_title + ' - ' + translate.title: ''}</title>
            </Helmet>
            <div className={cn(cssL.article,{[cssL.block]: tab.id === 1 })}>
                <Screen screen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}>
                    <div className={cssL.head}>
                        <Back title={translate.back}/>
                        <div className={cssL.tab}>
                            {TABS.map(item =>
                                <div className={cn(cssL.tabItem, {[cssL.active]: item.id === tab.id})} key={item.id} onClick={() => setTab(item)}>{item.title}</div>
                            )}
                        </div>
                    </div>
                </Screen>

                <Split sizes={[70,30]} tab={screen !== SCREEN_VARIANT.DESKTOP ? tab.id : null}>
                    <Scrollbar>
                        <div className={cssL.inner}>
                            <Article/>
                        </div>
                    </Scrollbar>
                    <List
                        name={LIST_NAME.ARTICLE_LIST}
                        variant={LIST_VARIANT.MINI}
                        onArticle={onArticle}
                        priority={true}
                        isBreaking={false}
                        title={translate.pages.articles.title}
                        filterParams={{
                            ...initialStateList.filter,
                            ...{sid_list: [SIDS[lang].ARTICLE_NEWS]}
                        }}
                    />
                </Split>
            </div>
        </>
    )
};

export default Articles