import React, { FC } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectFade, A11y } from "swiper";
import Icon from '../Icon';
import {ICON_VARIANT} from "../../assets/svg";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import riaRuSm1 from '../../assets/images/slider/ria/ru/sm1.png'
import riaRuSm2 from '../../assets/images/slider/ria/ru/sm2.png'
import riaRuSm3 from '../../assets/images/slider/ria/ru/sm3.png'
import riaRuSm4 from '../../assets/images/slider/ria/ru/sm4.png'
import riaRuSm5 from '../../assets/images/slider/ria/ru/sm5.png'
import riaRuSm6 from '../../assets/images/slider/ria/ru/sm6.png'
import riaRuMd1 from '../../assets/images/slider/ria/ru/md1.png'
import riaRuMd2 from '../../assets/images/slider/ria/ru/md2.png'
import riaRuMd3 from '../../assets/images/slider/ria/ru/md3.png'
import riaRuMd4 from '../../assets/images/slider/ria/ru/md4.png'
import riaRuMd5 from '../../assets/images/slider/ria/ru/md5.png'
import riaRuMd6 from '../../assets/images/slider/ria/ru/md6.png'
import riaRuLg1 from '../../assets/images/slider/ria/ru/lg1.png'
import riaRuLg2 from '../../assets/images/slider/ria/ru/lg2.png'
import riaRuLg3 from '../../assets/images/slider/ria/ru/lg3.png'
import riaRuLg4 from '../../assets/images/slider/ria/ru/lg4.png'
import riaRuLg5 from '../../assets/images/slider/ria/ru/lg5.png'
import riaRuLg6 from '../../assets/images/slider/ria/ru/lg6.png'
import riaEnSm1 from '../../assets/images/slider/ria/en/sm1.png'
import riaEnSm2 from '../../assets/images/slider/ria/en/sm2.png'
import riaEnSm3 from '../../assets/images/slider/ria/en/sm3.png'
import riaEnSm4 from '../../assets/images/slider/ria/en/sm4.png'
import riaEnSm5 from '../../assets/images/slider/ria/en/sm5.png'
import riaEnSm6 from '../../assets/images/slider/ria/en/sm6.png'
import riaEnMd1 from '../../assets/images/slider/ria/en/md1.png'
import riaEnMd2 from '../../assets/images/slider/ria/en/md2.png'
import riaEnMd3 from '../../assets/images/slider/ria/en/md3.png'
import riaEnMd4 from '../../assets/images/slider/ria/en/md4.png'
import riaEnMd5 from '../../assets/images/slider/ria/en/md5.png'
import riaEnMd6 from '../../assets/images/slider/ria/en/md6.png'
import riaEnLg1 from '../../assets/images/slider/ria/en/lg1.png'
import riaEnLg2 from '../../assets/images/slider/ria/en/lg2.png'
import riaEnLg3 from '../../assets/images/slider/ria/en/lg3.png'
import riaEnLg4 from '../../assets/images/slider/ria/en/lg4.png'
import riaEnLg5 from '../../assets/images/slider/ria/en/lg5.png'
import riaEnLg6 from '../../assets/images/slider/ria/en/lg6.png'
import sputnikRuSm1 from '../../assets/images/slider/sputnik/ru/sm1.png'
import sputnikRuSm2 from '../../assets/images/slider/sputnik/ru/sm2.png'
import sputnikRuSm3 from '../../assets/images/slider/sputnik/ru/sm3.png'
import sputnikRuSm4 from '../../assets/images/slider/sputnik/ru/sm4.png'
import sputnikRuSm5 from '../../assets/images/slider/sputnik/ru/sm5.png'
import sputnikRuSm6 from '../../assets/images/slider/sputnik/ru/sm6.png'
import sputnikRuMd1 from '../../assets/images/slider/sputnik/ru/md1.png'
import sputnikRuMd2 from '../../assets/images/slider/sputnik/ru/md2.png'
import sputnikRuMd3 from '../../assets/images/slider/sputnik/ru/md3.png'
import sputnikRuMd4 from '../../assets/images/slider/sputnik/ru/md4.png'
import sputnikRuMd5 from '../../assets/images/slider/sputnik/ru/md5.png'
import sputnikRuMd6 from '../../assets/images/slider/sputnik/ru/md6.png'
import sputnikRuLg1 from '../../assets/images/slider/sputnik/ru/lg1.png'
import sputnikRuLg2 from '../../assets/images/slider/sputnik/ru/lg2.png'
import sputnikRuLg3 from '../../assets/images/slider/sputnik/ru/lg3.png'
import sputnikRuLg4 from '../../assets/images/slider/sputnik/ru/lg4.png'
import sputnikRuLg5 from '../../assets/images/slider/sputnik/ru/lg5.png'
import sputnikRuLg6 from '../../assets/images/slider/sputnik/ru/lg6.png'
import sputnikEnSm1 from '../../assets/images/slider/sputnik/en/sm1.png'
import sputnikEnSm2 from '../../assets/images/slider/sputnik/en/sm2.png'
import sputnikEnSm3 from '../../assets/images/slider/sputnik/en/sm3.png'
import sputnikEnSm4 from '../../assets/images/slider/sputnik/en/sm4.png'
import sputnikEnSm5 from '../../assets/images/slider/sputnik/en/sm5.png'
import sputnikEnSm6 from '../../assets/images/slider/sputnik/en/sm6.png'
import sputnikEnMd1 from '../../assets/images/slider/sputnik/en/md1.png'
import sputnikEnMd2 from '../../assets/images/slider/sputnik/en/md2.png'
import sputnikEnMd3 from '../../assets/images/slider/sputnik/en/md3.png'
import sputnikEnMd4 from '../../assets/images/slider/sputnik/en/md4.png'
import sputnikEnMd5 from '../../assets/images/slider/sputnik/en/md5.png'
import sputnikEnMd6 from '../../assets/images/slider/sputnik/en/md6.png'
import sputnikEnLg1 from '../../assets/images/slider/sputnik/en/lg1.png'
import sputnikEnLg2 from '../../assets/images/slider/sputnik/en/lg2.png'
import sputnikEnLg3 from '../../assets/images/slider/sputnik/en/lg3.png'
import sputnikEnLg4 from '../../assets/images/slider/sputnik/en/lg4.png'
import sputnikEnLg5 from '../../assets/images/slider/sputnik/en/lg5.png'
import sputnikEnLg6 from '../../assets/images/slider/sputnik/en/lg6.png'
import "swiper/css";
import './SwiperPagination.scss';
import css from './Slider.module.scss';
import {LANG, THEME} from "../../types/IUser";
SwiperCore.use([EffectFade, Navigation, Pagination, A11y]);

const IMAGES = {
    [THEME.RIA]: {
        [LANG.RU]: {
            [SCREEN_VARIANT.MOBILE]: [riaRuSm1, riaRuSm2, riaRuSm3, riaRuSm4, riaRuSm5, riaRuSm6],
            [SCREEN_VARIANT.TABLET]: [riaRuMd1, riaRuMd2, riaRuMd3, riaRuMd4, riaRuMd5, riaRuMd6],
            [SCREEN_VARIANT.DESKTOP]: [riaRuLg1, riaRuLg2, riaRuLg3, riaRuLg4, riaRuLg5, riaRuLg6],
        },
        [LANG.EN]: {
            [SCREEN_VARIANT.MOBILE]: [riaEnSm1, riaEnSm2, riaEnSm3, riaEnSm4, riaEnSm5, riaEnSm6],
            [SCREEN_VARIANT.TABLET]: [riaEnMd1, riaEnMd2, riaEnMd3, riaEnMd4, riaEnMd5, riaEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [riaEnLg1, riaEnLg2, riaEnLg3, riaEnLg4, riaEnLg5, riaEnLg6],
        },
        [LANG.ES]: {
            [SCREEN_VARIANT.MOBILE]: [riaEnSm1, riaEnSm2, riaEnSm3, riaEnSm4, riaEnSm5, riaEnSm6],
            [SCREEN_VARIANT.TABLET]: [riaEnMd1, riaEnMd2, riaEnMd3, riaEnMd4, riaEnMd5, riaEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [riaEnLg1, riaEnLg2, riaEnLg3, riaEnLg4, riaEnLg5, riaEnLg6],
        },
        [LANG.CN]: {
            [SCREEN_VARIANT.MOBILE]: [riaEnSm1, riaEnSm2, riaEnSm3, riaEnSm4, riaEnSm5, riaEnSm6],
            [SCREEN_VARIANT.TABLET]: [riaEnMd1, riaEnMd2, riaEnMd3, riaEnMd4, riaEnMd5, riaEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [riaEnLg1, riaEnLg2, riaEnLg3, riaEnLg4, riaEnLg5, riaEnLg6],
        },
        [LANG.FA]: {
            [SCREEN_VARIANT.MOBILE]: [riaEnSm1, riaEnSm2, riaEnSm3, riaEnSm4, riaEnSm5, riaEnSm6],
            [SCREEN_VARIANT.TABLET]: [riaEnMd1, riaEnMd2, riaEnMd3, riaEnMd4, riaEnMd5, riaEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [riaEnLg1, riaEnLg2, riaEnLg3, riaEnLg4, riaEnLg5, riaEnLg6],
        },
        [LANG.EG]: {
            [SCREEN_VARIANT.MOBILE]: [riaEnSm1, riaEnSm2, riaEnSm3, riaEnSm4, riaEnSm5, riaEnSm6],
            [SCREEN_VARIANT.TABLET]: [riaEnMd1, riaEnMd2, riaEnMd3, riaEnMd4, riaEnMd5, riaEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [riaEnLg1, riaEnLg2, riaEnLg3, riaEnLg4, riaEnLg5, riaEnLg6],
        }
    },
    [THEME.SPUNTIK]: {
        [LANG.RU]: {
            [SCREEN_VARIANT.MOBILE]: [sputnikRuSm1, sputnikRuSm2, sputnikRuSm3, sputnikRuSm4, sputnikRuSm5, sputnikRuSm6],
            [SCREEN_VARIANT.TABLET]: [sputnikRuMd1, sputnikRuMd2, sputnikRuMd3, sputnikRuMd4, sputnikRuMd5, sputnikRuMd6],
            [SCREEN_VARIANT.DESKTOP]: [sputnikRuLg1, sputnikRuLg2, sputnikRuLg3, sputnikRuLg4, sputnikRuLg5, sputnikRuLg6],
        },
        [LANG.EN]: {
            [SCREEN_VARIANT.MOBILE]: [sputnikEnSm1, sputnikEnSm2, sputnikEnSm3, sputnikEnSm4, sputnikEnSm5, sputnikEnSm6],
            [SCREEN_VARIANT.TABLET]: [sputnikEnMd1, sputnikEnMd2, sputnikEnMd3, sputnikEnMd4, sputnikEnMd5, sputnikEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [sputnikEnLg1, sputnikEnLg2, sputnikEnLg3, sputnikEnLg4, sputnikEnLg5, sputnikEnLg6],
        },
        [LANG.ES]: {
            [SCREEN_VARIANT.MOBILE]: [sputnikEnSm1, sputnikEnSm2, sputnikEnSm3, sputnikEnSm4, sputnikEnSm5, sputnikEnSm6],
            [SCREEN_VARIANT.TABLET]: [sputnikEnMd1, sputnikEnMd2, sputnikEnMd3, sputnikEnMd4, sputnikEnMd5, sputnikEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [sputnikEnLg1, sputnikEnLg2, sputnikEnLg3, sputnikEnLg4, sputnikEnLg5, sputnikEnLg6],
        },
        [LANG.CN]: {
            [SCREEN_VARIANT.MOBILE]: [sputnikEnSm1, sputnikEnSm2, sputnikEnSm3, sputnikEnSm4, sputnikEnSm5, sputnikEnSm6],
            [SCREEN_VARIANT.TABLET]: [sputnikEnMd1, sputnikEnMd2, sputnikEnMd3, sputnikEnMd4, sputnikEnMd5, sputnikEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [sputnikEnLg1, sputnikEnLg2, sputnikEnLg3, sputnikEnLg4, sputnikEnLg5, sputnikEnLg6],
        },
        [LANG.FA]: {
            [SCREEN_VARIANT.MOBILE]: [sputnikEnSm1, sputnikEnSm2, sputnikEnSm3, sputnikEnSm4, sputnikEnSm5, sputnikEnSm6],
            [SCREEN_VARIANT.TABLET]: [sputnikEnMd1, sputnikEnMd2, sputnikEnMd3, sputnikEnMd4, sputnikEnMd5, sputnikEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [sputnikEnLg1, sputnikEnLg2, sputnikEnLg3, sputnikEnLg4, sputnikEnLg5, sputnikEnLg6],
        },
        [LANG.EG]: {
            [SCREEN_VARIANT.MOBILE]: [sputnikEnSm1, sputnikEnSm2, sputnikEnSm3, sputnikEnSm4, sputnikEnSm5, sputnikEnSm6],
            [SCREEN_VARIANT.TABLET]: [sputnikEnMd1, sputnikEnMd2, sputnikEnMd3, sputnikEnMd4, sputnikEnMd5, sputnikEnMd6],
            [SCREEN_VARIANT.DESKTOP]: [sputnikEnLg1, sputnikEnLg2, sputnikEnLg3, sputnikEnLg4, sputnikEnLg5, sputnikEnLg6],
        }
    }
};

const Slider: FC = () => {
    const { theme, lang }= useAppSelector(state => state.auth);
    const { screen }= useAppSelector(state => state.screen);

    return (
        <div className={css.slider}>
            {screen === SCREEN_VARIANT.DESKTOP
                ? <div>
                    <button className={cn(css.nav, css.prev)} data-nav={`prev-1`}>
                        <Icon name={ICON_VARIANT.ARROW_LEFT}/>
                    </button>
                    <button className={cn(css.nav, css.next)} data-nav={`next-1`}>
                        <Icon name={ICON_VARIANT.ARROW_RIGHT}/>
                    </button>
                </div>
                : null
            }
            <Swiper
                navigation={{
                    prevEl: `[data-nav="prev-1"]`,
                    nextEl: `[data-nav="next-1"]`
                }}
                // slidesPerView={"auto"}
                pagination={{
                    dynamicBullets: true,
                }}
                loop={true}
                speed={300}
                // modules={[Pagination]}
            >
                {IMAGES[theme]?.[lang]?.[screen].map((item, i) =>
                    <SwiperSlide key={i}>
                        <div className={css.slide}>
                            <img src={item}/>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    );
};

export default Slider