import React, { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import ArticleMedia from "../Article/ArticleMedia";
import {ROUTE} from "../../constants";
import {useAppDispatch} from "../../hooks/redux";
import { setLoginOpen } from "../../store/reducers/ModalSlice";
import {IArticle} from "../../types/IArticle";
import {analyticsArticle} from "../../utils/analytics";
import css from './News.module.scss'

interface Props {
    articles: IArticle[] | null
}

const News: FC<Props> = ({articles}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    if(!articles){
        return <div className={css.news}/>
    }

    const { article_title, article_id, article_lead, medias } = articles[0];


    const onClick = (e: MouseEvent) => {
        navigate(ROUTE.ARTICLE + article_id);
        dispatch(setLoginOpen(true));

        analyticsArticle();
    };

    return (
        <div className={css.news}>
            {medias && medias.length
                ? <div className={css.media} onClick={onClick}>
                    <ArticleMedia media={medias}/>
                </div>
                : null
            }
            <div className={css.title} onClick={onClick}>
                {article_title}
            </div>
            <div className={css.announce} dangerouslySetInnerHTML={{__html: article_lead}}/>
        </div>
    );
};

export default News