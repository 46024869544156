import React, { FC, useState, useEffect, useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import ReactDOM from 'react-dom';
import css from './Tooltip.module.scss';
import {getPlacement, getScrollParent} from "../../utils/utils";
import {DROPDOWN_PLACEMENT} from "../Dropdown/DropdownMenu";
import useScroll from "../../hooks/useScroll";
import {ICON_VARIANT} from "../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslate} from "../../hooks/useTranslate";
import {initialProfileLenta, setProfileLenta} from "../../store/reducers/AuthSlice";

interface Props {
    title: string | React.ReactNode,
    placement?: DROPDOWN_PLACEMENT,
    maxWidth?: number,
    delay?: number,
    stopPropagation?: boolean,
    isClick?: boolean,
    isOver?: boolean,
    offsetX?: number,
    offsetY?: number,
    varinat?: TOOLTIP_VARIANT
}

export enum TOOLTIP_VARIANT {
    DEFAULT = 'default',
    INPUT = 'input',
    FILTER = 'filter',
    LIGHT = 'light',
    BLUE = 'blue'
}

export const TooltipIcon:FC<{varinat?: TOOLTIP_VARIANT}>  = ({varinat = TOOLTIP_VARIANT.DEFAULT}) => {
    return (
        <div className={classNames(css.tooltipIcon, css[varinat])}>
            <Icon name={ICON_VARIANT.QUESTION}/>
        </div>
    )
};

export const TooltipProfile: FC<Props> = ({title, children, ...props}) => {
    const { profile_lenta } = useAppSelector(state => state.auth);
    const profile = profile_lenta?.find(item => item.name === 'main');
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onDisabled = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if(profile) {
            dispatch(setProfileLenta({
                name: profile.name,
                type: profile.type,
                value: {...profile.value, ...{tooltip: false}}
            }))
        }
    };

    if(!title || !profile?.value.tooltip || true){
        return (
            <>
                {children}
            </>
        );
    }


    return <Tooltip
            title={<>
                <div>{title}</div>
                {translate.tooltip.disable
                    ? <div className={css.disabled} onClick={onDisabled}>
                        {translate.tooltip.disable}
                    </div>
                    : null
                }
            </>}
            {...props}>
        {React.Children.count(children)
            ? children
            : <span/>
        }
    </Tooltip>
};

const Tooltip: FC<Props> = ({title, placement = DROPDOWN_PLACEMENT.CENTER_BOTTOM, varinat = TOOLTIP_VARIANT.DEFAULT, isOver = true, isClick = false, stopPropagation = false, offsetX = 0, offsetY = 0, maxWidth = 250, delay = 350, children}) => {
    const { profile_lenta, rtl } = useAppSelector(state => state.auth);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [transition, setTransition] = useState<boolean>(false);
    const [coordinate, setCoordinate] = useState<{x: number, y: number}>({x: 0, y: 0});
    const parent = useRef<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const refTooltip = useRef<HTMLDivElement | null>(null);
    const refTimer = useRef<any>(null);

    const onOpen = (e: MouseEvent) => {
        if(stopPropagation) {
            e.stopPropagation()
        }

        if(isClick) {
            if (!isOpen) {
                parent.current = getScrollParent(ref.current);
            }
            setOpen(true);
        }
    };

    const onMouseOver = (e: MouseEvent) => {
        clearTimeout(refTimer.current);
        if(!isClick && isOver) {
            refTimer.current = setTimeout(() => {
                if (!isOpen) {
                    parent.current = getScrollParent(ref.current);
                }
                setOpen(true);
            }, 300);
        }
    };

    const onMouseLeave = (e: MouseEvent) => {
        clearTimeout(refTimer.current);
        refTimer.current = setTimeout(() => {
            setOpen(false);
        }, delay)
    };

    useEffect(() => {
        if(isOpen && ref.current && refTooltip.current){
            const t = getPlacement(ref.current, refTooltip.current, {placement, offsetX, offsetY, rtl});
            setCoordinate(t);
        }
    }, [isOpen]);

    useEffect(() => {
        setTimeout(() => {
            setTransition(isOpen);
        }, 10);
    },[isOpen]);

    useEffect(() => {
        setOpen(false);
    },[title]);

    useScroll(parent, isOpen, () => setOpen(false));

    return (
        <div className={css.container}
             onMouseOver={onMouseOver}
             onMouseLeave={onMouseLeave}
             ref={ref}
        >
            <div className={css.header} onClick={onOpen}>
                {React.Children.count(children)
                    ? children
                    : title ? <TooltipIcon varinat={varinat}/> : null
                }
            </div>
            {isOpen && title
                ? ReactDOM.createPortal(
                    <div
                        ref={refTooltip}
                        style={{
                            left: coordinate.x + 'px',
                            top: coordinate.y + 'px',
                            maxWidth: maxWidth + 'px'
                        }}
                        className={classNames(css.tooltip, css[varinat], {[css.active]: transition})}>
                        {title}
                    </div>
                    , document.getElementById('root') as HTMLElement)
                : null
            }
        </div>
    )
};


export default Tooltip