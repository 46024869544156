import React, { FC, useState, useEffect } from 'react';
import {arrayMoveImmutable} from 'array-move';
import { useParams, useNavigate } from 'react-router-dom';
import Toolbar from '../../../component/Toolbar/index';
import Title from '../../../component/Title/index';
import {Sortable, SortableItem} from '../../../component/Sortable/index';
import {arrayCompareCheckbox, declOfNum} from "../../../utils/utils";
import {OnCheckboxChange, OnInputChange} from "../../../types/IInput";
import {
    deleteQueries,
    saveSort,
    setChecked,
    setCheckedAll,
    saveQueries,
    loadQueries, setOpen, CREATE_QUERIES_PLACEMENT
} from "../../../store/reducers/CreateLentaSlice";
import {ROUTE, TMP_QUERY} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {IQueries} from "../../../types/IUser";
import {useTranslate} from "../../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {ITitleMenu} from "../../../component/Title/index";
import cssL from '../../../component/Layout/Layout.module.scss';
import {ICON_VARIANT} from "../../../assets/svg/index";

interface Props {
    title: string
    titleMenu: ITitleMenu[] | []
}

const Sort: FC<Props> = ({title, titleMenu}) => {
    const { queries, user } = useAppSelector(state => state.auth);
    const { checked } = useAppSelector(state => state.lentaCreate);
    const [sort, setSort] = useState<IQueries[]>(queries.filter(item => item.name !==  TMP_QUERY));
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const translate = useTranslate();

    const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        const sorted = arrayMoveImmutable(sort, oldIndex, newIndex);
        setSort(sorted);
        dispatch(saveSort(sorted.map(item => item.name)));
    };

    const onChacked = ({name}: OnCheckboxChange) => {
        dispatch(setChecked(name));
    };

    const onChange = ({name, value}: OnInputChange) => {
        const query = queries.find(item => item?.id === name);

        if(query){
            dispatch(saveQueries({
                id: query.id,
                name: value,
                filter: query.value
            }));
        }
    };

    const onEdit = ({name}: {name: string}) => {
        const query = queries.find(item => item?.id === name);
        const placement = CREATE_QUERIES_PLACEMENT.MODAL;

        if(query){
            dispatch(loadQueries({placement: placement, data: query}));
            dispatch(setOpen({placement: placement, isOpen: true}))
        }
    };

    const onDelete = async (ids: string[]) => {
        dispatch(deleteQueries(ids));

        if(checked.includes(id as string)){
            navigate(ROUTE.QUERIES);
        }
    };

    const onTelegram = ({name}: {name: string}) => {
        const query = queries.find(item => item.id === name);

        if(query && query.id){
            dispatch(saveQueries({
                name: query.name,
                id: query.id,
                filter: {...query.value,...{send_to_telegram: Boolean(!query.value?.send_to_telegram)}}
            }));
        }
    };

    const onCheckedAll = ({value}: OnCheckboxChange) => {
        const ids = queries.filter(item => item.name !== TMP_QUERY).map(item => item.id as string);
        dispatch(setCheckedAll({
            ids: ids,
            value: value
        }));
    };


    useEffect(() => {
        setSort(queries.filter(item => item.name !==  TMP_QUERY));
    },[queries]);

    return(
        <>
            <div className={cssL.sticky}>
                <Title
                    title={title}
                    titleMenu={titleMenu}
                    sticky={true}
                    backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}
                    menuIcon={ICON_VARIANT.ACTION}
                    menu={[
                        {title: translate.pages.query.delete, icon: ICON_VARIANT.DELETE, callback: () =>  onDelete(checked)}
                    ]}
                />
            </div>

            <div className={cssL.sortWrapper}>
                <Toolbar
                    title={`${sort.length} ${declOfNum(sort.length, [translate.pages.query.count.nominative, translate.pages.query.count.singular, translate.pages.query.count.plural])}`}
                    checkboxTitle={`${translate.pages.query.select} ${checked.length}`}
                    checkboxValue={arrayCompareCheckbox(checked, sort.map(item => item.id))}
                    onChange={onCheckedAll}/>
                <Sortable onSortEnd={onSortEnd} useDragHandle>
                    {sort?.map((item, i) =>
                        <SortableItem
                            key={item.id as string}
                            index={i}
                            title={item.name}
                            name={item.id as string}
                            checked={checked.includes(item.id as string)}
                            isTelegram={item?.value?.send_to_telegram}
                            onChacked={onChacked}
                            onChange={onChange}
                            menu={[
                                {icon: ICON_VARIANT.EDIT, title: translate.blocks.edit, onClick: onEdit},
                                {icon: ICON_VARIANT.IMPORT, title: translate.blocks.import, onClick: onTelegram},
                                {icon: ICON_VARIANT.DELETE, title: translate.blocks.delete, onClick: ({name}: {name: string}) => onDelete([name])}
                            ]}
                        />
                    )}
                </Sortable>
            </div>
        </>
    )
};

export default Sort