import React, { FC, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom"
import { TooltipProfile } from '../../Tooltip';
import {getKeywords} from "../../../utils/utils";
import {useAppDispatch} from "../../../hooks/redux";
import {getList, setParams, LIST_NAME} from "../../../store/reducers/LSlice";
import {ROUTE} from "../../../constants";
import css from './ArticleKeywords.module.scss';
import {useTranslate} from "../../../hooks/useTranslate";

interface Props {
    keywords: string,
}

const ArticleKeywords: FC<Props> = ({keywords}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const translate = useTranslate();

    const onClick = (query: string) => {
        if(query){
            dispatch(setParams({
                name: LIST_NAME.ALL_NEWS,
                params: {
                    query: query?.replace(/<\/?[^>]+>/g, '').replace(/&nbsp;/gi, " ")?.toLowerCase(),
                    fields: ['keywords']
                }
            }));
            if(location.pathname !== ROUTE.INDEX){
                navigate(ROUTE.INDEX);
            } else{
                dispatch(getList(LIST_NAME.ALL_NEWS));
            }
        }
    };

    return (
        <div className={css.keywords}>
            {getKeywords(keywords).map((item, i) =>
                <div className={css.item} key={i} onClick={() => onClick(item)}>
                    <TooltipProfile
                        title={translate.tooltip.article.keywords}
                    >
                        <span dangerouslySetInnerHTML={{ __html: item }}/>
                    </TooltipProfile>
                </div>
            )}
        </div>
    );
};

export default ArticleKeywords;