import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import Button, {BUTTON_VARIANT} from '../../Button';
import Icon from '../../Icon';
import {ICON_VARIANT} from "../../../assets/svg";
import {useGetColor} from "../../../hooks/useGetColor";
import css from './Sizer.module.scss';

export interface ISizerChange{
    name: string,
    value: string | number
}

interface ISizer {
    name?: string,
    value: string,
    options: string[],
    onChange: (arg: ISizerChange) => void
}


const Sizer: FC<ISizer> = ({name = '', value = '', options, onChange}) => {
    const color = useGetColor();

    const onMinus = () => {
        const index = options.indexOf(value) > 0 ?  options.indexOf(value) - 1 : 0;

        onChange({name: name, value: options[index]})
    };

    const onPlus = () => {
        const index = options.indexOf(value) === options.length - 1 ? options.length - 1 : options.indexOf(value) + 1;
        onChange({name: name, value: options[index]})
    };

    return(
        <div className={css.sizer}>
            <Button onClick={onMinus} width={'32px'} padding={'0'} variant={BUTTON_VARIANT.LIGHT}>
                <div className={css.button}>
                    <Icon name={ICON_VARIANT.MINUS}/>
                </div>
            </Button>
            <div className={css.input}>{value}</div>
            <Button onClick={onPlus} width={'32px'} padding={'0'} variant={BUTTON_VARIANT.LIGHT}>
                <div className={css.button}>
                    <Icon name={ICON_VARIANT.PLUS}/>
                </div>
            </Button>
        </div>
    )
};

export default Sizer