import React, {FC} from 'react';
import image6 from '../../../assets/images/faq/image6.png';

export const STRUCTURE_STRUCTURE = () => {
    return(
        <div>
            <section>
                <h3>Главная страница</h3>
                <img src={image6} width="1598" height="1999" alt="Главная страница авторизации"/>
                <ol>
                    <li>“Картина дня” - главное событие текущего часа.</li>
                    <li>Ссылки на информацию: О медиагруппе, Правила цитирования, Политика конфиденциальности, Обратная связь, Новостные ленты, Руководство.</li>
                    <li>Самые свежие новости из Telegram канала “РИА Новости”.</li>
                    <li>Ссылки на Telegram каналы МИА “Россия сегодня”/ SPUTNIK.</li>
                    <li>Кнопки для оформления подписки и доступа к Информационному мультимедийному терминалу РИА Новости.</li>
                </ol>
            </section>
        </div>
    )
};