import { createSlice } from '@reduxjs/toolkit';
import { SCREEN_VARIANT, SCREEN_MEDIA_QUERIES } from "../../types/IScreen";
import {getCookie} from "../../utils/utils";

export enum GS_VARIANT {
    GS3 = 'GS3',
    GS2 = 'GS2'
}

interface WindowState {
    screen: SCREEN_VARIANT,
    windowY: number,
    windowX: number,
    lentaMini: boolean,
    premiumScreen: GS_VARIANT,
    isOpenFilter: boolean,
    isActiveHeader: boolean
}

const getTypeScreen = (): SCREEN_VARIANT => {
    const width = window.innerWidth;

    if(width >= SCREEN_MEDIA_QUERIES.DESKTOP) {
        return SCREEN_VARIANT.DESKTOP
    } else if(width >= SCREEN_MEDIA_QUERIES.TABLET) {
        return SCREEN_VARIANT.TABLET
    }
    return SCREEN_VARIANT.MOBILE
};

const getPremiumScreen = () => {
    if(localStorage.getItem('premiumScreen') && getCookie('tour')){
        return localStorage.getItem('premiumScreen') as GS_VARIANT
    } else {
        return GS_VARIANT.GS2
    }
};

const initialState: WindowState = {
    screen: getTypeScreen(),
    windowY: window.innerHeight,
    windowX: window.innerWidth,
    lentaMini: false,
    premiumScreen: getPremiumScreen(),
    isOpenFilter: false,
    isActiveHeader: false
};

export const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        resize(state){
            state.screen = getTypeScreen();
            state.windowY = window.innerHeight;
            state.windowX = window.innerWidth;
        },
        setLentaMini(state, action){
            state.lentaMini = action.payload
        },
        tooglePremiumScreen(state){
            const newValue = state.premiumScreen === GS_VARIANT.GS3 ? GS_VARIANT.GS2 : GS_VARIANT.GS3;
            state.premiumScreen = newValue;
            localStorage.setItem('premiumScreen', newValue);
        },
        setScreen(state, action){
            const newValue = action.payload;

            state.premiumScreen = newValue;
            localStorage.setItem('premiumScreen', newValue);
        },
        setOpenFilter(state, action){
            state.isOpenFilter = action.payload
        },
        setActiveHeader(state, action){
            state.isActiveHeader = action.payload
        }
    }
});

export const { resize, setLentaMini, tooglePremiumScreen, setOpenFilter, setActiveHeader, setScreen } = screenSlice.actions;
export default screenSlice.reducer;
