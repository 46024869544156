import react, { FC, createContext, useContext } from 'react';
import {INPUT_ALIGN} from "../../../types/IInput";

export interface RadioGroupContextInterface {
    disabled?: boolean;
    name: string;
    onChange: Function;
    align?: INPUT_ALIGN;
    value?: string | number | boolean;
}

const RadioContext = createContext<RadioGroupContextInterface | null>(null);

export const useRadioGroup = () => {
    const context = useContext(RadioContext);

    if (!context) {
        throw new Error('A `<Radio />` component can only be used inside a parent `<RadioGroup />`.');
    }
    return context;
};

export interface RadioGroupInterface {
    children: React.ReactNode;
    component?: React.ElementType;
    disabled?: boolean;
    name: string;
    onChange: Function;
    align?: INPUT_ALIGN;
    value?: string | number | boolean;
}

const RadioGroup:FC<RadioGroupInterface> = ({children, component = 'div', align = INPUT_ALIGN.VERTICAL, disabled, name, onChange, value, ...props}) => {
    const Component = component;

    return (
        <RadioContext.Provider value={{ disabled, name, onChange, value, align }}>
            <Component {...props}>{children}</Component>
        </RadioContext.Provider>
    );
};

export default RadioGroup;