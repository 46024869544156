import React, { FC, useEffect, useState, MouseEvent } from 'react';
import Dropdown, { DropdownItem, DropDownCheckbox, DropdownFooter } from '../../Dropdown';
import {DROPDOWN_PLACEMENT} from "../../Dropdown/DropdownMenu";
import Icon from '../../Icon';
import {IArticle} from "../../../types/IArticle";
import {ROUTE} from "../../../constants";
import {ICON_VARIANT} from "../../../assets/svg";
import {logHistory, LOG_FORMAT} from "../../../store/reducers/HistorySlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {EXPORT_FORMAT, exportArticles} from "../../../store/reducers/ExportSlice";
import {OnCheckboxChange} from "../../../types/IInput";
import {copyArticle} from "../../../utils/utils";
import {analyticsExport} from "../../../utils/analytics";
import css from './ArticleButtons.module.scss';

interface Props {
    article:IArticle
}

const ArticleShare:FC<Props> = ({article}) => {
    const { lang } = useAppSelector(state => state.auth);
    const articleId = article.article_id;
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [zip, setZip] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onFormat = (format: EXPORT_FORMAT | LOG_FORMAT) => {
        dispatch(exportArticles({
            ids: [articleId],
            format: format as EXPORT_FORMAT,
            lang: lang,
            zipFiles: zip
        }));

        dispatch(logHistory({
            article_id: [articleId],
            format: format as LOG_FORMAT
        }));

        analyticsExport(format);
    };

    const onCopy = () => {
        dispatch(logHistory({
            article_id: [articleId],
            format: LOG_FORMAT.PRINT
        }));
        copyArticle([article]);
        setCopy(true)
    };

    return (
        <div className={css.container}>
            <Dropdown
                header={<div className={css.icon}><Icon name={ICON_VARIANT.MORE}/></div>}
                placement={DROPDOWN_PLACEMENT.LEFT_TOP}
                width={'260px'}
                onClose={() =>{
                    setShowDownload(false);
                    setCopy(false);
                }}
            >

                {showDownload
                    ? <>
                        {Object.values(EXPORT_FORMAT).map(format =>
                            <DropdownItem
                                key={format}
                                onClick={() => onFormat(format)}
                            >
                                {format}
                            </DropdownItem>
                        )}
                        <DropdownFooter>
                            <DropDownCheckbox
                                value={zip}
                                title={translate.history.packageZip}
                                onChange={({value}: OnCheckboxChange) => setZip(value)}
                            />
                        </DropdownFooter>
                    </>
                    : null
                }

                {!showDownload
                    ? <>
                        <DropdownItem
                            preventClose={true}
                            onClick={onCopy}
                            active={copy}
                            icon={copy ? ICON_VARIANT.CHECK : ICON_VARIANT.COPY}>
                            {copy ? translate.list.copied : translate.list.copy}
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => {
                                dispatch(logHistory({
                                    article_id: [articleId],
                                    format: LOG_FORMAT.PRINT
                                }));
                                window.open(ROUTE.PRINT + '?ids=' + articleId);
                            }}
                            icon={ICON_VARIANT.PRINT}>
                            {translate.list.print}
                        </DropdownItem>
                        <DropdownItem
                            preventClose={true}
                            onClick={() => {setShowDownload(true)}}
                            icon={ICON_VARIANT.DOWNLOAD}>
                            {translate.list.download}
                        </DropdownItem>
                    </>
                    : null
                }
            </Dropdown>
        </div>
    );
};

export default ArticleShare