const locale_cn = {
	name: "locale_cn",
	months: [
		["1月", "1月"],
		["2月", "2月"],
		["3月", "3月"],
		["4月", "4月"],
		["5月", "5月"],
		["6月", "6月"],
		["7月", "7月"],
		["8月", "8月"],
		["9月", "9月"],
		["10月", "10月"],
		["11月", "11月"],
		["12月", "12月"],
	],
	weekDays: [
		["星期六", "周六"],
		["星期日", "周日"],
		["星期一", "周一"],
		["星期二", "周二"],
		["星期三", "周三"],
		["星期四", "周四"],
		["星期五", "周五"],
	],
	digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	meridiems: [
		["", ""],
		["", ""],
	],
};

export default locale_cn