import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISidebar {
    showSidebar: boolean,
    showMobileSidebar: boolean
}

const getShowSidebar = () => {
    if(localStorage.getItem('showSidebar')){
        return Boolean(parseInt(localStorage.getItem('showSidebar') as string))
    } else {
        return true
    }
};

const initialState: ISidebar = {
    showSidebar: getShowSidebar(),
    showMobileSidebar: false
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleSideBar(state){
            localStorage.setItem('showSidebar', state.showSidebar ? '0' : '1');
            state.showSidebar = !state.showSidebar;
        },
        toggleMobileSideBar(state){
            state.showMobileSidebar = !state.showMobileSidebar;
        },
        closeMobileSideBar(state){
            state.showMobileSidebar = false;
        }
    }
});

export const { toggleSideBar, toggleMobileSideBar, closeMobileSideBar } = sidebarSlice.actions;
export default sidebarSlice.reducer;