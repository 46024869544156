import React, {FC} from 'react';
import image6 from '../../../assets/images/faq/image6.png';

export const STRUCTURE_STRUCTURE_EN = () => {
    return(
        <div>
            <section>
                <h3>Homepage</h3>
                <img src={image6} width="1598" height="1999" alt="Login homepage"/>
                <ol>
                    <li>Highlights – the main news story of the day</li>
                    <li>Links to information about the media group, citing rules, privacy policy, feedback, newsfeeds</li>
                    <li>The latest news stories from the Sputnik Telegram channel</li>
                    <li>Links to Sputnik Telegram channel</li>
                    <li>Buttons for subscribing to and accessing the Sputnik multimedia news terminal</li>
                </ol>
            </section>
        </div>
    )
};