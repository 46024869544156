import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { createAsyncThunk } from "@reduxjs/toolkit";
import {TYPE_PREFERENCES, URL} from "../../constants";
import {convertToState, serialize} from "../../utils/utils";
import {addQueris, login, setQueries, removeQueries} from "./AuthSlice";
import axios from "axios";
import {IQueries, IQueriesFilter} from "../../types/IUser";

export enum CREATE_QUERIES_PLACEMENT {
    MODAL = 'modal',
    CREATE = 'create'
}

interface ILentaSlice {
    checked: string[],
    [CREATE_QUERIES_PLACEMENT.CREATE]: {
        isOpen: boolean,
        isLoading: boolean,
        name: string
        id: string | null
        filter: IQueriesFilter
    },
    [CREATE_QUERIES_PLACEMENT.MODAL]: {
        isOpen: boolean,
        isLoading: boolean,
        name: string
        id: string | null
        filter: IQueriesFilter
    }
}

export const initialQueriesFilter: IQueriesFilter = {
    sid_list: [],
    fields: [],
    priority: [],
    media_type: [],
    stopwords: '',
    case_sensitive: null,
    advanced_search: null,
    query: '',
    match: 'all',
    send_to_telegram: false
};

const initialState: ILentaSlice = {
    checked: [],
    [CREATE_QUERIES_PLACEMENT.CREATE]: {
        isOpen: false,
        isLoading: false,
        name: '',
        id: null,
        filter: initialQueriesFilter
    },
    [CREATE_QUERIES_PLACEMENT.MODAL]: {
        isOpen: false,
        isLoading: false,
        name: '',
        id: null,
        filter: initialQueriesFilter
    }
};

export const saveQueries = createAsyncThunk(
    'lentaCreate/saveLenta',
    async ({id, name, filter }: {id: string | null, name: string, filter: IQueriesFilter}, { rejectWithValue, dispatch, getState }) => {
        try {

            if(id){ // UPDATE
                const { data } = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                    type: TYPE_PREFERENCES.QUERY,
                    id: id,
                    name: name,
                    value: JSON.stringify(serialize(filter))
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const { result } = data.setPreferences;

                dispatch(addQueris(result));

                return result
            }else { // CREATE
                const { data } = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                    type: TYPE_PREFERENCES.QUERY,
                    name: name,
                    value: JSON.stringify(serialize(filter))
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const { result } = data.setPreferences;

                dispatch(addQueris(result));

                return result
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const deleteQueries = createAsyncThunk(
    'lentaCreate/deleteQueries',
    async (id: string[], { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axios.post(URL.DELETE_PREFERENS, qs.stringify({
                type: TYPE_PREFERENCES.QUERY,
                id: id,
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            if(data?.user?.deleted_records > 0){
                dispatch(removeQueries(id));
                dispatch(setCheckedAll({
                    ids: [],
                    value: false
                }));
            }

            return data?.user
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const saveSort = createAsyncThunk(
    'lentaCreate/saveSort',
    async (names: string[], { rejectWithValue, dispatch }) => {
        const response = await axios.post(URL.SORT_PREFERENS, qs.stringify({
            type: TYPE_PREFERENCES.QUERY,
            name: names
        }, {arrayFormat: 'bracket'}), {withCredentials: true});

        dispatch(setQueries(response.data.query));
    }
);

export const updateQueries = createAsyncThunk(
    'lentaCreate/updateQueries',
    async ({name, id, filter}: {name: string, id: string, filter: IQueriesFilter}, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                type: TYPE_PREFERENCES.QUERY,
                id: id,
                name: name,
                value: JSON.stringify(serialize(filter))
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            const { result } = response.data.setPreferences;

            dispatch(addQueris(result));

            return result
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const createLentaSlice = createSlice({
    name: 'lentaCreate',
    initialState,
    reducers: {
        setChecked(state: ILentaSlice, action: PayloadAction<string>){
            const id = action.payload;
            state.checked = state.checked.includes(id) ? state.checked.filter(e => e !== id) : state.checked.concat(id);
        },
        setCheckedAll(state: ILentaSlice, action: PayloadAction<{ids: string[], value: boolean}>){
            const { ids, value } = action.payload;
            state.checked = value ? ids : []
        },
        loadQueries(state: ILentaSlice, action: PayloadAction<{placement: CREATE_QUERIES_PLACEMENT, data: IQueries}>){
            const {placement, data} = action.payload;
            const {name, id, value} = data;

            state[placement].name = name;
            state[placement].id = id || null;
            state[placement].filter = value;

        },
        setName(state: ILentaSlice, action: PayloadAction<{placement: CREATE_QUERIES_PLACEMENT, name: string}>){
            const {placement, name} = action.payload;

            state[placement].name = name;
        },
        setFilterParams(state: ILentaSlice, action: PayloadAction<{placement: CREATE_QUERIES_PLACEMENT, params: object}>){
            const {placement, params} = action.payload;

            (Object.keys(params) as Array<keyof typeof params>).map(name => {
                state[placement].filter[name] = params[name];
            });
        },
        setOpen(state: ILentaSlice, action: PayloadAction<{placement: CREATE_QUERIES_PLACEMENT, isOpen: boolean}>){
            const {placement, isOpen} = action.payload;

            state[placement].isOpen = isOpen;
        }
    },
    extraReducers: {
        [saveQueries.pending.type]: (state: ILentaSlice) => {
            state[CREATE_QUERIES_PLACEMENT.CREATE].isLoading = true;
            state[CREATE_QUERIES_PLACEMENT.MODAL].isLoading = true;
        },
        [saveQueries.fulfilled.type]: (state: ILentaSlice) => {
            state[CREATE_QUERIES_PLACEMENT.CREATE].isOpen = false;
            state[CREATE_QUERIES_PLACEMENT.MODAL].isOpen = false;
            state[CREATE_QUERIES_PLACEMENT.CREATE].isLoading = false;
            state[CREATE_QUERIES_PLACEMENT.MODAL].isLoading = false;
            state[CREATE_QUERIES_PLACEMENT.CREATE].filter = initialQueriesFilter;
            state[CREATE_QUERIES_PLACEMENT.CREATE].name = '';
        }
    }
});

export const { setChecked, setCheckedAll, loadQueries, setName, setFilterParams, setOpen } = createLentaSlice.actions;
export default createLentaSlice.reducer;