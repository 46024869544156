import React, { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import cn from 'classnames';
import SwiperCore, { Navigation, Pagination, EffectFade, A11y } from "swiper";
import ArticlePhoto from './ArticlePhoto';
import ArticleVideo from './ArticleVideo';
import Icon from '../../Icon';
import {IMedia, MEDIA_VARIANT} from "../../../types/IMedia";
import {ICON_VARIANT} from "../../../assets/svg";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {useAppSelector} from "../../../hooks/redux";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import css from './ArticleMedia.module.scss';

SwiperCore.use([EffectFade, Navigation, Pagination, A11y]);

interface Props {
    media: IMedia[]
}

const ArticleVariableMedia:FC<{media: IMedia, index?: number}> = ({media, index = 0}) => {
    if(media.media_variants.find(mv => mv.media_variant_type === MEDIA_VARIANT.VIDEO)){
        return <ArticleVideo media={media} index={index}/>
    }

    if(media.media_variants.find(mv => mv.media_variant_type === MEDIA_VARIANT.PDF)){
        const externalUrl = media.media_variants.find(mv => mv.media_variant_external_url && mv.media_variant_type === MEDIA_VARIANT.PDF)?.media_variant_external_url;

        return <a href={externalUrl} target="_blank" download className={css.link}>
            <ArticlePhoto media={media} variants={[MEDIA_VARIANT.PREVIEW]}/>
        </a>
    }

    return <ArticlePhoto media={media}/>
};

const ArticleMedia: FC<Props> = ({media}) => {
    const {screen} = useAppSelector(state => state.screen);
    const [index, setIndex] = useState<number>(0);


    if(!media) {
        return null
    }

    if (media.length === 1){
        return (
            <div className={css.media}>
                <ArticleVariableMedia media={media[0]}/>
            </div>
        )
    }

    return (
        <div className={css.media}>
            {screen === SCREEN_VARIANT.DESKTOP && media.length > 1
                ? <div>
                    <div className={cn(css.navigation, css.prev)} data-nav={`prev-${media[0].media_id}`}>
                        <Icon name={ICON_VARIANT.ARROW_LEFT}/>
                    </div>
                    <div className={cn(css.navigation, css.next)} data-nav={`next-${media[0].media_id}`}>
                        <Icon name={ICON_VARIANT.ARROW_RIGHT}/>
                    </div>
                </div>
                : null
            }
            <Swiper
                navigation={{
                    prevEl: `[data-nav="prev-${media[0].media_id}"]`,
                    nextEl: `[data-nav="next-${media[0].media_id}"]`
                }}
                // slidesPerView={"auto"}
                autoHeight={true}
                loop={true}
                pagination={{
                    dynamicBullets: true,
                }}
                onSlideChange={(swiper) => setIndex(swiper?.realIndex || 0)}
            >
                {media.map(m =>
                    <SwiperSlide key={m.media_id}>
                        <ArticleVariableMedia media={m} index={index}/>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
};

export default ArticleMedia;
