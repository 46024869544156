import React, { FC, useState, MouseEvent, FormEvent } from 'react';
import Modal from '../index';
import css from '../Modal.module.scss';
import Form from '../../../pages/queries/form';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {CREATE_QUERIES_PLACEMENT, setOpen} from "../../../store/reducers/CreateLentaSlice";

const QueriesModal: FC = () => {
    const placement = CREATE_QUERIES_PLACEMENT.MODAL;
    const { isOpen } = useAppSelector(state => state.lentaCreate[placement]);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    return(
        <Modal
            isOpen={isOpen}
            close={() => dispatch(setOpen({placement: placement, isOpen:false}))}
            title={translate.pages.query.title}
            style={{
                content: {
                    width: '700px'
                }
            }}
        >
            <div className={css.content}>
                <Form placement={placement}/>
            </div>
        </Modal>
    )
};

export default QueriesModal