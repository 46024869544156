const BANNER_HEADER = `
	<div id="adfox_166556236583137165"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556236583137165',
	            params: {
	                pp: 'cvrm',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_HEADER_MOBILE = `
	<div id="adfox_167653491735079472"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_167653491735079472',
	            params: {
	                pp: 'ddjk',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS2 = `
	<div id="adfox_166556299912618435"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556299912618435',
	            params: {
	                pp: 'cvro',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS3 = `
	<div id="adfox_168846005339315146"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_168846005339315146',
	            params: {
	                pp: 'dolr',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_INDEX = `
	<div id="adfox_16655628824846851"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655628824846851',
	            params: {
	                pp: 'cvrn',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;


const BANNER_FEED = `
	<div id="adfox_166556310350565762_bannerId"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556310350565762_bannerId',
	            params: {
	                pp: 'cvrp',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>	
`;

export default {
	BANNER_HEADER,
	BANNER_HEADER_MOBILE,
	BANNER_SIDEBARE_GS2,
	BANNER_SIDEBARE_GS3,
	BANNER_INDEX,
	BANNER_FEED
}