import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Title, {ITitleMenu} from '../../../component/Title/index';
import Button, {BUTTON_VARIANT} from '../../../component/Button/index';
import { Form, Row, Cell, Title as FormTitle} from "../../../component/Input/Form/index";
import Input from "../../../component/Input/Input/Input";
import {INPUT_VARINAT, OnInputChange} from "../../../types/IInput";
import {CREATE_FAVORITE_PLACEMENT, createFavorite, setName} from "../../../store/reducers/FavoriteSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import cssL from '../../../component/Layout/Layout.module.scss';
import {
    FORM_CELL_VARINAT, FORM_FORM_VARIANT,
    FORM_ROW_ALIGN,
    FORM_ROW_JUSTIFY, FORM_TITLE_MARGIN,
    FORM_TITLE_VARINAT
} from "../../../component/Input/Form/index";

interface Props {
    title: string,
    titleMenu: ITitleMenu[] | [],
    placement?: CREATE_FAVORITE_PLACEMENT
}

const FavoriteForm: FC<Props> = ({title, titleMenu, placement = CREATE_FAVORITE_PLACEMENT.CREATE}) => {
    const { articles } = useAppSelector(state => state.auth);
    const { isLoading, name } = useAppSelector(state => state.favoriteCreate[placement]);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onChange = ({name, value}: OnInputChange) => {
        dispatch(setName({placement: placement, value: value}))
    };

    const onCreate = () => {
        dispatch(createFavorite({
            name: name,
            id: null,
            value: []
        }));
    };

    return (
        <>
            <div className={cssL.sticky}>
                <Title
                    title={title}
                    titleMenu={titleMenu}
                    backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}/>
            </div>
            <Form variant={FORM_FORM_VARIANT.INNER}>
                <Row align={FORM_ROW_ALIGN.CENTER}>
                    <FormTitle varinat={FORM_TITLE_VARINAT.H1} margin={FORM_TITLE_MARGIN.NO_MARGIN}>
                        {translate.pages.favorite.name}
                    </FormTitle>
                    <Cell varinat={FORM_CELL_VARINAT.FULL}>
                        <Input
                            variant={INPUT_VARINAT.BLUE}
                            name={'name'}
                            value={name}
                            onChange={onChange}/>
                    </Cell>
                </Row>
                <Row justify={FORM_ROW_JUSTIFY.END}>
                    <Button
                        disabled={!name || Boolean(articles.find(item => item.name === name))}
                        isLoading={isLoading}
                        height={'40px'}
                        onClick={onCreate}>
                            {translate.pages.favorite.create}
                    </Button>
                </Row>
            </Form>
        </>
    )
};

export default FavoriteForm