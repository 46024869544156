// export enum MEDIA_VARIANT_TYPE{
//     PHOTO = 'photo',
//     VIDEO = 'video'
// }
//
// export interface IMedia {
//     media_date: string
//     media_id: number
//     media_title: string | null
//     media_variants: IMediaVariant[]
// }
//
// export interface IMediaVariant {
//     media_variant_external_url: string
//     media_variant_id: number
//     media_variant_mimetype: string
//     media_variant_type: MEDIA_VARIANT_TYPE
// }

import {IFlooDate } from "./IFloor";

export enum MEDIA_VARIANT {
    AR1x1 = 'ar1x1',
    AR2x1 = 'ar2x1',
    AR2x3 = 'ar2x3',
    AR3x1 = 'ar3x1',
    AR3x2 = 'ar3x2',
    AR4x3 = 'ar4x3',
    AR6x1 = 'ar6x1',
    AR10x4 = 'ar10x4',
    AR16x9 = 'ar16x9',
    PHOTO = 'photo',
    HIRES = 'hires',
    VIDEO = 'video',

    PREVIEW = 'Preview',
    PDF = 'Pdf'
}

export interface IMedia {
    media_date: string
    media_id: number
    media_title: string | null
    media_variants: IMediaVariant[]
    media_variant?: IMediaVariantVideo[]
    // announce: string
    // date: IFlooDate[]
    // id: number,
    // title: string
    // "source-divider": string,
    // "source-name": string,
    // "source-sid": string,
    // "media2article-title"?: string,
    // "media2article-type"?: string
    // "media_variant": IMediaVariant[]
    // "media_variants": IMediaVariant[]
}

export interface IMediaVariant {
    // filename: string
    // height: number
    // "hires-filename": string
    // type: MEDIA_VARIANT
    // width: number
    // // media_variant_type: MEDIA_VARIANT
    // "meta-data"?: {
    //     meta: {
    //         "crop_info": {
    //             x1: string
    //             x2: string
    //             y1: string
    //             y2: string
    //         }
    //     }
    // }

    media_variant_external_id: number
    media_variant_external_url: string
    media_variant_filename: string
    media_variant_id: number
    media_variant_mimetype: string
    media_variant_type: MEDIA_VARIANT
    media_variant_meta_data: {
        crop_info: {
            x1: string
            x2: string
            y1: string
            y2: string
        }
    }


    "import-id": number
    type: string
}

export interface IMediaVariantVideo {
    "external-url": string
    "external-url-encoded": string
    height: number
    "hires-filename": string
    "import-id": number
    "meta-data": {
        meta: {
            PlayListFiles: {
                PlayListFile: string
            }
            VideoFileSize: string
            VideoLen: string
            external_preview_url: string
        }
    }
    type: MEDIA_VARIANT
    width: number
}