import React, { FC, useEffect } from 'react';
import css from './ArticleBody.module.scss';

interface Props {
    body: string,
    fontSize: string
}

const ArticleBody: FC<Props> = ({body, fontSize}) => {
    return (
        <div className={css.body}>
            <div className={css.text} dangerouslySetInnerHTML={{ __html: body }} style={{fontSize: fontSize}}/>
        </div>
    )
};

export default ArticleBody;