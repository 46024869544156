import React, { FC } from 'react';
import {useTranslate} from "../../../hooks/useTranslate";
import css from './ListEmpty.module.scss'

const ListEmpty: FC = () => {
    const translate = useTranslate();

    return (
        <div className={css.empty}>
            {translate.list.empty}
        </div>
    )
};

export default ListEmpty