import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import ru from '../lang/ru.json';
import en from '../lang/en.json';
import es from '../lang/es.json';
import cn from '../lang/cn.json';
import eg from '../lang/eg.json';
import fa from '../lang/fa.json';
import {Itranslate} from "../types/ITranslate";
import {useAppSelector} from "./redux";
import {LANG} from "../types/IUser";
import localeRu from "date-fns/locale/ru";
import localeEn from "date-fns/locale/en-GB";
import localeEs from "date-fns/locale/es";
import localeCn from "date-fns/locale/zh-CN";
import localeEg from "date-fns/locale/ar";
import localeFa from "date-fns/locale/fa-IR";

import persian from "react-date-object/calendars/persian"
// import persian_fa from "react-date-object/locales/persian_fa"
import persian_fa from '../lang/datepicker/fa.js';
import gregorian from "react-date-object/calendars/gregorian"
import gregorian_en from "react-date-object/locales/gregorian_en"
import gregorian_ar from "react-date-object/locales/gregorian_ar"
import gregorian_cn from '../lang/datepicker/cn.js'
import gregorian_es from '../lang/datepicker/es.js'
import gregorian_ru from '../lang/datepicker/ru.js'
import {DATE_FORMAT} from "../constants";

const TRANSLATE = {
    [LANG.RU]: ru,
    [LANG.EN]: en,
    [LANG.ES]: es,
    [LANG.CN]: cn,
    [LANG.EG]: eg,
    [LANG.FA]: fa,
};

const TRANSLATE_CALENDAR = {
    [LANG.RU]: localeRu,
    [LANG.EN]: localeEn,
    [LANG.ES]: localeEs,
    [LANG.CN]: localeCn,
    [LANG.EG]: localeEg,
    [LANG.FA]: localeFa,
};

const CALENDAR_LOCALE = {
    [LANG.RU]: gregorian_ru,
    [LANG.EN]: gregorian_en,
    [LANG.ES]: gregorian_es,
    [LANG.CN]: gregorian_cn,
    [LANG.EG]: gregorian_ar,
    [LANG.FA]: persian_fa,
};

const CALENDAR_CALENDAR = {
    [LANG.RU]: gregorian,
    [LANG.EN]: gregorian,
    [LANG.ES]: gregorian,
    [LANG.CN]: gregorian,
    [LANG.EG]: gregorian,
    [LANG.FA]: persian,
};

export function useTranslate(){
    const { lang } = useAppSelector(state => state.auth);

    return TRANSLATE[lang] as Itranslate
}

export function useLocaleCalendar() {
    const { lang } = useAppSelector(state => state.auth);

    return TRANSLATE_CALENDAR[lang]
}


export function useCalendar() {
    const { lang } = useAppSelector(state => state.auth);

    return {
        calendar: CALENDAR_CALENDAR[lang],
        locale: CALENDAR_LOCALE[lang],
        format: DATE_FORMAT[lang].FORMAT_DATE,
        formatMobile: DATE_FORMAT[lang].FORMAT_SHORT_DATE,
        convert: gregorian,
        gregorian: gregorian_en
    }
}
