import React, { FC } from 'react';
import css from './Dropdown.module.scss';


const DropDownFooter: FC = ({children}) => {
    return(
        <div className={css.footer}>
            {children}
        </div>
    )
};

export default DropDownFooter
