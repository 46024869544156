import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Title from '../../../component/Title/index';
import Toolbar from '../../../component/Toolbar/index';
import {Sortable, SortableItem} from '../../../component/Sortable/index';
import {arrayMoveImmutable} from 'array-move';
import {
    deleteFavorite,
    saveSort,
    setChecked,
    setCheckedAll,
    createFavorite,
    setOpen,
    loadFavorite, CREATE_FAVORITE_PLACEMENT
} from "../../../store/reducers/FavoriteSlice";
import {OnCheckboxChange, OnInputChange} from "../../../types/IInput";
import {ROUTE} from "../../../constants";
import {useTranslate} from "../../../hooks/useTranslate";
import {IFavorite} from "../../../types/IUser";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {arrayCompareCheckbox, arrayCompareElement} from "../../../utils/utils";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {ITitleMenu} from "../../../component/Title/index";
import cssL from '../../../component/Layout/Layout.module.scss';
import {ICON_VARIANT} from "../../../assets/svg/index";

interface Props {
    title: string,
    titleMenu: ITitleMenu[] | []
}

const Sort: FC<Props> = ({title, titleMenu}) => {
    const { articles } = useAppSelector(state => state.auth);
    const { checked } = useAppSelector(state => state.favoriteCreate);
    const translate = useTranslate();
    const [sort, setSort] = useState<IFavorite[]>(articles);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
        const sorted = arrayMoveImmutable(sort, oldIndex, newIndex)
        setSort(sorted);
        dispatch(saveSort(sorted.map(item => item.name)));
    };

    const onChacked = ({name, value}: OnCheckboxChange) => {
        dispatch(setChecked(name));
    };

    const onEdit = ({name}: {name: string}) => {
        const favorite = articles.find(item => item.id === name);

        if(favorite){
            dispatch(loadFavorite({placement: CREATE_FAVORITE_PLACEMENT.MODAL, data: favorite}));
            dispatch(setOpen({placement: CREATE_FAVORITE_PLACEMENT.MODAL, isOpen: true}));
        }
    };

    const onDelete = (ids: string[]) => {
        dispatch(deleteFavorite(ids))
    };

    const onChange = ({name, value}: OnInputChange) => {
        const favorite = articles.find(item => item.id == name);
        if(favorite) {
            dispatch(createFavorite({
                id: name,
                name: value,
                value: favorite.value
            }));
        }
    };

    const onCheckedAll = ({value}: OnCheckboxChange) => {
        const ids: string[] = articles.map(item => item.id as string);
        dispatch(setCheckedAll({
            ids: ids,
            value: value
        }));
    };

    useEffect(() => {
        setSort(articles);
    },[articles]);

    return (
        <>
            <div className={cssL.sticky}>
                <Title
                    title={title}
                    titleMenu={titleMenu}
                    sticky={true}
                    backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}
                    menuIcon={ICON_VARIANT.ACTION}
                    menu={[
                        {title: translate.pages.favorite.delete, icon: ICON_VARIANT.DELETE, callback: () =>  onDelete(checked)}
                    ]}
                />
            </div>
            <Toolbar title={`${sort.length} ${translate.pages.favorite.count.singular}`}
                     checkboxTitle={`${translate.pages.favorite.select} ${checked.length}`}
                     checkboxValue={arrayCompareCheckbox(checked, sort.map(item => item.id))}
                     onChange={onCheckedAll}/>
            <Sortable onSortEnd={onSortEnd} useDragHandle>
                {sort?.map((item, i) =>
                    <SortableItem
                        key={item.id}
                        index={i}
                        title={item.name}
                        count={item.value.length}
                        name={item.id as string}
                        checked={checked.includes(item.id as string)}
                        menu={[
                            {icon: ICON_VARIANT.EDIT, title: translate.blocks.edit, onClick: onEdit},
                            {icon: ICON_VARIANT.DELETE, title: translate.blocks.delete, onClick: ({name}: {name: string}) => onDelete([name])},
                        ]}
                        onChacked={onChacked}
                        onChange={onChange}
                    />
                )}
            </Sortable>
        </>
    )
};

export default Sort;