import React, { FC } from 'react';
import Checkbox from '../Input/Checkbox/Checkbox'
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange} from "../../types/IInput";
import css from './Toolbar.module.scss';

interface IToolbar {
    title: string,
    checkboxTitle?: string,
    checkboxName?: string,
    checkboxValue?: boolean | null,
    onChange?: (arg: OnCheckboxChange) => void
}

const Toolbar:FC<IToolbar> = ({title, checkboxTitle, checkboxName, checkboxValue, onChange }) => {
    return(
        <div className={css.toolbar}>
            <div className={css.title}>{title}</div>
            <div className={css.checkbox}>
                {checkboxValue !== undefined && onChange
                    ? <Checkbox
                        placement={INPUT_PLACEMENT.START}
                        title={checkboxTitle}
                        variant={INPUT_VARINAT.BLUE}
                        name={checkboxName}
                        value={checkboxValue}
                        onChange={onChange}/>
                    : null
                }
            </div>
        </div>
    )
};

export default Toolbar