//! moment.js locale configuration
//! locale : Persian [fa]
//! author : Ebrahim Byagowi : https://github.com/ebraminio

;(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined'
	&& typeof require === 'function' ? factory(require('moment')) :
		factory(global.moment)
}(this, (function (moment) { 'use strict';

	//! moment.js locale configuration

	var symbolMap = {
			1: '1',
			2: '2',
			3: '3',
			4: '4',
			5: '5',
			6: '6',
			7: '7',
			8: '8',
			9: '9',
			0: '0',
		},
		numberMap = {
			'1': '1',
			'2': '2',
			'3': '3',
			'4': '4',
			'5': '5',
			'6': '6',
			'7': '7',
			'8': '8',
			'9': '9',
			'0': '0',
		};

	var fa = moment.defineLocale('fa', {
		months: 'ژانویه_فوریه_مارس_آوریل_مه_ژوئن_ژوئیه_اوت_سپتامبر_اکتبر_نوامبر_دسامبر'.split(
			'_'
		),
		monthsShort:
			'ژانویه_فوریه_مارس_آوریل_مه_ژوئن_ژوئیه_اوت_سپتامبر_اکتبر_نوامبر_دسامبر'.split(
				'_'
			),
		weekdays:
			'یک\u200cشنبه_دوشنبه_سه\u200cشنبه_چهارشنبه_پنج\u200cشنبه_جمعه_شنبه'.split(
				'_'
			),
		weekdaysShort:
			'یک\u200cشنبه_دوشنبه_سه\u200cشنبه_چهارشنبه_پنج\u200cشنبه_جمعه_شنبه'.split(
				'_'
			),
		weekdaysMin: 'ی_د_س_چ_پ_ج_ش'.split('_'),
		weekdaysParseExact: true,
		longDateFormat: {
			LT: 'HH:mm',
			LTS: 'HH:mm:ss',
			L: 'DD/MM/YYYY',
			LL: 'D MMMM YYYY',
			LLL: 'D MMMM YYYY HH:mm',
			LLLL: 'dddd, D MMMM YYYY HH:mm',
		},
		meridiemParse: /قبل از ظهر|بعد از ظهر/,
		isPM: function (input) {
			return /بعد از ظهر/.test(input);
		},
		meridiem: function (hour, minute, isLower) {
			if (hour < 12) {
				return 'قبل از ظهر';
			} else {
				return 'بعد از ظهر';
			}
		},
		calendar: {
			sameDay: '[امروز ساعت] LT',
			nextDay: '[فردا ساعت] LT',
			nextWeek: 'dddd [ساعت] LT',
			lastDay: '[دیروز ساعت] LT',
			lastWeek: 'dddd [پیش] [ساعت] LT',
			sameElse: 'L',
		},
		relativeTime: {
			future: 'در %s',
			past: '%s پیش',
			s: 'چند ثانیه',
			ss: '%d ثانیه',
			m: 'یک دقیقه',
			mm: '%d دقیقه',
			h: 'یک ساعت',
			hh: '%d ساعت',
			d: 'یک روز',
			dd: '%d روز',
			M: 'یک ماه',
			MM: '%d ماه',
			y: 'یک سال',
			yy: '%d سال',
		},
		preparse: function (string) {
			return string
				.replace(/[۰-۹]/g, function (match) {
					return numberMap[match];
				})
				.replace(/،/g, ',');
		},
		postformat: function (string) {
			return string
				.replace(/\d/g, function (match) {
					return symbolMap[match];
				})
				.replace(/,/g, '،');
		},
		dayOfMonthOrdinalParse: /\d{1,2}م/,
		ordinal: '%dم',
		week: {
			dow: 6, // Saturday is the first day of the week.
			doy: 12, // The week that contains Jan 12th is the first week of the year.
		},
	});

	return fa;

})));
