import React, { FC } from 'react';
import ReactModal from 'react-modal';
import Icon from '../Icon';
import css from './Modal.module.scss';
import {ICON_VARIANT} from "../../assets/svg";

interface IModal {
    isOpen: boolean,
    title?: string,
    shouldCloseOnOverlayClick?: boolean,
    style?: {
        overlay?: object,
        content?: object
    },
    close: () => void
}

const Modal: FC<IModal> = ({isOpen, title, style = {}, shouldCloseOnOverlayClick = true, close = () => {}, children}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            onRequestClose={close}
            closeTimeoutMS={200}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor:'rgba(0, 0, 0, 0.5)',
                    textAlign: 'center',
                    zIndex: 9999999,
                    overflow: 'auto',
                    padding: '50px 12px',
                    ...style.overlay
                }, content: {
                    position:'relative',
                    display: 'inline-block',
                    textAlign: 'initial',
                    background: '#000',
                    overflow: 'hidden',
                    border: 'none',
                    WebkitOverflowScrolling: 'touch',
                    backgroundColor: '#fff',
                    outline: 'none',
                    borderRadius: '4px',
                    padding: '0',
                    width: '470px',
                    maxWidth: '100%',
                    margin: 'auto',
                    inset: 'initial',
                    ...style.content
                },
            }}
        >
            {close ? <div className={css.close} onClick={close}><Icon name={ICON_VARIANT.CLOSE}/></div> : null}
            {title ? <div className={css.title} dangerouslySetInnerHTML={{__html: title}}/> : null}
            {children}
        </ReactModal>
    )
};

export default Modal