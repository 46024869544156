import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import Icon from '../Icon'
import {SCREEN_VARIANT} from "../../types/IScreen";
import {toggleMobileSideBar} from "../../store/reducers/SidebarSlice";
import {ICON_VARIANT} from "../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslate} from "../../hooks/useTranslate";
import css from './Sidebar.module.scss';

const MobileToggle:FC = () => {
    const { screen } = useAppSelector(state => state.screen);
    const { showMobileSidebar } = useAppSelector(state => state.sidebar);
    const translate = useTranslate();
    const dispatch = useAppDispatch();

    if([SCREEN_VARIANT.DESKTOP].includes(screen)){
        return null
    }

    return (
        <div className={cn(css.toggle, {[css.isOpen]: showMobileSidebar})} onClick={() => dispatch(toggleMobileSideBar())}>
            {translate.filter.filterNews}
            <div className={css.toggleIcon}>
                <div className={css.toggleOpen}>
                    <Icon name={showMobileSidebar ? ICON_VARIANT.CLOSE : ICON_VARIANT.FILTER}/>
                </div>
            </div>
        </div>
    )
};

export default MobileToggle;