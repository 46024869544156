import React, { FC, useState, MouseEvent, FormEvent, useRef, useEffect } from 'react';
import Modal from '../index';
import Button, {BUTTON_GROUP_ALIGN, BUTTON_VARIANT, ButtonGroup} from '../../Button';
import Input  from '../../Input/Input/Input';
import {useTranslate} from "../../../hooks/useTranslate";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import css from '../Modal.module.scss';
import {INPUT_VARINAT, OnInputChange} from "../../../types/IInput";
import {CREATE_FAVORITE_PLACEMENT, createFavorite, setName, setOpen} from "../../../store/reducers/FavoriteSlice";


const FavoriteModal: FC = () => {
    const placement = CREATE_FAVORITE_PLACEMENT.MODAL;
    const { articles } = useAppSelector(state => state.auth);
    const { isLoading, name, isOpen, id, value } = useAppSelector(state => state.favoriteCreate[placement]);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onChange = ({name, value}: OnInputChange) => {
        dispatch(setName({placement: placement, value: value}))
    };

    const onCreate = () => {
        dispatch(createFavorite({name: name, id: id, value: value}));
    };

    return(
        <Modal
            isOpen={isOpen}
            close={() => dispatch(setOpen({placement: placement, isOpen:false}))}
            title={translate.pages.favorite.create}
        >
            <div className={css.content}>
                <Input variant={INPUT_VARINAT.GREY} title={translate.pages.favorite.name} value={name} onChange={onChange}/>
                <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                    <Button variant={BUTTON_VARIANT.TEXT} onClick={() => dispatch(setOpen({placement: placement, isOpen:false}))}>{translate.senMail.cancel}</Button>
                    <Button disabled={!name || Boolean(articles.find(item => item.name === name))} onClick={onCreate} isLoading={isLoading}>{translate.pages.favorite.save}</Button>
                </ButtonGroup>
            </div>
        </Modal>
    )
};

export default FavoriteModal