import react, { FC, createContext, useContext } from 'react';
import cn from 'classnames';
import {useRadioGroup} from "./RadioGroup";
import {INPUT_PLACEMENT, IRadio} from "../../../types/IInput";
import css from './Radio.module.scss';

const Radio:FC<IRadio> = ({disabled = false, title, value: valueCurrentRadio,  placement = INPUT_PLACEMENT.END, variant = null}) => {
    const { disabled: disabledGroup, name, onChange, value, align } = useRadioGroup();
    const checked = value === valueCurrentRadio;
    const isDisabled = Boolean(disabled || disabledGroup);

    const handleChange = () => {
        if (!isDisabled) {
            onChange && onChange({
                name: name,
                value: valueCurrentRadio
            });
        }
    };

    return (
        <label className={cn(css.label, css[placement], align ? css[align] : '', variant ? css[variant]: '', {[css.disabled]: isDisabled, [css.checked]: checked})}>
            <div className={css.container}>
                <div className={css.icon}>
                    <input
                        className={css.input}
                        checked={checked}
                        disabled={isDisabled}
                        name={name}
                        onChange={handleChange}
                        type="radio"
                        value={valueCurrentRadio}
                    />
                </div>
                {title
                    ? <div className={css.title}>{title}</div>
                    : null
                }
            </div>
        </label>
    );
};

export default Radio