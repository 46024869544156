import React, { FC, useState, useEffect, MouseEvent, FormEvent } from 'react';
import Button, {BUTTON_GROUP_ALIGN, ButtonGroup} from '../../Button'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import Modal from '../../Modal'
import {closeAlert} from "../../../store/reducers/AlertSlice";
import {setLoginOpen} from "../../../store/reducers/ModalSlice";
import {useTranslate} from "../../../hooks/useTranslate";
import css from '../Modal.module.scss';

const Alert: FC = () => {
    const { isOpen , title, description, button } = useAppSelector(state => state.alert);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const openLogin = () => {
        document.addEventListener("click", (e: any) => {
            if(e.target && e.target?.getAttribute('data-login')) {
                e.preventDefault();
                dispatch(closeAlert());
                dispatch(setLoginOpen(true));
            }
        });
    };


    useEffect(() => {
        openLogin();
    },[]);

    return(
        <Modal
            isOpen={isOpen}
            close={() => dispatch(closeAlert())}
            title={title}
        >
            <div className={css.content}>
                <div className={css.text} dangerouslySetInnerHTML={{__html: description}}></div>
                {button
                    ? <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                        <Button onClick={() => dispatch(closeAlert())}>{button}</Button>
                    </ButtonGroup>
                    : null
                }
            </div>
        </Modal>
    )
};

export default Alert;