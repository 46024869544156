import React, { FC, useState, useContext, useRef, useEffect, MouseEvent, RefObject } from 'react';
import { DropdownContext } from './Provider';
import Checkbox, {ICheckbox} from '../Input/Checkbox/Checkbox';
import css from './Dropdown.module.scss';

const DropDownCheckbox: FC<ICheckbox> = (props) => {
    const { closeDropdown } = useContext<any>(DropdownContext);

    return(
        <div className={css.checkbox}>
            <Checkbox {...props}/>
        </div>
    )
};

export default DropDownCheckbox;
