export function socialAuth(url, socialAuthSuccess, socialAuthError){
	let options = {
			windowName: 'Log in',
			windowParams: 'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes',
			windowWidth: '500',
			windowHeight: '300',
			mode: 'passport'
		},
		windowOffsetLeft = (window.innerWidth - options.windowWidth) / 2,
		windowOffsetTop = (window.innerHeight - options.windowHeight) / 2,
		socialAuthWindow = window.open(url, options.windowName, 'top=' + windowOffsetTop + ',left=' + windowOffsetLeft + ',width=' + options.windowWidth + ',height=' + options.windowHeight + options.windowParams),
		socialAuthInterval;

		window.document.socialAuthSuccess = socialAuthSuccess;
		window.document.socialAuthError = socialAuthError;

		function listenMessage(msg) {
			msg = JSON.parse(msg.data);
			if (typeof window.document[msg.func] === 'function') {
				window.document[msg.func](msg.data, msg.error);

				if (socialAuthWindow) {
					socialAuthWindow.close();
				}

				if (socialAuthInterval) {
					clearInterval(socialAuthInterval);
				}
			}
		}

		if (window.addEventListener) {
			window.addEventListener("message", listenMessage, false);
		} else {
			window.attachEvent("onmessage", listenMessage);
		}

		socialAuthInterval = setInterval(function() {
			socialAuthWindow.postMessage('socialAuth', '*');
		}, 2000)
}