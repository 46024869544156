import React, { FC, useState } from 'react';
import moment from 'moment';
import { Form, Row, Cell } from '../../../Input/Form';
import DatePicker, { OnChangeDatePicker} from '../../../DatePicker/index';
import {TooltipProfile, TOOLTIP_VARIANT} from '../../../Tooltip';
import DatePickerHeader, {DatePickerLabel, DATE_PICKER_LABEL} from '../../../DatePicker/DatePickerHeader';
import {DATE_FORMAT, DATE_FORMAT_SHORT, END_DATE_RANGE, START_DATE_RANGE, TIME_FORMAT, validDate} from "../../../../utils/utils";
import {ICON_VARIANT} from "../../../../assets/svg/index";
import {PERIOD_DATEPICKER} from "../../../../constants";
import {INPUT_VARINAT} from "../../../../types/IInput";
import {useTranslate} from "../../../../hooks/useTranslate";

interface Props {
    startDate: Date | null,
    endDate: Date | null,
    startTime: string | null,
    endTime: string | null,
    label?: DatePickerLabel | null,
    sidStatistics: string[]
    disabled?: boolean,
    onChange: (date: OnChangeDatePicker) => void
}

const FilterDatePicker: FC<Props> = ({startDate, endDate, startTime, endTime, label = null, disabled = false, sidStatistics = [], onChange }) => {
    const translate = useTranslate();

    const removeLabel = () => {
        onChange({
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            label: null
        });
    };

    const clickLabel = (item: DatePickerLabel) => {
        const interval = 5 * 60 * 1000, // 5 минут
            now = moment(Math.ceil(+moment() / interval) * interval),
            ago = moment(Math.ceil(+moment() / interval) * interval).subtract({
                hours: item.hours,
                days: item.days,
                months: item.months,
                years: item.years
            });

        onChange({
            startDate: now.diff(ago, 'days') > 0 ? ago.set({hour: 0, minute: 0}).toDate() : ago.toDate(),
            endDate: now.diff(ago, 'days') > 0 ? now.set({hour: 0, minute: 0}).toDate() : null,
            startTime: now.diff(ago, 'days') === 0 ? ago.format(TIME_FORMAT) : null,
            endTime: now.diff(ago, 'days') === 0 ? now.format(TIME_FORMAT) : null,
            label: item
        });
    };

    return (
        <Row>
            <Cell>
	            <DatePicker
		            header={
                        <DatePickerHeader
                            title={<>
                                {translate.datepicker.period}
                                    <TooltipProfile
                                        isClick={true}
                                        title={translate.tooltip.filter.date}
                                        stopPropagation={true}
                                        varinat={TOOLTIP_VARIANT.LIGHT}/>
                                </>
                            }
                            placeholder={[moment().format(DATE_FORMAT_SHORT), moment().format(DATE_FORMAT_SHORT)]}
                            value={[
                                startDate ? moment(startDate).format(DATE_FORMAT_SHORT) : '',
                                endDate ? moment(endDate).format(DATE_FORMAT_SHORT) : ''
                            ]}
                            mask={'99.99.99'}
                            format={DATE_FORMAT_SHORT}
                            label={label && label.hours === 0 ? label : null}
                            titleCheckbox={translate.datepicker.periodYear}
                            valueCheckbox={label?.months === 12}
                            removeLabel={removeLabel}
                            icon={ICON_VARIANT.CALENDAR}
                            variant={INPUT_VARINAT.LIGHT}
                            disabled={disabled}
                            onChange={(values: [string, string]) => {
                                const startDate = validDate(values[0], DATE_FORMAT_SHORT) ? moment(values[0], DATE_FORMAT_SHORT).toDate() : null;
                                const endDate = validDate(values[1], DATE_FORMAT_SHORT) ? moment(values[1], DATE_FORMAT_SHORT).toDate() : null;

                                onChange({
                                    startDate: startDate,
                                    endDate: endDate,
                                    startTime: null,
                                    endTime: null,
                                    label: null
                                });
                            }}
                            onChangeCheckbox={()=> {
                                if(label?.months === 12) {
                                    removeLabel();
                                } else {
                                    clickLabel(PERIOD_DATEPICKER.find(item => item.months === 12)!)
                                }
                            }}
                        />
                    }
		            label={label}
		            picker={[DATE_PICKER_LABEL.DAYS]}
		            startDate={startDate}
		            endDate={endDate}
		            startTime={startTime}
		            endTime={endTime}
		            sidStatistics={sidStatistics}
                    disabled={disabled}
		            onChange={onChange}/>
            </Cell>
	        <Cell>
		        <DatePicker
			        header={
                        <DatePickerHeader
                            title={<>
                                {translate.datepicker.time}
                                <TooltipProfile
                                    isClick={true}
                                    title={translate.tooltip.filter.time}
                                    stopPropagation={true}
                                    varinat={TOOLTIP_VARIANT.LIGHT}/>
                            </>
                            }
                            placeholder={[START_DATE_RANGE, END_DATE_RANGE]}
                            value={[
                                startTime || '',
                                endTime || ''
                            ]}
                            mask={'99:99'}
                            format={TIME_FORMAT}
                            label={label && label.hours > 0 ? label : null}
                            titleCheckbox={translate.datepicker.periodDay}
                            valueCheckbox={label?.hours === 24}
                            removeLabel={removeLabel}
                            icon={ICON_VARIANT.TIME}
                            variant={INPUT_VARINAT.LIGHT}
                            disabled={disabled}
                            onChange={(values: [string, string]) => {
                                const startTime = moment(values[0] || START_DATE_RANGE, TIME_FORMAT);
                                const endTime = moment(values[1] || END_DATE_RANGE, TIME_FORMAT);
                                const date = startDate ? moment(startDate, DATE_FORMAT) : moment();

                                onChange({
                                    startDate: date.set({hour: startTime.get('hour'), minute: startTime.get('minute')}).toDate(),
                                    endDate: date.set({hour: endTime.get('hour'), minute: endTime.get('minute')}).toDate(),
                                    startTime: values[0],
                                    endTime: values[1],
                                    label: null
                                });
                            }}
                            onChangeCheckbox={() => {
                                if(label?.hours === 24) {
                                    removeLabel();
                                } else {
                                    clickLabel(PERIOD_DATEPICKER.find(item => item.hours === 24)!)
                                }
                            }}
                        />
                    }
			        label={label}
			        picker={[DATE_PICKER_LABEL.TIME]}
			        startDate={startDate}
			        endDate={endDate}
			        startTime={startTime}
			        endTime={endTime}
			        sidStatistics={sidStatistics}
                    disabled={disabled}
			        onChange={onChange}/>
	        </Cell>
        </Row>
    )
};

export default FilterDatePicker