import React, { FC, useEffect } from 'react';
import { TagList, TagItem} from '../../Tags';
import {IArticle, ICategories} from "../../../types/IArticle";


interface Props {
    categories: ICategories[]
}

const ArticleTags: FC<Props> = ({ categories }) => {
    return (
        <TagList>
            {categories.map(item =>
                <TagItem key={item.list_sid}>{item.list_title}</TagItem>
            )}
        </TagList>
    )
};

export default ArticleTags;