import React from 'react';
import Select, { components, IndicatorsContainerProps, MultiValueRemoveProps } from 'react-select';
import Icon from '../component/Icon';
import {ICON_VARIANT} from "../assets/svg";
import {COLOR} from "../types/IColor";
import {useGetColor} from "../hooks/useGetColor";

export const mediaQueries = {
    tablet: '(min-width: 767px)',
    desktop: '(min-width: 1179px)',
    desktopL: '(min-width: 1439px)',
};

export const customStylesBlue: any = {
    container: (provided: any) => {
        return {...provided, ...{
                width: '100%',
                flex: 1,
            }}
    },
    control: (provided: any) => ({
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid #DADDEC',
        borderRadius: '4px',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '40px',
        outline: '0!important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box',

    }),
    valueContainer: (provided: any) => {
        return {...provided, ...{
            padding: '6px 12px',

            display: 'inline-block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: '#000'
        }}
    },
    placeholder: (provided: any) => {
        return {...provided, ...{
            color: '#545454'
        }}
    },
    input: (provided: any) => {
        return {...provided,...{
                color: '#000',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    singleValue: (provided: any) => {
        return {...provided,...{
                color: '#000',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    multiValue: (provided: any) => {
        return {...provided,...{
                padding: '0',
                background: 'none',
                margin: '5px 5px 5px 0',
                fontSize: '14px',
                display: 'inline',
            }}
    },
    multiValueLabel: (provided: any) => {
        return {
            ...provided, ...{
                color: '#000',
                padding: '0',
                fontSize: '14px'
            }
        }
    },
    indicatorsContainer: (provided: any) => {
        return {...provided,...{
                width: '38px',
                height: '38px',
                boxSizing: 'border-box',
                padding: '9px',
                fill:'#000',
                cursor: 'pointer',
            }}
    },
    menu: (provided: any) => {
        return {...provided,...{
                position: 'absolute'
            }}
    },
    option: (provided: any, state: any) => {
        if(state.isFocused){
            return {...provided,...{
                    backgroundColor: '#CCDFF3',
                    color: '#545454',
                    cursor: 'pointer',
                }}
        }
        if(state.isSelected){
            return {...provided,...{
                    backgroundColor: '#CCDFF3',
                    color: '#545454',
                }}
        }
        return {...provided,...{
                backgroundColor: '#fff',
                color:'#545454',
                cursor: 'pointer'
            }}
    },
};

export const customStylesBlueSputnik: any = {
    container: (provided: any) => {
        return {...provided, ...{
                width: '100%',
                flex: 1,
            }}
    },
    control: (provided: any) => ({
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid #DADDEC',
        borderRadius: '4px',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '40px',
        outline: '0!important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box',

    }),
    valueContainer: (provided: any) => {
        return {...provided, ...{
                padding: '6px 12px',

                display: 'inline-block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#000'
            }}
    },
    placeholder: (provided: any) => {
        return {...provided, ...{
                color: '#545454'
            }}
    },
    input: (provided: any) => {
        return {...provided,...{
                color: '#000',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    singleValue: (provided: any) => {
        return {...provided,...{
                color: '#000',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    multiValue: (provided: any) => {
        return {...provided,...{
                padding: '0',
                background: 'none',
                margin: '5px 5px 5px 0',
                fontSize: '14px',
                display: 'inline',
            }}
    },
    multiValueLabel: (provided: any) => {
        return {
            ...provided, ...{
                color: '#000',
                padding: '0',
                fontSize: '14px'
            }
        }
    },
    indicatorsContainer: (provided: any) => {
        return {...provided,...{
                width: '38px',
                height: '38px',
                boxSizing: 'border-box',
                padding: '9px',
                fill:'#000',
                cursor: 'pointer',
            }}
    },
    menu: (provided: any) => {
        return {...provided,...{
                position: 'absolute'
            }}
    },
    option: (provided: any, state: any) => {
        if(state.isFocused){
            return {...provided,...{
                    backgroundColor: '#FBE1BC',
                    color: '#545454',
                    cursor: 'pointer',
                }}
        }
        if(state.isSelected){
            return {...provided,...{
                    backgroundColor: '#FBE1BC',
                    color: '#545454',
                }}
        }
        return {...provided,...{
                backgroundColor: '#fff',
                color:'#545454',
                cursor: 'pointer'
            }}
    },
};

export const customStyles: any = {
    container: (provided: any) => {
        return {...provided, ...{
            width: '100%',
            flex: 1,
        }}
    },
    control: (provided: any) => ({
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid #5F72AF',
        borderRadius: '4px',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '40px',
        outline: '0!important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box',

    }),
    valueContainer: (provided: any) => {
        return {...provided, ...{
            padding: '6px 12px',

            display: 'inline-block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: '#fff'
        }}
    },
    placeholder: (provided: any) => {
        return {...provided, ...{
            color: '#DBDEE9'
        }}
    },
    input: (provided: any) => {
        return {...provided,...{
            color: '#fff',
            padding: '0',
            margin: '0',
            fontSize: '14px'
        }}
    },
    singleValue: (provided: any) => {
        return {...provided,...{
                color: '#fff',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    multiValue: (provided: any) => {
        return {...provided,...{
                padding: '0',
                background: 'none',
                margin: '5px 5px 5px 0',
                fontSize: '14px',
                display: 'inline',
            }}
    },
    multiValueLabel: (provided: any) => {
        return {
            ...provided, ...{
                color: '#fff',
                padding: '0',
                fontSize: '14px'
            }
        }
    },
    indicatorsContainer: (provided: any) => {
        return {...provided,...{
            width: '38px',
            height: '38px',
            boxSizing: 'border-box',
            padding: '9px',
            fill:'#fff',
            cursor: 'pointer',
        }}
    },
    menu: (provided: any) => {
        return {...provided,...{
            position: 'absolute'
        }}
    },
    option: (provided: any, state: any) => {
        if(state.isFocused){
            return {...provided,...{
                backgroundColor: '#CCDFF3',
                color: '#545454',
                cursor: 'pointer',
            }}
        }
        if(state.isSelected){
            return {...provided,...{
                backgroundColor: '#CCDFF3',
                color: '#545454',
            }}
        }
        return {...provided,...{
            backgroundColor: '#fff',
            color:'#545454',
            cursor: 'pointer'
        }}
    },
};

export const customStylesSputnik: any = {
    container: (provided: any) => {
        return {...provided, ...{
                width: '100%',
                flex: 1,
            }}
    },
    control: (provided: any) => ({
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid #878787',
        borderRadius: '4px',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '40px',
        outline: '0!important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box',

    }),
    valueContainer: (provided: any) => {
        return {...provided, ...{
                padding: '6px 12px',

                display: 'inline-block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#fff'
            }}
    },
    placeholder: (provided: any) => {
        return {...provided, ...{
                color: '#fffbf4'
            }}
    },
    input: (provided: any) => {
        return {...provided,...{
                color: '#fff',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    singleValue: (provided: any) => {
        return {...provided,...{
                color: '#fff',
                padding: '0',
                margin: '0',
                fontSize: '14px'
            }}
    },
    multiValue: (provided: any) => {
        return {...provided,...{
                padding: '0',
                background: 'none',
                margin: '5px 5px 5px 0',
                fontSize: '14px',
                display: 'inline',
            }}
    },
    multiValueLabel: (provided: any) => {
        return {
            ...provided, ...{
                color: '#fff',
                padding: '0',
                fontSize: '14px'
            }
        }
    },
    indicatorsContainer: (provided: any) => {
        return {...provided,...{
                width: '38px',
                height: '38px',
                boxSizing: 'border-box',
                padding: '9px',
                fill:'#fff',
                cursor: 'pointer',
            }}
    },
    menu: (provided: any) => {
        return {...provided,...{
                position: 'absolute'
            }}
    },
    option: (provided: any, state: any) => {
        if(state.isFocused){
            return {...provided,...{
                    backgroundColor: '#FBE1BC',
                    color: '#545454',
                    cursor: 'pointer',
                }}
        }
        if(state.isSelected){
            return {...provided,...{
                    backgroundColor: '#FBE1BC',
                    color: '#545454',
                }}
        }
        return {...provided,...{
                backgroundColor: '#fff',
                color:'#545454',
                cursor: 'pointer'
            }}
    },
};

const validateEmail = (email: string) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const customStylesTags: any = {
    container: (provided: any) => {
        return {...provided, ...{
                width: '100%',
                flex: 1,
            }}
    },
    control: (provided: any) => ({
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid var(--color-input)',
        borderRadius: '4px',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '40px',
        outline: '0!important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box',
    }),
    multiValue: (provided: any, value: any) => {
        const valid = validateEmail(value.data.value);

        return {...provided,...{
            height: '24px',
            lineHeight: '24px',
            backgroundColor: valid ? 'var(--color-blue2)' : 'var(--color-red)',
            borderRadius: '100px',
            padding: '0 8px 0 2px',
            margin: '5px 5px 5px 0',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            fontSize: '14px',
            "div": {
                color: valid ? 'var(--color-grey)' : '#fff'
            }
        }}
    },
    multiValueRemove: (provided: any) => {
        return {
            ...provided, ...{
                padding: '0',
                height: '15px',
                margin: '-2px 0 0 0',
                width: '15px',
                color: 'var(--color-grey)',
                cursor: 'pointer',
                "&:hover": {
                    backgroundColor: 'transparent',
                    color: '#000'
                    // svg:{
                    //     fill: '#000'
                    // }
                }
            }
        }
    },
    input: (provided: any) => {
        return {...provided,...{
                height: '24px',
                lineHeight: '24px',
                margin: '5px 5px 5px 5px',
                color: '#000',
                fontSize: '12px',
                padding: '0'
            }}
    },
};

export const IndicatorsContainer = (props: any) => {
    return (
        <components.IndicatorsContainer {...props}>
            <span className={'select-arrow-blue'}>
                <Icon name={ICON_VARIANT.ARROW_DOWN} fill={COLOR.INHERIT}/>
            </span>
        </components.IndicatorsContainer>
    );
};

export const MultiValueRemove = (props: any, a: any) => {
    return (
        <>
            {/*<components.MultiValueRemove {...props}>*/}
            {/*</components.MultiValueRemove>*/}
        </>
    );
};

export const MultiValueContainer = (props: any) => {
    const data = props.data;
    const value: [] = props.selectProps.value;
    const lastElement: any = value.at(-1);
    const getOptionLabel = props.selectProps.getOptionLabel;
    const menuIsOpen = props.selectProps.menuIsOpen;


    if(menuIsOpen){
        return <></>
    }

    return(
        <components.MultiValueContainer {...props}>
            {getOptionLabel(data)}
            {data?.id !== lastElement?.id ? ', ': null}
        </components.MultiValueContainer>
    );
};

export const MultiValueRemoveTags = (props: any, value: any) => {
    const valid = validateEmail(props?.data.value);

    return (
        <components.MultiValueRemove {...props}>
            <span className={valid ? 'select-remove' : 'select-remove-delete'}>
                <Icon name={ICON_VARIANT.CLOSE_2}/>
            </span>
        </components.MultiValueRemove>
    );
};