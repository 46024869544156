import React, { FC, useEffect, useState, MouseEvent, FocusEvent, RefObject, ChangeEvent, useRef } from 'react';
import cn from 'classnames';
import {TooltipProfile, TOOLTIP_VARIANT} from '../../Tooltip';
import { useLocation, useNavigate } from "react-router-dom"
import Select, { components, IndicatorsContainerProps } from 'react-select';
import { Form, Cell, Title, Row, Devide } from '../../Input/Form';
import Input from '../../Input/Input/Input';
import RadioGroup from '../../Input/Radio/RadioGroup';
import Radio from '../../Input/Radio/Radio';
import Checkbox  from '../../Input/Checkbox/Checkbox';
import Toggle  from '../../Input/Toggle';
import Button, {BUTTON_VARIANT} from '../../Button/index';
import Dropdown, {DropDownHeader, DropdownItem} from '../../Dropdown';
import { OnChangeDatePicker } from '../../DatePicker/index';
import FilterDatePicker from './FilterDatePicker/index';
import { INPUT_VARINAT, OnCheckboxChange, OnInputChange } from "../../../types/IInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { SCREEN_VARIANT} from "../../../types/IScreen";
import { ISid } from "../../../types/IUser";
import {LIST_FIELDS_DIFF, QUERY_LENGTH, QUERY_LENGTH_RU, ROUTE, TMP_QUERY} from "../../../constants";
import { useTranslate} from "../../../hooks/useTranslate";
import {initialStateList, LIST_NAME} from "../../../store/reducers/LSlice";
import { getList, setParams } from "../../../store/reducers/LSlice";
import { ICON_VARIANT} from "../../../assets/svg";
import css from './Filter.module.scss'
import {setShowFilter} from "../../../store/reducers/HeaderSlice";
import {
    setOpen,
    loadQueries,
    CREATE_QUERIES_PLACEMENT, saveQueries
} from "../../../store/reducers/CreateLentaSlice";
import {FORM_CELL_VARINAT, FORM_DEVIDE_COLOR, FORM_ROW_JUSTIFY, FORM_TITLE_COLOR} from "../../Input/Form";
import {DROPDOWN_HEADER_SIZE, DROPDOWN_HEADER_VARIANT} from "../../Dropdown/DropdownHeader";
import {diff, isRussian} from "../../../utils/utils";

interface IFilter {
    refSearch: RefObject<any>,
}


const Filter: FC<IFilter> = ({ refSearch = null }) => {
    const { showFilter } = useAppSelector(state => state.header);
    const { screen } = useAppSelector(state => state.screen);
    const { list, groups, queries } = useAppSelector(state => state.auth);
    const { filter } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]);
    // const { id, fields, match, query, stopwords, advanced_search, sid_list, date_start, priority, media_type, date_end, label, startTime, endTime } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS].filter);
    const [ transition, setTransition ] = useState<boolean>(false);
    const [error, setError] = useState<{query: string, stopwords: string}>({
        query: '',
        stopwords: ''
    });
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const location = useLocation();
    const navigate = useNavigate();
    const current = refSearch?.current;
    const query = queries.find(item => item.id === filter.id);
    const value = query ? list.map(item => item.sid) : (groups.length ? groups[0].value : []);
    const feed = list.filter(item => (value.includes(item.sid) || !value.length) && item.is_active);
    const tmp = queries.find(item => item.name === TMP_QUERY);
    const [ form, setForm ] = useState(initialStateList.filter);
    const [sidFilter, setSidFilter] = useState<string>('');

    const handleMatch = ({name, value}: OnCheckboxChange) => {
        setForm(state => ({...state, ...{[name]: value}}))
    };

    const handleAdvanced = ({name, value}: OnCheckboxChange) => {
        setForm(state => ({...state, ...{[name]: value ? 1 : null}}))
    };

    const handleChange = ({name, value}: OnCheckboxChange) => {
        const newFields = value ? form.fields.concat(name) : form.fields.filter(e => e !== name);
        setForm(state => ({...state, ...{fields: newFields}}))
    };

    const handleChangeMedia = ({name, value}: OnCheckboxChange) => {
        const newFields = value ? form.media_type.concat(name) : form.media_type.filter(e => e !== name);
        setForm(state => ({...state, ...{media_type: newFields}}))
    };

    const onSelect = (item: ISid) => {
        const sid = form.sid_list.includes(item.sid) ? form.sid_list.filter(sid => sid !== item.sid) : form.sid_list.concat(item.sid);

        setForm(state => ({...state, ...{sid_list: sid}}))
        setSidFilter('');
    };

    const onSelectFilter = ({value}: OnInputChange) => {
        setSidFilter(value)
    };

    const onChangeDatePicker = (date: OnChangeDatePicker) => {
        setForm(state => ({...state, ...{
            date_start: date.startDate,
            date_end: date.endDate,
            startTime: date.startTime,
            endTime: date.endTime,
            label: date.label
        }}));
    };

    const onChangeInput = ({name, value}: OnInputChange) => {
        const strArr = value.split(' ').filter(e => e);
        const newValue = strArr.slice(0, QUERY_LENGTH).join(' ').concat((value[value.length - 1] === ' ' || strArr.length > QUERY_LENGTH) ? ' ' : '');

        setError(prev => ({...prev,...{[name]: strArr.length > QUERY_LENGTH ? translate.pages.query.error : ''}}));
        setForm(state => ({...state, ...{[name]: newValue}}))
    };

    const onChangePriority = ({name, value}: OnCheckboxChange) => {
        const newFields = value ? form.priority.concat(parseInt(name)) : form.priority.filter(e => e !== parseInt(name));

        setForm(state => ({...state, ...{priority: newFields}}))
    };

    const createLenta = (e: MouseEvent) => {
        e.preventDefault();
        const placement = CREATE_QUERIES_PLACEMENT.MODAL;

        dispatch(setShowFilter(false));

        dispatch(setOpen({placement: placement, isOpen: true}));
        dispatch(loadQueries({
            placement: placement,
            data: {
                id: null,
                type: '',
                name: '',
                value: {
                    sid_list: form.sid_list,
                    fields: form.fields,
                    stopwords: form.stopwords,
                    priority: form.priority,
                    advanced_search: form.advanced_search,
                    media_type: form.media_type,
                    query: form.query,
                    match: form.match,
                    case_sensitive: null,
                    send_to_telegram: false
                }
            }
        }));
    };

    const search = async () => {
        dispatch(setShowFilter(false));

        if(form.id){
            const result = await dispatch(saveQueries({
                id: tmp ? tmp.id : null,
                name: TMP_QUERY,
                filter: {
                    sid_list: form.sid_list,
                    fields: form.fields,
                    priority: form.priority,
                    media_type: form.media_type,
                    stopwords: form.stopwords,
                    case_sensitive: form.case_sensitive,
                    advanced_search: form.advanced_search,
                    query: form.query,
                    match: form.match,
                    send_to_telegram: false
                }
            }));
            const id = result?.payload?.id;
            const parentId = form.parentId || filter.id;

            if(id) {
                dispatch(setParams({name: LIST_NAME.ALL_NEWS, params: {
                    ...filter,
                    ...{
                        id: result.payload.id,
                        parentId: id !== parentId ? form.parentId || filter.id : ''
                    }
                }}));
            }
        } else {
            dispatch(setParams({name: LIST_NAME.ALL_NEWS, params: form}));
        }

        if(location.pathname !==  ROUTE.INDEX){
            navigate(ROUTE.INDEX);
        } else {
            dispatch(getList(LIST_NAME.ALL_NEWS));
        }
    };

    useEffect(() => {
        setTimeout(()=> {
            setTransition(showFilter);
        },10);
        if(showFilter){
            setForm({...initialStateList.filter, ...{
                date_start: query ? null : filter.date_start,
                date_end: query ? null : filter.date_end,
                query: query ? query.value.query || '' : filter.query,
                advanced_search: query ? query.value.advanced_search || null : filter.advanced_search,
                media_type: query ? query.value.media_type || null : filter.media_type,
                stopwords: query ? query.value.stopwords || '' : filter.stopwords,
                sid_list: query ? query.value.sid_list || [] : filter.sid_list,
                priority: query ? query.value.priority || [] : filter.priority,
                fields: query ? query.value.fields || [] : filter.fields,
                match: query ? query.value.match || 'all' : filter.match,
                id: query ? query.id?.toString() : '',
                name: '',
                ids: [],
                parentId: filter.parentId,
                label: query ? null : filter.label,
                startTime: query ? null : filter.startTime,
                endTime: query ? null : filter.endTime
            }});
        }
    },[showFilter]);

    if(!showFilter) {
        return null
    }

    return (
        <>
            <div className={cn(css.filter, {[css.isOpen]: transition})}>
                 <div className={css.wrapper} data-tour={"filter"} style={{
                     left: (current && SCREEN_VARIANT.MOBILE !== screen) ? current.offsetLeft - 20 : '0',
                     width: (current && SCREEN_VARIANT.MOBILE !== screen) ? current.offsetWidth + 40 : 'auto'}}
                 >
                     <Form>
                         <Row>
                             <Cell>
                                 <Input
                                     variant={INPUT_VARINAT.LIGHT}
                                     title={translate.filter.query}
                                     name={'query'}
                                     value={form.query}
                                     error={error.query}
                                     alert={error.query && translate.pages.query.alert ? <span dangerouslySetInnerHTML={{__html: translate.pages.query.alert}}/> : ''}
                                     onChange={onChangeInput}/>
                             </Cell>
                             <Cell>
                                 <Input
                                     variant={INPUT_VARINAT.LIGHT}
                                     title={translate.filter.stopwords}
                                     name={'stopwords'}
                                     value={form.stopwords}
                                     error={error.stopwords}
                                     alert={error.stopwords && translate.pages.query.alert ? <span dangerouslySetInnerHTML={{__html: translate.pages.query.alert}}/> : ''}
                                     onChange={onChangeInput}/>
                             </Cell>
                         </Row>
                         {translate.filter.advanced
                            ? <Row>
                                 <Cell>
                                     <Toggle name={'advanced_search'} variant={INPUT_VARINAT.LIGHT} title={translate.filter.advanced} value={Boolean(form.advanced_search)} onChange={handleAdvanced}/>
                                 </Cell>
                                 <Cell>
                                 </Cell>
                             </Row>
                            : null
                         }
                         <Title color={FORM_TITLE_COLOR.WHITE}>
                             {translate.filter.titleWords}
                             <TooltipProfile
                                 title={translate.tooltip.filter.titleWords}
                                 isClick={true}
                                 varinat={TOOLTIP_VARIANT.FILTER}/>
                         </Title>
                         <RadioGroup name={'match'} value={form.match} onChange={handleMatch}>
                             <Row>
                                 <Cell varinat={FORM_CELL_VARINAT.AUTO}><Radio variant={INPUT_VARINAT.LIGHT} value={'all'} title={translate.filter.allwords}/></Cell>
                                 <Cell varinat={FORM_CELL_VARINAT.AUTO}><Radio variant={INPUT_VARINAT.LIGHT} value={'phrase'} title={translate.filter.phrasewords}/></Cell>
                                 <Cell varinat={FORM_CELL_VARINAT.AUTO}><Radio variant={INPUT_VARINAT.LIGHT} value={'any'} title={translate.filter.anywords}/></Cell>
                             </Row>
                         </RadioGroup>
                         <Title color={FORM_TITLE_COLOR.WHITE}>
                             {translate.filter.titleFilter}
                             <TooltipProfile
                                 title={translate.tooltip.filter.titleFilter}
                                 isClick={true}
                                 varinat={TOOLTIP_VARIANT.FILTER}/>
                         </Title>
                         <Row>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name={'title'}
                                     value={form.fields.includes('title')}
                                     title={translate.filter.filterTitle}
                                     onChange={handleChange}/>
                             </Cell>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name={'body'}
                                     value={form.fields.includes('body')}
                                     title={translate.filter.filterBody}
                                     onChange={handleChange}/>
                             </Cell>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name={'keywords'}
                                     value={form.fields.includes('keywords')}
                                     title={translate.filter.filterKeywords}
                                     onChange={handleChange}/>
                             </Cell>
                         </Row>
                         <Title color={FORM_TITLE_COLOR.WHITE}>
                             {translate.filter.typeTitle}
                             <TooltipProfile
                                 title={translate.tooltip.filter.typeTitle}
                                 isClick={true}
                                 varinat={TOOLTIP_VARIANT.FILTER}/>
                         </Title>
                         <Row>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name='1' value={Boolean((form.priority as number[]).includes(1))}
                                     title={translate.filter.breakingNews}
                                     onChange={onChangePriority}/>
                             </Cell>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name='2' value={Boolean((form.priority as number[]).includes(2))}
                                     title={translate.filter.express}
                                     onChange={onChangePriority}/>
                             </Cell>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name='photo' value={form.media_type.includes('photo')}
                                     title={translate.filter.withPhoto}
                                     onChange={handleChangeMedia}/>
                             </Cell>
                             <Cell varinat={FORM_CELL_VARINAT.AUTO}>
                                 <Checkbox
                                     variant={INPUT_VARINAT.LIGHT}
                                     name='video' value={form.media_type.includes('video')}
                                     title={translate.filter.withVideo}
                                     onChange={handleChangeMedia}/>
                             </Cell>
                         </Row>
                         <Title color={FORM_TITLE_COLOR.WHITE}>
                             {translate.filter.lenta}
                             <TooltipProfile
                                 title={translate.tooltip.filter.lenta}
                                 isClick={true}
                                 varinat={TOOLTIP_VARIANT.FILTER}/>
                         </Title>
                         <Row>
                             <Cell>
                                 <Dropdown
                                    header={
                                        <DropDownHeader
                                            value={feed.filter(item => form.sid_list.includes(item.sid)).map(item => item.title).join(', ')}
                                            size={DROPDOWN_HEADER_SIZE.LARGE}
                                            varinat={DROPDOWN_HEADER_VARIANT.LIGHT}
                                            onChange={onSelectFilter}
                                        />
                                    }
                                 >
                                     {feed.filter(item => item.is_active && (item.title.toLowerCase().indexOf(sidFilter.toLowerCase()) > -1))
                                         .sort((item) => form.sid_list.includes(item.sid) ? -1 : 1)
                                         .map(item =>
                                         <DropdownItem
                                             key={item.id}
                                             active={form.sid_list.includes(item.sid)}
                                             icon={form.sid_list.includes(item.sid) ? ICON_VARIANT.CHECK : null}
                                             onClick={() => onSelect(item)}>
                                             {item.title}
                                         </DropdownItem>
                                     )}
                                 </Dropdown>
                             </Cell>
                         </Row>
                         {/*<Row>*/}
                             {/*<Cell>*/}
                                 {/*<div>*/}
                                     {/*<Select*/}
                                         {/*isMulti={true}*/}
                                         {/*placeholder={null}*/}
                                         {/*styles={theme === THEME.RIA ? customStyles : customStylesSputnik}*/}
                                         {/*options={feed}*/}
                                         {/*getOptionLabel={(item: ISid) => item.title}*/}
                                         {/*getOptionValue={(item: ISid) => item.sid}*/}
                                         {/*value={feed.filter(i => sid_list.includes(i.sid))}*/}
                                         {/*hideSelectedOptions={false}*/}
                                         {/*components={{IndicatorsContainer, MultiValueRemove, MultiValueContainer}}*/}
                                         {/*onChange={handleSelect}*/}
                                     {/*/>*/}
                                 {/*</div>*/}
                             {/*</Cell>*/}
                             {/*<Cell/>*/}
                         {/*</Row>*/}
                         <Devide color={FORM_DEVIDE_COLOR.BLUE}/>
                         <FilterDatePicker
                             startDate={form.date_start}
                             endDate={form.date_end}
                             startTime={form.startTime}
                             endTime={form.endTime}
                             label={form.label}
                             sidStatistics={form.sid_list}
                             disabled={Boolean(form.id)}
                             onChange={onChangeDatePicker}
                         />
                         <Row justify={FORM_ROW_JUSTIFY.BETWEEN}>
                             <Button
                                 variant={BUTTON_VARIANT.TEXT_WHITE}
                                 onClick={createLenta}
                                 endIcon={ICON_VARIANT.PLUS}>
                                        {translate.filter.createLenta}
                             </Button>
                             <Button
                                 onClick={search}>
                                    {translate.filter.search}
                             </Button>
                         </Row>
                     </Form>
                 </div>
            </div>
        </>
    )
};

export default Filter