import React, { FC, useState, MouseEvent, FormEvent } from 'react';
import Modal from '../index';
import Input from '../../../component/Input/Input/Input';
import Button, {BUTTON_GROUP_ALIGN, ButtonGroup} from '../../../component/Button';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { closeSubscribeModal } from '../../../store/reducers/AuthSlice';
import {OnInputChange} from "../../../types/IInput";
import {useTranslate} from "../../../hooks/useTranslate";
import css from './Subscribe.module.scss';

const Subscribe: FC = () => {
    const { isOpenSubscription } = useAppSelector(state => state.auth);
    const [email, setEmail] = useState<string>('');
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onChange = ({name, value}: OnInputChange) => {
        setEmail(value);
    };

    const onClick = (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    };


    return(
        <Modal
            isOpen={isOpenSubscription}
            close={() => dispatch(closeSubscribeModal())}
        >
            <div className={css.title}>
                {translate.auth.subscribeAnnotation}
            </div>
            <div className={css.body}>
                <Input name={'email'} title={translate.auth.email} value={email} onChange={onChange}/>
                <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                    <Button onClick={onClick}>{translate.auth.submit}</Button>
                </ButtonGroup>
            </div>
        </Modal>
    )
};

export default Subscribe;