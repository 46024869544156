import React, { FC } from 'react';
import Icon from '../Icon';
import {ICON_VARIANT} from "../../assets/svg";
import css from './Spinner.module.scss';

interface Props {
    height?: string,
    spinner?: string
}

const Spinner: FC<Props> = ({height = '250px', spinner = '70px'}) => {
    return(
        <div className={css.container} style={{height: height}}>
            <div className={css.loader} style={{height: spinner, width: spinner}}>
                <Icon name={ICON_VARIANT.SPINNER}/>
            </div>
        </div>
    )
}

export default Spinner