import React, { FC } from 'react';
import cn from 'classnames';
import { Icons, ICON_VARIANT} from "../../assets/svg";
import {COLOR, COLOR_SPUTNIK} from "../../types/IColor";
import css from './Icon.module.scss';

export interface IIcon {
    name: ICON_VARIANT,
    margin?: string,
    onClick?: () => void
}

const Icon: FC<IIcon> = ({ name, onClick }) => {
    const IconSVG = Icons[name];

    return (
        <span
            className={cn(css.icon, {[css.pointer]: onClick})}
            onClick={onClick}
            >
            <IconSVG/>
        </span>
    )
};

export default Icon