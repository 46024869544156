import React from 'react';

export const STRUCTURE_INTRO = () => {
    return (
        <div>
            <section>
                <p>Информационный мультимедийный терминал РИА Новости является инновационным инструментом предоставления
                    новостной информации для работы и принятия решений, обеспечивая быстрый доступ к новостям, фото, видео,
                    от собственных корреспондентов агентства.</p>
                <h4>Информационный мультимедийный терминал позволяет:</h4>
                <ol>
                    <li>Получать доступ к фото и видео в новостях и отслеживать развитие событий.</li>
                    <li>Работать с широким спектром возможностей расширенного поиска по контенту.</li>
                    <li>Персонифицировать контент и настройки под удобный формат.</li>
                    <li>Делиться и экспортировать контент с сохранением истории.</li>
                    <li>Работать с любого устройства: десктоп, планшет или мобильный телефон.</li>
                </ol>
            </section>
        </div>
    )
};