import React, { FC, useState, MouseEvent, FormEvent, useRef, useEffect } from 'react';
import {useTranslate} from "../../hooks/useTranslate";
import Tooltip, {TOOLTIP_VARIANT} from '../Tooltip';
import css from './Warning.module.scss';
import {DROPDOWN_PLACEMENT} from "../Dropdown/DropdownMenu";

const Warning: FC = () => {
    const translate = useTranslate();

    return(
        <div className={css.container}>
            <div className={css.warning}>
                <div>
                    {translate.warning.title}
                    <Tooltip
                        title={<div dangerouslySetInnerHTML={{__html: translate.warning.modal.text}}/>}
                        maxWidth={450}
                        varinat={TOOLTIP_VARIANT.BLUE}
                        placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
                        offsetX={-30}
                    >
                    </Tooltip>
                </div>
            </div>
        </div>
    )
};

export default Warning