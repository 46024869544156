import React, { FC, useState, MouseEvent, FormEvent, useEffect, KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select/creatable';
import {customStyles, customStylesSputnik, customStylesTags, MultiValueRemoveTags} from "../../../utils/styled-utils";

interface PropOption {
    label: string;
    value: string;
}

interface PropsInputTags {
    title?:string,
    value: string[]
    onChange: (arg: string[]) => void
}

const createOption = (label: string) => ({
    label,
    value: label,
});

const convertState = (state : string[]) => {
    if(!state || !state.length){
        return []
    }
    return state.map(label => createOption(label));
};

const InputTags:FC<PropsInputTags> = ({value, onChange}) => {
    const [inputValue, setInputValue] = useState('');

    const onChangeSelect = (newValue: PropOption[]) => {
        onChange(newValue.map(item => item.value));
    };

    const onKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        if([9, 13, 32].includes(event.keyCode)){
            event.preventDefault();
            onChangeSelect(convertState(value.concat(inputValue)));
            setInputValue('');
        }
    };

    const onBlur = () => {
        if (!inputValue) return;
        onChangeSelect(convertState(value.concat(inputValue)));
        setInputValue('');
    };


    return (
        <div>
            <div>

            </div>
            <CreatableSelect
                components={{
                    DropdownIndicator: null,
                    MultiValueRemove: MultiValueRemoveTags
                }}
                inputValue={inputValue}
                isClearable={false}
                isMulti
                menuIsOpen={false}
                styles={customStylesTags}
                onChange={(newValue: any) => onChangeSelect(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                placeholder=""
                value={convertState(value)}
            />
        </div>
    );
};

export default InputTags;