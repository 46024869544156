import {useCallback, useRef} from 'react';
import {useAppSelector} from "./redux";
import cn from '../lang/banners/cn';
import eg from '../lang/banners/eg';
import es from '../lang/banners/es';
import en from '../lang/banners/en';
import fa from '../lang/banners/fa';
import ru from '../lang/banners/ru';
import {LANG} from "../types/IUser";

const BANNER = {
    [LANG.CN]: cn,
    [LANG.EG]: eg,
    [LANG.EN]: en,
    [LANG.ES]: es,
    [LANG.FA]: fa,
    [LANG.RU]: ru,
};


export const useGetBanner = () => {
    const { lang } = useAppSelector(state => state.auth);

    return BANNER[lang]
};