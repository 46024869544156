import React, { FC, useEffect } from 'react';
import {IMedia, MEDIA_VARIANT} from "../../../../types/IMedia";
import {getMediaSrc, getMediaTitle} from "../../../../utils/utils";
import css from './ArticlePhoto.module.scss'

interface Props {
    media: IMedia,
    variants?: MEDIA_VARIANT[]
}

const ArticlePhoto: FC<Props> = ({media, variants = [MEDIA_VARIANT.PHOTO, MEDIA_VARIANT.AR16x9]}) => {
    const { src, imageWidth, imageHeight} = getMediaSrc(media, variants, 1000);
    const externalUrl = media.media_variants.find(mv => mv.media_variant_external_url && variants?.includes(mv.media_variant_type))?.media_variant_external_url;
    const title = getMediaTitle(media) || '';
    // const mv = media.media_variants.find(mv => mv.media_variant_type === MEDIA_VARIANT.PHOTO);

    if(!src && !externalUrl){
        return null;
    }

    return (
        <div className={css.photo}>
            <img
                src={externalUrl ? externalUrl : src}
                // src={src}
                title={title}
                alt={title}
                width={imageWidth || ''}
                height={imageHeight || ''}
                data-id={media.media_id}/>
        </div>
    )
};

export default ArticlePhoto;
