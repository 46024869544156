import React, { FC, useRef, useState, MouseEvent, useEffect } from 'react';
import cn from 'classnames';
import { useLocation, useParams, useNavigate } from "react-router-dom"
import Icon from '../../Icon';
import { TooltipProfile } from '../../Tooltip';
import Button, {BUTTON_VARIANT, BUTTON_GROUP_ALIGN, ButtonGroup} from '../../Button';
import Checkbox from '../../Input/Checkbox/Checkbox';
import Input from '../../Input/Input/Input';
import {ROUTE} from "../../../constants";
import {COLOR} from "../../../types/IColor";
import {ICON_VARIANT} from "../../../assets/svg";
import {IFavorite} from "../../../types/IUser";
import {arrayCompare, arrayCompareCheckbox, arrayCompareSome, getUnique, objectToArray} from "../../../utils/utils";
import {LIST_NAME, setFavorites} from "../../../store/reducers/LSlice";
import {analyticsFavorite} from "../../../utils/analytics";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {useGetFavoriteIds} from "../../../hooks/useGetFavorite";
import css from './HeaderFavorite.module.scss';
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange, OnInputChange} from "../../../types/IInput";
import {
    CREATE_FAVORITE_PLACEMENT,
    loadFavorite,
    setOpen as setOpenFavorite
} from "../../../store/reducers/FavoriteSlice";

const HeaderFavorite: FC = () => {
    const checkedList = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS].checked);
    const checkedHistory = useAppSelector(state => state.history.checked);
    const { articles } = useAppSelector(state => state.auth);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [transition, setTransition] = useState<boolean>(false);
    const [list, setList] = useState(articles);
    const [isChange, setChange] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const translate = useTranslate();
    const dispatch = useAppDispatch();
    const favoriteIds = useGetFavoriteIds();
    const checked = location.pathname === ROUTE.HISTORY ? objectToArray(checkedHistory) : checkedList;
    const refDropdown = useRef<HTMLDivElement | null>(null);
    const filter = list.filter(item => !search || item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);


    const onFavorite = (item: IFavorite) => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(setFavorites({
                name: item.name,
                value: getUnique([...item.value, ...ids])
            }));

            analyticsFavorite();
        }
    };

    const onOpen = () => {
        if(checked.length){
            setOpen(true);
        }
    };

    const onChange = ({name, value}: OnCheckboxChange) => {
        // console.log(list);

        const newList = list.reduce((acc: any, cur: any) => {
            console.log(cur);
            if(cur.id === name){
                return acc.concat({...cur, ...{value: value ? getUnique(cur.value?.concat(checked)) : getUnique((cur.value as number[])?.filter(item => !checked.includes(item)))}})
            }
            return acc.concat(cur);
        },[]);
        setList(newList);
        setChange(true);
    };

    const onSubmit = async () => {
        const diff = list.reduce((acc: IFavorite[], cur: IFavorite) => {
            const original = articles.find(e => cur.id === e.id);

            if(original && !arrayCompare(cur.value, original.value)){
                return acc.concat(cur);
            }

            return acc
        },[]);

        if(diff.length){
            diff.forEach(async (item) => {
                await dispatch(setFavorites({
                    name: item.name,
                    value: getUnique(item.value)
                }));
            });

            analyticsFavorite();
        }
    };

    const onAdd = () => {
        setOpen(false);
        dispatch(loadFavorite({
            placement: CREATE_FAVORITE_PLACEMENT.MODAL,
            data: {
                id: null,
                name: '',
                type: '',
                value: checked,
            }
        }));
        dispatch(setOpenFavorite({placement: CREATE_FAVORITE_PLACEMENT.MODAL, isOpen:true}));
    };

    const onSettings = () => {
        setOpen(false);
        navigate(ROUTE.FAVORITE);
    };

    useEffect(() => {
        setTimeout(()=> {
            setTransition(isOpen);
        },10);
    },[isOpen]);

    useEffect(() => {
        if(isOpen){
            setList(articles);
            setChange(false);
        }
    },[isOpen]);

    useOnClickOutside(refDropdown, isOpen, () => setOpen(false));

    // console.log('articles', articles);

    return (
        <div className={css.container}>
            <div onClick={onOpen}>
                <TooltipProfile
                    title={translate.tooltip.header.favorite}
                >
                    <button className={cn(css.button, css.background, {[css.disabled]: Boolean(!checked.length)})}>
                        <div className={cn(css.buttonIcon, css.big)}>
                            <Icon name={ICON_VARIANT.STAR_BIG}/>
                        </div>
                        <div className={css.buttonText}>
                            {favoriteIds.length}
                        </div>
                    </button>
                </TooltipProfile>
            </div>
            {isOpen
                ? <div
                    className={cn(css.dropdown, {[css.active]: transition})}
                    onClick={e => e.stopPropagation()}
                    ref={refDropdown}>
                    <div className={css.title}>
                        {translate.pages.favorite.addRemove}
                    </div>
                    <div className={css.scroll}>
                        <div className={css.search}>
                            <Input value={search} placeholder={translate.search.search} icon={ICON_VARIANT.SEARCH} onChange={({value}: OnInputChange) => setSearch(value)}/>
                        </div>
                        <div className={css.list}>
                            {filter.length
                                ? filter.map(item =>
                                    <Checkbox
                                        variant={INPUT_VARINAT.BLUE}
                                        title={item.name}
                                        name={item.id as string}
                                        value={arrayCompareSome(checked, item.value)}
                                        onChange={onChange}/>
                                )
                                : <div className={css.empty}>{translate.pages.favorite.empty}</div>
                            }
                        </div>
                    </div>
                    <div className={css.footer}>
                        {isChange
                            ? <div>
                                <Button variant={BUTTON_VARIANT.TEXT_BLUE} onClick={onSubmit}>{translate.pages.favorite.apply}</Button>
                            </div>
                            : <>
                                <div>
                                    <Button variant={BUTTON_VARIANT.TEXT_BLUE} onClick={onAdd} endIcon={ICON_VARIANT.PLUS}>{translate.pages.favorite.create}</Button>
                                </div>
                                <div>
                                    <Button variant={BUTTON_VARIANT.TEXT_BLUE} onClick={onSettings} endIcon={ICON_VARIANT.SETTINGS}>{translate.pages.favorite.settings}</Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
                : null
            }
        </div>
    )
};

export default HeaderFavorite