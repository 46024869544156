import React, { FC, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import Back from '../../../component/Back';
import Icon from '../../../component/Icon';
import {STRUCTURE} from "../../../data/faq";
import {ICON_VARIANT} from "../../../assets/svg";
import {OPTIONS_SIZE_TEXT, ROUTE} from "../../../constants";
import {useUserSettings} from "../../../hooks/useUserSettings";
import tutorial from '../../../assets/tutorial/User_manual_Terminal.docx';
import css from '../FAQ.module.scss';
import {useTranslate} from "../../../hooks/useTranslate";

const FAQ_INDEX: FC  = () => {
    const { sizeBody } = useUserSettings();
    const [ bodySize, setBodySize ] = useState<string>(sizeBody);
    const translate = useTranslate();

    const onClickSmall = () => {
        const indexBody = OPTIONS_SIZE_TEXT.indexOf(bodySize) > 0 ?  OPTIONS_SIZE_TEXT.indexOf(bodySize) - 1 : 0;
        const newSizeBody = OPTIONS_SIZE_TEXT[indexBody];

        setBodySize(newSizeBody);
    };

    const onClickLarge = () => {
        const indexBody = OPTIONS_SIZE_TEXT.indexOf(bodySize) === OPTIONS_SIZE_TEXT.length - 1 ? OPTIONS_SIZE_TEXT.length - 1 : OPTIONS_SIZE_TEXT.indexOf(bodySize) + 1;
        const newSizeBody = OPTIONS_SIZE_TEXT[indexBody];

        setBodySize(newSizeBody);
    };

    return(
        <>
            <div className={css.content}>
                <div className={css.back}><Back/></div>
                <div className={css.header}>
                    <div className={css.info}>
                        <div className={css.title} style={{fontSize: parseInt(bodySize) + 16 + 'px'}}>
                            {translate.pages.onboard.rules}
                        </div>
                        <div className={css.size}>
                            <div className={css.smallIcon} onClick={onClickSmall}>
                                <Icon name={ICON_VARIANT.TEXT}/>
                            </div>
                            <div className={css.largeIcon} onClick={onClickLarge}>
                                <Icon name={ICON_VARIANT.TEXT}/>
                            </div>
                        </div>
                    </div>
                    <div className={css.download}>
                        <a className={css.downloadLink} download={true} href={tutorial}>
                            {translate.pages.onboard.download}
                            <div className={css.downloadIcon}>
                                <Icon name={ICON_VARIANT.DOWNLOAD}/>
                            </div>
                        </a>
                    </div>
                </div>

                <div className={css.intro}>
                    {STRUCTURE.map(item =>
                        <Link className={css.introLink} key={item.id} to={ROUTE.FAQ + item.route} style={{fontSize: bodySize}}>
                            {translate.pages.onboard.nav[item.translate]}
                        </Link>
                    )}
                </div>
            </div>
        </>
    )
};

export default FAQ_INDEX