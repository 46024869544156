import React, { FC } from 'react'
import cn from 'classnames';
import css from './Tags.module.scss';

interface Props {
    onClick?: (item: any) => void
}

const TagItem: FC<Props> = ({children, onClick}) => {
    return(
        <div className={cn(css.tag, {[css.click]: onClick})} onClick={onClick}>
            {children}
        </div>
    )
};

const TagList: FC = ({children}) => {
    return(
        <div className={css.tags}>
            {children}
        </div>
    )
};

const TagLink: FC<{href: string, variant?: string, onClick?: (item: any) => void}> = ({children, href, onClick, variant = ''}) => {
    return(
        <a className={cn(css.link, css[variant])} href={href} target={"_blank"} onClick={onClick}>
            {children}
        </a>
    )
};

export {
    TagItem,
    TagList,
    TagLink
}