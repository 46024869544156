const locale_es = {
	name: "locale_es",
	months: [
		["enero", "enero"],
		["febrero", "febrero"],
		["marzo", "marzo"],
		["abril", "abril"],
		["mayo", "mayo"],
		["junio", "junio"],
		["julio", "julio"],
		["agosto", "agosto"],
		["septiembre", "septiembre"],
		["octubre", "octubre"],
		["noviembre", "noviembre"],
		["diciembre", "diciembre"],
	],
	weekDays: [
		["sábado", "sáb"],
		["domingo", "dom"],
		["lunes", "lun"],
		["martes", "mar"],
		["miércoles", "mié"],
		["jueves", "jue"],
		["viernes", "vie"],
	],
	digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	meridiems: [
		["", ""],
		["", ""],
	],
};

export default locale_es