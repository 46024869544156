import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import Scrollbar from '../../../component/Scrollbar/index';
import Split from '../../../component/Split/index';
import Newsletter from './form/Newsletter';
import Title, {ITitleMenu} from '../../../component/Title/index';
import {SCREEN_VARIANT} from "../../../types/IScreen";
import cssL from '../../../component/Layout/Layout.module.scss'
import {ICON_VARIANT} from "../../../assets/svg";
import {useTranslate} from "../../../hooks/useTranslate";
import {useAppSelector} from "../../../hooks/redux";

interface TabProps {
    id: number,
    title: string
    titleMenu: ITitleMenu[] | []
}

const Letter: FC = () => {
    const translate = useTranslate();
    const { screen } = useAppSelector(state => state.screen);
    const TABS: TabProps[] = [
        {
            id: 1,
            title: translate.pages.query.title,
            titleMenu: [
                {title: translate.pages.query.title, active: true, icon: ICON_VARIANT.CHECK, callback: () => {setTab(TABS[0])}},
                {title: translate.pages.query.filters, callback: () => {setTab(TABS[1])}}
            ]
        },{
            id: 2,
            title: translate.pages.query.filters,
            titleMenu: [
                {title: translate.pages.query.title, callback: () => {setTab(TABS[0])}},
                {title: translate.pages.query.filters, active: true, icon: ICON_VARIANT.CHECK, callback: () => {setTab(TABS[1])}}
            ]
        }
    ];
    const [tab, setTab] = useState<TabProps>(TABS[0]);


    return (
        <div className={cssL.overflow}>
            <Split sizes={[50,50]} tab={screen !== SCREEN_VARIANT.DESKTOP ? tab.id : null}>
                <div className={cssL.stickyContent}>
                    <Scrollbar>
                        <div className={cssL.sticky}>
                            <Title title={TABS[0].title}
                                   titleMenu={screen !== SCREEN_VARIANT.DESKTOP ? TABS[0].titleMenu : []}
                                   backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}/>
                        </div>
                    </Scrollbar>
                </div>
                <div className={cssL.stickyContent}>
                    <Scrollbar>
                        <div className={cssL.sticky}>
                            <Title title={TABS[1].title}
                                   titleMenu={screen !== SCREEN_VARIANT.DESKTOP ? TABS[1].titleMenu : []}
                                   backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}/>
                            <Newsletter

                            />
                        </div>
                    </Scrollbar>
                </div>
            </Split>
        </div>
    )
};

export default Letter