import React, { FC, MouseEvent } from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import {ICON_VARIANT} from "../../assets/svg";
import css from './Button.module.scss';

export enum BUTTON_VARIANT {
    DEFAULT = 'default',
    DELETE = 'delete',
    RED = 'red',
    TEXT = 'text',
    TEXT_WHITE = 'textWhite', //todo
    TEXT_BLUE = 'textBlue',
    D = 'd', //todo
    LIGHT = 'light', //todo
    LIGHT_TRANSPARENT = 'transparentLight', //todo
}

export enum BUTTON_GROUP_ALIGN{
    RIGHT = 'right',
    LEFT = 'left',
    CENTER = 'center',
    VERTICAL = 'vertical',
    SIDEBAR = 'sidebar'
}

interface IButton {
    className?: string,
    onClick: (e: MouseEvent<HTMLButtonElement>) => void,
    isLoading?: boolean,
    width?: string,
    height?: string,
    padding?: string,
    margin?: string,
    fontSize?: string,
    disabled?: boolean,
    startIcon?: ICON_VARIANT,
    endIcon?: ICON_VARIANT,
    variant?: BUTTON_VARIANT,
    tour?: string
}

interface IButtonGroup {
    className?: string,
    align?: BUTTON_GROUP_ALIGN
}

const Button: FC<IButton> = ({children, className, isLoading = false, disabled = false, variant = BUTTON_VARIANT.DEFAULT, fontSize, padding, width, height, margin, startIcon, endIcon, tour, onClick}) => {
    return(
        <button
            data-tour={tour}
            className={cn(css.wrapper, className, css[variant], {[css.isLoading]: isLoading, [css.disabled]: disabled, [css.startIcon]: Boolean(startIcon), [css.endIcon]: Boolean(endIcon)})}
            style={{fontSize:fontSize, padding: padding, width: width, height: height, margin: margin}}
            onClick={onClick}>
            <div className={css.content}>
                {startIcon ? <div className={css.icon}><Icon name={startIcon}/></div>: null}
                <div className={css.children}>{children}</div>
                {endIcon ? <div className={css.icon}><Icon name={endIcon}/></div> : null}
            </div>
            {isLoading ? <div className={css.spinner}><Icon name={ICON_VARIANT.SPINNER}/></div> : null}
        </button>
    )
};

export const ButtonGroup: FC<IButtonGroup> = ({align = BUTTON_GROUP_ALIGN.LEFT, className, children}) => {
    return (
        <div className={cn(css.group, className, css[align])}>
            {children}
        </div>
    )
};

export default Button