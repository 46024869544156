import React, { FC, ChangeEvent } from 'react'
import cn from 'classnames';
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import css from './Toggle.module.scss';

interface IToggle {
    className?: string,
    name?: string,
    title?: string | React.ReactNode,
    value: boolean,
    type?: string,
    disabled?: boolean,
    variant?: INPUT_VARINAT,
    placement?: INPUT_PLACEMENT,
    onChange: (arg: OnCheckboxChange) => void
}

const Toggle: FC<IToggle> = ({className, disabled = false, value, title, name = '', onChange, placement = INPUT_PLACEMENT.END, variant = INPUT_VARINAT.GREY}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked  = e.target.checked;

        onChange && onChange({
            name: name,
            value: checked
        })
    };

    return (
        <label className={cn(css.label, className, css[placement], css[variant], {[css.checked]: value || value === null})}>
            <div className={css.toggle}>
                <input
                    className={css.input}
                    checked={Boolean(value)}
                    disabled={disabled}
                    name={name}
                    onChange={handleChange}
                    type="checkbox"
                />
                <div className={css.icon}/>
            </div>
            {title
                ? <div className={css.title}>{title}</div>
                : null
            }
        </label>
    )
};

export default Toggle


