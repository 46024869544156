import React, { FC, useState, useContext, useRef, useEffect, MouseEvent, RefObject, UIEvent } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { DropdownContext } from './Provider';
import {useAppSelector} from "../../hooks/redux";
import css from './Dropdown.module.scss';
import useClickOutside from "../../hooks/useClickOutside";
import useScroll from "../../hooks/useScroll";
import {getPlacement, getScrollParent} from "../../utils/utils";

export enum DROPDOWN_PLACEMENT {
    AUTO = 'auto',
    LEFT = 'left',
    LEFT_TOP = 'left-top',
    RIGHT_TOP = 'right-top',
    RIGHT_BOTTOM = 'right-bottom',

    CENTER_TOP = 'center-top',
    CENTER_BOTTOM = 'center-bottom',
    OVERLAY = 'overlay'
}

export enum DROPDOWN_EFFECT {
    AUTO = 'auto',
    FADE = 'fade'
}

export enum DROPDOWN_VARIANT {
    DEFAULT = 'default',
    WHITE = 'white',
    LIST = 'list',
    MINI = 'mini'
}


export interface PropsDropDown {
    disabled?: boolean,
    placement?: DROPDOWN_PLACEMENT,
    effect?: DROPDOWN_EFFECT,
    header: string | React.ReactNode,
    onClose?: () => void
    onOpen?: () => void
    width?: string,
    height?: number,
    variant?: DROPDOWN_VARIANT,
    offset?: {
        offsetX: number,
        offsetY: number
    }
}

const OFFSET = 10;

const DropDown: FC<PropsDropDown> = ({header, placement = DROPDOWN_PLACEMENT.AUTO, effect = DROPDOWN_EFFECT.AUTO, offset = {offsetX: 0, offsetY: 0}, width, height = 350, disabled = false, variant = DROPDOWN_VARIANT.DEFAULT, onOpen, onClose, children}) => {
    const { state, openDropdown, closeDropdown } = useContext<any>(DropdownContext);
    const { windowY } = useAppSelector(state => state.screen);
    const { rtl } = useAppSelector(state => state.auth);
    const parent = useRef<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const refDropdown = useRef<HTMLDivElement | null>(null);
    const [transition, setTransition] = useState<boolean>(false);
    const [coordinate, setCoordinate] = useState<{x: number, y: number}>({x: 0, y: 0});

    const open = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if(!state.isOpen && !disabled){
            parent.current = getScrollParent(ref.current);
            openDropdown();
            onOpen && onOpen();
        }
    };

    const close = () => {
        onClose && onClose();
        closeDropdown();
    };

    useEffect(() => {
        if(state.isOpen && ref.current && refDropdown.current){
            const t = getPlacement(ref.current, refDropdown.current, {placement: placement, offsetX: offset.offsetX, offsetY: offset.offsetY, rtl: rtl});
            setCoordinate(t);
        }
    },[state.isOpen, state.search]);

    useEffect(() => {
        setTimeout(()=> {
            setTransition(state.isOpen);
        },10);
    },[state.isOpen]);


    useClickOutside(refDropdown, state.isOpen, () => close());
    useScroll(parent, state.isOpen, () => close());

    return(
        <div className={classNames(css.dropwdown, css[effect], css[variant], {[css.disabled]: disabled})} ref={ref}>
            <div className={css.header} onClick={open}>
                {header}
            </div>
            {state.isOpen && children
                ? ReactDOM.createPortal(
                    <div
                        className={classNames(css.content, css[effect], css[variant], {[css.active]: transition})}
                        style={{
                            left: coordinate.x + 'px',
                            top: coordinate.y + 'px',
                            width: width || ((ref.current!.getBoundingClientRect().width || 0) + 'px'),
                            maxHeight: height + 'px'
                        }}
                        ref={refDropdown}>
                        {children}
                    </div>
                    , document.getElementById('root') as HTMLElement)
                : null
            }
        </div>
    )
};

export default DropDown
