import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
import cn from 'classnames';
import Icon from '../Icon';
import Back from '../Back';
import {TooltipProfile} from '../Tooltip';
import ArticleTitle from './ArticleTitle';
import ArticleDate from './ArticleDate';
import ArticleShare from './ArticleShare';
import ArticleButtons from './ArticleButtons';
import ArticleKeywords from './ArticleKeywords';
import ArticleBody from './ArticleBody/index';
import ArticleTags from './ArticleTags/index';
import ArticleMedia from './ArticleMedia/index';
import ListMenu from '../List/ListItem/ListMenu';
import ListShare from '../List/ListItem/ListShare';
import {ICON_VARIANT} from "../../assets/svg";
import {useAppSelector} from "../../hooks/redux";
import {OPTIONS_SIZE_TEXT, OPTIONS_SIZE_TITLE, ROUTE, SID_EXCLUSIVE} from "../../constants";
import {useUserSettings} from "../../hooks/useUserSettings";
import {useTranslate} from "../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useUserPremium} from "../../hooks/useUserPremium";
import css from './Article.module.scss';
import {containsSids, isArticleRtl} from "../../utils/utils";

interface Props {
    preview?: boolean
}

const Article: FC<Props>  = ({preview = false}) => {
    const { user } = useAppSelector(state => state.auth);
    const { article, isLoading } = useAppSelector(state => state.article);
    const { screen } = useAppSelector(state => state.screen);
    const { sizeTitle, sizeBody, hideSids } = useUserSettings();
    const [ titleSize, setTitleSize ] = useState<string>(sizeTitle);
    const [ bodySize, setBodySize ] = useState<string>(sizeBody);
    const navigate = useNavigate();
    const translate = useTranslate();
    const premium = useUserPremium();


    const onClickSmall = () => {
        const indexTitle = OPTIONS_SIZE_TITLE.indexOf(titleSize) > 0 ?  OPTIONS_SIZE_TITLE.indexOf(titleSize) - 1 : 0;
        const newSizeTitle = OPTIONS_SIZE_TITLE[indexTitle];
        const indexBody = OPTIONS_SIZE_TEXT.indexOf(bodySize) > 0 ?  OPTIONS_SIZE_TEXT.indexOf(bodySize) - 1 : 0;
        const newSizeBody = OPTIONS_SIZE_TEXT[indexBody];

        setTitleSize(newSizeTitle);
        setBodySize(newSizeBody);
    };

    const onClickLarge = () => {
        const indexTitle = OPTIONS_SIZE_TITLE.indexOf(titleSize) === OPTIONS_SIZE_TITLE.length - 1 ? OPTIONS_SIZE_TITLE.length - 1 : OPTIONS_SIZE_TITLE.indexOf(titleSize) + 1;
        const newSizeTitle = OPTIONS_SIZE_TITLE[indexTitle];
        const indexBody = OPTIONS_SIZE_TEXT.indexOf(bodySize) === OPTIONS_SIZE_TEXT.length - 1 ? OPTIONS_SIZE_TEXT.length - 1 : OPTIONS_SIZE_TEXT.indexOf(bodySize) + 1;
        const newSizeBody = OPTIONS_SIZE_TEXT[indexBody];

        setTitleSize(newSizeTitle);
        setBodySize(newSizeBody);
    };


    if(isLoading || !article){
        return <Spinner/>
    }

    const isRtl = isArticleRtl(article);

    return (
        <div className={cn('article', css.article,{[css.preview]: preview})} data-dir={isRtl ? 'rtl' : 'ltr'}>
            {!preview && screen === SCREEN_VARIANT.DESKTOP
                ? <div className={css.back}><Back /></div>
                : null
            }
            {premium && user?.user_type === 1 && !hideSids
                ? <ArticleTags categories={article.categories}/>
                : null
            }

            <div className={css.header}>
                <div className={css.info}>
                    <div className={css.left}>
                        <ArticleDate date={article.article_date}/>
                        {containsSids([article], [SID_EXCLUSIVE]).length ? <div className={css.exclusive}>{translate.list.exclusive}</div> : null}
                    </div>
                    <div className={css.btns}>
                        <TooltipProfile
                            title={translate.tooltip.article.size}
                        >
                            <div className={css.size}>
                                <div className={css.small} onClick={onClickSmall}>
                                    <Icon name={ICON_VARIANT.TEXT}/>
                                </div>
                                <div className={css.large} onClick={onClickLarge}>
                                    <Icon name={ICON_VARIANT.TEXT}/>
                                </div>
                            </div>
                        </TooltipProfile>
                        {preview
                            ?<>
                                <ListMenu article={article}/>
                                <ListShare article={article}/>
                            </>
                            : null
                        }
                    </div>
                </div>
                <ArticleTitle title={article.article_title} fontSize={titleSize}/>
            </div>

            <ArticleKeywords keywords={article.article_keywords}/>

            {article?.medias?.length ? <ArticleMedia media={article?.medias}/> : null}

            <ArticleBody body={article.article_body} fontSize={bodySize}/>
            {!preview && screen === SCREEN_VARIANT.DESKTOP
                ? <div className={css.controls}>
                    <div className={css.wrapper}>
                        <ArticleButtons article={article}/>
                        <ArticleShare article={article}/>
                    </div>
                </div>
                : null
            }
        </div>
    )
};

export default Article