import React, { FC, useState, useRef, ChangeEvent, MouseEvent, useEffect, memo } from 'react';
import InputMask from 'react-input-mask';
import Tooltip, {TooltipProfile, TOOLTIP_VARIANT  } from '../../Tooltip';
import cn from 'classnames';
import Icon from '../../Icon'
import Checkbox from '../../Input/Checkbox/Checkbox';
import {INPUT_SIZE, INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import {DATE_FORMAT, TIME_FORMAT, validDate, validTime} from "../../../utils/utils";
import {ICON_VARIANT} from "../../../assets/svg";
import {COLOR} from "../../../types/IColor";
import {useTranslate} from "../../../hooks/useTranslate";
import css from './DatepickerHeader.module.scss';

export enum DATE_PICKER_LABEL {
    TIME = 'time',
    DAYS = 'days'
}

export interface DatePickerLabel {
    id: number,
    // title: string,
    hours: number,
    days: number,
    months: number,
    years: number,
    // label: string,
    picker: DATE_PICKER_LABEL,
}

interface Props {
    title: string | React.ReactNode,
    placeholder: [string, string],
    value: [string, string],
    label: DatePickerLabel | null,
    mask: string,
    format?: string,
    titleCheckbox: string,
    valueCheckbox: boolean,
    disabled?: boolean,
    onChange: (arg: [string, string]) => void
    removeLabel: () => void,
    icon?: ICON_VARIANT,
    onClickIcon?: () => void,
    size?: INPUT_SIZE,
    variant?: INPUT_VARINAT | null,
    onChangeCheckbox: (arg: OnCheckboxChange) => void
}

const DatePickerHeader: FC<Props> = ({title, placeholder,  size = INPUT_SIZE.NORMAL, variant = INPUT_VARINAT.BLUE, disabled = false, label, format = DATE_FORMAT, value, mask, titleCheckbox, valueCheckbox, onChange, removeLabel, icon, onChangeCheckbox, onClickIcon}) => {
    const translate = useTranslate();
    const [input, setInput] = useState({
        input1: value[0],
        input2: value[1]
    });

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        const merge = {...input,...{[name]: value}};
        setInput(merge);

        if(value.replace('_','').length === format.length && (validDate(value, format) || validTime(value, TIME_FORMAT))){
            onChange([merge.input1, merge.input2]);
        }
    };

    const onRemoveInput = (name: string) => {
        const merge = {...input,...{[name]: ''}};
        setInput(merge);
        onChange([merge.input1, merge.input2]);
    };

    const onRemoveInputAll = () => {
        const merge = {input1: '',input2: ''};
        setInput(merge);
        onChange([merge.input1, merge.input2]);
    };

    useEffect(()=>{
        if(input.input1 !== value[0] || input.input2 !== value[1]) {
            setInput({
                input1: value[0],
                input2: value[1]
            })
        }
    },[value]);

    return(
        <div className={cn(css.header, css[size], variant ? css[variant]: null, {[css.smFieldWidth]: placeholder[0].length < 9, [css.disabled]: disabled})}>
            <div className={css.title}>
                {title}
                {/*{tooltip*/}
                    {/*? <Tooltip*/}
                        {/*isClick={true}*/}
                        {/*title={tooltip}*/}
                        {/*stopPropagation={true}*/}
                        {/*varinat={INPUT_VARINAT.LIGHT === variant ? TOOLTIP_VARIANT.LIGHT : TOOLTIP_VARIANT.INPUT}/>*/}
                    {/*: null*/}
                {/*}*/}
            </div>
            <div className={css.wrapper}>
                <div className={css.inputs}>
                    <div className={css.field} onClick={(e)=> e.stopPropagation()}>
                        {label
                            ? <div className={css.tag}>
                                {translate.datepicker.tags[label.id]?.label}
                                <div className={css.close} onClick={removeLabel}><Icon name={ICON_VARIANT.CLOSE} /></div>
                            </div>
                            : <>
                                <div className={css.label}>{translate.datepicker.since}</div>
                                <InputMask
                                    name="input1"
                                    value={label ? '' : input.input1}
                                    placeholder={placeholder[0]}
                                    mask={mask}
                                    maskPlaceholder="_"
                                    onChange={onChangeInput}
                                    disabled={Boolean(disabled || label)}
                                />
                            </>
                        }
                        <div className={cn(css.remove,{[css.hasValue]: Boolean(input.input1.length)})} onClick={() => onRemoveInput('input1')}>
                            <div className={css.removeIcon}>
                                <Icon name={ICON_VARIANT.CLOSE}/>
                            </div>
                        </div>
                    </div>
                    <div className={css.field} onClick={(e)=> e.stopPropagation()}>
                        <div className={css.label}>{translate.datepicker.by}</div>
                        <InputMask
                            name="input2"
                            value={label ? '' : input.input2}
                            placeholder={placeholder[1]}
                            mask={mask}
                            maskPlaceholder="_"
                            onChange={onChangeInput}
                            disabled={Boolean(disabled || label)}
                        />
                        <div className={cn(css.remove,{[css.hasValue]: Boolean(input.input2.length)})} onClick={() => onRemoveInput('input2')}>
                            <div className={css.removeIcon}>
                                <Icon name={ICON_VARIANT.CLOSE}/>
                            </div>
                        </div>
                    </div>
                    <div className={css.checkbox}>
                        <Checkbox
                            variant={variant}
                            size={INPUT_SIZE.SMALL}
                            title={titleCheckbox}
                            onChange={onChangeCheckbox}
                            disabled={disabled}
                            value={valueCheckbox}/>
                        {icon
                            ? <div className={css.icon} onClick={onClickIcon}>
                                <Icon name={icon}/>
                            </div>
                            : null
                        }
                    </div>
                    <div className={cn(css.remove,{[css.hasValue]: Boolean(input.input1.length || input.input2.length)})} onClick={() => onRemoveInputAll()}>
                        <div className={css.removeIcon}>
                            <Icon name={ICON_VARIANT.CLOSE}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default memo(DatePickerHeader);