import React, { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import cn from 'classnames';
import Icon from '../../../Icon';
import Screen from '../../../Screen';
import { TooltipProfile } from '../../../Tooltip';
import DropDown, { DropdownItem, DropDownCheckbox, DropdownFooter } from '../../../Dropdown';
import {DROPDOWN_PLACEMENT} from "../../../Dropdown/DropdownMenu";
import {ICON_VARIANT} from "../../../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {setFavorites, setPin, setReading} from "../../../../store/reducers/LSlice";
import {useGetPinndedIds} from "../../../../hooks/useGetArticles";
import {useGetFavoriteIds} from "../../../../hooks/useGetFavorite";
import {IFavorite} from "../../../../types/IUser";
import {EXPORT_FORMAT, exportArticles} from "../../../../store/reducers/ExportSlice";
import {logHistory, LOG_FORMAT} from "../../../../store/reducers/HistorySlice";
import {ROUTE} from "../../../../constants";
import {IArticle} from "../../../../types/IArticle";
import {copyArticle} from "../../../../utils/utils";
import {useTranslate} from "../../../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../../../types/IScreen";
import {OnCheckboxChange} from "../../../../types/IInput";
import {analyticsExport} from "../../../../utils/analytics";
import css from '../ListItem.module.scss';

interface Props {
    article: IArticle
}

const ListMenu:FC<Props> = ({article}) => {
    const articleId = article.article_id;
    const [isOpen, setOpen] = useState<boolean>(false);
    const [showFavorites, setShowfavorites] = useState<boolean>(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [zip, setZip] = useState<boolean>(false);
    const [copy, setCopy] = useState<boolean>(false);
    const { articles, lang } = useAppSelector(state => state.auth);
    const { run } = useAppSelector(state => state.tour);
    const pinsIds = useGetPinndedIds();
    const dispatch = useAppDispatch();
    const favoriteIds = useGetFavoriteIds();
    const translate = useTranslate();

    const onPin = () => {
        const ids: number[] = pinsIds.includes(articleId)
            ? pinsIds.filter(i => i !== articleId)
            : pinsIds.concat(articleId);

        dispatch(setPin(ids));
    };


    const onFavorites = (favorite: IFavorite) => {
        dispatch(setFavorites({
            name: favorite.name,
            value: favorite.value.includes(articleId) ? favorite.value.filter(item => item !== articleId) : favorite.value.concat(articleId)
        }))
    };

    const onFormat = (format: EXPORT_FORMAT | LOG_FORMAT) => {
        dispatch(exportArticles({
            ids: [articleId],
            format: format as EXPORT_FORMAT,
            lang: lang,
            zipFiles: zip
        }));

        dispatch(logHistory({
            article_id: [articleId],
            format: format as LOG_FORMAT
        }));

        analyticsExport(format);
    };

    const onReading = () => {
        dispatch(setReading([articleId]));
    };


    const onCopy = () => {
        dispatch(logHistory({
            article_id: [articleId],
            format: LOG_FORMAT.PRINT
        }));
        copyArticle([article]);
        setCopy(true)
    };


    return(
        <DropDown
            header={
                <TooltipProfile
                    title={translate.tooltip.list.menu}
                >
                    <div className={cn(css.icon, {[css.active]: isOpen})}><Icon name={ICON_VARIANT.MORE}/></div>
                </TooltipProfile>
            }
            placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() =>{
                setShowDownload(false);
                setShowfavorites(false);
                setCopy(false);
                setOpen(false);
            }}
            width={'240px'}
        >
            {showFavorites
                ? <>
                    {articles.map(item =>
                        <DropdownItem
                            key={item.name}
                            active={item.value.includes(articleId)}
                            onClick={() => onFavorites(item)}
                            icon={item.value.includes(articleId) ? ICON_VARIANT.CHECK : null}
                        >
                            {item.name}
                        </DropdownItem>
                    )}
                </>
                : null
            }
            {showDownload
                ? <>
                    {Object.values(EXPORT_FORMAT).map(format =>
                        <DropdownItem
                            key={format}
                            onClick={() => onFormat(format)}
                        >
                            {format}
                        </DropdownItem>
                    )}
                    <DropdownFooter>
                        <DropDownCheckbox
                            value={zip}
                            title={translate.history.packageZip}
                            onChange={({value}: OnCheckboxChange) => setZip(value)}
                        />
                    </DropdownFooter>
                </>
                : null
            }

            {!showFavorites && !showDownload
                ? <>
                    <Screen screen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}>
                        <DropdownItem
                            preventClose={true}
                            active={pinsIds.includes(articleId)}
                            onClick={onPin}
                            icon={pinsIds.includes(articleId) ? ICON_VARIANT.PIN_FILL : ICON_VARIANT.PIN}>
                                {translate.list.pin}
                        </DropdownItem>
                        <DropdownItem
                            preventClose={true}
                            active={favoriteIds.includes(articleId)}
                            onClick={() => setShowfavorites(!showFavorites)}
                            icon={favoriteIds.includes(articleId) ? ICON_VARIANT.STAR_FILL : ICON_VARIANT.STAR}>
                            {translate.list.pin}
                        </DropdownItem>
                    </Screen>

                    {article.article_read === 0
                        ? <DropdownItem
                            onClick={onReading}
                            icon={ICON_VARIANT.READ}>
                            {translate.list.isReading}
                        </DropdownItem>
                        : null
                    }
                    <DropdownItem
                        onClick={() => window.open(ROUTE.ARTICLE + articleId)}
                        icon={ICON_VARIANT.TAB}>
                        {translate.list.newWindow}
                    </DropdownItem>
                    {/*<DropdownItem*/}
                        {/*preventClose={true}*/}
                        {/*onClick={onCopy}*/}
                        {/*active={copy}*/}
                        {/*icon={copy ? ICON_VARIANT.CHECK : ICON_VARIANT.COPY}>*/}
                        {/*{copy ? translate.list.copied : translate.list.copy}*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem
                        onClick={() => {
                            dispatch(logHistory({
                                article_id: [articleId],
                                format: LOG_FORMAT.PRINT
                            }));
                            window.open(ROUTE.PRINT + '?ids=' + articleId);
                        }}
                        icon={ICON_VARIANT.PRINT}>
                        {translate.list.print}
                    </DropdownItem>
                    <DropdownItem
                        preventClose={true}
                        onClick={() => {setShowDownload(!showFavorites)}}
                        icon={ICON_VARIANT.DOWNLOAD}>
                        {translate.list.download}
                    </DropdownItem>
                </>
                : null
            }
        </DropDown>
    )
};

export default ListMenu