const BANNER_HEADER = `
	<div id="adfox_166556244392157136"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556244392157136',
	            params: {
	                pp: 'cvrh',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_HEADER_MOBILE = `
	<div id="adfox_167653489047343813"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_167653489047343813',
	            params: {
	                pp: 'ddjj',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS2 = `
	<div id="adfox_166556304660027115"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556304660027115',
	            params: {
	                pp: 'cvrk',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS3 = `
	<div id="adfox_168845873437827021"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_168845873437827021',
	            params: {
	                pp: 'dolq',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_INDEX = `
	<div id="adfox_166556294446546234"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556294446546234',
	            params: {
	                pp: 'cvrj',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;


const BANNER_FEED = `
	<div id="adfox_16655631489975_bannerId"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655631489975_bannerId',
	            params: {
	                pp: 'cvrl',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

export default {
	BANNER_HEADER,
	BANNER_HEADER_MOBILE,
	BANNER_SIDEBARE_GS2,
	BANNER_SIDEBARE_GS3,
	BANNER_INDEX,
	BANNER_FEED
}