import React, { FC } from 'react';
import classNames from 'classnames';
import {TooltipProfile} from '../../Tooltip';
import css from './RubricForm.module.scss';

export enum FORM_FORM_VARIANT {
    DEFAULT = 'default',
    INNER = 'inner'
}

export enum FORM_CELL_VARINAT {
    W25 = 'w25',
    W33 = 'w33',
    W50 = 'w50',
    W75 = 'w75',
    AUTO = 'auto',
    FULL = 'full'
}

export enum FORM_ROW_ALIGN {
    START = 'start',
    END = 'end',
    CENTER = 'center'
}

export enum FORM_ROW_VARIANT {
    DEFAULT = 'default',
    SUB_LIST = 'subList'
}

export enum FORM_ROW_JUSTIFY {
    START = 'start',
    END = 'end',
    BETWEEN = 'between'
}

export enum FORM_TITLE_VARINAT {
    H1 = 'h1',
    H2 = 'h2',
    H6 = 'h6'
}

export enum FORM_TITLE_COLOR {
    BLACK = 'black',
    WHITE = 'white'
}

export enum FORM_TITLE_MARGIN {
    DEFAULT = 'default',
    NO_MARGIN = 'noMargin'
}


export enum FORM_ROW_MARGIN {
    DEFAULT = 'default',
    MINI_MARGIN = 'miniMargin',
    NO_MARGIN = 'noMargin'
}

export enum FORM_DEVIDE_COLOR {
    GREY = 'grey',
    BLUE = 'blue'
}

interface FORM_FORM {
    variant?: FORM_FORM_VARIANT
}

interface FORM_CELL {
    varinat?: FORM_CELL_VARINAT
}

interface FORM_ROW {
    align?: FORM_ROW_ALIGN
    justify?: FORM_ROW_JUSTIFY,
    varinat?: FORM_ROW_VARIANT,
    margin?: FORM_ROW_MARGIN
}

interface FORM_TITLE {
    varinat?: FORM_TITLE_VARINAT
    margin?: FORM_TITLE_MARGIN,
    color?: FORM_TITLE_COLOR
}

interface FORM_DL {
    title:string,
    tooltip?:string
}

interface FORM_DEVIDE {
    color?: FORM_DEVIDE_COLOR
}

export const Row:FC<FORM_ROW> = ({children, varinat = FORM_ROW_VARIANT.DEFAULT, margin = FORM_ROW_MARGIN.DEFAULT, align = FORM_ROW_ALIGN.START, justify = FORM_ROW_JUSTIFY.START}) => {
    return (
        <div className={classNames(css.row, css[varinat], css[margin], css['align-'+ align], css['justify-'+ justify])}>
            {children}
        </div>
    )
};

export const Cell:FC<FORM_CELL> = ({children, varinat = FORM_CELL_VARINAT.W50}) => {
    return (
        <div className={classNames(css.cell, css[varinat])}>
            {children}
        </div>
    )
};

export const Form: FC<FORM_FORM> = ({children, variant = FORM_FORM_VARIANT.DEFAULT}) => {
    return(
        <div className={classNames(css.form, css[variant])}>
            {children}
        </div>
    )
};

export const Title: FC<FORM_TITLE> = ({children, margin = FORM_TITLE_MARGIN.DEFAULT, color = FORM_TITLE_COLOR.BLACK, varinat = FORM_TITLE_VARINAT.H2}) => {
    return(
        <div className={classNames(css.title, css[color], css[margin], css[varinat])}>
            {children}
        </div>
    )
};

export const DL: FC<FORM_DL> = ({children, title, tooltip = ''}) => {
    return(
        <div className={css.dl}>
            <div className={css.dt}>
                {title}
                {tooltip
                    ? <TooltipProfile
                        isClick={true}
                        title={tooltip}
                    />
                    : null
                }
            </div>
            <div className={css.dd}>
                {children}
            </div>
        </div>
    )
};

export const Devide: FC<FORM_DEVIDE> = ({color = FORM_DEVIDE_COLOR.GREY}) => {
    return(
        <div className={classNames(css.devide, css[color])}/>
    )
};

