const BANNER_HEADER = `
	<div id="adfox_166556241873272854"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556241873272854',
	            params: {
	                pp: 'cvsc',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_HEADER_MOBILE = `
	<div id="adfox_167653501761751412"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_167653501761751412',
	            params: {
	                pp: 'ddjo',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS2 = `
	<div id="adfox_16655630321538612"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655630321538612',
	            params: {
	                pp: 'cvse',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS3 = `
	<div id="adfox_168846025873993652"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_168846025873993652',
	            params: {
	                pp: 'dolw',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_INDEX = `
	<div id="adfox_166556292650012507"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556292650012507',
	            params: {
	                pp: 'cvsd',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;


const BANNER_FEED = `
	<div id="adfox_16655631322137780_bannerId"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655631322137780_bannerId',
	            params: {
	                pp: 'cvsf',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

export default {
	BANNER_HEADER,
	BANNER_HEADER_MOBILE,
	BANNER_SIDEBARE_GS2,
	BANNER_SIDEBARE_GS3,
	BANNER_INDEX,
	BANNER_FEED
}