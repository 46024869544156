import React, {FC} from 'react';
import image1 from '../../../assets/images/faq/image1.png';
import image23 from '../../../assets/images/faq/image23.png';

export const STRUCTURE_AUTH_EN = () => {
    return (
        <div>
            <section id="registration">
                <h3>Registration</h3>
                <p>Contact the sales team to obtain full access to Terminal functions and subscribe to commercial newswires by clicking the Subscribe button at the top of the Terminal’s home page <a href="https://new.wire.sputniknews.com/" target="_blank">https://new.wire.sputniknews.com/</a>, choose trial access and/or obtain a paid subscription to the selected product.</p>
                <img src={image1} width="1999" height="1373" alt="Subscribe button on the login screen"/>
                <p>After filling the form, a notification with further subscription instructions will be sent through the user contact details.</p>
            </section>
            <section id="auth">
                <h3>Login</h3>
                <p>You can access the Terminal by pressing the Login button (user icon) on the homepage to see a modal login popup.</p>
                <img src={image23} width="1999" height="691" alt="Login button and form using a social login or user data"/>
                <p>To access the Sputnik multimedia news terminal, fill in the Login/Password as provided by your manager.</p>
                <p>Please be aware that one account can be used to open one session only. To enable more users to login, contact your account manager to obtain additional user login credentials.</p>
                <p>ДFill in the form available at <a href="https://rossiyasegodnya.com/web_terminal/" target="_blank">https://rossiyasegodnya.com/web_terminal/</a> to request demo access</p>
            </section>
        </div>
    )
};