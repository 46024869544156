import React, { FC, useState } from 'react';
import moment from 'moment';
import Dropdown, { DropdownItem } from '../../Dropdown'
import Icon from '../../Icon';
import Input from '../../Input/Input/Input';
import { TooltipProfile, TOOLTIP_VARIANT} from '../../Tooltip';
import DatePicker, { OnChangeDatePicker} from '../../DatePicker';
import DatePickerHeader, {DatePickerLabel, DATE_PICKER_LABEL} from '../../DatePicker/DatePickerHeader';
import {LOG_FORMAT, setParams, getHistory} from "../../../store/reducers/HistorySlice";
import {ICON_VARIANT} from "../../../assets/svg";
import {INPUT_VARINAT} from "../../../types/IInput";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {DATE_FORMAT_SHORT, TIME_FORMAT, validDate} from "../../../utils/utils";
import {PERIOD_DATEPICKER} from "../../../constants";
import {DROPDOWN_VARIANT} from "../../Dropdown/DropdownMenu";
import css from './HistoryFilter.module.scss';

const HistoryFilter: FC  = () => {
    const { format, td, fd } = useAppSelector(state => state.history.filter);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const FORMAT = {
        'vk': translate.history.filterFormat.vk,
        'telegram': translate.history.filterFormat.telegram,
        'ok': translate.history.filterFormat.ok,
        'mail': translate.history.filterFormat.mail,
        'copy': translate.history.filterFormat.copy,
        'print': translate.history.filterFormat.print,
        'pdf': translate.history.filterFormat.pdf,
        'zip': translate.history.filterFormat.zip,
        'doc': translate.history.filterFormat.doc,
        'newsml': translate.history.filterFormat.newsml
    };

    const [label, setLabel] = useState<DatePickerLabel | null>(null);

    const onClean = () =>{
        dispatch(setParams({
            format: '',
            offset: 0
        }));
        dispatch(getHistory());
    };

    const onFormat = (format: LOG_FORMAT) => {
        dispatch(setParams({
            format: format,
            offset: 0
        }));
        dispatch(getHistory());
    };

    const onChange = (values: OnChangeDatePicker) => {
        dispatch(setParams({
            fd: values.startDate,
            td: values.endDate
        }));
        dispatch(getHistory());
    };

    const removeLabel = () => {
        dispatch(setParams({
            fd: null,
            td: null,
        }));

        setLabel(null);
        dispatch(getHistory());
    };

    const clickLabel = (item: DatePickerLabel) => {
        const interval = 5 * 60 * 1000, // 5 минут
            now = moment(Math.ceil(+moment() / interval) * interval),
            ago = moment(Math.ceil(+moment() / interval) * interval).subtract({
                hours: item.hours,
                days: item.days,
                months: item.months,
                years: item.years
            });

        setLabel(item);

        dispatch(setParams({
            fd: now.diff(ago, 'days') > 0 ? ago.set({hour: 0, minute: 0}).toDate() : ago.toDate(),
            td: now.diff(ago, 'days') > 0 ? now.set({hour: 0, minute: 0}).toDate() : null,
        }));

        dispatch(getHistory());
    };

    return (
        <div className={css.filter}>
            <div className={css.filters}>
                <div className={css.filterItem}>
                    <Dropdown
                        variant={DROPDOWN_VARIANT.WHITE}
                        header={<div className={css.header}>
                            <Input
                                variant={INPUT_VARINAT.GREY}
                                name={'format'}
                                value={FORMAT[format as LOG_FORMAT] || ''}
                                readonly={true}
                                title={translate.history.format}
                                placeholder={translate.history.format}
                                tooltip={translate.tooltip.history.format}
                                onChange={() => {}}/>
                            {format
                                ? <div className={css.close} onClick={onClean}><Icon name={ICON_VARIANT.CLOSE}/></div>
                                : <div className={css.arrow}><Icon name={ICON_VARIANT.ARROW_DOWN}/></div>
                            }
                        </div>}
                    >
                        {Object.values(LOG_FORMAT).map(f =>
                            <DropdownItem key={f} onClick={() => onFormat(f)} active={Boolean(format === f)}>
                                {FORMAT[f]}
                            </DropdownItem>
                        )}
                    </Dropdown>
                </div>
                <div className={css.filterItem}>
                    <DatePicker
                        header={
                            <DatePickerHeader
                                title={<>
                                    {translate.datepicker.period}
                                    <TooltipProfile
                                        isClick={true}
                                        title={translate.tooltip.history.datepicker}
                                        stopPropagation={true}
                                        varinat={TOOLTIP_VARIANT.INPUT}/>
                                    </>
                                }
                                placeholder={[moment().format(DATE_FORMAT_SHORT), moment().format(DATE_FORMAT_SHORT)]}
                                value={[
                                    fd ? moment(fd).format(DATE_FORMAT_SHORT) : '',
                                    td ? moment(td).format(DATE_FORMAT_SHORT) : ''
                                ]}
                                mask={'99.99.99'}
                                format={DATE_FORMAT_SHORT}
                                label={label}
                                titleCheckbox={translate.datepicker.periodYear}
                                valueCheckbox={label?.months === 12}
                                removeLabel={removeLabel}
                                icon={ICON_VARIANT.CALENDAR}
                                // tooltip={translate.tooltip.history.datepicker}
                                // variant={INPUT_VARINAT.LIGHT}
                                onChange={(values: [string, string]) => {
                                    const startDate = validDate(values[0], DATE_FORMAT_SHORT) ? moment(values[0], DATE_FORMAT_SHORT).toDate() : null;
                                    const endDate = validDate(values[1], DATE_FORMAT_SHORT) ? moment(values[1], DATE_FORMAT_SHORT).toDate() : null;

                                    onChange({
                                        startDate: startDate,
                                        endDate: endDate,
                                        startTime: null,
                                        endTime: null,
                                        label: null
                                    });
                                }}
                                onChangeCheckbox={()=> {
                                    if(label?.months === 12) {
                                        removeLabel();
                                    } else {
                                        clickLabel(PERIOD_DATEPICKER.find(item => item.months === 12)!)
                                    }
                                }}
                            />
                        }
                        label={label}
                        picker={[DATE_PICKER_LABEL.DAYS]}
                        startDate={fd}
                        endDate={td}
                        startTime={null}
                        endTime={null}
                        sidStatistics={[]}
                        onChange={onChange}/>
                </div>
            </div>
        </div>
    )
};

export default HistoryFilter;