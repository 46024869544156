import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { useLocation, useNavigate } from "react-router-dom";
import Subscribe from './Subscribe';
import Scrollbar from '../Scrollbar';
import { TooltipProfile } from '../Tooltip';
import Feed from './Feed';
import Query from './Query';
import Favorite from './Favorite';
import Settings from './Settings';
import Analytics from './Analytics';
import MobileToggle from './MobileToggle';
import Icon from '../Icon'
import BannerSidebar from '../Banner/BannerSidebar';
import {SCREEN_VARIANT} from "../../types/IScreen";
import {ICON_VARIANT} from "../../assets/svg";
import {useTranslate} from "../../hooks/useTranslate";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {toggleSideBar, closeMobileSideBar } from '../../store/reducers/SidebarSlice';
import {useUserPremium} from "../../hooks/useUserPremium";
import css from './Sidebar.module.scss';
import {getList, LIST_NAME, setParams} from "../../store/reducers/LSlice";
import {ROUTE} from "../../constants";

export interface PropsSidebar {
    onChange?: (params: object) => void
    active: boolean
    disabled?: boolean
}

const Sidebar: FC = () => {
    const { screen } = useAppSelector(state => state.screen);
    const { showSidebar, showMobileSidebar } = useAppSelector(state => state.sidebar);
    const { filter } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]);
    const premium = useUserPremium();
    const translate = useTranslate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const onClick = () =>{
        dispatch(toggleSideBar());
    };

    const onChange = (params: object) => {
        dispatch(setParams({
            name: LIST_NAME.ALL_NEWS,
            params: params
        }));

        if(location.pathname !== ROUTE.INDEX){
            navigate(ROUTE.INDEX);
        } else{
            dispatch(getList(LIST_NAME.ALL_NEWS));
        }
    };

    useEffect(() => {
        dispatch(closeMobileSideBar());
    },[location.pathname]);

    return (
        <div className={cn(css.container, {[css.showSidebar]: showSidebar})}>
            {showSidebar || screen !== SCREEN_VARIANT.DESKTOP
                ? <Scrollbar>
                    <div className={css.sidebar}>
                        <MobileToggle/>

                        {(showMobileSidebar || screen === SCREEN_VARIANT.DESKTOP)
                            ? <div className={css.filter}>
                                {!premium
                                    ? <Subscribe/>
                                    : null
                                }
                                <Feed
                                    onChange={onChange}
                                    active={Boolean(location.pathname.includes(ROUTE.FEED) || (location.pathname === ROUTE.INDEX && filter.sid_list.length && !filter.id && !filter.name))}
                                    disabled={Boolean(filter.id || filter.name || location.pathname !== ROUTE.INDEX)}
                                />
                                <Query
                                    onChange={onChange}
                                    active={Boolean(location.pathname.includes(ROUTE.QUERIES) || filter.id)}/>
                                <Favorite
                                    onChange={onChange}
                                    active={Boolean(location.pathname.includes(ROUTE.FAVORITE) || filter.name)}/>
                                <BannerSidebar/>
                                {premium
                                    ? <Analytics
                                        active={Boolean([ROUTE.ANALYTICS].includes(location.pathname))}/>
                                    : null
                                }
                                <Settings
                                    active={Boolean([ROUTE.ADJUSTMENT, ROUTE.LENTA, ROUTE.HISTORY].includes(location.pathname))}/>
                            </div>
                            : null
                        }
                    </div>
                </Scrollbar>
                : null
            }

            <div className={cn(css.hider, {[css.active]: showSidebar})} onClick={onClick}>
                <TooltipProfile title={showSidebar ? translate.tooltip.sidebar.hideSidebar : translate.tooltip.sidebar.showSidebar}>
                    <Icon name={ICON_VARIANT.ARROW_RIGHT}/>
                </TooltipProfile>
            </div>
        </div>
    )
};

export default Sidebar