import React, { FC, ChangeEvent, useState } from 'react';
import cn from 'classnames';
import {TooltipProfile, TOOLTIP_VARIANT } from '../../Tooltip';
import {IInput} from "../../../types/IInput";
import css from './TextArea.module.scss';

const TextArea: FC<IInput> = ({title, name = '', tooltip, onChange, value, variant = null}) => {
    const [focus, setFocus] = useState<boolean>(false);

    const handlerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        onChange && onChange({
            name: name,
            value: e.target.value
        })
    };

    return (
        <label className={cn(css.label, variant ? css[variant] : '', {[css.hasValue]: value, [css.focus]: focus})}>
            {title
                ? <div className={css.title}>
                    {title}
                    {tooltip
                        ? <TooltipProfile
                            title={tooltip}
                            isClick={true}
                            varinat={TOOLTIP_VARIANT.INPUT}/>
                        : null
                    }
                </div>
                : null
            }
            <textarea
                className={css.input}
                name={name}
                value={value}
                autoComplete="off"
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
                onChange={handlerChange}
            />
        </label>
    )
};

export default TextArea