import React, { FC, useState, useContext, useRef, useEffect, MouseEvent, RefObject, ChangeEvent } from 'react';
import classNames from 'classnames';
import { DropdownContext } from './Provider';
import Icon from '../Icon';
import css from './Dropdown.module.scss';
import {ICON_VARIANT} from "../../assets/svg";
import {OnInputChange} from "../../types/IInput";

export enum DROPDOWN_HEADER_VARIANT {
    DEFAULT = 'default',
    LIGHT = 'light'
}

export enum DROPDOWN_HEADER_SIZE {
    DEFAULT = 'default',
    LARGE = 'large'
}

interface DropdownHeader {
    count?: number,
    title?: string,
    placeholder?: string,
    value?: string,
    error?: boolean
    isLoading?: boolean,
    onClean?: () => void,
    size?: DROPDOWN_HEADER_SIZE,
    onChange?: (params: OnInputChange) => void,
    varinat?: DROPDOWN_HEADER_VARIANT
}

const DropDownHeader: FC<DropdownHeader> = ({placeholder, title = '', value, count = 0, isLoading, error = false, size = DROPDOWN_HEADER_SIZE.DEFAULT, varinat = DROPDOWN_HEADER_VARIANT.DEFAULT, onClean, onChange}) => {
    const { state, searchDropdown } = useContext<any>(DropdownContext);
    const refInput = useRef<HTMLInputElement | null>(null);

    const onClick = (e: MouseEvent) => {
        if(value && onClean){
            e.stopPropagation();
            e.preventDefault();
            onClean();
        }
    };

    const onInput = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        searchDropdown(value);
        onChange && onChange({
            name: name,
            value: value
        })
    };

    useEffect(() => {
        if(state.isOpen && onChange && refInput.current){
            refInput.current?.focus()
        };
    },[state.isOpen]);

    return(
        <div className={classNames(css.headerContainer, css[size], css[varinat])}>
            {title ? <div className={css.headerTitle}>{title}</div> : null}
            <div className={classNames(css.headerLabel, {[css.active]: state.isOpen, [css.error]: error})}>
                <div className={css.headerContent}>
                    {onChange && state.isOpen
                        ? <input
                            ref={refInput}
                            className={css.headerInput}
                            type="text"
                            autoComplete="off"
                            value={state.search}
                            // onClick={(e: MouseEvent) => e.stopPropagation()}
                            onChange={onInput}
                            />
                        : <>
                            <div className={classNames(css.headerText, {[css.headerPlaceholder]: !value})}>
                                {value
                                    ? value
                                    : placeholder
                                }
                            </div>
                            {count && value
                                ? <div className={css.headerCount}>
                                    +{count}
                                </div>
                                : null
                            }
                        </>
                    }
                </div>
                <div className={classNames(css.headerIcon, {[css.headerLoading]: isLoading})} onClick={onClick}>
                    {value && onClean
                        ? <Icon name={ICON_VARIANT.CLOSE}/>
                        : <Icon name={isLoading ? ICON_VARIANT.SPINNER : ICON_VARIANT.ARROW_DOWN}/>
                    }
                </div>
            </div>
        </div>
    )
};

export default DropDownHeader
