import React, { FC, useState, MouseEvent, FormEvent } from 'react';
import cn from 'classnames';
import Scrollbar from '../../component/Scrollbar/index';
import Button, {BUTTON_VARIANT} from '../../component/Button/index';
import Title from '../../component/Title/index';
import {Form, Row, Cell, Title as FormTitle } from '../../component/Input/Form/index';
import Checkbox from '../../component/Input/Checkbox/Checkbox';
import Input from '../../component/Input/Input/Input';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setSidGroup} from "../../store/reducers/SidSlice";
import {INPUT_VARINAT, OnCheckboxChange, OnInputChange} from "../../types/IInput";
import {LENTA_GROUP, PRIVATE_SIDS, PUBLIC_SIDS, SUBSCRIBE} from "../../constants";
import {useTranslate} from "../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useUserPremium} from "../../hooks/useUserPremium";
import {alert} from "../../store/reducers/AlertSlice";
import css from './Feed.module.scss';
import cssL from '../../component/Layout/Layout.module.scss';
import {ISid} from "../../types/IUser";
import {ICON_VARIANT} from "../../assets/svg/index";
import {
    FORM_CELL_VARINAT,
    FORM_FORM_VARIANT, FORM_ROW_MARGIN, FORM_TITLE_MARGIN,
    FORM_TITLE_VARINAT
} from "../../component/Input/Form/index";

const Feed: FC  = () => {
    const { isLoading } = useAppSelector(state => state.sid);
    const { list, groups, lang } = useAppSelector(state => state.auth);
    const [search, setSearch] = useState<string>('');
    const value = groups.length ? groups[0].value : [];
    const [feed, setFeed] = useState(value);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const premium = useUserPremium();

    const onSearch = ({value}: OnInputChange) => {
        setSearch(value)
    };

    const onClick = (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(setSidGroup(feed));
    };

    const subscribe = (sid?: ISid) => {
        dispatch(alert({
            title: translate.list.subscribe,
            description: sid ? translate.list.subscribeFeed1 + ' <b>«' + sid.title + '»</b> '+ translate.list.subscribeFeed2 : translate.list.subscribeInfo,
            button: ''
        }))
    };

    const onChange = ({name, value}: OnCheckboxChange) => {
        const newValue = value ? feed.concat(name) : feed.filter(item => item !== name);
        const sid = list.find(item => item.sid === name);

        if(premium || sid?.is_active) {
            setFeed(newValue)
        } else if(sid){
            subscribe(sid);
        }
    };

    const selectIds = (group: string[]) => {
        if(list.some(item => group.includes(item.sid) && !item.is_active)){
            subscribe();
        } else if(group.every(sid => feed.includes(sid))){
            setFeed(feed.filter(sid => !group.includes(sid)))
        } else {
            setFeed(feed.concat(group).reduce<string[]>((result, item) => result.includes(item) ? result : [... result, item], []));
        }
    };

    const selectGroup = (id: number) => {
        const group = list.filter(sid => sid['id-parent'] === id).filter(sid => sid.is_active).map(sid => sid.sid);
        selectIds(group);
    };


    const selectAll = () => {
        const sids = list.filter(sid => sid.is_active).map(sid => sid.sid);

        if(sids.every(sid => feed.includes(sid))){
            setFeed([]);
        } else{
            setFeed(sids);
        }
    };

    return (
        <div className={cssL.stickyContent}>
            <Scrollbar>
                <Title
                    title={translate.pages.feed.title}
                    sticky={true}
                    backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}/>

                <div className={css.order}>
                    <Form variant={FORM_FORM_VARIANT.INNER}>
                        <Row>
                            {premium
                                ? LENTA_GROUP.map((column,i) =>
                                    <Cell key={i} varinat={FORM_CELL_VARINAT.W50}>
                                        {column.map(group =>
                                            list.filter(item => item['id-parent'] === group.id).some(item => item.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 || !search)
                                                ? <Row key={group.id}>
                                                    <div>
                                                        <FormTitle margin={FORM_TITLE_MARGIN.NO_MARGIN}>{translate.feeds[group.id]}</FormTitle>
                                                        <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                                                            <Button
                                                                onClick={() => selectGroup(group.id as number)}
                                                                variant={BUTTON_VARIANT.D}>
                                                                    {translate.pages.feed.selectAll}
                                                            </Button>
                                                        </Row>
                                                        {list.filter(item => item['id-parent'] === group.id).filter(item => item.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 || !search).map(sid =>
                                                            <Row margin={FORM_ROW_MARGIN.MINI_MARGIN} key={sid.id}>
                                                                <Checkbox
                                                                      title={sid.title}
                                                                      name={sid.sid}
                                                                      variant={INPUT_VARINAT.BLUE}
                                                                      value={feed.includes(sid.sid)}
                                                                      onChange={onChange}/>
                                                            </Row>
                                                        )}
                                                    </div>
                                                </Row>
                                                : null
                                        )}
                                    </Cell>
                                )
                                : <>
	                                <Cell varinat={FORM_CELL_VARINAT.W50}>
                                        <div className={cn(css.section)}>
                                            <Row margin={FORM_ROW_MARGIN.NO_MARGIN}>
                                                <FormTitle varinat={FORM_TITLE_VARINAT.H1}>{translate.pages.feed.yourFeeds}</FormTitle>
                                            </Row>
                                            {PUBLIC_SIDS
                                                .filter(item => list.filter(sid => item.ids.includes(sid.sid)).some(item => item.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 || !search))
                                                .map((group, i) =>
                                                    <Row key={i}>
                                                        <div>
                                                            <FormTitle margin={FORM_TITLE_MARGIN.NO_MARGIN}>{translate.lang[group.lang]}</FormTitle>
                                                            <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                                                                <Button
                                                                    onClick={() => selectIds(group.ids)}
                                                                    variant={BUTTON_VARIANT.D}>
                                                                        {translate.pages.feed.selectAll}
                                                                </Button>
                                                            </Row>
                                                            {group.ids.map(item => list.find(sid => item === sid.sid)).map(sid =>
                                                                sid
                                                                    ? <Row margin={FORM_ROW_MARGIN.MINI_MARGIN} key={sid.id}>
                                                                        <Checkbox
                                                                            title={sid.title}
                                                                            name={sid.sid}
                                                                            variant={INPUT_VARINAT.BLUE}
                                                                            value={feed.includes(sid.sid)}
                                                                            onChange={onChange}/>
                                                                    </Row>
                                                                    : null
                                                            )}
                                                        </div>
                                                    </Row>
                                                )}
                                        </div>
                                    </Cell>
                                    <Cell varinat={FORM_CELL_VARINAT.W50}>
                                        <div className={cn(css.section, css.private)}>
                                            <Row margin={FORM_ROW_MARGIN.NO_MARGIN}>
                                                <Cell varinat={FORM_CELL_VARINAT.FULL}>
                                                    <FormTitle varinat={FORM_TITLE_VARINAT.H1}>{translate.pages.feed.subscribeFeeds}</FormTitle>
                                                </Cell>
                                                <a className={css.link} href={SUBSCRIBE[lang].link} target="_blank">{translate.pages.feed.link}</a>
                                            </Row>
                                            {PRIVATE_SIDS
                                                .filter(item => list.filter(sid => item.ids.includes(sid.sid)).some(item => item.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 || !search))
                                                .map((group, i) =>
                                                    <Row key={i}>
                                                        <div>
                                                            <FormTitle margin={FORM_TITLE_MARGIN.NO_MARGIN}>{translate.lang[group.lang]}</FormTitle>
                                                            <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                                                                <Button
                                                                    onClick={() => selectIds(group.ids)}
                                                                    variant={BUTTON_VARIANT.D}>
                                                                    {translate.pages.feed.selectAll}
                                                                </Button>
                                                            </Row>
                                                            {group.ids.map(item => list.find(sid => item === sid.sid)).map(sid =>
                                                                sid
                                                                    ? <Row margin={FORM_ROW_MARGIN.MINI_MARGIN} key={sid.id}>
                                                                        <Checkbox
                                                                            title={sid.title}
                                                                            name={sid.sid}
                                                                            variant={INPUT_VARINAT.BLUE}
                                                                            value={feed.includes(sid.sid)}
                                                                            onChange={onChange}/>
                                                                    </Row>
                                                                    : null
                                                            )}
                                                        </div>
                                                    </Row>
                                            )}
                                        </div>
                                    </Cell>
                                </>
                            }
                        </Row>
                    </Form>
                    <div className={css.controls}>
                        <div className={css.controlsColumn}>
                            <div className={css.info}>
                                <div className={css.title}>{translate.pages.feed.subtitle}</div>
                                <Button onClick={selectAll} variant={BUTTON_VARIANT.TEXT_BLUE} fontSize={'12px'} padding={'0'}>{translate.pages.feed.selectAllFeed}</Button>
                            </div>
                            <div className={css.buttons}>
                                <Button
                                    disabled={!feed.length}
                                    onClick={onClick}
                                    height={'40px'}
                                    fontSize={'16px'}
                                    isLoading={isLoading}>
                                    {translate.pages.feed.apply}
                                </Button>
                            </div>
                        </div>
                        {translate.pages.feed.search
                            ? <div className={css.field}>
                                <div className={css.fieldTitle}>{translate.pages.feed.search}</div>
                                <div className={css.fieldInput}>
                                    <Input
                                        value={search}
                                        variant={INPUT_VARINAT.GREY}
                                        onChange={onSearch}
                                        placeholder={translate.pages.feed.placeholder}
                                        icon={ICON_VARIANT.SEARCH}
                                    />
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </Scrollbar>
        </div>
    )
};

export default Feed