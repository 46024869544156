import React, { FC, useRef, useState, MouseEvent, useEffect } from 'react';
import cn from 'classnames';
import { useLocation, useParams, useNavigate } from "react-router-dom"
import Icon from '../../Icon';
import {TooltipProfile } from '../../Tooltip';
import DropDown, { DropdownItem, DropdownFooter, DropDownCheckbox } from '../../Dropdown';
import HeaderFavorite from '../HeaderFavorite';
import {COLOR} from "../../../types/IColor";
import {ICON_VARIANT} from "../../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useGetFavoriteIds} from "../../../hooks/useGetFavorite";
import {IFavorite} from "../../../types/IUser";
import {copyArticle, copyHistory, getUnique, objectToArray} from "../../../utils/utils";
import {EXPORT_FORMAT, exportArticles} from "../../../store/reducers/ExportSlice";
import {LOGIN_FIELD, sendMailOpen, setLoginOpen, telegramModalOpen} from "../../../store/reducers/ModalSlice";
import {ROUTE, SHARE_URL} from "../../../constants";
import {useGetArticles} from "../../../hooks/useGetArticles";
import {logHistory, LOG_FORMAT} from "../../../store/reducers/HistorySlice";
import {LIST_NAME, setFavorites, resetFilter, getList, setParams} from "../../../store/reducers/LSlice";
import {useUserPremium} from "../../../hooks/useUserPremium";
import {alert} from "../../../store/reducers/AlertSlice";
import {useTranslate} from "../../../hooks/useTranslate";
import {GS_VARIANT, tooglePremiumScreen} from "../../../store/reducers/ScreenSlice";
import {useGetMainSid} from "../../../hooks/useGetMainSid";
import {useGetCheckedHistory, useGetHistory} from "../../../hooks/useGetHistory";
import {
    analyticsChangeGS,
    analyticsCopy,
    analyticsExport, analyticsFavorite, analyticsGraph,
    analyticsMail,
    analyticsPrint,
    analyticsTelegram
} from "../../../utils/analytics";
import {OnCheckboxChange} from "../../../types/IInput";
import css from './HeaderButtons.module.scss';

const HeaderButtons: FC = () => {
    const checkedList = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS].checked);
    const checkedHistory = useAppSelector(state => state.history.checked);
    const { articles, lang } = useAppSelector(state => state.auth);
    const { premiumScreen } = useAppSelector(state => state.screen);
    const [zip, setZip] = useState<boolean>(false);
    const location = useLocation();
    const checked = location.pathname === ROUTE.HISTORY ? objectToArray(checkedHistory) : checkedList;
    const { id } = useParams();
    const favoriteIds = useGetFavoriteIds();
    const dispatch = useAppDispatch();
    const checkedArticles = useGetArticles(checked);
    const article = useGetArticles([parseInt(id || '0')]);
    const history = useGetCheckedHistory();
    const premium = useUserPremium();
    const translate = useTranslate();
    const sid = useGetMainSid();
    const navigate = useNavigate();

    const onFavorite = (item: IFavorite) => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(setFavorites({
                name: item.name,
                value: getUnique([...item.value, ...ids])
            }));

            analyticsFavorite();
        }
    };

    const onFormat = (format: EXPORT_FORMAT | LOG_FORMAT) => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(exportArticles({
                ids: ids,
                format: format as EXPORT_FORMAT,
                lang: lang,
                zipFiles: zip
            }));
            dispatch(logHistory({
                article_id: ids,
                format: format as LOG_FORMAT
            }));

            analyticsExport(format);
        }
    };

    const onPrint = () => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(logHistory({
                article_id: ids,
                format: LOG_FORMAT.PRINT
            }));
            window.open(ROUTE.PRINT + '?ids=' + ids.join(','));

            analyticsPrint();
        }
    };

    const onMail = () => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(sendMailOpen(ids));

            analyticsMail();
        }
    };

    const onCopy = () => {
        const ids = id ? [parseInt(id)] : checked;
        const data = id ? article : checkedArticles;

        if(location.pathname === ROUTE.HISTORY){
            copyHistory(history);

            analyticsCopy();
        }else if(data && ids.length){
            dispatch(logHistory({
                article_id: ids,
                format: LOG_FORMAT.COPY
            }));
            copyArticle(data);

            analyticsCopy();
        }
    };

    const onLetter = () => {
        dispatch(telegramModalOpen());

        analyticsTelegram();
    };

    const onAnalytics = () => {
        if(location.pathname !== ROUTE.ANALYTICS){
            navigate(ROUTE.ANALYTICS);

            analyticsGraph();
        }
    };


    const onChangeSreen = () => {
        if(premium){
            dispatch(resetFilter({
                name: LIST_NAME.ALL_NEWS
            }));

            dispatch(tooglePremiumScreen());
            if(premiumScreen === GS_VARIANT.GS2){
                dispatch(setParams({
                    name: LIST_NAME.ALL_NEWS,
                    params: {
                        sid_list: [sid.sid],
                        id: '',
                        name: '',
                        ids: []
                    }
                }));
                dispatch(getList(LIST_NAME.ALL_NEWS));
            }
        } else {
            dispatch(alert({
                title: translate.list.subscribe,
                description: translate.list.subscribeInfo,
                button: ''
            }))
        }

        analyticsChangeGS();
    };

    return (
        <div className={css.container}>
            <div>
                <HeaderFavorite/>
                {/*<DropDown*/}
                    {/*disabled={Boolean(!checked.length)}*/}
                    {/*header={*/}
                        {/*<TooltipProfile*/}
                            {/*title={translate.tooltip.header.favorite}*/}
                        {/*>*/}
                            {/*<button className={cn(css.button, css.background, {[css.disabled]: Boolean(!checked.length)})}>*/}
                                {/*<div className={cn(css.buttonIcon, css.big)}>*/}
                                    {/*<Icon name={ICON_VARIANT.STAR_BIG} fill={COLOR.WHITE}/>*/}
                                {/*</div>*/}
                                {/*<div className={css.buttonText}>*/}
                                    {/*{favoriteIds.length}*/}
                                {/*</div>*/}
                            {/*</button>*/}
                        {/*</TooltipProfile>*/}
                    {/*}*/}
                    {/*width={'240px'}*/}
                {/*>*/}
                    {/*{articles.map(item =>*/}
                        {/*<DropdownItem*/}
                            {/*key={item.name}*/}
                            {/*onClick={() => onFavorite(item)}>*/}
                            {/*{item.name}*/}
                        {/*</DropdownItem>*/}
                    {/*)}*/}
                    {/*<DropdownItem*/}
                        {/*icon={ICON_VARIANT.PLUS}*/}
                        {/*active={true}*/}
                        {/*onClick={() => navigate(ROUTE.FAVORITE)}>*/}
                        {/*{translate.pages.favorite.create}*/}
                    {/*</DropdownItem>*/}
                {/*</DropDown>*/}
            </div>

            <div>
                <DropDown
                    disabled={Boolean(!checked.length)}
                    width={'240px'}
                    header={
                        <TooltipProfile
                            title={translate.tooltip.header.download}
                        >
                            <button className={cn(css.button, {[css.disabled]: Boolean(!checked.length)})}>
                                <div className={css.buttonIcon}>
                                    <Icon name={ICON_VARIANT.DOWNLOAD}/>
                                </div>
                                {/*<CSSHeaderButton disabled={Boolean(!checked.length)}>*/}
                                    {/*<CSSHeaderButtonIcon disabled={Boolean(!checked.length)}><Icon name={ICON_VARIANT.DOWNLOAD} fill={COLOR.WHITE}/></CSSHeaderButtonIcon>*/}
                                {/*</CSSHeaderButton>*/}
                            </button>
                        </TooltipProfile>
                    }
                >
                    {Object.values(EXPORT_FORMAT).map(format =>
                        <DropdownItem
                            key={format}
                            onClick={() => onFormat(format)}
                        >
                            {format}
                        </DropdownItem>
                    )}
                    <DropdownFooter>
                        <DropDownCheckbox
                            value={zip}
                            title={translate.history.packageZip}
                            onChange={({value}: OnCheckboxChange) => setZip(value)}
                        />
                    </DropdownFooter>
                </DropDown>
            </div>
            <div>
                <TooltipProfile
                    title={translate.tooltip.header.print}
                >
                    <button onClick={onPrint} className={cn(css.button, {[css.disabled]: Boolean(!checked.length)})}>
                        <div className={css.buttonIcon}>
                            <Icon name={ICON_VARIANT.PRINT}/>
                        </div>
                    </button>
                </TooltipProfile>
            </div>
            {premium
                ? <div>
                <TooltipProfile
                    title={translate.tooltip.header.sendEmail}
                >
                    <button onClick={onMail} className={cn(css.button, {[css.disabled]: Boolean(!checked.length)})}>
                        <div className={css.buttonIcon}>
                            <Icon name={ICON_VARIANT.MAIL} />
                        </div>
                    </button>
                </TooltipProfile>
            </div>
                : null
            }
            <div>
                <TooltipProfile
                    title={translate.tooltip.header.copy}
                >
                    <button onClick={onCopy} className={cn(css.button, {[css.disabled]: Boolean(!checked.length)})}>
                        <div className={css.buttonIcon}>
                            <Icon name={ICON_VARIANT.COPY} />
                        </div>
                    </button>
                </TooltipProfile>
            </div>
            {/*{premium*/}
                {/*? <div>*/}
                    {/*<TooltipProfile*/}
                        {/*title={translate.tooltip.header.analytics}*/}
                    {/*>*/}
                        {/*<button onClick={onAnalytics} className={cn(css.button, {[css.disabled]: Boolean(!checked.length)})}>*/}
                            {/*<div className={cn(css.buttonIcon, css.big)}>*/}
                                {/*<Icon name={ICON_VARIANT.ANALYTICS} />*/}
                            {/*</div>*/}
                        {/*</button>*/}
                    {/*</TooltipProfile>*/}
                {/*</div>*/}
                {/*: null*/}
            {/*}*/}
        </div>
    );
};

export default HeaderButtons