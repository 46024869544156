import React, { FC, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import qs from 'query-string';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import Split from '../../component/Split/index';
import Title from '../../component/Title';
import Scrollbar from '../../component/Scrollbar';
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useAppSelector} from "../../hooks/redux";
import {STRUCTURE} from "../../data/faq";
import {useTranslate} from "../../hooks/useTranslate";
import {ROUTE} from "../../constants";
import {IStructure} from "../../data/faq";
import css from './FAQ.module.scss';
import cssL from '../../component/Layout/Layout.module.scss';

interface TabProps {
    id: number,
    title: string
}


const FAQ: FC  = () => {
    const { screen } = useAppSelector(state => state.screen);
    const translate = useTranslate();
    const TABS: TabProps[] = [
        {
            id: 1,
            title: translate.pages.onboard.manual
        },{
            id: 2,
            title: translate.pages.onboard.chapter
        }
    ];
    const [tab, setTab] = useState<TabProps>(TABS[0]);
    const refObserver = useRef<IntersectionObserver | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { chapter } = qs.parse(location.search);

    const scrollTo = (target: string) => {
        const anchor = document.getElementById(target);
        if(anchor){
            anchor.scrollIntoView({ behavior: "smooth" });
        }
    };

    const onClickSub = (sub: IStructure) => {
        const link = ROUTE.FAQ + sub.route;
        if(location.pathname !== link){
            navigate(link)
        }

        setTimeout(() =>{
            if(sub.target) {
                scrollTo(sub.target);
            }
        }, 150);
    };

    return(
        <>
            <Helmet>
                <title>{translate.pages.onboard.manual + ' - ' + translate.title}</title>
            </Helmet>
            <div className={css.faq}>
                <Split sizes={[70,30]} tab={screen !== SCREEN_VARIANT.DESKTOP ? tab.id : null}>
                    <Scrollbar>
                        <div className={cssL.inner}>
                            <Outlet/>
                        </div>
                    </Scrollbar>
                    <div className={css.structure}>
                        <Scrollbar>
                            <div className={css.structureTitle}>
                                <Title title={translate.pages.onboard.chapter}/>
                            </div>
                            <div className={css.structureContent}>
                                <div className={css.structureList}>
                                    {STRUCTURE.map((sturcture, i) =>
                                        <div className={css.structureItem} key={i}>
                                            <Link className={css.structureLink} to={ROUTE.FAQ + sturcture.route} key={sturcture.id}>
                                                {translate.pages.onboard.nav[sturcture.translate]}
                                            </Link>
                                            {sturcture.children?.map(sub =>
                                                <div className={css.structureSub} key={sub.id} onClick={() => onClickSub(sub)}>
                                                    {translate.pages.onboard.nav[sub.translate]}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Scrollbar>
                    </div>
                </Split>
            </div>
        </>
    )
};

export default FAQ