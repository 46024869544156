import React, { FC, useState } from 'react';
import cn from 'classnames';
import {TooltipProfile} from '../Tooltip';
import { useLocation, useNavigate } from "react-router-dom"
import Button, {ButtonGroup, BUTTON_GROUP_ALIGN, BUTTON_VARIANT} from '../Button';
import Radio from '../Input/Radio/Radio';
import RadioGroup  from '../Input/Radio/RadioGroup';
import Icon from '../Icon';
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange} from "../../types/IInput";
import {useAppSelector} from "../../hooks/redux";
import {ICON_VARIANT} from "../../assets/svg";
import {ROUTE, TMP_QUERY} from "../../constants";
import {useTranslate} from "../../hooks/useTranslate";
import {LIST_NAME} from "../../store/reducers/LSlice";
import css from './Sidebar.module.scss';
import {PropsSidebar} from "./index";

const COUNT = 10;

const Query: FC<PropsSidebar> = ({ onChange, active }) => {
    const { queries } = useAppSelector(state => state.auth);
    const { id, parentId } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS].filter);
    const [count, setCountSid] = useState<number>(COUNT);
    const translate = useTranslate();
    const navigate = useNavigate();
    const allCount = queries.filter(item => item.name !== TMP_QUERY).length;

    const onChangeSid = ({name, value}: OnCheckboxChange) => {
        onChange && onChange({
            id: value,
            name: '',
            ids: [],
            parentId: '',
            offset: 0
        });
    };

    const onIncrement = () => {
        const newCount = (allCount - count) >= 10 ? count + 10 : count + (allCount - count);
        setCountSid(newCount);
    };

    const onDecrement = () => {
        const newCount = count - 10 >= COUNT ?  count - 10 : COUNT;
        setCountSid(newCount);
    };

    const onCreate = () => {
        navigate(ROUTE.QUERIES);
    };

    return (
        <div className={cn(css.box, {[css.active]: active})}>
            <div className={css.header}>
                <div className={css.title}>{translate.sidebar.filter}</div>
                <div className={css.icon} onClick={onCreate}>
                    <TooltipProfile
                        title={translate.tooltip.sidebar.query}
                    >
                        <Icon name={ICON_VARIANT.SETTINGS}/>
                    </TooltipProfile>
                </div>
            </div>

            <RadioGroup name={'id'} value={parentId || id} onChange={onChangeSid}>
                {queries.filter(item => item.name !== TMP_QUERY).slice(0, count).map(item =>
                    <Radio
                        key={item.id as string}
                        variant={INPUT_VARINAT.BLUE}
                        value={item.id as string}
                        placement={INPUT_PLACEMENT.START}
                        title={<>
                                <div className={css.text}>
                                    {item.name}
                                </div>
                                {(item.value as any)?.send_to_telegram
                                    ? <div className={css.import}>
                                        <TooltipProfile
                                            title={translate.tooltip.sidebar.import}>
                                            <div className={css.importIcon}>
                                                <Icon name={ICON_VARIANT.TELEGRAM_2}/>
                                            </div>
                                        </TooltipProfile>
                                    </div>
                                    : null
                                }
                            </>
                        }/>
                )}
            </RadioGroup>

            <ButtonGroup align={BUTTON_GROUP_ALIGN.SIDEBAR}>
                {count > COUNT
                    ? <Button onClick={onDecrement} variant={BUTTON_VARIANT.LIGHT_TRANSPARENT} width={'100%'} height={'20px'} fontSize={'10px'}>
                        {translate.sidebar.hide} {(count - 10 >= COUNT) ? '10' : count - COUNT}
                    </Button>
                    : null
                }
                {allCount > count
                    ? <Button onClick={onIncrement} variant={BUTTON_VARIANT.LIGHT} width={'100%'} height={'20px'} fontSize={'10px'}>
                        {translate.sidebar.more} {((allCount - count) >= 10) ? '10' : allCount - count}
                    </Button>
                    : null
                }
            </ButtonGroup>
        </div>
    )
};

export default Query