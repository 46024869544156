export enum COLOR {
    MAIN = '#354577',
    GREY = '#545454',
    BLACK = '#000',
    BLUE = '#1345AE',
    BLUE2 = '#44579E',
    LIGHT = '#E1EFFF',
    WHITE = '#fff',
    INHERIT = 'inherit'
}

export enum COLOR_SPUTNIK {
    MAIN = '#FFAC39',
    GREY = '#545454',
    BLACK = '#000',
    BLUE = '#E79A2D',
    BLUE2 = '#FF9600',
    LIGHT = '#FBE1BC',
    WHITE = '#fff',
    INHERIT = 'inherit'
}