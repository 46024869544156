import React, { FC  } from 'react';
import {useTranslate} from "../../../hooks/useTranslate";
import css from './HistoryHeader.module.scss'

const HistoryHeader: FC = () => {
    const translate = useTranslate();
    return(
        <div className={css.header}>
            <div className={css.item}>{translate.history.date}</div>
            <div className={css.item}>{translate.history.type}</div>
            <div className={css.item}>{translate.history.title}</div>
            <div className={css.item}>{translate.history.id}</div>
        </div>
    )
};

export default HistoryHeader;