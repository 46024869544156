import React, { FC, useState } from 'react';
import cn from 'classnames';
import {TooltipProfile } from '../../../Tooltip';
import Icon from '../../../Icon';
import {copyArticle} from "../../../../utils/utils";
import {IArticle} from "../../../../types/IArticle";
import css from '../ListItem.module.scss';
import {ICON_VARIANT} from "../../../../assets/svg";
import {LOG_FORMAT, logHistory} from "../../../../store/reducers/HistorySlice";
import {useAppDispatch} from "../../../../hooks/redux";
import {useTranslate} from "../../../../hooks/useTranslate";

interface Props {
    article: IArticle
}

const ListCopy:FC<Props> = ({article}) => {
    const articleId = article.article_id;
    const [isOpen, setOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const translate = useTranslate();


    const onCopy = () => {
        dispatch(logHistory({
            article_id: [articleId],
            format: LOG_FORMAT.COPY
        }));
        copyArticle([article]);
    };


    return(
        <TooltipProfile
            title={translate.tooltip.list.copy}
        >
            <div className={cn(css.icon, css.copyIcon, {[css.active]: isOpen})} onClick={onCopy}>
                <Icon name={ICON_VARIANT.COPY}/>
            </div>
        </TooltipProfile>
    )
};

export default ListCopy