import React, { FC, useState, MouseEvent, FormEvent, useEffect, useRef } from 'react';
import Title from "../../../../component/Title";
import { Form, Row, Cell } from "../../../../component/Input/Form";
import Newsletter from "./Form";
import {useTranslate} from "../../../../hooks/useTranslate";
import css from '../Export.module.scss';
import {FORM_FORM_VARIANT} from "../../../../component/Input/Form";

const ExportForm: FC = () => {
    const translate = useTranslate();

    return (
        <div className={css.form}>
            <Title title={translate.pages.export.mailTitle} sticky={true}/>
	        <Form variant={FORM_FORM_VARIANT.INNER}>
                <Row>
                    <Cell>
	                    <Newsletter/>
                    </Cell>
                    <Cell/>
                </Row>
            </Form>
        </div>
    )
};

export default ExportForm;