import {useCallback, useRef} from 'react';
import {useAppSelector} from "./redux";
import {ISid} from "../types/IUser";

export function useGetMainSid(): ISid{
    const { list, groups } = useAppSelector(state => state.auth);
    let index = 0;
    let group = groups?.[0]?.value || [];

    if(group.length && list.length) {
        let arrSids = list.map(e => e.sid);
        index = arrSids.indexOf(group[0]) > 0 ? arrSids.indexOf(group[0]) : 0;

        for(let i = 1; i < groups.length; i++){
            const currentIndex = arrSids.indexOf(group[i]);
            if(currentIndex >= 0 && currentIndex < index){
                index = currentIndex;
            }
        }
    }

    return list[index];
}

export function useGetGroups(): string[]{
    const { groups } = useAppSelector(state => state.auth);
    let group = groups?.[0]?.value || [];

    return group;
}