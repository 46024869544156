import React, { FC, useState, useEffect, MouseEvent } from 'react';
import cn from 'classnames';
import { useLocation, useParams, useNavigate } from "react-router-dom"
import Button, {ButtonGroup, BUTTON_VARIANT, BUTTON_GROUP_ALIGN} from '../Button';
import Checkbox from '../Input/Checkbox/Checkbox';
import Icon from '../Icon';
import {TooltipProfile} from '../Tooltip';
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange} from "../../types/IInput";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {ICON_VARIANT} from "../../assets/svg";
import { ROUTE} from "../../constants";
import {LIST_NAME} from "../../store/reducers/LSlice";
import {cleanStoreSids} from "../../store/reducers/StoreSlice";
import {useTranslate} from "../../hooks/useTranslate";
import {arrayCompareCheckbox} from "../../utils/utils";
import {useUserSids} from "../../hooks/useUserSids";
import {ISid} from "../../types/IUser";
import css from './Sidebar.module.scss';
import {PropsSidebar} from "./index";

const COUNT = 5;

const Feed: FC<PropsSidebar> = ({onChange, active, disabled = false}) => {
    const { sids } = useAppSelector(state => state.store);
    const [count, setCountSid] = useState<number>(COUNT);
    const feed = useUserSids();
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();

    const filter = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]?.filter);
    const sid_list = filter?.sid_list || [];
    const allCount = feed.length;

    const onChangeSid = ({name, value}: OnCheckboxChange) => {
        const allSids = feed.map(item => item.sid);
        const filtedList = sid_list.filter(sid => allSids.includes(sid));
        const newValue = value ? filtedList.concat(name) : filtedList.filter(item => item !== name);

        onChange && onChange({
            sid_list: disabled ? filtedList : newValue,
            id: '',
            name: '',
            ids: [],
            parentId: '',
            offset: 0
        });

        dispatch(cleanStoreSids([name]));
    };

    const onChangeAll = ({name, value}: OnCheckboxChange) => {
        const newValue = (value && feed.length) ? feed.map(item => item.sid) : [];

        onChange && onChange({
            sid_list: newValue,
            id: '',
            name: '',
            ids: [],
            parentId: '',
            offset: 0
        });

        dispatch(cleanStoreSids(newValue));
    };

    const onWrapper = () => {
        if(filter.id || filter.name || location.pathname !== ROUTE.INDEX) {
            const allSids = feed.map(item => item.sid);
            const filtedList = sid_list.filter(sid => allSids.includes(sid));
            const newValue = filtedList.length ? filtedList : allSids;

            onChange && onChange({
                sid_list: newValue,
                id: '',
                name: '',
                ids: [],
                parentId: '',
                offset: 0
            });

            dispatch(cleanStoreSids(sid_list));
        }
    };

    const onIncrement = () => {
        const newCount = (allCount - count) >= 10 ? count + 10 : count + (allCount - count);
        setCountSid(newCount);
    };

    const onDecrement = () => {
        const newCount = count - 10 >= COUNT ?  count - 10 : COUNT;
        setCountSid(newCount);
    };

    const onCreate = (e: MouseEvent) => {
        e.stopPropagation();
        navigate(ROUTE.FEED);
    };

    const getCount = (item: ISid) => {
        const sid = item.sid;

        return sids[sid] && !sid_list.includes(sid) ? sids[sid].length : 0
    };

    return (
        <div className={cn(css.box, {[css.active]: active, [css.disabled]: disabled})} onClick={onWrapper}>
            <div className={css.header}>
                <div className={css.title}>{translate.sidebar.lenta}</div>
                <div className={css.icon} onClick={onCreate}>
                    <TooltipProfile
                        title={translate.tooltip.sidebar.feed}
                    >
                        <Icon name={ICON_VARIANT.SETTINGS}/>
                    </TooltipProfile>
                </div>
                <div className={css.icon}>
                    <Checkbox
                        variant={INPUT_VARINAT.BLUE}
                        value={arrayCompareCheckbox(sid_list, feed.map(item => item.sid))}
                        onChange={onChangeAll}/>
                </div>
            </div>
            <div className={css.checkbox}>
                {feed.slice(0, count).map(item =>
                    <Checkbox
                        key={item.id}
                        variant={INPUT_VARINAT.BLUE}
                        placement={INPUT_PLACEMENT.START}
                        name={item.sid}
                        value={sid_list.includes(item.sid)}
                        title={<>
                            <div className={cn(css.text, {[css.disabled]: disabled})}>{item.title}</div>
                            <div className={cn(css.counter, {[css.hide]: !Boolean(getCount(item)), [css.disabled]: disabled})}>{getCount(item)}</div>
                        </>}
                        onChange={onChangeSid}/>
                )}
            </div>
            <ButtonGroup align={BUTTON_GROUP_ALIGN.SIDEBAR}>
                {count > COUNT
                    ? <div className={css.button}>
                        <Button onClick={onDecrement} variant={BUTTON_VARIANT.LIGHT_TRANSPARENT} width={'100%'} height={'20px'} fontSize={'10px'}>
                            {translate.sidebar.hide} {(count - 10 >= COUNT) ? '10' : count - COUNT}
                        </Button>
                    </div>
                    : null
                }
                {allCount > count
                    ? <div className={css.button}>
                        <Button onClick={onIncrement} variant={BUTTON_VARIANT.LIGHT} width={'100%'} height={'20px'} fontSize={'10px'}>
                            {translate.sidebar.more} {((allCount - count) >= 10) ? '10' : allCount - count}
                        </Button>
                    </div>
                    : null
                }
            </ButtonGroup>
        </div>
    )
};

export default Feed