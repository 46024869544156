import React, { FC, useState, useRef, ChangeEvent, MouseEvent, useEffect } from 'react';
import { Calendar, DateObject } from "react-multi-date-picker"
import moment from 'moment-jalaali'
import cn from 'classnames'
import Dropdown, { DropdownItem } from '../../Dropdown';
import Icon from '../../Icon'
import { DATE_KEYS} from "../../../utils/utils";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getCountCalendar, resetCalendar} from "../../../store/reducers/CalendarSlice";
import '../Datepicker.scss'
import { useCalendar} from "../../../hooks/useTranslate";
import css from './Calendar.module.scss';
import {DROPDOWN_VARIANT} from "../../Dropdown/DropdownMenu";
import {ICON_VARIANT} from "../../../assets/svg";

export interface OnChangeCalendar {
    startDate: Date | null,
    endDate: Date | null,
}

interface ICalendar {
    startDate: Date | null,
    endDate: Date | null,
    sidStatistics: string[]
    onChange: (dates: OnChangeCalendar) => void
}


const Calendar2:FC<ICalendar> = ({startDate, endDate, sidStatistics = [], onChange}) => {
    const { screen } = useAppSelector(state => state.screen);
    const {counts, isLoading} = useAppSelector(state => state.calendar);
    const dispatch = useAppDispatch();
    const calendar = useCalendar();
    const calendarRef = useRef();
    const [date, setDate] = useState(new DateObject({date: startDate!, locale: calendar.locale, calendar: calendar.calendar}));
    const next = new DateObject(date).add(1, "month");

    const handleChange = (dates: DateObject[]) => {
        const [ startDate, endDate ] = dates;

        onChange({
            startDate: startDate ? moment(startDate.convert(calendar.convert, calendar.gregorian).format(), startDate._format).toDate() : null,
            endDate: endDate ? moment(endDate.convert(calendar.convert, calendar.gregorian).format(), endDate._format).toDate() : null,
        })
    };

    const onChangeDropdown = (name: string, index: number) => {
        let date = (calendarRef.current as any).date;

        (calendarRef.current as any)?.set(name, index);
        setDate(new DateObject(date));
    };

    const update = (date: Date) => {
        if(sidStatistics.length) {
            dispatch(getCountCalendar({
                date_start: moment(date).set('date', 1).subtract({months: 2}).format('YYYY-MM-DD'),
                date_end: moment(date).set('date', 1).add({months: 1}).format('YYYY-MM-DD'),
                sidStatistics: sidStatistics
            }))
        }
    };


    useEffect(() => {
        update(new Date())
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetCalendar());
        }
    }, []);

    return (
        <div>
            <div className={css.header}>
                <button className={cn(css.arrow, css.arrowLeft)} onClick={() => onChangeDropdown('month', date.month.index)}>
                    <Icon name={ICON_VARIANT.ARROW_LEFT}/>
                </button>
                <button className={cn(css.arrow, css.arrowRight)} onClick={() => onChangeDropdown('month', date.month.index + 2)}>
                    <Icon name={ICON_VARIANT.ARROW_RIGHT}/>
                </button>
                <div className={css.column}>
                    <div className={css.month}>
                        <Dropdown
                            header={date.month.name}
                            variant={DROPDOWN_VARIANT.MINI}
                            width={'90px'}
                            offset={{offsetX: 0, offsetY: 50}}
                        >
                            {date.months.map(item =>
                                <DropdownItem
                                    key={item.index}
                                    onClick={() => onChangeDropdown('month', item.index + 1)}>
                                    {item.name}
                                </DropdownItem>
                            )}
                        </Dropdown>
                    </div>
                    <div className={css.year}>
                        <Dropdown
                            header={<>{date.format('YYYY')}<span className={css.yearIcon}><Icon name={ICON_VARIANT.PICKER}/></span></>}
                            variant={DROPDOWN_VARIANT.MINI}
                            width={'90px'}
                            offset={{offsetX: 0, offsetY: 50}}
                        >
                            {Array(20).fill(null).map((item, i) => {
                                const year = new DateObject(date).set('year', date.year - i);

                                return (
                                    <DropdownItem
                                        key={i}
                                        onClick={() => onChangeDropdown('year', year.year)}
                                    >
                                        {year.format('YYYY')}
                                    </DropdownItem>
                                )
                            })}
                        </Dropdown>
                    </div>
                </div>
                <div className={css.column}>
                    <div className={css.month}>
                        <Dropdown
                            header={next.month.name}
                            variant={DROPDOWN_VARIANT.MINI}
                            width={'90px'}
                            offset={{offsetX: 0, offsetY: 50}}
                        >
                            {next.months.map(item =>
                                <DropdownItem
                                    key={item.index}
                                    onClick={() => onChangeDropdown('month', item.index)}>
                                    {item.name}
                                </DropdownItem>
                            )}
                        </Dropdown>
                    </div>
                    <div className={css.year}>
                        <Dropdown
                            header={<>{next.format('YYYY')}<span className={css.yearIcon}><Icon name={ICON_VARIANT.PICKER}/></span></>}
                            variant={DROPDOWN_VARIANT.MINI}
                            width={'90px'}
                            offset={{offsetX: 0, offsetY: 50}}
                        >
                            {Array(20).fill(null).map((item, i) => {
                                const year = new DateObject(next).set('year', date.year - i);

                                return (
                                    <DropdownItem
                                        key={i}
                                        onClick={() => onChangeDropdown('year', year.year)}
                                    >
                                        {year.format('YYYY')}
                                    </DropdownItem>
                                )
                            })}
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className={'datepicker'}>
                <Calendar
                    ref={calendarRef}
                    value={[startDate!, endDate!]}
                    onChange={handleChange}
                    range
                    rangeHover
                    numberOfMonths={2}
                    maxDate={new Date()}
                    calendar={calendar.calendar}
                    locale={calendar.locale}
                    hideMonth={true}
                    hideYear={true}
                    buttons={false}
                    weekStartDayIndex={1}
                    mapDays={({ date }: {date: DateObject}) => {
                        const d = date.format(DATE_KEYS);
                        const value: number = counts[d] || 0;

                        if(value === 0) return {};

                        if(value > 1000){
                            return { className: 'day-1000'}
                        } else if(value > 500){
                            return { className: 'day-500'}
                        } else if(value > 350){
                            return { className: 'day-350'}
                        } else if(value > 250){
                            return { className: 'day-250'}
                        } else {
                            return { className: 'day-0'}
                        }
                    }}
                />
            </div>
        </div>
    )
};

export default Calendar2;


