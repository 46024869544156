import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import Scrollbar from '../../component/Scrollbar/index';
import Form from './form/index';
import Sort from './sort/index';
import Split from '../../component/Split/index';
import {useTranslate} from "../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useAppSelector} from "../../hooks/redux";
import {ITitleMenu} from "../../component/Title/index";
import {ICON_VARIANT} from "../../assets/svg/index";
import cssL from '../../component/Layout/Layout.module.scss';

interface TabProps {
    id: number,
    title: string,
    titleMenu: ITitleMenu[] | []
}

const Favorite: FC  = () => {
    const translate = useTranslate();
    const { screen } = useAppSelector(state => state.screen);
    const TABS: TabProps[] = [
        {
            id: 1,
            title: translate.pages.favorite.title,
            titleMenu: [
                {title: translate.pages.favorite.title, active: true, icon: ICON_VARIANT.CHECK, callback: () => {setTab(TABS[0])}},
                {title: translate.pages.favorite.list, callback: () => {setTab(TABS[1])}}
            ]

        },{
            id: 2,
            title: translate.pages.favorite.list,
            titleMenu: [
                {title: translate.pages.favorite.title, active: true, icon: ICON_VARIANT.CHECK, callback: () => {setTab(TABS[0])}},
                {title: translate.pages.favorite.list, callback: () => {setTab(TABS[1])}}
            ]
        }
    ];
    const [tab, setTab] = useState<TabProps>(TABS[0]);

    return(
        <div className={cssL.overflow}>
            <Split sizes={[50,50]} tab={screen !== SCREEN_VARIANT.DESKTOP ? tab.id : null}>
                <div className={cssL.stickyContent}>
                    <Scrollbar>
                        <Sort title={TABS[1].title} titleMenu={screen !== SCREEN_VARIANT.DESKTOP ? TABS[1].titleMenu : []}/>
                    </Scrollbar>
                </div>
                <div className={cssL.stickyContent}>
                    <Scrollbar>
                        <Form title={TABS[0].title} titleMenu={screen !== SCREEN_VARIANT.DESKTOP ? TABS[0].titleMenu : []}/>
                    </Scrollbar>
                </div>
            </Split>
        </div>
    )
};

export default Favorite;
