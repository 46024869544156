import React, { FC, useRef, useState, MouseEvent, useEffect } from 'react';
import cn from 'classnames';
import { Link } from "react-router-dom";
import Profile from '../Profile'
import Icon from '../../Icon';
import Burger from '../Burger';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {useTranslate} from "../../../hooks/useTranslate";
import {MAIL, ROUTE} from "../../../constants";
import {logout, changeLang} from "../../../store/reducers/AuthSlice";
import {ICON_VARIANT} from "../../../assets/svg";
import {LANG} from "../../../types/IUser";
import {analyticsChangeGS, analyticsChangeLang, analyticsTelegram, analyticsButtonUserGuide} from "../../../utils/analytics";
import {GS_VARIANT, tooglePremiumScreen} from "../../../store/reducers/ScreenSlice";
import {getList, LIST_NAME, resetFilter, setParams} from "../../../store/reducers/LSlice";
import {alert} from "../../../store/reducers/AlertSlice";
import {useGetMainSid} from "../../../hooks/useGetMainSid";
import {useUserPremium} from "../../../hooks/useUserPremium";
import {telegramModalOpen} from "../../../store/reducers/ModalSlice";
import css from './HeaderMenu.module.scss';
import { setShowMenu } from "../../../store/reducers/HeaderSlice";

interface Props {

}

const HeaderMenu: FC<Props> = ({}) => {
    const { showMenu } = useAppSelector(state => state.header);
    const { screen, premiumScreen } = useAppSelector(state => state.screen);
    const { lang, user } = useAppSelector(state => state.auth);
    const refDropdown = useRef<HTMLDivElement | null>(null);
    const premium = useUserPremium();
    const translate = useTranslate();
    const sid = useGetMainSid();
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(setShowMenu(!showMenu));
    };

    const onLogout = (e: MouseEvent) => {
        e.preventDefault();
        dispatch(setShowMenu(false));
        dispatch(logout());
    };

    const close = () => {
        dispatch(setShowMenu(false));
    };

    const onChangeLang = (lang: LANG) => {
        dispatch(setShowMenu(false));
        dispatch(changeLang(lang));

        analyticsChangeLang(lang);
    };

    const onTelegram = (e: MouseEvent) => {
        e.preventDefault();
        dispatch(setShowMenu(false));
        dispatch(telegramModalOpen());

        analyticsTelegram();
    };

    const onUserGuide = () => {
        analyticsButtonUserGuide()
        close()
    };

    const onChangeSreen = () => {
        dispatch(setShowMenu(false));
        if(premium){
            dispatch(resetFilter({
                name: LIST_NAME.ALL_NEWS
            }));

            dispatch(tooglePremiumScreen());
            if(premiumScreen === GS_VARIANT.GS2){
                dispatch(setParams({
                    name: LIST_NAME.ALL_NEWS,
                    params: {
                        sid_list: [sid.sid],
                        id: '',
                        name: '',
                        ids: []
                    }
                }));
                dispatch(getList(LIST_NAME.ALL_NEWS));
            }
        } else {
            dispatch(alert({
                title: translate.list.subscribe,
                description: translate.list.subscribeInfo,
                button: ''
            }))
        }
        analyticsChangeGS();
    };

    useOnClickOutside(refDropdown, showMenu, () => onClick());

    return (
        <div className={css.container}>
            <Burger
                active={screen !==  SCREEN_VARIANT.DESKTOP ? showMenu : false}
                onClick={onClick}
            />
            {screen === SCREEN_VARIANT.DESKTOP
                ? <div className={cn(css.menu, {[css.isOpen]: showMenu})} ref={refDropdown}>
                    <div className={css.close} onClick={close}><Icon name={ICON_VARIANT.CLOSE}/></div>
                    {premium
                        ? <div className={css.user}><div className={css.userIcon}><Icon name={ICON_VARIANT.PROFILE}/></div>{user.id}</div>
                        : null
                    }
                    <div className={css.list}>
                        <Link
                            className={css.item}
                            to={ROUTE.ADJUSTMENT}
                            onClick={close}>
                                {translate.header.allSettings}
                        </Link>
                        <a
                            className={css.item}
                            href={`mailto:${MAIL}`}
                            target={"_blank"}
                            onClick={close}>
                                {translate.header.feedback}
                        </a>
                        <Link
                            className={css.item}
                            to={'/'}
                            onClick={onTelegram}>
                                {translate.header.telegram}
                        </Link>
                        {translate.header.faq
                            ? <Link
                                className={css.item}
                                to={ROUTE.FAQ}
                                onClick={onUserGuide}>
                                    {translate.header.faq}
                            </Link>
                            : null
                        }
                        {!premium
                            ? <Link
                                className={css.item}
                                to={'/'}
                                onClick={onChangeSreen}>
                                {premiumScreen === GS_VARIANT.GS3 ? translate.header.subscribeScreen : translate.header.premiumScreen}
                            </Link>
                            : null
                        }
                        <Link
                            className={css.item}
                            to={'/'}
                            onClick={onLogout}>
                                <div className={css.icon}><Icon name={ICON_VARIANT.LOGOUT}/></div>
                                {translate.header.logout}
                        </Link>
                    </div>

                    <div className={css.footer}>
                        <div/>
                        <div className={css.lang}>
                            {Object.values(LANG).map(l =>
                                <div className={cn(css.langItem, {[css.active]: lang === l})} key={l} onClick={() => onChangeLang(l)}>{l}</div>
                            )}
                        </div>
                    </div>
                </div>
                :  <Profile/>
            }
        </div>
    )
};

export default HeaderMenu;