import React, { FC, useState, useEffect, MouseEvent, useRef } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {IMedia, MEDIA_VARIANT} from "../../../../types/IMedia";
import Preview from './Preview';
import Controls from './Controls';
import { isMobile} from "../../../../utils/utils";
import {VIDEO_RIA_URL} from "../../../../constants";
import {useDebounce} from "../../../../hooks/useDebounce";
import css from './ArticleVideo.module.scss';


interface Props {
    media: IMedia,
    index: number
}

export interface PropVideoSettings {
    init: boolean,
    playing: boolean,
    played: number,
    duration: number,
    mouseProgress: number,
    volume: number,
    muted: boolean,
    activeMouseProgress: boolean,
    activeMouseEnter: boolean,
}

const ArticleVideo:FC<Props> = ({media, index}) => {
    const mv = (media.media_variants || media.media_variant).find(mv => mv.media_variant_type === MEDIA_VARIANT.VIDEO || mv.type === MEDIA_VARIANT.VIDEO);
    const [settings, setSettings] = useState({
        init: false,
        playing: false,
        played: 0,
        duration: 0,
        mouseProgress: 0,
        volume: 0.8,
        muted: false,
        activeMouseProgress: false,
    });

    const [show, setShow] = useState<boolean>(true);
    const refVideo = useRef<HTMLDivElement | null>(null);
    const refPlayer = useRef<any | null>(null);
    const fullscreen = useFullScreenHandle();
    const mobile = isMobile();
    const debounce = useDebounce(() => { setShow(false) }, 3000);

    const onDuration = (duration: number) => {
        setSettings({...settings, ...{duration: duration}});
    };

    const onProgress = (state: {played: number}) => {
        setSettings({...settings, ...{played: state.played}});
    };

    const onMouseMove = () => {
        if(settings.init) {
            if (!show) {
                setShow(true)
            } else {
                debounce();
            }
        }
    };

    const getUrl = () => {
        if(mv?.["import-id"]){
            return VIDEO_RIA_URL + mv["import-id"];
        } else if(mv?.media_variant_external_id) {
            return VIDEO_RIA_URL + mv.media_variant_external_id
        } else if(mv?.media_variant_external_url) {
            return mv.media_variant_external_url
        } else if(mv?.media_variant_id){
            return VIDEO_RIA_URL + mv?.media_variant_id
        }

        return ''
    };

    useEffect(()=>{
        setSettings({...settings, ...{playing: false}});
    },[index]);

    if(!media || !mv){
        return null
    }

    return (
        <FullScreen handle={fullscreen}>
            <div className={cn(css.container, {[css.fullscreen]: fullscreen.active})}
                 onClick={(e: MouseEvent) => {
                     e.preventDefault();
                     e.stopPropagation();
                 }}
                 data-video
            >
                <div className={cn(css.video, {[css.fullscreen]: fullscreen.active, [css.show]: !settings.playing || show})}
                     ref={refVideo}
                     onMouseMove={onMouseMove}>
                    <ReactPlayer
                        ref={refPlayer}
                        width={'100%'}
                        height={'100%'}
                        style={{
                            position: 'absolute',
                            left: '0',
                            top: '0'
                        }}
                        controls={false}
                        playing={settings.playing}
                        volume={settings.volume}
                        muted={settings.muted}
                        onDuration={onDuration}
                        onProgress={onProgress}
                        url={getUrl()}/>


                    {!settings.init || !mobile
                        ? <Preview
                            preview={''}
                            settings={settings}
                            setSettings={setSettings}
                        />
                        : null
                    }

                    {!mobile && settings.init
                        ? <Controls
                            over={show}
                            settings={settings}
                            fullscreen={fullscreen}
                            setSettings={setSettings}
                            refPlayer={refPlayer}
                        />
                        : null
                    }
                </div>
            </div>
        </FullScreen>
    )
};

export default ArticleVideo;
