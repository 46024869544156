import React, { FC, useEffect, useState, MouseEvent } from 'react';
import cn from 'classnames';
import { Link } from "react-router-dom";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Button, { BUTTON_VARIANT} from '../../Button/index';
import Dropdown, { DropdownItem, DropdownFooter, DropDownCheckbox } from '../../Dropdown';
import Icon from '../../Icon';
import {ICON_VARIANT} from "../../../assets/svg/index";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {changeLang, logout} from "../../../store/reducers/AuthSlice";
import {useTranslate} from "../../../hooks/useTranslate";
import {MAIL, ROUTE, SUBSCRIBE} from "../../../constants";
import {copyArticle, getUnique, objectToArray} from "../../../utils/utils";
import {sendMailOpen, telegramModalOpen} from "../../../store/reducers/ModalSlice";
import {useGetArticles} from "../../../hooks/useGetArticles";
import {IFavorite, LANG} from "../../../types/IUser";
import {EXPORT_FORMAT, exportArticles} from "../../../store/reducers/ExportSlice";
import {LIST_NAME, setFavorites} from "../../../store/reducers/LSlice";
import {LOG_FORMAT, logHistory} from "../../../store/reducers/HistorySlice";
import {OnCheckboxChange} from "../../../types/IInput";
import css from './Profile.module.scss';
import { setShowMenu } from "../../../store/reducers/HeaderSlice";
import {useUserPremium} from "../../../hooks/useUserPremium";
import {analyticsChangeLang, analyticsTelegram, analyticsButtonAnalytic, analyticsButtonUserGuide} from "../../../utils/analytics";


const Profile: FC<{}> = ({}) => {
    const { showMenu } = useAppSelector(state => state.header);
    const checkedList = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS].checked);
    const checkedHistory = useAppSelector(state => state.history.checked);
    const { articles, lang } = useAppSelector(state => state.auth);
    const [zip, setZip] = useState<boolean>(false);
    const location = useLocation();
    const { id } = useParams();
    const checked = location.pathname === ROUTE.HISTORY ? objectToArray(checkedHistory) : checkedList;
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const checkedArticles = useGetArticles(checked);
    const article = useGetArticles([parseInt(id || '0')]);
    const [ transition, setTransition ] = useState<boolean>(false);
    const navigate = useNavigate();
    const link = SUBSCRIBE[lang].link;
    const premium = useUserPremium();

    const userLogout = () => {
        dispatch(logout());
    };

    const onFavorite = (item: IFavorite) => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(setFavorites({
                name: item.name,
                value: getUnique([...item.value, ...ids])
            }));
        }
        close()
    };

    const onFormat = (format: EXPORT_FORMAT | LOG_FORMAT) => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(exportArticles({
                ids: ids,
                format: format as EXPORT_FORMAT,
                lang: lang,
                zipFiles: zip
            }));

            dispatch(logHistory({
                article_id: ids,
                format: format as LOG_FORMAT
            }))
        }
        close()
    };

    const onPrint = () => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(logHistory({
                article_id: ids,
                format: LOG_FORMAT.PRINT
            }));
            window.open(ROUTE.PRINT + '?ids=' + ids.join(','))
        }
        close()
    };

    const onMail = () => {
        const ids = id ? [parseInt(id)] : checked;

        if(ids.length){
            dispatch(sendMailOpen(ids));
        }
        close()
    };

    const onTelegram = (e: MouseEvent) => {
        e.preventDefault();
        dispatch(telegramModalOpen());
        analyticsTelegram();
        close()
    };

    const onCopy = () => {
        const ids = id ? [parseInt(id)] : checked;
        const data = id ? article : checkedArticles;

        if(data && ids.length){
            dispatch(logHistory({
                article_id: ids,
                format: LOG_FORMAT.COPY
            }));
            copyArticle(article);
        }
        close()
    };

    const onChangeLang = (lang: LANG) => {
        dispatch(changeLang(lang));

        analyticsChangeLang(lang);
        // close();
    };

    const onAnalytics = () => {
        analyticsButtonAnalytic()
        close()
    };

    const onUserGuide = () => {
        analyticsButtonUserGuide()
        close()
    };

    const createFavorite = () => {
        navigate(ROUTE.FAVORITE);
    };

    const close = () => {
        dispatch(setShowMenu(false));
    };

    useEffect(() => {
        setTimeout(()=>{
            setTransition(showMenu);
        },10);
    },[showMenu]);

    if(!showMenu) {
        return null
    }

    return(
        <div className={cn(css.container, {[css.isOpen]:transition})}>
            <div className={css.content}>
                <div className={css.group}>
                    <Button onClick={userLogout} variant={BUTTON_VARIANT.TEXT_WHITE} width={'215px'} padding={'0'}>
                        <div className={css.inner}>
                            <div className={css.icon}>
                                <Icon name={ICON_VARIANT.LOGOUT}/>
                            </div>
                            <div className={css.button}>
                                {translate.header.logout}
                            </div>
                        </div>
                    </Button>
                    <div className={css.favourites}>
                        <div>
                            <Dropdown
                                header={
                                    <Button startIcon={ICON_VARIANT.STAR_BIG} onClick={() => {}}>
                                        <div className={css.button}>{translate.header.favorite}</div>
                                    </Button>
                                }
                                width={'240px'}
                            >
                                {articles.map(item =>
                                    <DropdownItem key={item.name} onClick={() => onFavorite(item)}>{item.name}</DropdownItem>
                                )}
                                <DropdownItem
                                    icon={ICON_VARIANT.PLUS}
                                    active={true}
                                    onClick={createFavorite}
                                >
                                    {translate.pages.favorite.create}
                                </DropdownItem>
                            </Dropdown>
                        </div>
                    </div>
                    <Dropdown
                        header={
                            <Button startIcon={ICON_VARIANT.DOWNLOAD} onClick={() => {}} variant={BUTTON_VARIANT.TEXT_WHITE} width={'215px'}>
                                <div className={css.button}>
                                    {translate.header.download}
                                </div>
                            </Button>
                        }
                        width={'240px'}
                    >
                        {Object.values(EXPORT_FORMAT).map(format =>
                            <DropdownItem
                                key={format}
                                onClick={() => onFormat(format)}
                            >
                                {format}
                            </DropdownItem>
                        )}
                        <DropdownFooter>
                            <DropDownCheckbox
                                value={zip}
                                title={translate.history.packageZip}
                                onChange={({value}: OnCheckboxChange) => setZip(value)}
                            />
                        </DropdownFooter>
                    </Dropdown>
                    <Button startIcon={ICON_VARIANT.PRINT} onClick={onPrint} variant={BUTTON_VARIANT.TEXT_WHITE} width={'215px'}>
                        <div className={css.button}>
                            {translate.header.print}
                        </div>
                    </Button>
                    <Button startIcon={ICON_VARIANT.MAIL} onClick={onMail} variant={BUTTON_VARIANT.TEXT_WHITE} width={'215px'}>
                        <div className={css.button}>
                            {translate.header.mail}
                        </div>
                    </Button>
                    <Button startIcon={ICON_VARIANT.COPY} onClick={onCopy} variant={BUTTON_VARIANT.TEXT_WHITE} width={'215px'}>
                        <div className={css.button}>
                            {translate.header.copy}
                        </div>
                    </Button>
                    {/*<Button startIcon={ICON_VARIANT.ANALYTICS} iconSize={30} onClick={onAnalytics} variant={BUTTON_VARIANT.TEXT_WHITE} width={'215px'}>*/}
                        {/*<div className={css.button}>*/}
                            {/*{translate.header.analytics}*/}
                        {/*</div>*/}
                    {/*</Button>*/}
                </div>

                {!premium
                    ? <div className={css.box}>
                        <a className={css.link} href={link} target={"_blank"}>
                            {translate.header.subcribe}
                        </a>
                    </div>
                    : null
                }

                <div className={css.lang}>
                    {Object.values(LANG).map(l =>
                        <div className={cn(css.langItem, {[css.active]: lang === l})} key={l} onClick={() => onChangeLang(l)}>{l}</div>
                    )}
                </div>

                <div className={cn(css.box, css.padding)}>
                    <Link className={css.nav} to={ROUTE.ADJUSTMENT} onClick={close}>
                        {translate.header.allSettings}
                    </Link>
                    {premium
                        ? <Link
                            className={css.nav}
                            to={ROUTE.ANALYTICS}
                            onClick={onAnalytics}>
                            {translate.pages.analytics.title}
                        </Link>
                        : null
                    }
                    <a className={css.nav} href={`mailto:${MAIL}`} target={"_blank"} onClick={close}>
                        {translate.header.feedback}
                    </a>
                    <Link
                        className={css.nav}
                        to={'/'}
                        onClick={onTelegram}>
                        {translate.header.telegram}
                    </Link>
                    {translate.header.faq
                        ? <Link className={css.nav} to={ROUTE.FAQ} onClick={onUserGuide}>{translate.header.faq}</Link>
                        : null
                    }
                </div>

            </div>
        </div>
    )
};

export default Profile