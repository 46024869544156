import {useState, useEffect, RefObject, MouseEvent} from "react";

export default function useOnClickOutside(ref: RefObject<any>, active: boolean, callback: Function, options = true) {
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        const onClick: any = (event: MouseEvent<EventTarget>) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            callback(event);
        };


        if(ref.current){
            if(init) {
                document.addEventListener('click', onClick, options);
            }
        }

        return () => {
            if(ref.current && init) {
                document.removeEventListener('click', onClick, options);
            }
        };
    },[init]);

    useEffect(() => {
        if(ref.current){
            if(active) {
                setInit(true);
            } else {
                setInit(false);
            }
        }
    }, [ref, active, callback]);
};