import React, { FC, ChangeEvent, useState } from 'react';
import cn from 'classnames';
import Icon from '../../Icon';
import Tooltip, {TooltipProfile, TOOLTIP_VARIANT} from '../../Tooltip';
import {IInput} from "../../../types/IInput";
import css from './Input.module.scss';
import {DROPDOWN_PLACEMENT} from "../../Dropdown/DropdownMenu";
import {ICON_VARIANT} from "../../../assets/svg";

const Input: FC<IInput> = ({className = '', title, disabled = false, readonly = false, name = '', placeholder = '',  tooltip = '', alert = '', onChange, type = 'text', error = '', icon, value, variant = null}) => {
    const [focus, setFocus] = useState<boolean>(false);

    const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        onChange && onChange({
            name: name,
            value: e.target.value
        })
    };

    return (
        <label
            className={cn(css.label,
                className,
                variant ? css[variant] : '',
                {   [css.hasValue]: Boolean(value),
                    [css.focus]: focus,
                    [css.hasIcon]: icon,
                    [css.disabled]: disabled,
                    [css.hasError]: Boolean(error)
            })}>

            {title
                ? <div className={css.title}>
                    {title}
                    {tooltip
                        ? <TooltipProfile
                            title={tooltip}
                            isClick={true}
                            stopPropagation={true}
                            varinat={TOOLTIP_VARIANT.INPUT}/>
                        : null
                    }
                </div>
                : null
            }
            <div className={css.container}>
                <input
                    className={css.input}
                    name={name}
                    value={value}
                    autoComplete="off"
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled || readonly}
                    onBlur={() => setFocus(false)}
                    onFocus={() => setFocus(true)}
                    onChange={handlerChange}
                />
                {icon
                    ? <div className={css.icon}>
                        <Icon name={icon}/>
                    </div>
                    : null
                }
                {alert
                    ? <div className={css.alert}>
                        <Tooltip
                            title={alert}
                            placement={DROPDOWN_PLACEMENT.LEFT}
                        >
                            <div className={css.alertIcon}>
                                <Icon name={ICON_VARIANT.WARNING}/>
                            </div>
                        </Tooltip>
                </div>
                    : null
                }
            </div>
            {error
                ? <div className={css.error}>
                    {error}
                </div>
                : null
            }
        </label>
    )
};

export default Input