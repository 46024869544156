import {STRUCTURE_INTRO} from "./ru/introduction";
import {STRUCTURE_AUTH} from "./ru/auth";
import {STRUCTURE_STRUCTURE} from './ru/structure';
import {STRUCTURE_INTERFACE} from './ru/interface';
import {STRUCTURE_INTRO_EN} from "./en/introduction";
import {STRUCTURE_AUTH_EN} from "./en/auth";
import {STRUCTURE_STRUCTURE_EN} from './en/structure';
import {STRUCTURE_INTERFACE_EN} from './en/interface';
import {LANG} from "../../types/IUser";

export interface IStructure {
    id: number,
    title: string
    children?: IStructure[],
    translate: string
    route: string,
    content?: {[key: string]: JSX.Element},
    target?: string
}

export const STRUCTURE: IStructure[] = [
    {
        id: 1,
        title: 'Введение',
        route: 'intro',
        translate: 'intro',
        content: {
            [LANG.RU]: <STRUCTURE_INTRO/>,
            [LANG.EN]: <STRUCTURE_INTRO_EN/>,
            [LANG.ES]: <STRUCTURE_INTRO/>,
            [LANG.CN]: <STRUCTURE_INTRO/>,
            [LANG.EG]: <STRUCTURE_INTRO/>,
            [LANG.FA]: <STRUCTURE_INTRO/>,
        }
    },
    {
        id: 2,
        title: 'Вход в систему',
        route: 'auth',
        translate: 'auth',
        content: {
            [LANG.RU]: <STRUCTURE_AUTH/>,
            [LANG.EN]: <STRUCTURE_AUTH_EN/>,
            [LANG.ES]: <STRUCTURE_AUTH/>,
            [LANG.CN]: <STRUCTURE_AUTH/>,
            [LANG.EG]: <STRUCTURE_AUTH/>,
            [LANG.FA]: <STRUCTURE_AUTH/>,
        },
        children: [{
            id: 1,
            title: 'Регистрация',
            route: 'auth',
            translate: 'registration',
            target: 'registration'
        },{
            id: 2,
            title: 'Авторизация',
            route: 'auth',
            translate: 'signIn',
            target: 'auth'
        }]
    },
    {
        id: 3,
        title: 'Структура Терминала',
        route: 'structure',
        translate: 'structure',
        content: {
            [LANG.RU]: <STRUCTURE_STRUCTURE/>,
            [LANG.EN]: <STRUCTURE_STRUCTURE_EN/>,
            [LANG.ES]: <STRUCTURE_STRUCTURE/>,
            [LANG.CN]: <STRUCTURE_STRUCTURE/>,
            [LANG.EG]: <STRUCTURE_STRUCTURE/>,
            [LANG.FA]: <STRUCTURE_STRUCTURE/>,
        },
    },
    {
        id: 4,
        title: 'Структура интерфейса приложения',
        route: 'interface',
        translate: 'interface',
        content: {
            [LANG.RU]: <STRUCTURE_INTERFACE/>,
            [LANG.EN]: <STRUCTURE_INTERFACE_EN/>,
            [LANG.ES]: <STRUCTURE_INTERFACE/>,
            [LANG.CN]: <STRUCTURE_INTERFACE/>,
            [LANG.EG]: <STRUCTURE_INTERFACE/>,
            [LANG.FA]: <STRUCTURE_INTERFACE/>,
        },
        children: [{
            id: 1,
            title: 'Сайдбар',
            route: 'interface',
            translate: 'sidebar',
            target: 'sidebar'
        },{
            id: 2,
            title: 'Преднастроенные фильтры',
            route: 'interface',
            translate: 'filter',
            target: 'filter'
        },{
            id: 3,
            title: 'Уведомления в Telegram',
            route: 'interface',
            translate: 'telegram',
            target: 'telegram'
        },{
            id: 4,
            title: 'Настройки',
            route: 'interface',
            translate: 'settings',
            target: 'settings'
        },{
            id: 5,
            title: 'Динамика дня',
            route: 'interface',
            translate: 'dynamics',
            target: 'dynamics'
        },{
            id: 6,
            title: 'Хэдер',
            route: 'interface',
            translate: 'header',
            target: 'header'
        },{
            id: 7,
            title: 'Синтаксис поиска',
            route: 'interface',
            translate: 'searchSyntax',
            target: 'search-syntax'
        },{
            id: 8,
            title: 'Кнопки массовых действий',
            route: 'interface',
            translate: 'buttons',
            target: 'buttons'
        },{
            id: 9,
            title: 'Список новостей',
            route: 'interface',
            translate: 'list',
            target: 'list'
        },{
            id: 10,
            title: 'Разделитель и форматы отображения',
            route: 'interface',
            translate: 'devide',
            target: 'devide'
        },{
            id: 11,
            title: 'Смена локализации',
            route: 'interface',
            translate: 'lang',
            target: 'lang'
        }]
    },
];