import React, { FC, useEffect } from 'react';
import { Link } from "react-router-dom";
import Icon from '../../component/Icon';
import {useTranslate} from "../../hooks/useTranslate";
import {ICON_VARIANT} from "../../assets/svg";
import css from './NotFound.module.scss'
import cssL from "../../component/Layout/Layout.module.scss";

const NotFound: FC  = () => {
    const translate = useTranslate();

    return (
        <div className={cssL.containerInner}>
            <div className={css.notFound}>
                <div className={css.info}>
                    <div className={css.head}><Icon name={ICON_VARIANT.NOT_FOUND}/></div>
                    <div className={css.title}>{translate.pages['404'].title}</div>
                    <div className={css.text}>{translate.pages['404'].subtitle}</div>
                    <Link className={css.link} to={'/'}>{translate.pages['404'].link}</Link>
                </div>
            </div>
        </div>
    )
};

export default NotFound;