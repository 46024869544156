import React, { FC, useEffect, useState } from 'react';
import Split from '../../../component/Split';
import Today from './Today';
import Lenta from './Lenta';
import Title, {ITitleMenu} from '../../../component/Title';
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {LIST_VARIANT} from "../../../component/List/ListItem";
import {ICON_VARIANT} from "../../../assets/svg";
import {LIST_NAME} from "../../../store/reducers/LSlice";
import cssL from '../../../component/Layout/Layout.module.scss';

interface TabProps {
    id: number,
    title: string
    titleMenu:  ITitleMenu[] | [],
    menu: ITitleMenu[] | []
}

const Default: FC  = () => {
    const { screen } = useAppSelector(state => state.screen);
    const translate = useTranslate();
    const TABS: TabProps[] = [
        {
            id: 1,
            title: translate.today,
            titleMenu: [
                {title: translate.today, active: true, icon: ICON_VARIANT.CHECK, callback: () => {setTab(TABS[0])}},
                {title: translate.pages.index.opinion, callback: () => {setTab(TABS[1])}}
            ],
            menu: [],
        },{
            id: 2,
            title: translate.pages.index.opinion,
            titleMenu: [
                {title: translate.today, callback: () => {setTab(TABS[0])}},
                {title: translate.pages.index.opinion, active: true, icon: ICON_VARIANT.CHECK, callback: () => {setTab(TABS[1])}}
            ],
            menu: [
                {title: translate.list.menu.standart, type: LIST_VARIANT.STANDART, icon: ICON_VARIANT.LIST_STANDART, callback: () => {localStorage?.setItem(LIST_NAME.OPINION, LIST_VARIANT.STANDART); setListVariant(LIST_VARIANT.STANDART)}},
                {title: translate.list.menu.title, type: LIST_VARIANT.TITLE, icon: ICON_VARIANT.LIST_TITLE, callback: () => {localStorage?.setItem(LIST_NAME.OPINION, LIST_VARIANT.TITLE); setListVariant(LIST_VARIANT.TITLE)}},
                {title: translate.list.menu.lead, type: LIST_VARIANT.LEAD, icon: ICON_VARIANT.LIST_LEAD, callback: () => {localStorage?.setItem(LIST_NAME.OPINION, LIST_VARIANT.LEAD); setListVariant(LIST_VARIANT.LEAD)}},
                {title: translate.list.menu.compact, type: LIST_VARIANT.COMPACT, icon: ICON_VARIANT.LIST_COMPACT, callback: () => {localStorage?.setItem(LIST_NAME.OPINION, LIST_VARIANT.COMPACT); setListVariant(LIST_VARIANT.COMPACT)}}
            ]
        }
    ];
    const [listVariant, setListVariant] = useState<LIST_VARIANT>((localStorage?.getItem(LIST_NAME.OPINION) as LIST_VARIANT) || LIST_VARIANT.STANDART);
    const [tab, setTab] = useState<TabProps>(TABS[0]);

    return (
        <div className={cssL.overflow}>
            <Split sizes={[50, 50]} tab={SCREEN_VARIANT.DESKTOP !== screen ? tab.id : null}>
                <div className={cssL.height}>
                    <Today title={TABS[0].title} titleMenu={SCREEN_VARIANT.DESKTOP !== screen ? TABS[0].titleMenu : []}/>
                </div>
                <div className={cssL.height}>
                    <Lenta
                        title={TABS[1].title} titleMenu={SCREEN_VARIANT.DESKTOP !== screen ? TABS[1].titleMenu : []}
                        variant={listVariant}
                        menu={TABS[1].menu}/>
                </div>
            </Split>
        </div>
    );
};

export default Default;