import React, { FC } from 'react';
import {useTranslate} from "../../hooks/useTranslate";
import css from './Footer.module.scss'

const Footer: FC = () => {
    const translate = useTranslate();

    return(
        <div className={css.footer}>{translate.footer.title}</div>
    )
};

export default Footer