import React from 'react';

export const STRUCTURE_INTRO_EN = () => {
    return (
        <div>
            <section>
                <p>Sputnik multimedia news terminal is an innovative news content delivery tool that can be used in analytics, research and decision-making by offering instant access to news, photo and video content from the agency’s correspondents.</p>
                <h4>The multimedia news terminal offers:</h4>
                <ol>
                    <li>Access to photo and video in news articles and in monitoring developments</li>
                    <li>Extensive content search options</li>
                    <li>Customer-tailored content and settings</li>
                    <li>Content sharing and export functions with the ability to save search results</li>
                    <li>Access from any device: desktop, tablet and smartphone</li>
                </ol>
            </section>
        </div>
    )
};