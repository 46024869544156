const BANNER_HEADER = `
	<div id="adfox_166556229157445481"></div>
	<script>
	window.yaContextCb.push(()=>{
		Ya.adfoxCode.create({
			ownerId: 249922,
			containerId: 'adfox_166556229157445481',
			params: {
				pp: 'cvry',
				ps: 'gasz',
				p2: 'fluh',
				puid1: '',
				puid2: '',
				puid3: '',
				puid4: '',
				puid5: '',
				puid6: ''
			}
		})
	})
	</script>
`;

const BANNER_HEADER_MOBILE = `
	<div id="adfox_167653499701731847"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_167653499701731847',
	            params: {
	                pp: 'ddjn',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS2 = `
	<div id="adfox_16655629634736341"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655629634736341',
	            params: {
	                pp: 'cvsa',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS3 = `
	<div id="adfox_168846019786332405"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_168846019786332405',
	            params: {
	                pp: 'dolv',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_INDEX = `
	<div id="adfox_16655628321632365"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655628321632365',
	            params: {
	                pp: 'cvrz',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;


const BANNER_FEED = `
	<div id="adfox_166556306792593887_bannerId"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556306792593887_bannerId',
	            params: {
	                pp: 'cvsb',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

export default {
	BANNER_HEADER,
	BANNER_HEADER_MOBILE,
	BANNER_SIDEBARE_GS2,
	BANNER_SIDEBARE_GS3,
	BANNER_INDEX,
	BANNER_FEED
}