import React, { FC, TouchEvent, useState, useRef, useEffect, MouseEvent, memo} from 'react';
import Icon from '../Icon';
import cn from 'classnames';
import { useNavigate } from "react-router-dom";
import Button from '../Button';
import Spinner from '../Spinner';
import Scrollbar from '../Scrollbar';
import {ICON_VARIANT} from "../../assets/svg";
import {BUTTON_VARIANT} from "../Button";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {dateFormat, round} from "../../utils/utils";
import {useGetArticles} from "../../hooks/useGetArticles";
import { SIDS } from "../../constants";
import {useTranslate} from "../../hooks/useTranslate";
import {LIST_NAME, getList, loadMore, setParams} from "../../store/reducers/LSlice";
import { setLentaMini } from "../../store/reducers/ScreenSlice";
import {LANG, THEME} from "../../types/IUser";
import css from './Lenta.module.scss';

export const LENTA_HEIGHT =  {
    [SCREEN_VARIANT.MOBILE]: 70,
    [SCREEN_VARIANT.TABLET]: 70,
    [SCREEN_VARIANT.DESKTOP]: 42,
};

const DELTA = 150;
const DELTA_MINI = 50;

const Lenta: FC = () => {
    const { lang, theme } = useAppSelector(state => state.auth);
    const { screen, lentaMini } = useAppSelector(state => state.screen);
    const { articlesIds, isLoading, resultsCount } = useAppSelector(state => state.l[LIST_NAME.LENTA]);
    const articles = useGetArticles(articlesIds);
    const lentaHeight = LENTA_HEIGHT[screen];
    const [top, setTop] = useState<number>(lentaHeight);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [transition, setTransition] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const articleLength = articlesIds.length;

    const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        let windowY = window.innerHeight;
        let clientY = e.changedTouches[0].clientY;
        let delta = windowY - clientY;

        if(!isOpen) {
            setTransition(false);
            setTop(delta);
        }
    };


    const onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
        let windowY = window.innerHeight;
        let clientY = e.changedTouches[0].clientY;
        let delta = windowY - clientY;

        if(!isOpen) {
            if (delta > DELTA && isOpen) {
                closeLenta()
            } else if (delta > DELTA) {
                openLenta()
            } else if (DELTA_MINI > delta) {
                openMini();
            } else {
                setTransition(true);
                setTop(isOpen ? windowY : lentaHeight);
            }
        }
    };

    const openLenta = () => {
        let windowY = window.innerHeight * (screen === SCREEN_VARIANT.DESKTOP ? .9 : 1);
        setTransition(true);
        setOpen(true);
        setTop(windowY);
    };

    const closeLenta = () => {
        setTransition(true);
        setOpen(false);
        setTop(lentaHeight);
    };


    const toggleLenta = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if(isOpen){
            closeLenta()
        } else{
            openLenta()
        }
    };


    const toggleMini = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if(lentaMini){
            closeMini();
        } else{
            openMini();
        }
    };

    const openMini = () => {
        setTop(0);
        setTransition(true);
        setOpen(false);
        setTimeout(()=> {
            dispatch(setLentaMini(true));
        },200);
    };

    const closeMini = () => {
        setTransition(true);
        setOpen(false);
        dispatch(setLentaMini(false));
        setTimeout(()=>{
            setTop(LENTA_HEIGHT[screen]);
        },200);
    };

    const onScrollEnd = () => {
        if(resultsCount > articles?.length && !isLoading){
            dispatch(loadMore({
                name: LIST_NAME.LENTA,
                params: {
                    offset: articles?.length || 0
                }
            }))
        }
    };

    const openArtilce = (id: number) => {
        // if(isOpen) {
        //     navigate(ROUTE.ARTICLE + id);
        //     closeLenta();
        // }
    };

    useEffect(()=>{
        if(isOpen){
            openLenta();
        } else if(!lentaMini){
            setTop(lentaHeight)
        }
    },[screen]);

    useEffect(()=>{
        dispatch(setParams({
            name: LIST_NAME.LENTA,
            params: {
                sid_list: [SIDS[lang].LENTA]
            }
        }));
        dispatch(getList(LIST_NAME.LENTA));
    },[]);

    useEffect(() => {
        if(!lentaMini){
            setCount(articleLength);
        }
    },[articlesIds]);

    return (
        <div className={cn(css.lenta, {[css.isOpen]: isOpen, [css.transition]: transition})}>

            {screen === SCREEN_VARIANT.DESKTOP && isOpen
                ? <div className={cn(css.background, {[css.transition]: transition})} onClick={closeLenta}/>
                : null
            }

            <div className={css.widget} style={{transform: `translateY(${-top}px)`}}>
                <div className={css.wrapper} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}>
                    <div className={css.holder}/>
                    <div className={css.grid}>
                        <div className={css.header}>{translate.lenta.dynamics}</div>

                        <div className={css.list}>
                            {!articles.length && isLoading
                                ? <Spinner height={'42px'} spinner={'35px'}/>
                                : <Scrollbar screenVariant={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]} onScrollEnd={onScrollEnd}>
                                    {articles?.map(item =>
                                        <div className={css.item} key={item.article_id}>
                                            <div className={css.date}>{dateFormat(item.article_date)}</div>
                                            <div className={css.title} onClick={() => openArtilce(item.article_id)} dangerouslySetInnerHTML={{ __html: item.article_title}}/>
                                        </div>
                                    )}
                                </Scrollbar>
                            }
                        </div>

                        <div className={css.button}>
                            <Button
                                onClick={toggleMini}
                                width={'32px'}
                                height={'32px'}
                                padding={'0'}
                                variant={theme === THEME.RIA ? BUTTON_VARIANT.LIGHT : BUTTON_VARIANT.DEFAULT}
                            >
                                <div className={css.buttonIcon}>
                                    <Icon name={ICON_VARIANT.LINE}/>
                                </div>
                            </Button>
                            <Button
                                onClick={toggleLenta}
                                width={'32px'}
                                height={'32px'}
                                padding={'0'}
                                variant={theme === THEME.RIA ? BUTTON_VARIANT.LIGHT : BUTTON_VARIANT.DEFAULT}
                            >
                                <div className={css.buttonIcon}>
                                    <Icon name={isOpen ? ICON_VARIANT.ARROW_DOWN : ICON_VARIANT.ARROW_UP}/>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={css.wrapper}>
                <div className={css.mini}>
                    <div className={css.miniButton} style={{transform: `translateY(${lentaMini ? '-55px' : '0px'})`}} onClick={toggleMini}>
                        <div className={css.miniText}>{translate.lenta.dynamics}</div>
                        <div className={css.miniCount}>{round(articleLength - count)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Lenta