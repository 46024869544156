import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from 'query-string';
import axios from "axios";
import {URL} from "../../constants";
import {containsSids, serialize} from "../../utils/utils";

export interface IPivot {
    count: number
    field: string
    value: string
}

export interface IAnalyticsData {
    count: number,
    field: string
    pivot: IPivot[]
    value: string
}

export interface IAnalyticsFilter {
    query: string,
    date_start: null | Date,
    date_end: null | Date
}

interface IAnalytics {
    isLoading: boolean,
    data: IAnalyticsData[] | [],
    count: number
}

const initialState: IAnalytics = {
    isLoading: false,
    data: [],
    count: 0
};

export const getAnalytics = createAsyncThunk(
    'analytics/getAnalytics',
    async (params: IAnalyticsFilter, { getState, dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post(
                URL.PIVOT_STATISTICS,
                qs.stringify(serialize(params), {skipNull: true, arrayFormat: 'bracket'}),
                { withCredentials: true });

            const result = response?.data?.facet_pivot?.['published_date,lists_sids'] || [];
            const count = response?.data?.count;
            return {
                data: result,
                count: count
            };
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getAnalytics.pending.type]: (state: IAnalytics) => {
            state.isLoading = true;
            state.data = [];
        },
        [getAnalytics.fulfilled.type]: (state: IAnalytics, action: PayloadAction<{data: IAnalyticsData[], count: number}>) => {
            const {data, count} = action.payload;

            state.data = data;
            state.count = count;
            state.isLoading = false;
        },
    }
});

export const { } = analyticsSlice.actions;
export default analyticsSlice.reducer;