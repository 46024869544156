import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import List from '../../../component/List'
import {IArticle} from "../../../types/IArticle";
import {LIST_VARIANT} from "../../../component/List/ListItem";
import {ROUTE, SIDS} from "../../../constants";
import {LIST_NAME, initialStateList} from "../../../store/reducers/LSlice";
import {ITitleMenu} from "../../../component/Title";
import {useAppSelector} from "../../../hooks/redux";

interface Props {
    title: string,
    titleMenu: ITitleMenu[] | []
}

const Today: FC<Props>  = ({title, titleMenu}) => {
    const { lang } = useAppSelector(state => state.auth);
    const navigate = useNavigate();

    const onArticle = (article: IArticle) => {
        navigate(ROUTE.ARTICLE + article.article_id);
    };

    return (
        <>
            <List
                tour={'today'}
                name={LIST_NAME.TOP_NEWS}
                main={true}
                // variant={LIST_VARIANT.MINI}
                title={title}
                titleMenu={titleMenu}
                onArticle={onArticle}
                filterParams={{
                    ...initialStateList.filter,
                    ...{sid_list: [SIDS[lang].TOP_NEWS]}
                }}
            />
        </>
    );
};

export default Today;