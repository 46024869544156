import React, { FC, useEffect } from 'react';
import Scrollbar from '../Scrollbar';
import BannerRight from '../Banner/BannerRight';
import Spinner from '../Spinner';
import Article from '../Article'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {unloadArticle} from "../../store/reducers/ArticleSlice";
import {setShowBanner} from "../../store/reducers/AuthSlice";

interface Props {
    preview: boolean
}

const Preview:FC<Props> = ({preview}) => {
    const {article, isLoading } = useAppSelector(state => state.article);
    const { showBanner } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(unloadArticle());
        }
    },[]);


    useEffect(() => {
        if(article && !showBanner){
            dispatch(setShowBanner(false));
        }
    },[article]);

    if(isLoading && !preview){
        return(
            <Scrollbar>
                <Spinner/>
            </Scrollbar>
        )
    }

    if((article && preview) || (!article && !showBanner)){
        return (
            <Scrollbar>
                <div style={{width: '325px', maxWidth: '100%'}}>
                </div>
            </Scrollbar>
        )
    }

    if(!article) {
        return(
            <Scrollbar>
                <BannerRight/>
            </Scrollbar>
        )
    }

    return(
        <Scrollbar>
            <Article preview={true}/>
        </Scrollbar>
    )
};

export default Preview


