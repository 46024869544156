import React, { FC } from 'react';
import Icon from '../../../Icon';
import { useLocation, useNavigate } from "react-router-dom";
import DropDown, { DropdownItem } from '../../../Dropdown';
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {ICON_VARIANT} from "../../../../assets/svg";
import {setFavorites} from "../../../../store/reducers/LSlice";
import {IFavorite} from "../../../../types/IUser";
import {useGetFavoriteIds} from "../../../../hooks/useGetFavorite";
import {analyticsFavorite} from "../../../../utils/analytics";
import {useGetColor} from "../../../../hooks/useGetColor";
import {DROPDOWN_PLACEMENT} from "../../../Dropdown/DropdownMenu";
import {useTranslate} from "../../../../hooks/useTranslate";
import {ROUTE} from "../../../../constants";
import css from '../Listitem.module.scss';

interface Props {
    articleId: number
}

const ListFavorite:FC<Props> = ({articleId}) => {
    const { articles } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const favoriteIds = useGetFavoriteIds();
    const color = useGetColor();
    const translate = useTranslate();
    const navigate = useNavigate();

    const onClick = (favorite: IFavorite) => {
        dispatch(setFavorites({
            name: favorite.name,
            value: favorite.value.includes(articleId)
                        ? favorite.value.filter(item => item !== articleId)
                        : favorite.value.concat(articleId)
        }));

        analyticsFavorite();
    };

    const createFavorite = () => {
        navigate(ROUTE.FAVORITE);
    };

    return(
        <DropDown
            header={<Icon name={favoriteIds.includes(articleId) ? ICON_VARIANT.STAR_FILL : ICON_VARIANT.STAR}/>}
            placement={DROPDOWN_PLACEMENT.LEFT}
            width={'200px'}
        >
            {articles.map(item =>
                <DropdownItem
                    key={item.name}
                    icon={item.value.includes(articleId) ? ICON_VARIANT.CHECK : null}
                    active={item.value.includes(articleId)}
                    onClick={() => onClick(item)}>
                        {item.name}
                </DropdownItem>
            )}
            <DropdownItem
                icon={ICON_VARIANT.PLUS}
                active={true}
                onClick={createFavorite}
            >
                {translate.pages.favorite.create}
            </DropdownItem>
        </DropDown>
    )
};

export default ListFavorite