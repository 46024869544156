import { FC, useState, useReducer, createContext, useContext } from 'react';
import React from 'react';

interface Props {
    isOpen: boolean,
    search: string
}

const initialState: Props = {
    isOpen: false,
    search: ''
};

const DROPDOWN_ACTION = {
    TOGGLE: 'TOGGLE',
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    SEARCH: 'SEARCH'
};

const DropdownContext = React.createContext({});

const dropdownReducer = (state: any, action: any) => {
    switch (action.type){
        case DROPDOWN_ACTION.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }
        case DROPDOWN_ACTION.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }
        case DROPDOWN_ACTION.CLOSE: {
            return {
                ...state,
                search: '',
                isOpen: false
            }
        }
        case DROPDOWN_ACTION.SEARCH: {
            return {
                ...state,
                search: action.search
            }
        }
        default: {
            return state
        }
    }
};

const DropdownProvider: FC = ({children}) => {
    const [state, dispatch] = useReducer(dropdownReducer, initialState);

    return(
        <DropdownContext.Provider value={{
            state,
            openDropdown: () => dispatch({type: DROPDOWN_ACTION.OPEN}),
            closeDropdown: () => dispatch({type: DROPDOWN_ACTION.CLOSE}),
            toggleDropdown: () => dispatch({type: DROPDOWN_ACTION.TOGGLE}),
            searchDropdown: (search: string) => dispatch({type: DROPDOWN_ACTION.SEARCH, search: search})
        }}>
            {children}
        </DropdownContext.Provider>
    )
};

export {
    DropdownContext,
    DropdownProvider
}