import {ICON_VARIANT} from "../assets/svg";

export enum INPUT_PLACEMENT {
    START = 'start',
    END = 'end'
}

export enum INPUT_VARINAT {
    LIGHT = 'light',
    BLUE = 'blue',
    GREY = 'grey'
}

export enum INPUT_ALIGN {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export enum INPUT_SIZE {
    NORMAL = 'normal',
    SMALL = 'sm'
}

export interface OnInputChange {
    name: string,
    value: string
}

export interface OnCheckboxChange {
    name: string,
    value: boolean
}

export interface OnRadioChange {
    name: string,
    value: string | number
}

export interface IInput {
    className?: string,
    name?: string,
    title?: string,
    value: string,
    type?: string,
    disabled?: boolean,
    readonly?: boolean,
    placeholder?: string,
    tooltip?: string,
    icon?: ICON_VARIANT,
    variant?: INPUT_VARINAT | null,
    error?: string,
    alert?: string | React.ReactNode,
    onChange: (arg: OnInputChange) => void,
}

export interface IRadio {
    name?: string,
    title?: string | React.ReactNode,
    value: string | number,
    type?: string,
    disabled?: boolean,
    variant?: INPUT_VARINAT | null,
    placement?: INPUT_PLACEMENT,
    onChange?: (arg: OnRadioChange) => void
}