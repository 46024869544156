import React, {FC} from 'react';
import image41 from '../../../assets/images/faq/image41.png';
import image18 from '../../../assets/images/faq/image18.png';
import image7 from '../../../assets/images/faq/image7.gif';
import image14 from '../../../assets/images/faq/image14.gif';
import image38 from '../../../assets/images/faq/image38.gif';
import image37 from '../../../assets/images/faq/image37.gif';
import image30 from '../../../assets/images/faq/image30.gif';
import image2 from '../../../assets/images/faq/image2.gif';
import image45 from '../../../assets/images/faq/image45.gif';
import image39 from '../../../assets/images/faq/image39.gif';
import image32 from '../../../assets/images/faq/image32.gif';
import image3 from '../../../assets/images/faq/image3.gif';
import image5 from '../../../assets/images/faq/image5.gif';
import image17 from '../../../assets/images/faq/image17.gif';
import image9 from '../../../assets/images/faq/image9.gif';
import image24 from '../../../assets/images/faq/image24.png';
import image11 from '../../../assets/images/faq/image11.gif';
import image22 from '../../../assets/images/faq/image22.gif';
import image19 from '../../../assets/images/faq/image19.gif';
import image44 from '../../../assets/images/faq/image44.gif';
import image42 from '../../../assets/images/faq/image42.png';
import image10 from '../../../assets/images/faq/image10.gif';
import image28 from '../../../assets/images/faq/image28.gif';
import image21 from '../../../assets/images/faq/image21.gif';
import image25 from '../../../assets/images/faq/image25.png';
import image35 from '../../../assets/images/faq/image35.png';
import image4 from '../../../assets/images/faq/image4.gif';
import image12 from '../../../assets/images/faq/image12.gif';
import image33 from '../../../assets/images/faq/image33.png';
import image31 from '../../../assets/images/faq/image31.png';
import image34 from '../../../assets/images/faq/image34.png';
import image15 from '../../../assets/images/faq/image15.png';
import image26 from '../../../assets/images/faq/image26.png';
import image36 from '../../../assets/images/faq/image36.gif';
import image8 from '../../../assets/images/faq/image8.png';
import image29 from '../../../assets/images/faq/image29.png';
import image16 from '../../../assets/images/faq/image16.gif';
import image13 from '../../../assets/images/faq/image13.gif';
import image20 from '../../../assets/images/faq/image20.png';
import image47 from '../../../assets/images/faq/image47.gif';
import image27 from '../../../assets/images/faq/image27.png';
import image48 from '../../../assets/images/faq/image48.png';
import image49 from '../../../assets/images/faq/image49.gif';

export const STRUCTURE_INTERFACE_EN = () => {
    return(
        <div>
            <section>
                <h3>Application interface structure</h3>
                <img src={image41} width="1999" height="1290" alt="Application interface structure"/>
                <ol>
                    <li>Sidebar – manage and set up content display</li>
                    <li>Top stories – headlines of the main news stories visible across Terminal pages</li>
                    <li>Header – a panel with a search box, action buttons and a user menu</li>
                    <li>Article viewing block – an area for displaying the selected item</li>
                    <li>Newsfeed – list of news items as selected by the user (newsfeed or search results)</li>
                </ol>
            </section>
            <section id="sidebar">
                <h3>Sidebar</h3>
                <p>A sidebar on the left part of the screen shows the available subscriber newsfeeds, My Newsfeed, Favorite News Items and the Settings block.</p>
                <p>The control sidebar offers four blocks:</p>
                <img src={image18} width="1664" height="1768" alt="Newsfeed blocks and sidebar settings"/>
                <p>The logical AND operator is activated inside the Newsfeed block when selecting several elements.</p>
                <p>Between the blocks, the logical OR operator is used.</p>
                <p>The logical OR is used inside the My Newsfeed and Favorites blocks.</p>
                <p>For example, when the user selects several newsfeeds from the Newsfeed List block, the search results will show newsfeed items without repeating the same news stories.</p>
                <p>If the user adds the My Newsfeed or Favorites blocks to the example above, the search results will show news items from the last query only.</p>
                <p>The list of displayed newsfeeds is saved when switching from the Newsfeed List to other blocks. Clicking the Newsfeed List button returns the user to the starting point.</p>
                <img src={image7} width="960" height="709" alt="Sidebar blocks and display logic for the selected newsfeeds"/>
            </section>
            <section>
                <h3>Newsfeed list</h3>
                <p>Use the settings icon in this block to select the newsfeeds for the sidebar.</p>
                <img src={image14} width="960" height="340" alt="Adding more newsfeeds to the sidebar"/>
                <p>In the Newsfeed List block, the top checkbox checks or unchecks all selected newsfeeds.</p>
                <img src={image38} width="466" height="432" alt=""/>
                <p>Selecting the displayed newsfeeds</p>
                <img src={image37} width="906" height="704" alt="Collapsing and expanding the Newsfeed List in the sidebar"/>
                <p>When there are many newsfeeds in any block in the sidebar, the user can expand or collapse the block lists.</p>
            </section>
            <section id="filter">
                <h3>My Newsfeed</h3>
                <p>If a user regularly uses a specific query or needs it for specific tasks, there is a function to save it and use it later when needed.</p>
                <p>To create My Newsfeed, enter a search query, set the filters you need in advanced search and press Create Newsfeed in advanced search.</p>
                <img src={image30} width="800" height="587" alt="Using advanced search to create My Newsfeed"/>
                <p>The saved Newsfeed will appear in the corresponding sidebar block.</p>
                <h4>To change the search criteria for My (customized) Newsfeed: </h4>
                <ul>
                    <li>Enter a query with the desired search settings and filters</li>
                    <li>Click the button to add a new My Newsfeed</li>
                    <li>Enter the name of the existing My Newsfeed you want to update in the filter creation form</li>
                </ul>
                <p>Users can also create and edit the My Newsfeed block using the settings button in the sidebar’s My Newsfeed block.</p>
                <img src={image2} width="960" height="605" alt="Editing and deleting the Favorites list in the block settings menu"/>
                <p>To change the order of newsfeeds in the sidebar’s My Newsfeed or Favorites blocks, use the settings icon in the corresponding block. Move the cursor over the relevant newsfeed, press and hold, and then move the newsfeed to the desired location.</p>
                <img src={image45} width="960" height="706" alt="Changing the sequence in the sidebar Favorites list using the block settings menu"/>
                <p>Use the settings block to delete the My Newsfeed block or the Favorites list.</p>
                <img src={image39} width="960" height="701" alt="Deleting one or several Favorites lists using checkboxes in the block settings menu"/>
            </section>
            <section id="telegram">
                <h3>Telegram notifications</h3>
                <p>The Terminal can deliver notifications from the My Newsfeed block. To receive them, synchronize the account with the Telegram bot <a href="https://t.me/TRMNL_bot" target="_blank">https://t.me/TRMNL_bot</a> using the user guide that pops up when pressing on the Telegram icon in the header.</p>
                <h4>Only the Terminal’s paid newsfeed subscribers can use the bot.</h4>
                <img src={image32} width="960" height="540" alt="Adding a Telegram bot"/>
                <h4>Connecting the Terminal account to Telegram</h4>
                <ol>
                    <li>Click Add Telegram in the Terminal Menu</li>
	                <li>Add bot using the link <a href="https://t.me/TRMNL_bot" target="_blank">https://t.me/TRMNL_bot</a></li>
                    <li>Click <b>start</b> in the Telegram bot’s chat</li>
                    <li>Enter the six-digit code generated in the Terminal window and click send in the Telegram bot’s chat.</li>
                </ol>
                <p>The code will be valid for five minutes and can be used to add only one account. Telegram must be installed in advance.</p>
                <img src={image3} width="960" height="706" alt="Adding notifications with new articles from the Telegram bot’s filter"/>
                <p>To add a saved My Newsfeed block or edit it, tick the Telegram Notifications checkbox and update settings for My Newsfeed. The Telegram bot will then receive headlines and links to news items from the My Newsfeed block. When several My Newsfeed blocks are activated in the Telegram bot, there will be notifications on all the checked newsfeeds, excluding duplicates.</p>
                <p>To turn off notifications, uncheck the box in the My Newsfeed settings and click Update Newsfeed.</p>
            </section>
            <section>
                <h3>Favorite news</h3>
                <p>Users can create lists of favorite news items (collections) in the Terminal.</p>
                <p>Create a list first in order to add an article by clicking the settings button in the sidebar.</p>
                <img src={image5} width="960" height="706"  alt="Create and add news articles to the Favorites list"/>
                <p>Users can add news articles to lists created earlier. To do so, mark the news story with a star and choose the desired list in the pop-up list box.</p>
                <p>You can check several news items at once.</p>
                <p>Tick every listed news item you want, or use the Favorites star icon in the display section.</p>
                <img src={image17} width="960" height="706"  alt="Adding several news items to the Favorites lists"/>
                <p>One item can be added to several Favorites lists.</p>
            </section>
            <section>
                <h3>Deleting from the Favorites list</h3>
                <p>To delete an item from the Favorites list, click the star icon again and click on the corresponding list.</p>
                <img src={image9} width="960" height="706" alt="Deleting a Favorites list"/>
                <p>To cancel all the markings, go to the corresponding list of favorite news items and click the Delete List button.</p>
            </section>
            <section id="settings">
                <h3>Settings</h3>
                <img src={image24} width="1999" height="1470" alt="Exported news settings menu"/>
                <h4>Use export and print settings to arrange the exported materials in the following ways:</h4>
                <ul>
                    <li>Every news item displayed on a separate page</li>
                    <li>All news articles one after another</li>
                </ul>
                <h4>News article specifiers. The following parameters are included by default:</h4>
                <ul>
                    <li>Photo and video, if available</li>
                    <li>Date</li>
                    <li>Time</li>
                    <li>Headline</li>
                    <li>Keywords</li>
                </ul>
                <h4>Users can exclude media or change their settings:</h4>
                <ul>
                    <li>Media data is printed sequentially If the article includes video content, the printed version will show a blank space with a video icon</li>
                </ul>
                <h4>The media included in the exported document can be selected:</h4>
                <ul>
                    <li>Only title media (first media on the list)</li>
                    <li>All media (one after the other)</li>
                </ul>
                <h4>The size of the media in the exported document can be selected:</h4>
                <ul>
	                <li>Small (25%)</li>
                    <li>Medium (50%)</li>
                    <li>Large (100%)</li>
                </ul>
                <h4>Available settings:</h4>
                <ul>
	                <li>Font size for the news article</li>
                    <li>Font size for the headline of the news item</li>
                    <li>Line spacing</li>
                </ul>
                <p>Users can create a list of news items to export by email.</p>
                <p>To create a list for exporting news items to the same addresses, enter the name of the list, the email address and, if necessary, add a description to the mailing list. Click Save List. </p>
                <h4>Newsfeed settings for defining the data display format in the newsfeed</h4>
                <ul>
                    <li>Create a pinned list (fixed scroll) – the selected article will stay in the newsfeed as new ones are published, with notifications in the indicator button for returning to the top of the list.</li>
                </ul>
                <img src={image11} width="960" height="1142" alt=""/>
                <ul>
                    <li>Set default font size for text and headline.</li>
                    <li>Highlight favorite news items with a choice of colors by ticking or unticking the checkbox.</li>
                    <li>Highlighting search query results in the list of news items and the body of a news item with a choice of colors is activated by ticking or unticking the checkbox.</li>
                </ul>
            </section>
            <section>
                <h3>Export history</h3>
                <p>This section contains a log of all exported files.</p>
                <p>Filter the log by export date and type for more convenience.</p>
                <p>All exported files can be downloaded again in the desired format using the checkbox or the corresponding button in the header for working with several exports at once.</p>
                <img src={image22} width="960" height="706" alt="Working with export history"/>
            </section>
            <section>
                <h3>Hiding the sidebar</h3>
                <p>To expand the list and display area, the filter panel can be collapsed by pressing <b>{'<–'}</b>.</p>
                <img src={image19} width="960" height="706" alt="Hiding the sidebar"/>
            </section>
            <section id="dynamics">
                <h3>Top stories</h3>
                <p>This newsfeed streamlines access to the top news of the day by showing their headlines. It is available across all pages of the News Terminal.</p>
                <p>The collapse and expand buttons are available on the right side of the Top Stories section.</p>
                <img src={image44} width="960" height="706" alt="Top stories"/>
            </section>
            <section id="header">
                <h3>Header</h3>
                <p>The header contains navigation elements, export options for multiple items, and search.</p>
                <h4>The header includes:</h4>
                <img src={image42}  width="1804" height="228" alt="Header"/>
                <ol>
                    <li>The News Terminal logo. Click on the logo to go back to the newsfeed homepage</li>
                    <li>Search bar to search for selected newsfeeds from the Newsfeed List</li>
                    <li>Advanced Search for filtering search results</li>
                    <li>Adding multiple items to the Favorites list</li>
                    <li>Downloading multiple news items in the desired format</li>
                    <li>Printing several articles</li>
                    <li>Sending several items by email</li>
                    <li>Adding a Telegram bot</li>
	                <li>Copying several news items to the clipboard</li>
	                <li>Newsfeed publication analytics</li>
                    <li>Switching to default newsfeeds: Highlights and Interview, Opinion</li>
                    <li>User menu</li>
                </ol>
            </section>
            <section>
                <h3>Search bar and advanced search</h3>
                <p>The search bar offers full-text search queries.</p>
                <p>Search queries can be entered in six languages: Russian, English, Chinese, Spanish, Arabic, and Farsi. Additional search parameters can be used in advanced search.</p>
	            <p>Automatic search is triggered one second after the search query is entered into the search bar.</p>
                <p>Advanced search helps narrow the search using various filters, including date, availability of media content, and article type.</p>
                <p>Pressing the down arrow (↓) in the search bar’s right-hand section expands a modal box with advanced search options with the following tools:</p>
                <img src={image10} width="960" height="707" alt=""/>
                <h4>Multi-select is available:</h4>
                <ul>
                    <li>logical search checkboxes (All words / Same phrase / Any word)</li>
                    <li>search scope checkboxes: Headline / Article text / Keywords</li>
                    <li>article type and content checkboxes: News / Flash / Urgent / With photo / With video</li>
                </ul>
                <p>BY DATE: a dropdown calendar (to designate the start and end date for the search query with the current date as the default end date) and a daily timeframe to designate the time of day to the hour.</p>
                <p>BY FEED: limits search to specific newsfeeds.</p>
                <p>Search covers all available materials by default.</p>
	            <p>To expedite search by hours, use predetermined intervals of 3, 6, 12 and 24 hours.</p>
                <p>The checkbox attributes use the logical AND operator.</p>
                <p>The radio button attributes use the logical OR operator.</p>
            </section>
            <section id="search-syntax">
                <h3>Query syntax</h3>
                <p>For better search accuracy, the following operators and symbols can be used in queries:</p>
                <h4>Substitution</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Meaning</th>
                            <th>Example and result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>*</td>
                            <td>any symbol at the end of the word (truncation operator)</td>
                            <td>
                                <p><span className="highlight">Frame*</span></p>
                                <p><b>*</b> ("star") means any number of replaced characters. In this case, the search will turn out all the words, starting with “frame” like “framing” and “framerate” and the word preceding the * character “frame” itself.</p>
                                <p>Note that there must be at least three characters before the star.</p>
                                <p><span className="highlight">Incompatible with the “!” operator preceding the word.</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h4>Grouping and logical operators</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Meaning</th>
                            <th>Example and result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>-</td>
                            <td>Negation</td>
                            <td>
                                <p><span className="highlight">Washington-Roosevelt</span></p>
                                <p>Excludes a term or a phrase. For example, all pages will mention <b>“Washington”</b> but not <b>“Roosevelt”</b></p>
                            </td>
                        </tr>
                        <tr>
                            <td>()</td>
                            <td>Grouping search </td>
                            <td>
                                <p><span className="highlight">(Commission | growth) & (market | “gold”)</span></p>
                                <p>The search results will include all four possible combinations <b>(Commission & growth, Commission & “gold”, growth & market, growth & “gold”)</b> in all the text and in all word endings. To search combinations of words, list all of them in quotes using the OR operator.</p>
                                <p><span className="highlight">(“commission growth” | “gold market growth” | “market growth” | “gold growth”)</span></p>
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>+</td>*/}
                        {/*    <td>логическое И</td>*/}
                        {/*    <td>*/}
                        {/*        <p>Пример: Оператор "+" используется для обозначения логического "И" в поисковом запросе.</p>*/}
                        {/*        <p>Например, запрос <span className="highlight">+комиссия +рост +рынок +золото</span> вернет результаты, содержащие все указанные слова.</p>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>|</td>
                            <td>Logical <b>OR</b></td>
                            <td>
                                <p>By default, spaces in a search query operate as a logical AND.</p>
                                <p>The engine turns out all texts with at least one of the words in any form, listing the texts with the highest number of query words.</p>
                                <p><span className="highlight">(Commission | growth) & (market | “gold”)</span></p>
                                <p>This query will turn out four word combinations <b>(Commission & growth, Commission & “gold”, growth & market, growth & “gold”)</b> in all forms across all texts.</p>
                                <p>To find specific word combinations, list all phrases in quotes using the OR operator <span className="highlight"> (“commission growth” | “gold market growth” | “market growth”| “gold growth”) </span></p>
                            </td>
                        </tr>
                        {/*<tr>*/}
                            {/*<td>| или OR</td>*/}
                            {/*<td>логическое ИЛИ</td>*/}
                            {/*<td>*/}
                                {/*<p>По умолчанию пробел между словами в поисковой фразе трактуется как логическое "И".</p>*/}
                                {/*<p>Найдет все тексты, где содержится хотя бы одно из перечисленных слов в любом склонении. Первыми будут выведены тексты, содержащие максимальное количество указанных слов.</p>*/}
                                {/*<p><span className="highlight">(Комиссия | рост) & (рынок | "золото")</span></p>*/}
                                {/*<p>Найдет все четыре комбинации указанных слов (Комиссия & рост, Комиссия & "золото", рост & рынок, рост & "золото") во всем тексте в любых склонениях. Для поиска словосочетаний необходимо перечислить все фразы в кавычках через оператор OR.</p>*/}
                                {/*<p><span className="highlight">("рост комиссии" | "рост рынка золота" | "рост рынка" | "рост золота")</span></p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                    </tbody>
                </table>
                {/*<h4>Группировка условий и логические операторы</h4>*/}
                {/*<table>*/}
                    {/*<thead>*/}
                        {/*<tr>*/}
                            {/*<th>Обозначение оператора</th>*/}
                            {/*<th>Значение оператора</th>*/}
                            {/*<th>Пример запроса и результаты</th>*/}
                        {/*</tr>*/}
                    {/*</thead>*/}
                    {/*<tbody>*/}
                        {/*<tr>*/}
                            {/*<td>/(n) или  ~N или within</td>*/}
                            {/*<td>Расстояние между словами</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">Курьер /2 цветов</span> или <span className="highlight">Курьер within 2 цветов</span></p>*/}
                                {/*<p><span className="highlight">"Курьер цветов"~2</span></p>*/}
                                {/*<p>Найдет все тексты, содержащие в произвольном порядке и любом склонении слова "Курьер" и "цветов", находящиеся друг от друга на расстоянии не более, чем 2 слова.</p>*/}
                                {/*<p>Для слов, идущих подряд расстояние = 1.</p>*/}
                                {/*<p><span className="highlight">Курьер /1 цветов</span> или <span className="highlight">Курьер within 1 цветов</span></p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                <h4>Sequence of words</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Meaning</th>
                            <th>Example and result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>/(n) or  ~N </td>
                            <td>
                                <p>Word spacing</p>
                            </td>
                            <td>
                                <p><span className="highlight">Delivery / 2 flowers</span></p>
                                <p>This query will return results containing the words <b>“Delivery”</b> and <b>“flowers”</b> in all their forms and combinations, but only when two or fewer words separate them.</p>
                                <p>Use <b>/1</b> when searching two consecutive words</p>
                                <p><span className="highlight">Delivery /1 flowers</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>""</td>
                            <td>
                                <p>Searches for text with the exact order of words between quotation marks in all forms. No logical operators can be used inside the quotes, except <b>* and !.</b></p>
                                <p>To find an exact match without changing word endings, use the ! operator in front of the opening quote mark</p>
                            </td>
                            <td>
                                <p><span className="highlight">house</span> search will not include other forms such as “houses”, unless the text includes the exact word “house"</p>
                                <p><span className="highlight">Russian forces</span> all documents with the combination of words “Russian forces” in all its forms.</p>
                                <p><span className="highlight">!Russian forces</span> all documents containing this combination of words in the stated form only.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Space or &</td>
                            <td>
                                <p>Logical <b>AND</b>.</p>
                                <p>Searches messages with all words included in the query. All forms will be taken into account regardless of the used modality.</p>
                            </td>
                            <td>
                                <p><span className="highlight">robots & drones</span></p>
                                <p>All texts with these two words in any sequence and form.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>! or =</td>
                            <td><p>Messages with the exact match.</p></td>
                            <td>
                                <p><span className="highlight">!Biden</span></p>
                                <p>All documents containing the word Biden</p>
                                <p>The <b>“*”</b> operator cannot be placed at the end of the query.</p>
                            </td>
                        </tr>
                        {/*<tr>*/}
                            {/*<td>! или =</td>*/}
                            {/*<td>Поиск сообщений с точной словоформой заданного слова.</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">!Собянину</span></p>*/}
                                {/*<p>Найдутся все документы, содержащие словоформу "Собянину".</p>*/}
                                {/*<p>Не совместим с оператором "*" после слова.</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>AND NOT</td>*/}
                            {/*<td>Оператор "НО НЕ" в пределах документа</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">банк AND NOT закон</span></p>*/}
                                {/*<p>Найдет все предложения со словом "банк" в любых склонениях, кроме текстов, содержащих еще и слово "закон" в любых склонениях.</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>BEFORE, AFTER</td>*/}
                            {/*<td>Порядок следования</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">Меркель BEFORE Шольц</span></p>*/}
                                {/*<p>Найдет все тексты, содержащие слово Меркель до слова Шольц.</p>*/}
                                {/*<p><span className="highlight">Меркель AFTER Шольц</span></p>*/}
                                {/*<p>Найдет все тексты, содержащие слово Меркель после слова Шольц.</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>GE, LT</td>*/}
                            {/*<td>Поиск текстов, в которых контекст упоминается не меньше (не больше) N раз</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">облигации  GE 5</span></p>*/}
                                {/*<p>Слово «облигации» должно встречаться в тексте 5-ть или более раз</p>*/}
                                {/*<p><span className="highlight">облигации LT 5</span></p>*/}
                                {/*<p>Слово «облигации» должно встречаться в тексте 5-ть или менее раз</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                    </tbody>
                </table>
            </section>
            <section id="buttons">
                <h3>Multiple action buttons</h3>
                <p>These buttons become active when ticking several articles.</p>
            </section>
            <section>
                <h3>Favorites</h3>
                <p>Add several articles to the Favorites list/lists.</p>
            </section>
            <section>
                <h3>Download</h3>
                <p>Download several news items in the selected format with the corresponding export settings.</p>
                <img src={image28} width="960" height="707" alt=""/>
            </section>
            <section>
                <h3>Print</h3>
                <img src={image49} width="960" height="707" alt=""/>
                <p>Creates a print page for all the selected news items as per the export settings.</p>
            </section>
            <section>
                <h3>Email</h3>
                <p>For sending several news items in the selected format as per the exports settings to an email address(es) according to a selected in advance list/lists.</p>
            </section>
            <section>
                <h3>Copy</h3>
                <p>Copies several news items to the clipboard without formatting, media or export settings.</p>
            </section>
            <section>
                <h3>Analytics</h3>
                <img src={image48} width="1999" height="1002" alt=""/>
                <p>Use this function to obtain statistical data on the publication of news items on the newsfeed, as well as a chart showing the words mentioned in the news during a designated period.</p>
            </section>
            <section>
                <h3>Switch view</h3>
                <p>Switches the account to the default newsfeeds: Highlights and Interviews, Opinion.</p>
                <img src={image21} width="960" height="706" alt=""/>
            </section>
            <section>
                <h3>User menu</h3>
                <h4>Links to:</h4>
                <ul>
                    <li>export settings menu</li>
	                <li>feedback request by email</li>
	                <li>onboarding</li>
                    <li>Terminal user guide</li>
                    <li>location options</li>
                </ul>
            </section>
            <section id="list">
                <h3>Newsfeed</h3>
                <img src={image25} width="1999" height="1865" alt=""/>
            </section>
            <section>
                <h3>List elements (feed)</h3>
                <ol>
                    <li>Control panel</li>
                    <li>
                        Date
                        <ul>
                            <li>Calendar</li>
                        </ul>
                    </li>
                    <li>Type filter: Flash/Urgent or All News </li>
                    <li>Menu for display formats from a list of options</li>
                    <li>Master checkbox</li>
                    <li>News block</li>
                    <li>Viewed news block</li>
                </ol>
                <img src={image35} width="1410" height="822" alt=""/>
                <p>The opened news items will be highlighted in grey in the newsfeed.</p>
            </section>
            <section>
                <h3>Control panel for the feed</h3>
                <p>Prompt adjustments to the news item format, type, and date.</p>
                <h4>Date</h4>
                <p>The publication date of the news items below. <br/>The down arrow near the date expands the calendar box. </p>
                <h4>Calendar</h4>
                <p>Set a start and end date for the search query.</p>
                <img src={image4} width="1328" height="1348" alt="Working with the calendar"/>
                <p>Clicking on a date in the calendar within the newsfeed shows news items released during the 24 hours of that day by sending an advanced search query to the newsfeed in question for the designated number of products.</p>
                <h4>Flash filter</h4>
                <p>This filter has two options:</p>
                <ul>
                    <li>When activated, the results will include Flash and Urgent news items only.</li>
                    <li>Otherwise, the results include all news items, including those labelled as Flash and Urgent.</li>
                </ul>
                <img src={image12} width="1328" height="1348" alt=""/>
                <h3>Menu for choosing the news display format in the list</h3>
                <h4>1. Display options for newsfeed blocks:</h4>
                <img src={image33} width="1328" height="394" alt="Dropdown menu for selecting lists"/>
                <p>There are four available options:</p>
                <h4>2. Headlines and keywords (Standard view)</h4>
                <img src={image31} width="1328" height="838" alt="Headlines and sluglines"/>
                <h4>3. Headlines; (Headlines only)</h4>
                <img src={image34} width="1328" height="838" alt="Headlines only"/>
                <h4>4. Headlines, keywords and lede (Headlines + lede)</h4>
                <img src={image15} width="1328" height="1046" alt="Headlines and ledes"/>
                <h5>5. Headlines only (Compact).</h5>
                <img src={image26} width="1328" height="1046" alt="Compact design"/>
            </section>
            <section>
                <h3>Master checkbox</h3>
                <p>Located in the date block and allows users to select all news items published on the newsfeed on a selected date (40 items).</p>
                <img src={image36} width="1328" height="1046" alt=""/>
            </section>
            <section>
                <h3>News block</h3>
                <img src={image8} width="1999" height="770" alt=""/>
                <ol>
                    <li>News item headline</li>
                    <li>Time of publication</li>
                    <li>Pin button</li>
                    <li>Add to Favorites button
                        <ul>
	                        <li>Keywords</li>
                        </ul>
                    </li>
                    <li>Lede</li>
                    <li>Share button</li>
                    <li>Three-dot button (“More options”) </li>
                    <li>Checkbox for marking the news item (multiple action)</li>
                </ol>
            </section>
            <section>
                <h3>Pin: pin the news item on top of the newsfeed</h3>
                <p>News items can be pinned on top of the newsfeed, remaining there when scrolling down.</p>
                <img src={image29} width="1272" height="560" alt=""/>
                <img src={image16} width="1272" height="1036" alt=""/>
                <p>Press Open Lede to see the ledes of the pinned articles.</p>
                <p>The pinned news items will be displayed as per the selected newsfeed. The pinned news item will no longer be displayed and the pin function will be cancelled once the user changes the query or switches newsfeeds using the sidebar.</p>
                <p>Appears when hovering the mouse over the news block.</p>
            </section>
            <section>
                <h3>Favorites</h3>
                <p>Click the icon to add the selected item to favorites.</p>
            </section>
            <section>
                <h3>Three-dots</h3>
                <img src={image13} width="1442" height="802" alt=""/>
                <p>Use the three-dots button to:</p>
                <p>Mark a news item as read – changes the way the item appears in the newsfeed.</p>
                <p>Open the news item in a new window – opens the news item in a new browser tab.</p>
                <img src={image20} width="1999" height="1803" alt=""/>
                <p>Opening a news item in a new window reveals the following options: share, search by keyword and font size.</p>
                <p>An updated list from the All News newsfeed appears on the right-hand side of the window with Flash + Urgent filters.</p>
	            <p>Copy to clipboard – copies several news items onto the clipboard without formatting, media, or export settings.</p>
	            <p>Print – creates a print page for the selected news item as per the export settings.</p>
                <p>Download – to download the news item in the selected format as per the export settings.</p>
            </section>
            <section>
                <h3>Share</h3>
                <p>The News Block enables users to publish and send a news item quickly.и</p>
                <h4>The share functions allows users to:</h4>
                <ul>
                    <li>send a news item by email</li>
                    <li>post it on Telegram</li>
                    <li>post it on the VK and Odnoklassniki social networks</li>
                </ul>
                <p>Only the headline and a link to the news item in the Multimedia News Terminal will be sent.</p>
            </section>
            <section>
                <h3>Checkbox</h3>
                <p>Selects a news item in order to perform multiple actions. Appears when hovering the cursor over the News Bloc</p>
            </section>
            <section>
                <h3>Automatic list updates </h3>
                <p>News alerts. Back to Top button</p>
            </section>
            <section id="devide">
                <h3>Divider</h3>
                <p>There is a drag-to-resize divider separating the News List and Display Window for resizing these two areas by dragging the slider horizontally.</p>
                <img src={image47} width="960" height="706" alt="Divider"/>
                <p>When the width is changed by less than 50 percent, the list of news and the display area are merged to show the news items as a list.</p>
            </section>
            <section>
                <h3>Display area</h3>
                <img src={image27} width="1726" height="1999" alt=""/>
                <ol>
                    <li>News headline</li>
                    <li>Newsfeed where the item was published</li>
                    <li>Change font size button</li>
	                <li>Keywords – clicking on the keyword in the display area or on a separate page creates a search query based on this keyword and shows results</li>
	                <li>Media content – can be presented in photo and video formats. If several media content items are available, they will be displayed in carousel mode
                        <ul>
                            <li>Media content scroll buttons, when several media content items are available</li>
                        </ul>
                    </li>
                    <li>Text.</li>
                </ol>
            </section>
            <section id="lang">
                <h3>Change location</h3>
                <p>To change the interface language, go to the user menu and choose language.</p>
            </section>
        </div>
    )
};