import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import Icon from '../Icon'
import {ICON_VARIANT} from "../../assets/svg";
import {useTranslate} from "../../hooks/useTranslate";
import {SUBSCRIBE} from "../../constants";
import css from './Banner.module.scss';
import {useAppSelector} from "../../hooks/redux";
import {THEME} from "../../types/IUser";

const BannerSubscribe: FC = () => {
    const { theme, lang } = useAppSelector(state => state.auth);
    const translate = useTranslate();
    const link = SUBSCRIBE[lang].link;

    const onClick = () => {
        // analyticsSubscribe();
    };

    return (
        <a className={cn(css.subcribe, {[css.sputnik]: theme === THEME.SPUNTIK})} onClick={onClick} href={link} target="_blank" data-tour={"subscribe"}>
            <span className={css.text}>
                {translate.header.subcribe}
            </span>
            <span className={css.icon}>
                {theme === THEME.RIA
                    ? <Icon name={ICON_VARIANT.SUBSCRIBE_BLUE}/>
                    : <Icon name={ICON_VARIANT.SUBSCRIBE_BLACK}/>
                }
            </span>
        </a>
    )
};

export default BannerSubscribe;