import {useCallback, useRef} from 'react';
import moment from 'moment';
import {useAppSelector} from "./redux";
import {IHistory} from "../types/IHistory";

export function useGetHistory(ids: string[] | number[]): IHistory[][]{
    const arr = [];
    const { history } = useAppSelector(state => state.store);
    if(!ids.length) return [];

    for(let i = 0; i < ids.length; i++){
        if(history[ids[i]]){
            arr.push(history[ids[i]])
        }
    }

    return arr;
}


export function useGetCheckedHistory(): IHistory[]{
    const { checked } = useAppSelector(state => state.history);
    const exportIds: string[] = Object.keys(checked);
    const history = useGetHistory(exportIds);

    return history.reduce((accumulator: IHistory[], currentValue: IHistory[]) => {
        const exportId = currentValue[0].export_id;
        const value = currentValue.filter(h => checked[exportId]?.includes(h.article_id)) || [];

        return accumulator.concat(value);
    }, []);
}

export function useGetAndSortHistory(ids: number[]) {
    const history = useGetHistory(ids);
    if(!history.length) return {};


    // console.log(history);

    return history.reduce<Record<string, IHistory[]>>((accumulator, currentValue) => {
        const key: string = moment(currentValue[0].date).format('YYYYMMDD');
        return {...accumulator, ...{[key]: currentValue}};
    }, {});
}