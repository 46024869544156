import React, { FC, useEffect } from 'react';
import css from './ArticleTitle.module.scss';

interface Props {
    title: string,
    fontSize: string
}

const ArticleTitle: FC<Props> = ({ title, fontSize }) => {
    return (
        <div className={css.title} dangerouslySetInnerHTML={{ __html: title }} style={{fontSize: fontSize}}/>
    )
};

export default ArticleTitle;