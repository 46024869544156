import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useLocation, useNavigate } from "react-router-dom"
import Button, {ButtonGroup, BUTTON_GROUP_ALIGN, BUTTON_VARIANT} from '../Button';
import Radio from '../Input/Radio/Radio';
import RadioGroup  from '../Input/Radio/RadioGroup';
import Icon from '../Icon';
import {TooltipProfile} from '../Tooltip';
import {INPUT_PLACEMENT, INPUT_VARINAT, OnInputChange} from "../../types/IInput";
import {useAppSelector} from "../../hooks/redux";
import {ICON_VARIANT} from "../../assets/svg";
import {ROUTE} from "../../constants";
import {useTranslate} from "../../hooks/useTranslate";
import {LIST_NAME} from "../../store/reducers/LSlice";
import css from './Sidebar.module.scss';
import {PropsSidebar} from "./index";

const COUNT = 5;

const Favorite: FC<PropsSidebar> = ({ onChange, active }) => {
    const { articles } = useAppSelector(state => state.auth);
    const { name } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS].filter);
    const [count, setCount] = useState<number>(COUNT);
    const translate = useTranslate();
    const navigate = useNavigate();
    const allCount = articles.length;

    const onChangeSid = ({ value } : OnInputChange) => {
        const ids: number[] = articles.find(item => item.name === value)?.value || [];

        onChange && onChange({
            name: value,
            ids: ids,
            id: '',
            parentId: '',
            offset: 0
        });
    };

    const onIncrement = () => {
        const newCount = (allCount - count) >= 10 ? count + 10 : count + (allCount - count);
        setCount(newCount);
    };

    const onDecrement = () => {
        const newCount = count - 10 >= COUNT ?  count - 10 : COUNT;
        setCount(newCount);
    };

    const onCreate = () => {
        navigate(ROUTE.FAVORITE);
    };

    return (
        <div className={cn(css.box, {[css.active]: active})}>
            <div className={css.header}>
                <div className={css.title}>{translate.sidebar.favorite}</div>
                <div className={css.icon} onClick={onCreate}>
                    <TooltipProfile
                        title={translate.tooltip.sidebar.favorite}
                    >
                        <Icon name={ICON_VARIANT.SETTINGS}/>
                    </TooltipProfile>
                </div>
            </div>

            <RadioGroup name={'name'} value={name} onChange={onChangeSid}>
                {articles.slice(0, count).map(item =>
                    <Radio
                        key={item.id as string}
                        variant={INPUT_VARINAT.BLUE}
                        value={item.name as string}
                        placement={INPUT_PLACEMENT.START}
                        title={<>
                                <div className={css.text}>{item.name}</div>
                                <div className={cn(css.counter, {[css.disabled]: ((name === item.name) || !item.value.length)})}>{item.value.length}</div>
                            </>
                        }/>
                )}
            </RadioGroup>

            <ButtonGroup align={BUTTON_GROUP_ALIGN.SIDEBAR}>
                {count > COUNT
                    ? <Button onClick={onDecrement} variant={BUTTON_VARIANT.LIGHT_TRANSPARENT} width={'100%'} height={'20px'} fontSize={'10px'}>
                        {translate.sidebar.hide} {(count - 10 >= COUNT) ? '10' : count - COUNT}
                    </Button>
                    : null
                }
                {allCount > count
                    ? <Button onClick={onIncrement} variant={BUTTON_VARIANT.LIGHT} width={'100%'} height={'20px'} fontSize={'10px'}>
                        {translate.sidebar.more} {((allCount - count) >= 10) ? '10' : allCount - count}
                    </Button>
                    : null
                }
            </ButtonGroup>
        </div>
    )
};

export default Favorite;