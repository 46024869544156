import React, { FC, useState, useEffect, MouseEvent, useRef } from 'react';
import classNames from 'classnames';
import Icon from '../../../../Icon';
import css from './Controls.module.scss';
import {PropVideoSettings} from "../index";
import {getPlayerTime} from "../../../../../utils/utils";
import {ICON_VARIANT} from "../../../../../assets/svg";

interface PropsControls {
    over: boolean
    settings: PropVideoSettings,
    fullscreen: {
        active: boolean,
        exit: () => void,
        enter: () => void
    },
    setSettings: (settings: PropVideoSettings) => void,
    refPlayer: any
}

const Controls:FC<PropsControls> = ({ over, settings, setSettings, fullscreen, refPlayer}) => {
    const [move, setMove] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const refVolume = useRef<HTMLDivElement | null>(null);
    const refProgress = useRef<HTMLDivElement | null>(null);

    const onFullscreen = () => {
        if(fullscreen.active){
            fullscreen.exit()
        } else {
            fullscreen.enter()
        }
    };

    const onPlaying = () => {
        setSettings({...settings, ...{init: true, playing:!settings.playing}});
    };

    const onMuted = () => {
        setSettings({...settings, ...{muted: !settings.muted}});
    };

    const onClickValue = (e: MouseEvent) => {
        const rect = refVolume.current!.getBoundingClientRect();
        const clientY = e.clientY;
        const volume = 1 - ((clientY - rect.y) / rect.height);

        setSettings({...settings, ...{volume: volume}});
    };

    const mouseMove = (e: any)  => {
        const rect = refVolume.current!.getBoundingClientRect();
        const clientY = e.clientY;
        let volume = 0;

        if(clientY < rect.y){
            volume = 1;
        } else if(clientY > rect.height + rect.y){
            volume = 0
        } else {
            volume = 1 - ((clientY - rect.y) / rect.height);
        }

        setSettings({...settings, ...{volume: volume, muted: false}})
    };

    const onMouseDownValue = () => {
        setMove(true);
        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', () => {
            setMove(false);
            document.removeEventListener('mousemove', mouseMove)
        }, {once: true});
    };


    const onClickProgress = (e: MouseEvent) => {
        const rect = refProgress.current!.getBoundingClientRect();
        const clientX = e.clientX;
        const played = (clientX - rect.x) / rect.width;

        setSettings({...settings, ...{played: played}});
        refPlayer.current?.seekTo(played);
    };

    const mouseMoveProgress = (e: any)  => {
        const rect = refProgress.current!.getBoundingClientRect();
        const clientX = e.clientX;
        let played = 0;

        if(clientX < rect.x){
            played = 0;
        } else if(clientX > rect.width + rect.x){
            played = 1;
        } else {
            played = (clientX - rect.x) / rect.width;
        }

        refPlayer.current?.seekTo(played);
        setSettings({...settings, ...{played: played, volume: 0}});
    };

    const onMouseDownProgress = () => {
        const volume = settings.volume;
        setShow(true);
        document.addEventListener('mousemove', mouseMoveProgress);
        document.addEventListener('mouseup', () => {
            setShow(false);
            setSettings({...settings, ...{volume: volume}});
            document.removeEventListener('mousemove', mouseMoveProgress)
        }, {once: true});
    };

    const onMouseEnter = (e: MouseEvent) => {
        const react = refProgress.current!.getBoundingClientRect();
        const clientX = e.clientX;
        const played = (clientX - react.x) / react.width;

        setSettings({...settings, ...{mouseProgress: played, activeMouseProgress: true}});
    };

    const onMouseLeave = () => {
        setSettings({...settings, ...{activeMouseProgress: false}});
    };

    return (
        <div className={classNames(css.controls, {[css.show]: show, [css.move]: move, [css.over]: over})}>
            <button className={css.play} onClick={onPlaying}>
                <Icon name={settings.playing ? ICON_VARIANT.PAUSE : ICON_VARIANT.PLAY}/>
            </button>

            <div className={css.time}>
                <div className={css.duration}>{getPlayerTime(settings.duration * settings.played)}</div>
                <div className={css.devide}>/</div>
                <div className={css.duration}>{getPlayerTime(settings.duration)}</div>
            </div>

            <div className={classNames(css.sound, {[css.move]: move})}>
                <div className={css.soundIcon}
                     onClick={onMuted}
                >
                    <Icon name={settings.muted ? ICON_VARIANT.MUTE : ICON_VARIANT.SOUND}/>
                </div>
                <div className={css.soundBar}>
                    <div
                        className={css.soundSlider}
                        ref={refVolume}
                        onMouseDown={onMouseDownValue}
                        onClick={onClickValue}
                    >
                        <div className={css.soundVolume} style={{
                            height: settings.muted ? '0' : (settings.volume * 100).toFixed(2) + '%',
                        }}/>
                    </div>
                </div>
            </div>

            <div className={css.fullscreen} onClick={onFullscreen}>
                <Icon name={fullscreen.active ? ICON_VARIANT.FULLSCREEN_OFF : ICON_VARIANT.FULLSCREEN}/>
            </div>

            <div className={css.progress}
                 onMouseDown={onMouseDownProgress}
                 onClick={onClickProgress}
                 onMouseMove={onMouseEnter}
                 onMouseLeave={onMouseLeave}
                 ref={refProgress}>
                <div className={css.progressPadding}>
                    <div className={css.progressLoad}/>
                    <div className={css.progressCurrent} style={{
                        width: ((settings.duration * settings.played) / settings.duration * 100).toFixed(2) + '%',
                    }}/>
                    <div className={classNames(css.progressMouse, {[css.active]: settings.activeMouseProgress})} style={{
                        left: ((settings.mouseProgress * settings.duration) / settings.duration * 100).toFixed(2) + '%',
                    }}>
                        <div className={css.progressTooltip}>
                            {getPlayerTime(settings.mouseProgress * settings.duration)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Controls;