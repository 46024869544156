import React, { FC } from 'react';
import Form from './form';
import ExportForm from './export';
import Scrollbar from '../../../component/Scrollbar/index';
import cssL from '../../../component/Layout/Layout.module.scss';

const Export: FC = () => {
    return (
        <Scrollbar>
            <div className={cssL.stickyContent}>
                <ExportForm/>
                <Form/>
            </div>
        </Scrollbar>
    )
};

export default Export;