import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import cn from 'classnames';
import { useSearchParams } from "react-router-dom";
import Modal from '../index';
import Input from '../../Input/Input/Input';
import Button, {ButtonGroup, BUTTON_GROUP_ALIGN, BUTTON_VARIANT} from '../../Button';
import css from '../Login/Login.module.scss'
import {OnInputChange} from "../../../types/IInput";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setPasswordRecoveryOpen} from "../../../store/reducers/ModalSlice";
import {passwordRecovery} from "../../../store/reducers/AuthSlice";
import {useTranslate} from "../../../hooks/useTranslate";


const PasswordRecovery: FC = () => {
    const { isOpen, isLoading } = useAppSelector(state => state.modal.passwordRecovery);
    const [form, setForm] = useState({hash: '', password: '', password2: ''});
    const [error, setError] = useState('');
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const translate = useTranslate()

    const onChange = ({name, value}: OnInputChange) => {
        setForm(prev => ({...prev, ...{[name]: value}}));
        setError('')
    };


    const onSubmit = (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(form.password === form.password2){
            dispatch(passwordRecovery({
                form:form,
                success: {
                    title: translate.auth.recoveryPassword,
                    description: translate.auth.recoverySuccess,
                    button: translate.auth.close
                }
            }))
        } else{
            setError( translate.auth.error4)
        }
    };

    useEffect(() => {
        setForm(prev => ({...prev, ...{hash: searchParams.get('password_recovery') || ''}}));
    },[isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            close={() => dispatch(setPasswordRecoveryOpen(false))}
        >
            <div className={css.tab}>
                <div className={cn(css.tabItem, css.active)}>
                    {translate.auth.recovery}
                </div>
                <div className={css.content}>
                    <form onSubmit={onSubmit}>
                        <Input name={'password'} value={form.password} type="password" title={translate.auth.recoveryPassword1} onChange={onChange}/>
                        <Input name={'password2'} value={form.password2} type="password" title={translate.auth.recoveryPassword2} onChange={onChange}/>
                        <button hidden/>
                        {error
                            ? <div className={css.error}>{error}</div>
                            : null
                        }
                    </form>
                    <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                        <Button onClick={onSubmit} disabled={!Boolean(form.password && form.password2)}>Сохранить</Button>
                    </ButtonGroup>
                </div>
            </div>
        </Modal>
    )

};

export default PasswordRecovery