const locale_ru = {
	name: "locale_ru",
	months: [
		["Январь", "янв"],
		["Февраль", "февр"],
		["Март", "мар"],
		["Апрель", "апр"],
		["Май", "май"],
		["Июнь", "июнь"],
		["Июль", "июль"],
		["Август", "авг"],
		["Сентябрь", "сент"],
		["Октябрь", "окт"],
		["Ноябрь", "нояб"],
		["Декабрь", "дек"],
	],
	weekDays: [
		["Суббота", "сб"],
		["Воскресенье", "вс"],
		["Понедельник", "пн"],
		["Вторник", "вт"],
		["Среда", "ср"],
		["Четверг", "чт"],
		["Пятница", "пт"],
	],
	digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	meridiems: [
		["", ""],
		["", ""],
	],
};

export default locale_ru