import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Preview from '../../../component/Preview/index';
import List from '../../../component/List';
import Split from '../../../component/Split';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {useTranslate} from "../../../hooks/useTranslate";
import {IArticle} from "../../../types/IArticle";
import {getArticle} from "../../../store/reducers/ArticleSlice";
import {LIST_VARIANT} from "../../../component/List/ListItem";
import {useGetGroups} from "../../../hooks/useGetMainSid";
import { LIST_NAME, initialStateList } from "../../../store/reducers/LSlice";
import {ICON_VARIANT} from "../../../assets/svg";
import {arrayCompareSome, convertToState, diff, getSearchParams, objKeys} from "../../../utils/utils";
import {analyticsArticle} from "../../../utils/analytics";
import {alert} from "../../../store/reducers/AlertSlice";
import {useUserPremium} from "../../../hooks/useUserPremium";
import {LIST_FIELDS_DIFF} from "../../../constants";


const Premium: FC  = () => {
    const { isLoading, article } = useAppSelector(state => state.article);
    const { queries, showBanner, user } = useAppSelector(state => state.auth);
    const { screen } = useAppSelector(state => state.screen);
    const sids = useGetGroups();
    const { filter } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]);
    const [listVariant, setListVariant] = useState<LIST_VARIANT>((localStorage?.getItem(LIST_NAME.ALL_NEWS) as LIST_VARIANT) || LIST_VARIANT.STANDART);
    const [preview, setPreview] = useState<boolean>(false);
    const [sizes, setSizes] = useState<[number, number]>([75, 25]);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const premium = useUserPremium();
    const terms = objKeys(user?.terms || {});
    const id = article?.article_id || null;
    const diffFilter = diff(initialStateList.filter, filter, LIST_FIELDS_DIFF).length;

    const onArticle = (article: IArticle) => {
        const { article_id } = article;
        const sids = article?.categories.map(item => item.list_sid) || [];

        let query = '';

        if(filter.id && filter.id !== ''){
            query = (queries.find(item => item.id === filter.id)?.value as any)?.query || '';
        } else if (!(filter.ids && filter.ids.length)){
            query = filter.query;
        }

        if(!id){
            setSizes([50, 50])
        }
        if(sids.length && !premium && !arrayCompareSome(sids, terms)){
            dispatch(alert({
                title: translate.list.subscribe,
                description:translate.list.subscribeAnnounce,
                button: ''
            }))
        }else{
            dispatch(getArticle({
                id: article_id,
                query: query
            }));
        }
    };

    const getParams = () => {
        const params = getSearchParams();

        if(Object.keys(params).length){
            return params
        } else{
            return {
                // sid_list: filter?.sid_list.length ? filter?.sid_list : ((filter.id || filter.ids?.length) ? [] : sids)
                sid_list: filter?.sid_list.length ? filter?.sid_list : ((filter.id || filter.ids?.length) ? [] : sids)
            }
        }
    };

    const filterParams = {...filter, ...getParams()};

    const onDrag = (sizes: any) => {
        setPreview(Boolean(sizes[0] > 60));
        setSizes(sizes);
    };

    return (
        <Split sizes={sizes} minSize={showBanner ? [400, 325] : [400, 1]} tab={SCREEN_VARIANT.DESKTOP !== screen ? 1 : null} onDrag={onDrag}>
            <div>
                <List
                    tour={'lenta'}
                    name={LIST_NAME.ALL_NEWS}
                    filterParams={filterParams}
                    title={diffFilter ? translate.pages.index.search : translate.pages.index.title}
                    backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET]}
                    variant={listVariant}
                    preview={preview || [SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET].includes(screen)}
                    onArticle={onArticle}
                    pined={true}
                    isBreaking={true}
                    isChecked={true}
                    priority={true}
                    keyboard={true}
                    isWarning={true}
                    isCount={Boolean(diffFilter)}
                    menu={[
                        {title: translate.list.menu.standart, type: LIST_VARIANT.STANDART, icon: ICON_VARIANT.LIST_STANDART, callback: () => {localStorage?.setItem(LIST_NAME.ALL_NEWS, LIST_VARIANT.STANDART); setListVariant(LIST_VARIANT.STANDART)}},
                        {title: translate.list.menu.title, type: LIST_VARIANT.TITLE, icon: ICON_VARIANT.LIST_TITLE, callback: () => {localStorage?.setItem(LIST_NAME.ALL_NEWS, LIST_VARIANT.TITLE);  setListVariant(LIST_VARIANT.TITLE)}},
                        {title: translate.list.menu.lead, type: LIST_VARIANT.LEAD, icon: ICON_VARIANT.LIST_LEAD, callback: () => {localStorage?.setItem(LIST_NAME.ALL_NEWS, LIST_VARIANT.LEAD);  setListVariant(LIST_VARIANT.LEAD)}},
                        {title: translate.list.menu.compact, type: LIST_VARIANT.COMPACT, icon: ICON_VARIANT.LIST_COMPACT, callback: () => {localStorage?.setItem(LIST_NAME.ALL_NEWS, LIST_VARIANT.COMPACT); setListVariant(LIST_VARIANT.COMPACT)}}
                    ]}
                />
            </div>
            <Preview preview={preview}/>
        </Split>
    );
};

export default Premium