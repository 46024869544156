import React, { FC  } from 'react';
import Checkbox from '../../../component/Input/Checkbox/Checkbox';
import moment from 'moment-jalaali'
import Icon from '../../Icon'
import {INPUT_PLACEMENT, INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import {ICON_VARIANT} from "../../../assets/svg";
import {useTranslate} from "../../../hooks/useTranslate";
import css from './HistoryBar.module.scss';
import {useDateFormat} from "../../../hooks/useDateFormat";

interface Props {
    day: string,
    id: string
    collapse: boolean,
    onCollapse: (day: string) => void,
    onChange: (arg: OnCheckboxChange) => void,
    checked: boolean | null
    count: number
}

const HistoryHeader: FC<Props> = ({id, day, count, collapse, onCollapse, checked, onChange}) => {
    const { FORMAT_DATE } = useDateFormat();
    const translate = useTranslate();

    return(
        <div className={css.bar}>
            <div className={css.info}>
	            <div className={css.date}>{moment(day).format(FORMAT_DATE)}</div>
	            <div className={css.toggle} onClick={() => onCollapse(id)}>
                    {count} {translate.history.count.singular}
                    <div className={css.icon}><Icon name={collapse ? ICON_VARIANT.ARROW_UP : ICON_VARIANT.ARROW_DOWN}/></div>
                </div>
            </div>
            <div className={css.checkbox}>
                <Checkbox
                    variant={INPUT_VARINAT.BLUE}
                    placement={INPUT_PLACEMENT.START}
                    title={translate.history.select}
                    value={checked}
                    onChange={onChange}/>
            </div>
        </div>
    )
};

export default HistoryHeader;