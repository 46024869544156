export const analyticsAuth = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Form_submit',
        'eventLabel': 'Login_pass'
    });
};

export const analyticsAuthSocial = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Form_submit',
        'eventLabel': 'Login_social'
    });
};

export const analyticsArticle = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Click',
        'eventLabel': 'Main_article'
    });
};

export const analyticsBannerGS1 = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Click',
        'eventLabel': 'Banner'
    });
};

export const analyticsSubscribe = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Click',
        'eventLabel': 'Subscription'
    });
};

export const analyticsExport = (format: string) => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Expot_' + format
    });
};

export const analyticsPrint = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Print'
    });
};

export const analyticsMail = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Email'
    });
};

export const analyticsTelegram = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Telegram'
    });
};

export const analyticsCopy = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Copy'
    });
};

export const analyticsGraph = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Chart'
    });
};

export const analyticsCreateLenta = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Feed'
    });
};

export const analyticsBanner = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Banner'
    });
};

export const analyticsSaveLenta = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Filter'
    });
};

export const analyticsChangeGS = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Move'
    });
};

export const analyticsBreaking = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Lightning'
    });
};

export const analyticsPin = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Pin'
    });
};

export const analyticsFavorite = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Favorites'
    });
};


export const analyticsScrollUp = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Top'
    });
};

export const analyticsChangeLang = (lang: string) => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click_language',
        'eventLabel': lang
    });
};

export const analyticsUserId = (userId: number) => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'userId': userId
    });
};

export const analyticsTelegramButton = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Click',
        'eventLabel': 'Under_telegram'
    });
};

export const analyticsButtonNewsList = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Click',
        'eventLabel': 'News_feeds'
    });
};

export const analyticsFreeRegistration = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(1)',
        'eventAction': 'Click',
        'eventLabel': 'Signin'
    });
};

export const analyticsButtonAnalytic = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Analytics'
    });
};

export const analyticsButtonUserGuide = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'User_guide'
    });
};

export const analyticsButtonHistory = () => {
    window.dataLayer?.push({
        'event': 'tr_ria',
        'eventCategory': 'Main_page(2,3)',
        'eventAction': 'Click',
        'eventLabel': 'Export_history'
    });
};