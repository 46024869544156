import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { createAsyncThunk } from "@reduxjs/toolkit";
import {TYPE_PREFERENCES, URL} from "../../constants";
import {serialize} from "../../utils/utils";
import {addFavorite, replaceFavorite, deleteUserFavorite} from "./AuthSlice";
import axios from "axios";
import {CREATE_QUERIES_PLACEMENT} from "./CreateLentaSlice";
import {IFavorite, IQueries} from "../../types/IUser";

export enum CREATE_FAVORITE_PLACEMENT {
    CREATE = 'create',
    MODAL = 'modal'
}

interface ILentaSlice {
    checked: string[]
    [CREATE_FAVORITE_PLACEMENT.CREATE]: {
        isLoading: boolean,
        isOpen: boolean,
        name: string
        id: string | null,
        value: number[]
    },
    [CREATE_FAVORITE_PLACEMENT.MODAL]: {
        isLoading: boolean,
        isOpen: boolean
        name: string,
        id: string | null,
        value: number[]
    },
}


const initialState: ILentaSlice = {
    checked: [],
    [CREATE_FAVORITE_PLACEMENT.CREATE]: {
        isLoading: false,
        isOpen: false,
        name: '',
        id: null,
        value: []
    },
    [CREATE_FAVORITE_PLACEMENT.MODAL]: {
        isLoading: false,
        isOpen: false,
        name: '',
        id: null,
        value: []
    }
};

// export const createFavorite = createAsyncThunk(
//     'createFavorite/createFavorite',
//     async ({name}: {name: string}, { rejectWithValue, dispatch, getState }) => {
//         try {
//             const { data } = await axios.post(URL.SET_PREFESENCES, qs.stringify({
//                 type: TYPE_PREFERENCES.ARTICLE,
//                 name: name,
//                 value: JSON.stringify(serialize([]))
//             }, {arrayFormat: 'bracket'}), {withCredentials: true});
//
//             const unix = new Date().getTime();
//             const result = {...data.setPreferences.result, ...{sortkey: {sec: unix, usec: unix}}};
//             dispatch(addFavorite(result));
//             return result
//
//         } catch (e) {
//             return rejectWithValue(e)
//         }
//     }
// );

export const createFavorite = createAsyncThunk(
    'createFavorite/updateFavorite',
    async ({id, name, value}: {id: string | null, name: string, value: number[]}, { rejectWithValue, dispatch, getState }) => {
        try {

            if(id) {
                const {data} = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                    type: TYPE_PREFERENCES.ARTICLE,
                    id: id,
                    name: name,
                    value: JSON.stringify(serialize(value))
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const unix = new Date().getTime();
                const result = {...data.setPreferences.result, ...{sortkey: {sec: unix, usec: unix}}};

                dispatch(addFavorite(result));
                return result
            } else {
                const { data } = await axios.post(URL.SET_PREFESENCES, qs.stringify({
                    type: TYPE_PREFERENCES.ARTICLE,
                    name: name,
                    value: JSON.stringify(serialize(value))
                }, {arrayFormat: 'bracket'}), {withCredentials: true});

                const unix = new Date().getTime();
                const result = {...data.setPreferences.result, ...{sortkey: {sec: unix, usec: unix}}};
                dispatch(addFavorite(result));
                return result
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const saveSort = createAsyncThunk(
    'createFavorite/saveSort',
    async (names: string[], { rejectWithValue, dispatch, getState }) => {
        const { data } = await axios.post(URL.SORT_PREFERENS, qs.stringify({
            type: TYPE_PREFERENCES.ARTICLE,
            name: names
        }, {arrayFormat: 'bracket'}), {withCredentials: true});

        dispatch(replaceFavorite(data.article));
    }
);

export const deleteFavorite = createAsyncThunk(
    'createFavorite/deleteFavorite',
    async (ids: string[], { rejectWithValue, dispatch }) => {
        try {

            const { data } = await axios.post(URL.DELETE_PREFERENS, qs.stringify({
                type: TYPE_PREFERENCES.ARTICLE,
                id: ids,
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            if(data?.user?.deleted_records > 0){
                dispatch(deleteUserFavorite(ids));
                dispatch(setCheckedAll({
                    ids: [],
                    value: false
                }));
            }

            return data?.user;

        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const favoriteSlice = createSlice({
    name: 'createFavorite',
    initialState,
    reducers: {
        setChecked(state: ILentaSlice, action: PayloadAction<string>){
            const id = action.payload;
            state.checked = state.checked.includes(id) ? state.checked.filter(e => e !== id) : state.checked.concat(id);
        },
        setCheckedAll(state: ILentaSlice, action: PayloadAction<{ids: string[], value: boolean}>){
            const { ids, value } = action.payload;
            state.checked = value ? ids : []
        },
        setName(state: ILentaSlice, action: PayloadAction<{placement: CREATE_FAVORITE_PLACEMENT, value: string}>) {
            const { placement, value } = action.payload;

            state[placement].name = value;
        },
        setOpen(state: ILentaSlice, action: PayloadAction<{placement: CREATE_FAVORITE_PLACEMENT, isOpen: boolean}>){
            const {placement, isOpen} = action.payload;

            state[placement].isOpen = isOpen;
        },
        setValue(state: ILentaSlice, action: PayloadAction<{placement: CREATE_FAVORITE_PLACEMENT, value: number[]}>){
            const {placement, value} = action.payload;

            state[placement].value = value;
        },
        loadFavorite(state: ILentaSlice, action: PayloadAction<{placement: CREATE_FAVORITE_PLACEMENT, data: IFavorite}>){
            const {placement, data} = action.payload;
            const {name, id, value} = data;

            state[placement].name = name;
            state[placement].id = id || null;
            state[placement].value = value;

        },
    },
    extraReducers: {
        [createFavorite.pending.type]: (state: ILentaSlice) => {
            state[CREATE_FAVORITE_PLACEMENT.CREATE].isLoading = true;
            state[CREATE_FAVORITE_PLACEMENT.MODAL].isLoading = true;
        },
        [createFavorite.fulfilled.type]: (state: ILentaSlice) => {
            state[CREATE_FAVORITE_PLACEMENT.CREATE] = initialState[CREATE_FAVORITE_PLACEMENT.CREATE];
            state[CREATE_FAVORITE_PLACEMENT.MODAL] = initialState[CREATE_FAVORITE_PLACEMENT.MODAL];
        }
    }
});

export const { setChecked, setCheckedAll, setName, setOpen, setValue, loadFavorite } = favoriteSlice.actions;
export default favoriteSlice.reducer;