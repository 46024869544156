import React, { FC, useState, useEffect, MouseEvent, useRef } from 'react';
import classNames from 'classnames';
import Icon from '../../../../Icon';
import css from './Preview.module.scss';
import {PropVideoSettings} from "../index";
import {ICON_VARIANT} from "../../../../../assets/svg";

interface PropsPreview {
    settings: PropVideoSettings,
    setSettings: (settings: PropVideoSettings) => void,
    preview: string
}

const Preview: FC<PropsPreview> = ({settings, setSettings, preview}) => {
    const onPlaying = () => {
        setSettings({...settings, ...{init: true, playing:!settings.playing}});
    };

    return (
        <div className={classNames(css.background, {[css.playing]: settings.playing})} style={{backgroundImage: 'url(' + preview + ')'}} onClick={onPlaying}>
            <div className={css.play}>
                <Icon name={ICON_VARIANT.PLAY}/>
            </div>
        </div>
    )
};

export default Preview;