import React, { FC, useState, useContext, useRef, useEffect, MouseEvent, RefObject } from 'react';
import classNames from 'classnames';
import { DropdownContext } from './Provider';
import css from './Dropdown.module.scss';
import {ICON_VARIANT} from "../../assets/svg";
import Icon from "../Icon";

export enum DROPDOWN_ITEM_VARIANT {
    DEFAULT = 'default',
    LIST = 'list',
    WHITE = 'white',
    LINK = 'link',
    MINI = 'mini'
}

interface PropsDropDownItem {
    onClick?: () => void,
    active?: boolean,
    icon?: ICON_VARIANT | null,
    varinat?: DROPDOWN_ITEM_VARIANT,
    preventClose?: boolean
}

const DropDownItem: FC<PropsDropDownItem> = ({children, active, icon, varinat = DROPDOWN_ITEM_VARIANT.DEFAULT, preventClose, onClick}) => {
    const { closeDropdown } = useContext<any>(DropdownContext);

    const handleClick = (e: MouseEvent) => {
        if(!preventClose) {
            closeDropdown();
        }

        onClick && onClick();
    };

    return(
        <div className={classNames(css.item, css[varinat], {[css.active]: active})} onClick={handleClick}>
            <div className={css.itemIcon}>
                {icon
                    ? <Icon name={icon}/>
                    : null
                }
            </div>
            <div className={css.itemText}>
                {children}
            </div>
        </div>
    )
};

export default DropDownItem
