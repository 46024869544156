import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from 'query-string';
import axios from "axios";
import {URL} from "../../constants";
import {containsSids, DATE_KEYS, serialize} from "../../utils/utils";
import {FILTER_TYPE} from "./FilterSlice";


interface IDate {
    date_end: string | null,
    date_start: string | null,
    sidStatistics: string[]
}

interface ICaledarItem {
    date: string,
    value: number
}

interface ICaledar {
    counts: {
        [key: string]: number
    },
    isLoading: boolean
}

const initialState: ICaledar = {
    counts: {},
    isLoading: false,
};

export const getCountCalendar = createAsyncThunk(
    'initialState/getCountCalendar',
    async ({date_end, date_start, sidStatistics}: IDate, { getState, dispatch, rejectWithValue}) => {
        try {

            const response = await axios.post(
                URL.SID_STATISTICS,
                qs.stringify(serialize({
                    sid: sidStatistics,
                    date_end: date_end,
                    date_start: date_start,
                }), {skipNull: true, arrayFormat: 'bracket'}),
                { withCredentials: true });

            const result = response?.data?.facet_pivot?.['published_date,lists_sids'];
            return result;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        resetCalendar(state: ICaledar) {
            state.counts = {};
            state.isLoading = false;
        },
    },
    extraReducers: {
        [getCountCalendar.pending.type]: (state: ICaledar) => {
            state.isLoading = true;
            state.counts = {};
        },
        [getCountCalendar.fulfilled.type]: (state: ICaledar, action: PayloadAction<any>) => {
            const counts = action.payload;

            for(let i = 0; i < counts.length; i++){
                const key = moment(counts[i].value).format(DATE_KEYS);
                state.counts[key] = counts[i].count;
            }
            state.isLoading = false;
        },
    }
});

export const { resetCalendar } = calendarSlice.actions;
export default calendarSlice.reducer;