import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import qs from 'query-string';
import axios from "axios";
import {IArticle} from "../../types/IArticle";
import {URL} from "../../constants";

export const setDebug = createAsyncThunk(
    'debug/setDebug',
    async (articles: IArticle[], { rejectWithValue, dispatch, getState }) => {
        try {
            const articleIds = articles.map(article => article.article_id);
            const message = {
                date: moment().format("HH:mm:ss DD.MM.YYYY"),
                articles: articles.reduce((acc: any, current: IArticle) => {
                    const id = current.article_id;
                    const sids = current.categories.map(list => list.list_sid);
                    return {...acc, ...{[id]: sids}}
                }, {})
            };

            const { data } = await axios(URL.SET_DEBUG, {
                method: 'post',
                data: qs.stringify({
                    message: JSON.stringify(message),
                    article_id: articleIds
                }, {arrayFormat: 'bracket'}),
                withCredentials: true,
            });
            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);