import Joyride, { CallBackProps, TooltipRenderProps, Step } from 'react-joyride';
import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import Button, {ButtonGroup, BUTTON_GROUP_ALIGN, BUTTON_VARIANT} from '../Button';
import Icon from '../Icon';
import {ICON_VARIANT} from "../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setOpenFilter, setActiveHeader} from "../../store/reducers/ScreenSlice";
import {setIndex, setRun} from "../../store/reducers/TourSlice";
import {setCookie} from "../../utils/utils";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useTranslate} from "../../hooks/useTranslate";
import { setShowFilter } from "../../store/reducers/HeaderSlice";
import css from './Tour.module.scss';

type PropsStep = Step & {nextCallback?: () => void} & {beforeCallback?: () => void}


const Tour: FC = () => {
    const {run, stepIndex, variant } = useAppSelector(state => state.tour);
    const { screen } = useAppSelector(state => state.screen);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const STEPS1: PropsStep[] = [
        {
            placement: 'center',
            isFixed: true,
            target: 'body',
            spotlightClicks: false,
            content: translate.tour.gs1.part1

        },
        {
            placement: 'center',
            target: 'body',
            spotlightClicks: false,
            content: translate.tour.gs1.part2
        },
        {
            placement: 'bottom',
            target: '[data-tour="login"]',
            spotlightClicks: true,
            content: translate.tour.gs1.part3
        },
    ];

    const STEPS2: PropsStep[] = [
        {
            placement: 'center',
            target: 'body',
            content: translate.tour.gs2.part1,
            nextCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    dispatch(setShowFilter(true))
                    dispatch(setActiveHeader(true))
                }
            }
        },
        {
            placement: 'right-start',
            target: screen === SCREEN_VARIANT.DESKTOP ? '[data-tour="filter"]' : '[data-tour="header"]',
            content: translate.tour.gs2.part2,
            nextCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    dispatch(setShowFilter(false))
                    dispatch(setActiveHeader(false))
                }
            },
            beforeCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    dispatch(setShowFilter(false))
                    dispatch(setActiveHeader(false))
                }
            },
        },
        {
            placement: 'right-start',
            target: screen === SCREEN_VARIANT.DESKTOP ? '[data-tour="today"]' : '[data-tour="today-title"]',
            isFixed: true,
            content: translate.tour.gs2.part3,
            nextCallback: () => {
                const share = document.querySelectorAll('div[data-tour="lenta-0-0"] div[data-share]')?.[0] as HTMLElement;
                if(share){
                    share.click()
                }else{
                    close();
                }
            },
            beforeCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    dispatch(setOpenFilter(true))
                    dispatch(setActiveHeader(true))
                }
            }
        },
        {
            placement: 'left-start',
            isFixed: true,
            target: screen === SCREEN_VARIANT.DESKTOP ? '[data-tour="lenta-0-0"]' : '[data-tour="today-0-0"]',
            content: translate.tour.gs2.part4,
            beforeCallback: () => {
                const share = document.querySelectorAll('div[data-tour="lenta-0-0"] div[data-share]')?.[0] as HTMLElement;
                if(share){
                    share.click()
                }
            }
        },
        {
            placement: 'left',
            target: '[data-tour="subscribe"]',
            content: translate.tour.gs2.part5
        },
    ];

    const STEPS3: PropsStep[] = [
        {
            placement: 'center',
            target: 'body',
            content: translate.tour.gs3.part1,
            nextCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    dispatch(setShowFilter(true))
                    // dispatch(setOpenFilter(true))
                    dispatch(setActiveHeader(true))
                }
            }
        },
        {
            placement: 'right-start',
            target: screen === SCREEN_VARIANT.DESKTOP ? '[data-tour="filter"]' : '[data-tour="header"]',
            content: translate.tour.gs3.part2,
            nextCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    // dispatch(setOpenFilter(false));
                    // console.log(11);
                    dispatch(setShowFilter(false))
                    dispatch(setActiveHeader(false))
                }
                const share = document.querySelectorAll('div[data-tour="lenta-0-0"] div[data-share]')?.[0] as HTMLElement;
                if(share){
                    share.click()
                }else {
                    close();
                }
            },
            beforeCallback: () => {
                if(screen === SCREEN_VARIANT.DESKTOP) {
                    // dispatch(setOpenFilter(false));
                    dispatch(setShowFilter(false))
                    dispatch(setActiveHeader(false))
                }
            },
        },
        {
            placement: screen === SCREEN_VARIANT.DESKTOP ? 'left-start' : 'bottom',
            isFixed: true,
            target: '[data-tour="lenta-0-0"]',
            content: translate.tour.gs3.part3,
            beforeCallback: () => {
                dispatch(setShowFilter(true))
                // dispatch(setOpenFilter(true))
                dispatch(setActiveHeader(true))
            },
        },
        {
            placement: 'left',
            target: '[data-tour="lenta-0-0"] [data-tour="list-checkbox"] label',
            content: translate.tour.gs3.part4,
            beforeCallback: () => {
                const share = document.querySelectorAll('div[data-tour="lenta-0-0"] div[data-share]')?.[0] as HTMLElement;
                if(share){
                    share.click()
                }
            }
        },
        {
            placement: 'center',
            target: 'body',
            content: translate.tour.gs3.part5
        }
    ];

    const STEPS = {
        1: STEPS1,
        2: STEPS2,
        3: STEPS3,
    };

    const close = () => {
        dispatch(setRun(false));

        setCookie('tour-auth', '1', 60 * 1000 * 60 * 24 * 730 * 1000, '/');
        if(variant === 2 || variant === 3){
            setCookie('tour', '1', 60 * 1000 * 60 * 24 * 730 * 1000, '/');
        }
    };

    const tooltipComponent = ({continuous, index, step, size, backProps, closeProps, primaryProps, tooltipProps}: any) => {

        const setStep = (i: number) => {
            if(index > i && step?.beforeCallback){
                step.beforeCallback()
            }

            if(index < i && step?.nextCallback){
                step.nextCallback()
            }

            dispatch(setIndex(i))
        };

        return (
            <div className={css.modal} {...tooltipProps}>
                <div className={css.tourIndex}><span>{index + 1}</span>/{size}</div>
                <div className={css.close} onClick={close}>
                    <Icon name={ICON_VARIANT.CLOSE}/>
                </div>
                <div className={css.text} dangerouslySetInnerHTML={{__html:step.content}}/>
                <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                    {index > 0
                        ? <Button onClick={() => setStep(index - 1)} variant={BUTTON_VARIANT.TEXT_BLUE}>{translate.tour.back}</Button>
                        : null
                    }
                    {index + 1 === size
                        ? <Button onClick={close}>{translate.tour.close}</Button>
                        : <Button onClick={() => setStep(index + 1)}>{translate.tour.next}</Button>
                    }
                </ButtonGroup>
            </div>
        )
    };

    if(!translate.tour.back || !translate.tour.next){
        return null
    }

    return(
        <Joyride
            run={run}
            stepIndex={stepIndex}
            continuous={true}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            disableScrolling={true}
            // spotlightClicks={false}
            tooltipComponent={tooltipComponent}
            styles={{
                overlay: {
                    zIndex: 9000
                },
                options: {
                    zIndex: 90000,
                }
            }}
            steps={STEPS[variant as (1|2|3)]}
        />
    )
};

export default Tour;