import React, { FC, ChangeEvent, MouseEvent } from 'react'
import cn from 'classnames';
import Icon from '../../Icon';
import {INPUT_PLACEMENT, INPUT_VARINAT, INPUT_SIZE, OnCheckboxChange} from "../../../types/IInput";
import {ICON_VARIANT} from "../../../assets/svg/index";
import css from './Checkbox.module.scss';

export interface ICheckbox {
    className?: string,
    name?: string,
    title?: string | React.ReactNode,
    value: boolean | null,
    type?: string,
    disabled?: boolean,
    variant?: INPUT_VARINAT | null,
    placement?: INPUT_PLACEMENT,
    size?: INPUT_SIZE,
    onChange?: (arg: OnCheckboxChange) => void,
    onClick?: (arg: string) => void,
}

const Checkbox: FC<ICheckbox> = ({className, disabled = false, value, title, name = '', placement = INPUT_PLACEMENT.END, variant = null, size = INPUT_SIZE.NORMAL, onChange, onClick}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked  = e.target.checked;

        onChange && onChange({
            name: name,
            value: checked
        })
    };


    const handleClickTitle = (e: MouseEvent) => {
        if(onClick){
            e.preventDefault();
            onClick(name);
        }
    };

    return (
        <label className={cn(css.label, className, css[placement], css[size], variant ? css[variant] : '', {[css.checked]: value || value === null, [css.disabled]: disabled})}
            onClick={(e) => e.stopPropagation()}>
            <div className={css.icon}>
	            <input
                    className={css.input}
		            checked={Boolean(value)}
		            disabled={disabled}
		            name={name}
		            onChange={handleChange}
		            type="checkbox"
                />
                {value === null ? <Icon name={ICON_VARIANT.LINE}/>
                    : value ? <Icon name={ICON_VARIANT.CHECK}/>
                        : null}
            </div>
            {title
                ? <div className={css.title} onClick={handleClickTitle}>{title}</div>
                : null
            }
        </label>
    )
};

export default Checkbox


