import React, { FC, useEffect } from 'react';
import moment from 'moment-jalaali'
import css from './ArticleDate.module.scss';
import {useDateFormat} from "../../../hooks/useDateFormat";

interface Props {
    date: string
}

const ArticleDate: FC<Props> = ({ date }) => {
    const { FORMAT_DATE, FORMAT_TIME } = useDateFormat();

    return (
        <div className={css.date}>
            <div className={css.time}>{moment(date).format(FORMAT_TIME)}</div>
            {moment(date).format(FORMAT_DATE)}
        </div>
    )
};

export default ArticleDate;