import React, { FC } from 'react';
import cn from 'classnames';
import { TooltipProfile } from '../Tooltip';
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants";
import { useTranslate } from "../../hooks/useTranslate";
import { analyticsButtonHistory } from "../../utils/analytics";
import css from './Sidebar.module.scss';
import {PropsSidebar} from "./index";


const Settings: FC<PropsSidebar> = ({active}) => {
    const translate = useTranslate();

    const onHistory = () => {
        analyticsButtonHistory()
    }

    return(
        <div className={cn(css.box, {[css.active]: active})}>
            <div className={css.caption}>
                {translate.sidebar.settings}
                <TooltipProfile
                    isClick={true}
                    title={translate.tooltip.sidebar.settings}
                />
            </div>
            <div className={css.list}>
                <Link className={css.link} to={ROUTE.ADJUSTMENT}>{translate.sidebar.settingsExport}</Link>
                <Link className={css.link} to={ROUTE.LENTA}>{translate.sidebar.settingsLenta}</Link>
                <Link className={css.link} to={ROUTE.HISTORY} onClick={onHistory}>{translate.sidebar.settingsHistory}</Link>
            </div>
        </div>
    )
};

export default Settings