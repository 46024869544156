import React, {FC} from 'react';
import image41 from '../../../assets/images/faq/image41.png';
import image18 from '../../../assets/images/faq/image18.png';
import image7 from '../../../assets/images/faq/image7.gif';
import image14 from '../../../assets/images/faq/image14.gif';
import image38 from '../../../assets/images/faq/image38.gif';
import image37 from '../../../assets/images/faq/image37.gif';
import image30 from '../../../assets/images/faq/image30.gif';
import image2 from '../../../assets/images/faq/image2.gif';
import image45 from '../../../assets/images/faq/image45.gif';
import image39 from '../../../assets/images/faq/image39.gif';
import image32 from '../../../assets/images/faq/image32.gif';
import image3 from '../../../assets/images/faq/image3.gif';
import image5 from '../../../assets/images/faq/image5.gif';
import image17 from '../../../assets/images/faq/image17.gif';
import image9 from '../../../assets/images/faq/image9.gif';
import image24 from '../../../assets/images/faq/image24.png';
import image11 from '../../../assets/images/faq/image11.gif';
import image22 from '../../../assets/images/faq/image22.gif';
import image19 from '../../../assets/images/faq/image19.gif';
import image44 from '../../../assets/images/faq/image44.gif';
import image42 from '../../../assets/images/faq/image42.png';
import image10 from '../../../assets/images/faq/image10.gif';
import image28 from '../../../assets/images/faq/image28.gif';
import image21 from '../../../assets/images/faq/image21.gif';
import image25 from '../../../assets/images/faq/image25.png';
import image35 from '../../../assets/images/faq/image35.png';
import image4 from '../../../assets/images/faq/image4.gif';
import image12 from '../../../assets/images/faq/image12.gif';
import image33 from '../../../assets/images/faq/image33.png';
import image31 from '../../../assets/images/faq/image31.png';
import image34 from '../../../assets/images/faq/image34.png';
import image15 from '../../../assets/images/faq/image15.png';
import image26 from '../../../assets/images/faq/image26.png';
import image36 from '../../../assets/images/faq/image36.gif';
import image8 from '../../../assets/images/faq/image8.png';
import image29 from '../../../assets/images/faq/image29.png';
import image16 from '../../../assets/images/faq/image16.gif';
import image13 from '../../../assets/images/faq/image13.gif';
import image20 from '../../../assets/images/faq/image20.png';
import image47 from '../../../assets/images/faq/image47.gif';
import image27 from '../../../assets/images/faq/image27.png';
import image48 from '../../../assets/images/faq/image48.png';
import image49 from '../../../assets/images/faq/image49.gif';

export const STRUCTURE_INTERFACE = () => {
    return(
        <div>
            <section>
                <h3>Структура интерфейса приложения</h3>
                <img src={image41} width="1999" height="1290" alt="Структура интерфейса приложения"/>
                <ol>
                    <li>Сайдбар – боковая панель управления и настроек выводимого контента.</li>
                    <li>Динамика дня – главные новости в заголовках, доступные в любой странице терминала.</li>
                    <li>Хэдер – панель с областью поиска, кнопками действий и меню пользователя.</li>
                    <li>Блок просмотра материала – область демонстрации контента выбранной статьи.</li>
                    <li>Лента новостей – список новостей, соответствующий выбору пользователя (просмотр ленты или результаты поискового запроса).</li>
                </ol>
            </section>
            <section id="sidebar" name="sidebar">
                <h3>Сайдбар</h3>
                <p>В левой части приложения расположена боковая панель с доступными лентами, фильтрами и настройками.</p>
                <p>Боковая панель управления и фильтров содержит 4 блока:</p>
                <img src={image18} width="1664" height="1768" alt="Блоки фильтров и настройки боковой панели (сайдбар)"/>
                <p>Внутри блока “Список лент”  при выборе нескольких элементов работает логическое И.</p>
                <p>Между блоками работает логическое ИЛИ.</p>
                <p>Внутри блоков “Преднастроенные фильтры” и “Избранные списки” работает логическое ИЛИ.</p>
                <p>Например, если выбраны несколько лент из блока “Список лент”, в результатах поиска будут отображаться материалы лент без повтора одинаковых новостей.</p>
                <p>Если к предыдущему примеру добавить выбор по “преднастроенным фильтрам” или “Избранному списку”,  то будут отображены новости, принадлежащие к одному последнему выбору.</p>
                <img src={image7} width="960" height="709" alt="Блоки фильтров и логика отображения выбранных лент."/>
            </section>
            <section>
                <h3>Список лент</h3>
                <p>Для выбора лент в боковом меню необходимо обратиться через нажатие кнопки "шестеренка" в данном блоке.</p>
                <img src={image14} width="960" height="340" alt="Добавление доступных лент к отображению в боковом меню."/>
                <p>В группе фильтров “Список лент” первый сверху чекбокс является «мастер кнопкой» позволяющей выбрать или снять выделение по всем определенным к отображению лентам.</p>
                <img src={image38} width="466" height="432" alt=""/>
                <p>Выбор лент к отображению в фиде.</p>
                <img src={image37} width="906" height="704" alt="Скрытие и раскрытие блока “Список лент” в сайдбаре."/>
                <p>При наличии большого количества лент в любом из блоков сайдбара появляется возможность скрытия и раскрытия длинных списков блоков.</p>
            </section>
            <section id="filter" name="filter">
                <h3>Преднастроенные фильтры</h3>
                <p>Если какой-либо поисковый запрос используется регулярно и представляет особый интерес для пользователя, система позволяет сохранить его для быстрого использования в дальнейшем.</p>
                <p>Для создания преднастроенного фильтра необходимо ввести поисковый запрос, установить необходимые условия фильтрации в расширенном поиске и нажать кнопку «создать фильтр» в поисковой строке.</p>
                <img src={image30} width="800" height="587" alt="Создание фильтра через расширенный поиск."/>
                <p>Сохраненные фильтры отображаются в отдельном блоке панели фильтров бокового меню.</p>
                <h4>Если требуется изменить условия отбора преднастроенных фильтров или поисковых запросов, необходимо:</h4>
                <ul>
                    <li>Выполнить запрос с необходимыми настройками поиска и фильтрации;</li>
                    <li>Нажать на кнопку добавления нового фильтра;</li>
                    <li>В форме создания фильтра ввести наименование уже существующего фильтра, который надо обновить.</li>
                </ul>
                <p>Также возможно создание и редактирование преднастроенных фильтров по кнопке "шестеренка" в блоке “преднастроенные фильтры” бокового меню.</p>
                <img src={image2} width="960" height="605" alt="Редактирование и удаление фильтра (избр. списка) в меню настроек блока."/>
                <p>Для изменения порядка отображения преднастроенных фильтров или избранных списков в боковом меню – предусмотрена возможность изменения, в настройках каждого из блоков, вызываемая кнопкой "шестеренка". В правом блоке меню настроек, необходимо навести на необходимую ленту и перетащить на нужную позицию.</p>
                <img src={image45} width="960" height="706" alt="Изменение  порядка фильтра (избр. списка) в сайдбаре через меню настроек блока."/>
                <p>Удаление преднастроенного фильтра или избранного списка осуществляется из блока настроек.</p>
                <img src={image39} width="960" height="701" alt="Удаление 1-го или нескольких фильтров (избр. списков) чекбоксами через меню настроек блока."/>
            </section>
            <section id="telegram" name="telegram">
                <h3>Уведомления в Telegram</h3>
                <p>В Терминале возможно получать уведомления из “преднастроенного фильтра”, для этого необходимо синхронизировать аккаунт в Телеграм-боте https://t.me/TRMNL_bot по инструкции, вызываемой кликом по значку Telegram в хэдере.</p>
                <h4>Бот работает только для подписчиков на платные новостные ленты Терминала.</h4>
                <img src={image32} width="960" height="540" alt="Фильтра (избр. списка) в сайдбаре через меню настроек блока."/>
                <h4>Процедура связывания аккаунтов Терминала и Telegram.</h4>
                <ol>
                    <li>В хэдере Терминала нажать иконку "Telegram".</li>
	                <li>Добавьте бота по ссылке: <b>https://t.me/TRMNL_bot</b></li>
                    <li>Нажмите в чате телеграм бота: <b>/start</b></li>
                    <li>Ввести сгенерированный в появившемся окне Терминала 6 значный код и нажать отправить в чате Telegram-бота.</li>
                </ol>
                <p>Код действует 5 минут, и возможен для привязки только 1-го аккаунта. Telegram должен быть установлен заране</p>
                <img src={image3} width="960" height="706" alt="Добавление уведомлений с новыми статьями из фильтра в Telegram-бот."/>
                <p>При создании или редактировании сохраненного преднастроенного фильтра необходимо активировать чекбокс "Уведомлять через Telegram" и обновить настройки преднастроенного фильтра. В результате активации данной опции в Telegram-бот будут приходить Заголовки и ссылки на новости, согласно запросу преднастроенного фильтра.</p>
                <p>При активации уведомлений в Telegram-бот нескольких преднастроенных фильтров, уведомления будут приходить со всех фильтров, исключая повторения новостей.</p>
                <p>Для отключения уведомлений, необходимо отключить чекбокс в настройках преднастроенного фильтра и нажать кнопку “Обновить фильтр”.</p>
            </section>
            <section>
                <h3>Избранные списки</h3>
                <p>В Терминале, для пользователей доступен функционал создания списков избранных новостей (подборки).</p>
                <p>Для добавления новости в список, его сперва необходимо создать. Создание списка возможно из бокового меню, по нажатию кнопки "шестеренка".</p>
                <img src={image5} width="960" height="706" alt="Создание и добавление новостей в избранный список. "/>
                <p>Новости можно добавлять в созданные ранее списки. Для этого нужно выделить новость “звездочкой” и выбрать нужный список в появившейся панели списков.</p>
                <p>Система позволяет отметить несколько новостей для проведения групповой операции.</p>
                <p>Для отметки новости используется чекбокс напротив каждой новости в списке или значок «избранное» в виде звездочки в области просмотра.</p>
                <img src={image17} width="960" height="706" alt="Массовое добавление новостей в избранные  списки."/>
                <p>Одну и ту же  новость можно добавлять в несколько “избранных списков”.</p>
            </section>
            <section>
                <h3>Исключение из списка избранных</h3>
                <p>Для исключения одного материала из списка избранных нужно повторно нажать на звездочку и кликнуть на список, из которого необходимо исключить.</p>
                <img src={image9} width="960" height="706" alt="Удаление избранного списка."/>
                <p>Для сброса всех отметок необходимо зайти в нужный список избранных новостей и нажать кнопку удалить список.</p>
            </section>
            <section id="settings" name="settings">
                <h3>Настройки</h3>
                <img src={image24} width="1999" height="1470" alt="Меню настройки экспортируемых новостей."/>
                <h4>Настройки экспорта позволяют скомпоновать экспортируемый материал по следующим характеристикам:</h4>
                <ul>
                    <li>каждая новость на отдельной странице</li>
                    <li>все новости идут поочерёдно</li>
                </ul>
                <h4>Состав новости. По умолчанию в состав новости входят все элементы:</h4>
                <ul>
                    <li>фото и видео, если есть</li>
                    <li>Дата</li>
                    <li>время</li>
                    <li>Заголовки</li>
                    <li>Ключевые слова</li>
                </ul>
                <h4>Предусмотрена возможность исключить или настроить медиа:</h4>
                <ul>
                    <li>Вывод медиаданных на печать осуществляется поочередно. В случае наличия видеоконтента на печать выводится заглушка с иконкой видео</li>
                </ul>
                <h4>Предусмотрена возможность выбора состава медиа в экспортируемом документе:</h4>
                <ul>
                    <li>только титульное (первое медиа в списке)</li>
                    <li>все медиа (выводятся поочередно)</li>
                </ul>
                <h4>Предусмотрена возможность выбора размера медиа в экспортируемом документе:</h4>
                <ul>
	                <li>Маленький (25% )</li>
                    <li>Средний (50% )</li>
                    <li>Большой (100% )</li>
                </ul>
                <h4>Предусмотрена настройка:</h4>
                <ul>
	                <li>Размера шрифта текста новости</li>
                    <li>Размера шрифта заголовка новости</li>
                    <li>Межстрочного расстояния</li>
                </ul>
                <p>Предусмотрена возможность создания списка для экспорта новостей по электронной почте.</p>
                <p>Для создания списка, позволяющего экспортировать новости повторяющимся адресатам, необходимо ввести название списка, e-mail, а также при необходимости, добавить описание для списка рассылок, с последующем нажатием на кнопку “Сохранить список”.</p>
                <h4>Настройки лент позволяют настроить формат выводимых данных на ленте новостей.</h4>
                <ul>
                    <li>Фиксация скролла (Switch) сохраняет позицию в ленте при выходе свежих новостей, при этом, уведомления отображаются в индикаторе-кнопке, позволяющей вернуться к началу списка. </li>
                </ul>
                <img src={image11} width="960" height="1142" alt=""/>
                <ul>
                    <li>Установка размера шрифта текста и заголовка новости по умолчанию.</li>
                    <li>Подсветка избранных новостей с выбором цвета, активируется или деактивируется по нажатию на чекбокс</li>
                    <li>Подсветка результатов поискового запроса в списке новостей и теленовости с выбором цвета, активируется или деактивируется по нажатию на чекбокс</li>
                </ul>
            </section>
            <section>
                <h3>История экспорта</h3>
                <p>В данном разделе хранится история всех экспортируемых файлов.</p>
                <p>Для удобного поиска по истории предусмотрен фильтр по дате и типу экспорта.</p>
                <p>Все экспортированные файлы можно повторно скачивать в необходимом формате, новости чекбоксом, или используя кнопки в хэдере для массовых действий.</p>
                <img src={image22} width="960" height="706" alt="История экспорта"/>
            </section>
            <section>
                <h3>Скрытие боковой панели</h3>
                <p>Для увеличения ширины списка и области просмотра панель фильтров можно свернуть нажатием кнопки  <b>{'<–'}</b>.</p>
                <img src={image19} width="960" height="706" alt="Скрытие боковой панели"/>
            </section>
            <section id="dynamics" name="dynamics">
                <h3>Динамика дня</h3>
                <p>Лента с главными новостями в виде заголовков, доступная со всех страниц Информационного терминала.</p>
                <p>Для скрытия и раскрытия необходимо использовать кнопки в правой части области Динамики дня.</p>
                <img src={image44} width="960" height="706" alt="Динамика дня"/>
            </section>
            <section id="header" name="header">
                <h3>Хэдер</h3>
                <p>Элемент навигации, массовых действий экспорта и поиска необходимых материалов.</p>
                <h4>В Хэдере расположены:</h4>
                <img src={image42} width="1804" height="228" alt="Хэдер"/>
                <ol>
                    <li>Логотип Информационного терминала. При клике на логотип возвращает пользователя на стартовую страницу отображения лент.</li>
                    <li>Строка поиска позволяет осуществить поиск по всем выбранным лентам в “Списке лент”.</li>
                    <li>Расширенный поиск позволяет ограничить результаты поискового запроса.</li>
                    <li>Добавление в избранное нескольких новостей.</li>
                    <li>Скачивание нескольких новостей в необходимом формате.</li>
                    <li>Печать нескольких новостей.</li>
                    <li>Отправка по электронной почте нескольких новостей.</li>
                    <li>Добавление Telegram-бота.</li>
	                <li>Копирование в буфер нескольких новостей.</li>
	                <li> Аналитика выхода новостей по лентам.</li>
                    <li>Переключение на подключенные по умолчанию ленты: “Картина дня” и “Интервью, мнения”.</li>
                    <li>Меню пользователя.</li>
                </ol>
            </section>
            <section>
                <h3>Строка поиска и расширенный поиск.</h3>
                <p>Поисковая строка – поле в системе, предназначенное для осуществления полнотекстового поискового запроса по материалам.</p>
                <p>Поисковый запрос может осуществляться на шести языках: на русском, английском, китайском, испанском, арабском и фарси. Для расширенного поиска применим синтаксис дополнительных поисковых операторов.</p>
	            <p>При введении запроса в поле поиска по истечению первой секунды срабатывает автоматический поиск по введенному в поле значению.</p>
                <p>Расширенный поиск позволит отобрать наиболее релевантные результаты и предусматривает работу с различными фильтрами: поиск по времени, наличию медиаконтента, типу новости.</p>
                <p>При нажатии стрелки вниз (↓) в правой области поисковой строки – выпадает модальное окно расширенных настроек поиска со следующими инструментами поиска:</p>
                <img src={image10} width="960" height="707" alt=""/>
                <h4>Доступен множественный выбор (multiselect) из пунктов:</h4>
                <ul>
                    <li>чекбоксы логических поисковых форматов (Все слова / Совпадение фразы / Одно из слов)</li>
                    <li>чекбоксы выбора области поиска: Заголовок / Текст новости / ключи)</li>
                    <li>чекбоксы типа новости и содержания контента: новость / молния/ срочно/ с фото / с видео.</li>
                </ul>
                <p>ЗА ПЕРИОД – выпадающая область календарей (календарь для определения даты начала и даты окончания поиска (по умолчания дата окончания поиска - текущая) и временная шкала времени суток - позволяющие ограничить временной отрезок с точностью до часа.</p>
                <p>ПО ЛЕНТАМ – ограничение поиска по выбранным лентам.</p>
                <p>По умолчанию поиск ведется «За всё время».</p>
	            <p>Предусмотрены готовые временные интервалы  для быстрой настройки периодов поиска в 3, 6, 12 и 24 ч.</p>
                <p>Между атрибутами с чекбоксом работает логическое И.</p>
                <p>Между атрибутами с радиобаттон работает логическое ИЛИ.</p>
            </section>
            <section id="search-syntax" name="search-syntax">
                <h3>Синтаксис поиска</h3>
                <p>Для более точного поиска  в поисковом запросе можно использовать следующие операторы и символы подстановки.</p>
                <h4>Подстановка</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Обозначение оператора</th>
                            <th>Значение оператора</th>
                            <th>Пример запроса и результаты</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>*</td>
                            <td>любые символы в конце слова (Оператор усечения)</td>
                            <td>
                                <p><span className="highlight">Кадр*</span></p>
                                <p>* ("звездочка") означает любое количество заменяемых символов. В данном примере будут найдены все слова, начинающиеся с банк: "Кадровый", "Кадровой", в т. ч. само слово до “*” – “Кадр”.</p>
                                <p>Примечание: перед звездочкой должно стоять не меньше трех символов.</p>
                                <p><span className="highlight">Не совместим с оператором "!" перед словом.</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h4>Группировка условий и логические операторы</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Обозначение оператора</th>
                            <th>Значение оператора</th>
                            <th>Пример запроса и результаты</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>-</td>
                            <td>отрицание</td>
                            <td>
                                <p><span className="highlight">Москва - Лужков</span></p>
                                <p>Исключение термина или фразы. К  примеру все страницы будут упоминать “Москва”, но не с “Лужков”.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>()</td>
                            <td>группировка условий</td>
                            <td>
                                <p><span className="highlight">(Комиссия | рост) & (рынок | "золото")</span></p>
                                <p>Найдет все четыре комбинации указанных слов (Комиссия & рост, Комиссия & "золото", рост & рынок, рост & "золото") во всем тексте в любых склонениях. Для поиска словосочетаний необходимо перечислить все фразы в кавычках через оператор OR.</p>
                                <p><span className="highlight">("рост комиссии" | "рост рынка золота" | "рост рынка" | "рост золота")</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>+</td>
                            <td>логическое И</td>
                            <td>
                                <p>Пример: Оператор "+" используется для обозначения логического "И" в поисковом запросе.</p>
                                <p>Например, запрос <span className="highlight">+комиссия +рост +рынок +золото</span> вернет результаты, содержащие все указанные слова.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>| или “пробел”</td>
                            <td></td>
                            <td>
                                <p>По умолчанию пробел между словами в поисковой фразе трактуется как логическое "ИЛИ".</p>
                                <p>Найдет все тексты, где содержится хотя бы одно из перечисленных слов в любом склонении. Первыми будут выведены тексты, содержащие максимальное количество указанных слов.</p>
                                <p>Если нужно использовать логическое "ИЛИ", запрос может быть структурирован как <span className="highlight">(+комиссия +рост) | (+рынок +золото)</span>, что означает поиск результатов, содержащих либо пару слов "комиссия" и "рост", либо пару слов "рынок" и "золото".</p>
                            </td>
                        </tr>
                        {/*<tr>*/}
                            {/*<td>| или OR</td>*/}
                            {/*<td>логическое ИЛИ</td>*/}
                            {/*<td>*/}
                                {/*<p>По умолчанию пробел между словами в поисковой фразе трактуется как логическое "И".</p>*/}
                                {/*<p>Найдет все тексты, где содержится хотя бы одно из перечисленных слов в любом склонении. Первыми будут выведены тексты, содержащие максимальное количество указанных слов.</p>*/}
                                {/*<p><span className="highlight">(Комиссия | рост) & (рынок | "золото")</span></p>*/}
                                {/*<p>Найдет все четыре комбинации указанных слов (Комиссия & рост, Комиссия & "золото", рост & рынок, рост & "золото") во всем тексте в любых склонениях. Для поиска словосочетаний необходимо перечислить все фразы в кавычках через оператор OR.</p>*/}
                                {/*<p><span className="highlight">("рост комиссии" | "рост рынка золота" | "рост рынка" | "рост золота")</span></p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                    </tbody>
                </table>
                {/*<h4>Группировка условий и логические операторы</h4>*/}
                {/*<table>*/}
                    {/*<thead>*/}
                        {/*<tr>*/}
                            {/*<th>Обозначение оператора</th>*/}
                            {/*<th>Значение оператора</th>*/}
                            {/*<th>Пример запроса и результаты</th>*/}
                        {/*</tr>*/}
                    {/*</thead>*/}
                    {/*<tbody>*/}
                        {/*<tr>*/}
                            {/*<td>/(n) или  ~N или within</td>*/}
                            {/*<td>Расстояние между словами</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">Курьер /2 цветов</span> или <span className="highlight">Курьер within 2 цветов</span></p>*/}
                                {/*<p><span className="highlight">"Курьер цветов"~2</span></p>*/}
                                {/*<p>Найдет все тексты, содержащие в произвольном порядке и любом склонении слова "Курьер" и "цветов", находящиеся друг от друга на расстоянии не более, чем 2 слова.</p>*/}
                                {/*<p>Для слов, идущих подряд расстояние = 1.</p>*/}
                                {/*<p><span className="highlight">Курьер /1 цветов</span> или <span className="highlight">Курьер within 1 цветов</span></p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                <h4>Точное совпадение</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Обозначение оператора</th>
                            <th>Значение оператора</th>
                            <th>Пример запроса и результаты</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>""</td>
                            <td>
                                <p>Поиск сообщений, в которых слова, поставленные в кавычки, идут строго подряд и по порядку с учетом всех словоформ. Внутри кавычек нельзя использовать логические операторы, кроме оператора *.</p>
                            </td>
                            <td>
                                <p><span className="highlight">дом</span> – материалы со словами "дома", "домах" и т.п. не будут включены в поисковую выдачу, если в тексте нет слова "дом"</p>
                                <p><span className="highlight">красная армия</span> - найдутся все документы, содержащие словосочетание "красная армия" во всех словоформах.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Пробел или |</td>
                            <td>
                                <p>Логическое "И".</p>
                                <p>Поиск сообщений со всеми словами из запроса.</p>
                            </td>
                            <td>
                                <p><span className="highlight">роботы & дроны</span></p>
                                <p>Найдет все тексты, где встречаются эти два слова в любом порядке и склонении.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>~</td>
                            <td><p>Поиск слов с определённым расстоянием между ними</p></td>
                            <td><p>Оператор "~" применяется для определения максимального расстояния между словами в поисковом запросе. Допустим, мы ищем упоминание "Москва" и "собор" в одном контексте, но не обязательно рядом. Запрос <span className="highlight">Москва собор~3</span> вернет результаты, где между словами "Москва" и "собор" не более трех других слов.</p></td>
                        </tr>
                        {/*<tr>*/}
                            {/*<td>! или =</td>*/}
                            {/*<td>Поиск сообщений с точной словоформой заданного слова.</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">!Собянину</span></p>*/}
                                {/*<p>Найдутся все документы, содержащие словоформу "Собянину".</p>*/}
                                {/*<p>Не совместим с оператором "*" после слова.</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>AND NOT</td>*/}
                            {/*<td>Оператор "НО НЕ" в пределах документа</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">банк AND NOT закон</span></p>*/}
                                {/*<p>Найдет все предложения со словом "банк" в любых склонениях, кроме текстов, содержащих еще и слово "закон" в любых склонениях.</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>BEFORE, AFTER</td>*/}
                            {/*<td>Порядок следования</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">Меркель BEFORE Шольц</span></p>*/}
                                {/*<p>Найдет все тексты, содержащие слово Меркель до слова Шольц.</p>*/}
                                {/*<p><span className="highlight">Меркель AFTER Шольц</span></p>*/}
                                {/*<p>Найдет все тексты, содержащие слово Меркель после слова Шольц.</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>GE, LT</td>*/}
                            {/*<td>Поиск текстов, в которых контекст упоминается не меньше (не больше) N раз</td>*/}
                            {/*<td>*/}
                                {/*<p><span className="highlight">облигации  GE 5</span></p>*/}
                                {/*<p>Слово «облигации» должно встречаться в тексте 5-ть или более раз</p>*/}
                                {/*<p><span className="highlight">облигации LT 5</span></p>*/}
                                {/*<p>Слово «облигации» должно встречаться в тексте 5-ть или менее раз</p>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                    </tbody>
                </table>
            </section>
            <section id="buttons" name="buttons">
                <h3>Кнопки массовых действий</h3>
                <p>Становятся активны после выделения чекбоксом нескольких новостей.</p>
            </section>
            <section>
                <h3>Избранное</h3>
                <p>Позволяет добавить несколько новостей в выбранный список/списки</p>
            </section>
            <section>
                <h3>Скачать</h3>
                <p>Позволяет скачать несколько новостей в выбранном формате и в соответствии с настройками экспорта.</p>
                <img src={image28} width="960" height="707" alt=""/>
            </section>
            <section>
                <h3>Отправить на печать</h3>
                <img src={image49} width="960" height="707" alt=""/>
                <p>Формирует страницу для печати всех выбранных новостей в соответствии с настройками экспорта.</p>
            </section>
            <section>
                <h3>Отправить по эл. почте</h3>
                <p>Позволяет отправить несколько новостей в выбранном формате и в соответствии с настройками экспорта на электронную почту по предварительно настроенному и выбранному списку/спискам.</p>
            </section>
            <section>
                <h3>Скопировать</h3>
                <p>Позволяет скопировать несколько новостей в буфер обмена, без сохранения форматирования, медиа и настроек экспорта.</p>
            </section>
            <section>
                <h3>Аналитика</h3>
                <img src={image48} width="1999" height="1002" alt=""/>
                <p>Позволяет просмотреть статистику по выходу новостей на ленте, а также диаграмму по словам, упоминавшимся в новостях за нужный период.</p>
            </section>
            <section>
                <h3>Переключение аккаунта</h3>
                <p>Позволяет переключить вид на общедоступные ленты “Картина дня” и “Интервью, комментарии”.</p>
                <img src={image21} width="960" height="706" alt=""/>
            </section>
            <section>
                <h3>Меню пользователя</h3>
                <h4>Содержит ссылки на:</h4>
                <ul>
                    <li>меню настроек экспорта;</li>
	                <li>e-mail запрос на обратную связь;</li>
	                <li>онбординг;</li>
                    <li>руководство по использованию Терминала;</li>
                    <li>переключение локализаций.</li>
                </ul>
            </section>
            <section id="list">
                <h3>Список новостей</h3>
                <img src={image25} width="1999" height="1865" alt=""/>
            </section>
            <section>
                <h3>Элементы списка новостей (фид)</h3>
                <ol>
                    <li>Панель управления списком</li>
                    <li>
                        Дата
                        <ul>
                            <li>Календарь</li>
                        </ul>
                    </li>
                    <li>Фильтр по типу новости: Молния/срочно или Все новости</li>
                    <li>Меню выбора формата отображения новостей в списке</li>
                    <li>Мастер чекбокс</li>
                    <li>Блок новости</li>
                    <li>Блок просмотренной новости</li>
                </ol>
                <img src={image35} width="1410" height="822" alt=""/>
                <p>Просмотренные новости визуально отличаются в списке новостей от просмотренных новостей - серым цветом заголовка.</p>
            </section>
            <section>
                <h3>Панель управления списком</h3>
                <p>Позволяет оперативно настроить формат выводимых новостей, выбрать тип новостей, а также отфильтровать по необходимой дате.</p>
                <h4>Дата</h4>
                <p>Указывает дату выхода нижепредставленных новостей. <br/>Рядом с датой расположена стрелка вниз, вызывающая календарь.</p>
                <h4>Календарь</h4>
                <p>Позволяет ограничить выводимый результат указанным диапазоном дат.</p>
                <img src={image4} width="1328" height="1348" alt="Работа с календарем"/>
                <p>При клике на день в календаре в блоке фидленты новостей отображаются новости за выбранные сутки (00:00 – 23:59) формируя запрос расширенного поиска в отображаемой фидленте с выбранным количествомпродуктов на дату.</p>
                <h4>Фильтр - Молния</h4>
                <p>Позволяет определить выводимый тип новостей в двух форматах:</p>
                <ul>
                    <li>Фильтр активен - показываются только новости категорий Молния и Срочно</li>
                    <li>Фильтр НЕ активен - показываются  все новости в том числе категории Молния и Срочно.</li>
                </ul>
                <img src={image12} width="1328" height="1348" alt=""/>
                <h3>Меню выбора формата отображения новостей в списке</h3>
                <h4>1. Варианты отображения новостных блоков ленты новостей:</h4>
                <img src={image33}width="1328" height="394"  alt="Меню (дропдаун) смены вида списка"/>
                <p>Доступны четыре представления списка.</p>
                <h4>2. Заголовки и ключевые слова. (Стандартный вид)</h4>
                <img src={image31} width="1328" height="838" alt="Заголовки и слаглайны"/>
                <h4>3. Только заголовки; (Только заголовки)</h4>
                <img src={image34} width="1328" height="838" alt="Только заголовки"/>
                <h4>4. Заголовки, ключевые слова и лид новости. (Заголовок +лид)</h4>
                <img src={image15} width="1328" height="1046" alt="Заголовки и лиды"/>
                <h5>5. Только заголовки (Компактный вид).</h5>
                <img src={image26} width="1328" height="1046" alt="Компактный вид"/>
            </section>
            <section>
                <h3>Мастер чекбокс</h3>
                <p>Находится в блоке с датой и позволяет выбрать все загруженные (40 шт.) в ленту новости за день.</p>
                <img src={image36} width="1328" height="1046" alt=""/>
            </section>
            <section>
                <h3>Блок новости</h3>
                <img src={image8} width="1999" height="770" alt=""/>
                <ol>
                    <li>Заголовок новости</li>
                    <li>Время публикации новости</li>
                    <li>Кнопка закрепить (Pin)</li>
                    <li>Кнопка добавления в списки избранных
                        <ul>
	                    <li>4.1 Ключевые слова</li>
                        </ul>
                    </li>
                    <li>Лид новости</li>
                    <li>Кнопка “шеринг” новости</li>
                    <li>Кнопка “троеточие” («Дополнительные параметры»)</li>
                    <li>Чекбокс, для выделения новости (массовые действия).</li>
                </ol>
            </section>
            <section>
                <h3>Pin: закрепление новости в шапке</h3>
                <p>Предусмотрена возможность закрепления новостей в шапке списка без фиксации при скроллинге по списку новостей.</p>
                <img src={image29} width="1272" height="560" alt=""/>
                <img src={image16} width="1272" height="1036" alt=""/>
                <p>Для сличения текста новости предусмотрена  возможность демонстрации первых 2-х абзацев текста закрепленных новостей нажатием знака “Раскрыть анонс”.</p>
                <p>Действует правило: закрепление новости отображается в соответствии с выбранной лентой. В случае изменения запроса или переключения ленты из боковой панели, закрепленная новость не отображается и действие закрепления сбрасывается.</p>
                <p>Появляется при наведении указателем на блок новости.</p>
            </section>
            <section>
                <h3>Избранное</h3>
                <p>Иконка добавления выбранной новости в список избранных.</p>
            </section>
            <section>
                <h3>Троеточие</h3>
                <img src={image13} width="1442" height="802" alt=""/>
                <p>Кнопка для работы с новостью, позволяющая:</p>
                <p>Отметить новость как прочитанную - устанавливает новости статус “прочитанной”, изменяя её вид в общем списке новостей.</p>
                <p>Открыть новость в новом окне - открывает новость в новой вкладке браузера.</p>
                <img src={image20} width="1999" height="1803" alt=""/>
                <p>При открытии новости в новом окне, доступен функционал шеринга, поиска по ключевым словам и возможности настройки кегля.</p>
                <p>В правой области окна находится обновляемый список новостей из ленты “Все новости”, с возможностью фильтрации по категория “Молния” + “Срочно”.</p>
	            <p>Скопировать в буфер обмена - Позволяет скопировать несколько новостей в буфер обмена, без сохранения форматирования, медиа и настроек экспорта.</p>
	            <p>Распечатать - Формирует страницу для печати выбранной новости в соответствии с настройками экспорта. (См).</p>
                <p>Скачать – позволяет скачать новость в выбранном формате и в соответствии с настройками экспорта. (См)</p>
            </section>
            <section>
                <h3>Шеринг</h3>
                <p>В Блоке новости предусмотрен функционал быстрой публикации и отправки новости</p>
                <h4>Шеринг позволяет поделиться выбранной новостью:</h4>
                <ul>
                    <li>отправив по электронной почте</li>
                    <li>в Telegram</li>
                    <li>в соц. сети Вконтакте или Одноклассники.</li>
                </ul>
                <p>При этом отправляется только заголовок и ссылка на новость в Информационном мультимедийном терминале.</p>
            </section>
            <section>
                <h3>Чекбокс</h3>
                <p>Выделение новости для массового действия. Появляется при наведении указателем на блок новости.</p>
            </section>
            <section>
                <h3>Автообновление списка</h3>
                <p>Уведомление о новостях. Кнопка вверх</p>
            </section>
            <section id="devide" name="devide">
                <h3>Разделитель и форматы отображения</h3>
                <p>Между блоками «Список новостей» и «Область просмотра» сохраняется разделитель (ресайз), выполняющий функцию изменения соотношения сторон через перетаскивание в горизонтальной области окна.</p>
                <img src={image47} width="960" height="706" alt="Разделители"/>
                <p>При изменении ширины области просмотра менее чем на 50% список новостей объединяется с областью просмотра, отображая новости в списке.</p>
            </section>
            <section>
                <h3>Область просмотра</h3>
                <img src={image27} width="1726" height="1999" alt=""/>
                <ol>
                    <li>Заголовок новости</li>
                    <li>Ленты  на которые вышла новость</li>
                    <li>Кнопка изменения размера кегля</li>
	                <li>Ключевые слова - при клике на ключевое слово в окне просмотра новости или в отдельной странице, формируется поисковый запрос по данному ключевому слову с предоставлением результатов.</li>
	                <li>Медиаконтент - может быть представлен в формате фото или видео. При наличии нескольких материалов медиаконтента, отображаются в формате “карусель”.
                        <ul>
                            <li>Кнопки пролистывания медиаконтента, при наличии нескольких медиа в новости.</li>
                        </ul>
                    </li>
                    <li>Текст новости.</li>
                </ol>
            </section>
            <section id="lang" name="lang">
                <h3>Смена локализации</h3>
                <p>Для смены языка интерфейса, необходимо воспользоваться меню пользователя и выбрать интересующий языковой интерфейс.</p>
            </section>
        </div>
    )
};