import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import qs from 'query-string';
import Header from '../Header';
import Lenta from '../Lenta';
import WebSocket from '../WebSocket';
import Topline from '../Topline';
import cn from 'classnames';
import Sidebar from '../Sidebar';
import SendMail from '../Modal/SendMail'
import TelegramModal from '../Modal/TelegramModal'
import {Helmet} from "react-helmet";
import Login from '../Modal/Login';
import FavoriteModal from '../Modal/FavoriteModal';
import QueriesModal from '../Modal/QueriesModal';
import css from './Layout.module.scss';
import {ROUTE, URL_PULLING} from '../../constants';
import Comet from '../../utils/commet';
import {convertCometData, getCookie} from "../../utils/utils";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import { addStoreComet } from "../../store/reducers/StoreSlice";
import {logout, setShowBanner} from "../../store/reducers/AuthSlice";
import {useTranslate} from "../../hooks/useTranslate";
import {setTour} from "../../store/reducers/TourSlice";
import {useUserPremium} from "../../hooks/useUserPremium";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {alert} from "../../store/reducers/AlertSlice";
import {setDebug} from "../../store/reducers/DebugSlice";
import {LANG} from "../../types/IUser";
import {analyticsArticle, analyticsUserId} from "../../utils/analytics";

interface Props {
    sidebar?: boolean,
    services?: boolean
}

const HTML_LANG = {
    [LANG.RU]: 'ru',
    [LANG.EN]: 'en',
    [LANG.CN]: 'zh',
    [LANG.ES]: 'es',
    [LANG.EG]: 'ar',
    [LANG.FA]: 'fa'
};

const Layout: FC<Props> = ({sidebar = true, services = false}) => {
    const dispatch = useAppDispatch();
    const { lentaMini, screen } = useAppSelector(state => state.screen);
    const { showMobileSidebar } = useAppSelector(state => state.sidebar);
    const { topline, lang, rtl, list, user } = useAppSelector(state => state.auth);
    const translate = useTranslate();
    const premium = useUserPremium();
    const location = useLocation();
    const params = qs.parse(location.search);

    useEffect(() => {
        // if(params?.ws === undefined) {
        //     if (!window.socket) {
        //         window.socket = new Comet({
        //             url: URL_PULLING,
        //             callback: (data: any) => {
        //                 if (data) {
        //                     const convert = convertCometData(data, list);

        //                     if (convert.logout) {
        //                         dispatch(logout());
        //                         dispatch(alert({
        //                             title: translate.multiSession.disconnect,
        //                             description: translate.multiSession.disconnect,
        //                             button: translate.multiSession.close,
        //                         }));
        //                         window.stopComet();
        //                     } else if (convert.subscriptions_expired && translate.subscriptions_expired.title) {
        //                         dispatch(logout());
        //                         dispatch(alert({
        //                             title: translate.subscriptions_expired.title,
        //                             description: translate.subscriptions_expired.description,
        //                             button: translate.subscriptions_expired.close,
        //                         }));
        //                         window.stopComet();
        //                     }

        //                     if (user.debug && convert.articles.length) {
        //                         dispatch(setDebug(convert.articles));
        //                     }
        //                     dispatch(addStoreComet(convert.articles));
        //                 }
        //             },
        //         });
        //     } else {
        //         window.startComet();
        //     }
        // }

        if(!getCookie('tour') && getCookie('tour') !== '1' && location.pathname === ROUTE.INDEX){
            setTimeout(() => {
                dispatch(setTour(premium ? 3 : 2));
            }, 200);
        }

        if(user){
            analyticsUserId(user.user_id);
        }

        return () => {
            if(window.stopComet) {
                window.stopComet();
            }
        }
    },[]);

    useEffect(() => {
        if(location.pathname !== ROUTE.INDEX){
            dispatch(setShowBanner(false));
        }
    },[location]);

    return (
        <>
            <Helmet htmlAttributes={{ lang : HTML_LANG[lang], dir: rtl ? 'rtl' : 'ltr'}}>
                <title>{translate.title}</title>
            </Helmet>

            <div className={cn(css.page, {[css.isLentaMini]: lentaMini, [css.topline]: topline})}>
                {/*<Topline/>*/}
                <Header/>

                <div className={css.container}>
                    <div className={cn(css.containerInner, {[css.services]: services})}>
                        {sidebar
                            ? <div className={css.grid}>
                                <Sidebar/>

                                {[SCREEN_VARIANT.DESKTOP].includes(screen)
                                    ? <Outlet/>
                                    : <div className={cn(css.hide, {[css.active]: showMobileSidebar})}>
                                        <Outlet/>
                                    </div>
                                }
                            </div>
                            : <Outlet/>
                        }
                    </div>
                </div>

                <WebSocket/>
                {/* {params?.ws !== undefined
                    ? <WebSocket/>
                    : null
                } */}
                <Lenta/>
                <SendMail/>
                <TelegramModal/>
                <Login/>
                <FavoriteModal/>
                <QueriesModal/>
            </div>
        </>
    )
};

export default Layout