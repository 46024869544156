import React, { FC, useEffect } from 'react';
import Dropdown, { DropdownItem } from '../../Dropdown'
import Icon from '../../Icon'
import {IArticle} from "../../../types/IArticle";
import {ROUTE, SHARE_URL} from "../../../constants";
import {ICON_VARIANT} from "../../../assets/svg";
import {sendMailOpen} from "../../../store/reducers/ModalSlice";
import {logHistory, LOG_FORMAT} from "../../../store/reducers/HistorySlice";
import {useAppDispatch} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {DROPDOWN_PLACEMENT} from "../../Dropdown/DropdownMenu";
import css from './ArticleShare.module.scss'
import { useUserPremium } from '../../../hooks/useUserPremium';

interface Props {
    article:IArticle
}

const ArticleShare:FC<Props> = ({article}) => {
    const domain = window.location.origin;
    const id = article.article_id;
    const url = domain + ROUTE.ARTICLE + id;
    const title = article.article_title;
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const premium = useUserPremium();

    const onMail = () => {
        dispatch(sendMailOpen([article.article_id]));
    };

    return(
        <div className={css.container}>
            <Dropdown
                header={<div className={css.icon}><Icon name={ICON_VARIANT.SHARE}/></div>}
                placement={DROPDOWN_PLACEMENT.LEFT_TOP}
                width={'260px'}
            >
                {premium
                    ? <DropdownItem
                        icon={ICON_VARIANT.MAIL}
                        onClick={onMail}
                    >
                        {translate.list.sendMail}
                    </DropdownItem>
                    : null
                }
                <DropdownItem
                    icon={ICON_VARIANT.TELEGRAM}
                    onClick={() => {
                        dispatch(logHistory({article_id: [id], format: LOG_FORMAT.TELEGRAM}));
                        window.open(SHARE_URL.TELEGRAM + '?' + 'url=' + url + '&text=' + title);
                    }}
                >
                    {translate.list.shareTelegram}
                </DropdownItem>
                <DropdownItem
                    icon={ICON_VARIANT.VK}
                    onClick={() => {
                        dispatch(logHistory({article_id: [id], format: LOG_FORMAT.VK}));
                        window.open(SHARE_URL.VK + '?' + 'url=' + url + '&title=' + title)
                    }}
                >
                    {translate.list.shareVk}
                </DropdownItem>
                <DropdownItem
                    icon={ICON_VARIANT.OK}
                    onClick={() => {
                        dispatch(logHistory({article_id: [id], format: LOG_FORMAT.OK}));
                        window.open(SHARE_URL.OK + '?' + 'url=' + url + '&title=' + title)
                    }}
                >
                    {translate.list.shareOk}
                </DropdownItem>
            </Dropdown>
        </div>
    );
};

export default ArticleShare