export enum COMET_MESSAGE_TYPE {
    ARTICLE = 'new_article',
    LOGOUT = 'logout',
    SUBSCRIPTIONS_EXPIRED = 'subscriptions_expired'

}

export interface ICometDate {
    day: string,
    hour: string,
    min: string
    month: string,
    sec: string,
    year: string
}

export interface ICometList {
    sid: string
    title: string
    type: string
    is_internal: number
}

export interface IComet {
    message_type: COMET_MESSAGE_TYPE,
    article:{
        date: ICometDate,
        id: number,
        lang: string,
        lead: string,
        list: ICometList[],
        priority: number,
        title: string,
        keywords: string,
        article_read?: number
    }
}