import React, { FC, useState, MouseEvent, FormEvent, ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import Icon from '../Icon';
import cn from 'classnames';
import Dropdown, { DropdownItem } from '../Dropdown';
import Checkbox from '../Input/Checkbox/Checkbox';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {ICON_VARIANT} from "../../assets/svg";
import {INPUT_VARINAT, OnCheckboxChange, OnInputChange} from "../../types/IInput";
import css from './Sortable.module.scss'
import {DROPDOWN_PLACEMENT} from "../Dropdown/DropdownMenu";

const DragHandle = SortableHandle(() => <div className={css.drag}><Icon name={ICON_VARIANT.DRAG}/></div>);

interface ISortableItem {
    name?: string,
    checked?: boolean,
    title?: string,
    count?: number | string,
    isTelegram?: boolean,
    onChacked?: (arg: OnCheckboxChange) => void,
    onChange?: ({name, value}: OnInputChange) => void,
    menu: {icon?: ICON_VARIANT, title: string, onClick: ({name}: {name: string}) => void}[]
}

const SortableItem = SortableElement(({name = '', title = '', checked = false, count, menu = [], isTelegram, onChacked, onChange}: ISortableItem) => {
    const [input, setInput] = useState<string>(title);
    const [focus, setFocus] = useState<boolean>(false);
    const refInput = useRef<HTMLInputElement | null>(null);

    const onFocus = () => {
        setFocus(true);
    };

    const onBlur = () => {
        setInput(title);
        setFocus(false);
    };

    const onInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const onKeyDown = (e: KeyboardEvent) => {
        const { keyCode } = e;

        if(keyCode === 13){
            onSubmit();
        } else if(keyCode === 27){
            refInput.current?.blur();
            onBlur();
        }
    };

    const onSubmit = () => {
        if(input !== title) {
            onChange && onChange({name: name, value: input});
        }
    };

    useEffect(() => {
        if(title !== input){
            setInput(title);
        }
        refInput.current?.blur();
    },[title]);

    return (
        <div className={cn(css.item, {[css.active]: checked, [css.change]: title !== input, [css.focus]: focus})}>
            <DragHandle/>
            <div className={css.content}>
                <div className={css.wrapper}>
                    <span className={css.text}>{input}</span>
                    <input
                        ref={refInput}
                        className={css.input}
                        type="text"
                        value={input}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={onInput}/>
                    <div className={css.accept} onSubmit={onSubmit}>
                        <Icon name={ICON_VARIANT.CHECK}/>
                    </div>
                </div>
                {isTelegram
                    ? <div className={css.telegram}><Icon name={ICON_VARIANT.TELEGRAM_2}/></div>
                    : null
                }
                {count
                    ? <div className={css.count}>{count}</div>
                    : null
                }
            </div>
            <div className={css.right}>
                {menu.length
                    ? <Dropdown
                        header={
                            <div className={css.menu}>
                                <Icon name={ICON_VARIANT.MORE}/>
                            </div>
                        }
                        placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
                        width={'250px'}
                    >
                        {menu.map((item,i) =>
                            <DropdownItem
                                key={i}
                                icon={item.icon}
                                onClick={() => item.onClick({name})}>
                                {item.title}
                            </DropdownItem>
                        )}
                    </Dropdown>
                    : null
                }
                {onChacked && name
                    ? <div className={css.checkbox}>
                        <Checkbox variant={INPUT_VARINAT.BLUE} name={name} value={checked} onChange={onChacked}/>
                    </div>
                    : null
                }
            </div>
        </div>
    )
});

const Sortable = SortableContainer(({children}: {children: any}) => {
    return <div className={css.sortable}>{children}</div>;
});


export {SortableItem, Sortable};