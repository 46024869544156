import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import auth from "./reducers/AuthSlice";
import screen from "./reducers/ScreenSlice";
import filter from "./reducers/FilterSlice";
import sid from "./reducers/SidSlice";
import article from "./reducers/ArticleSlice";
import lentaCreate from './reducers/CreateLentaSlice';
import store from "./reducers/StoreSlice";
import favoriteCreate from "./reducers/FavoriteSlice";
import calendar from "./reducers/CalendarSlice";
import print from "./reducers/PrintSlice";
import history from "./reducers/HistorySlice";
import modal from "./reducers/ModalSlice";
import alert from "./reducers/AlertSlice";
import floor from "./reducers/FloorSlice";
import l from "./reducers/LSlice";
import analytics from "./reducers/AnalyticsSlice";
import header from "./reducers/HeaderSlice";
import tour from "./reducers/TourSlice";
import sidebar from "./reducers/SidebarSlice";

const rootReducer = combineReducers({
    auth,
    screen,
    filter,
    sid,
    article,
    store,
    favoriteCreate,
    calendar,
    print,
    lentaCreate,
    history,
    modal,
    alert,
    floor,
    l,
    analytics,
    header,
    tour,
    sidebar
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
        }),
    })
};

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']