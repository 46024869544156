// import {useState, useEffect, RefObject, MouseEvent} from "react";
//
// export default function useClickOutside(ref: RefObject<any>, active: boolean, callback: Function, options = true) {
//     const [init, setInit] = useState<boolean>(false);
//
//     useEffect(() => {
//         const onClick: any = (event: MouseEvent<EventTarget>) => {
//             if (!ref.current || ref.current.contains(event.target)) {
//                 return;
//             }
//             callback(event);
//         };
//
//
//         if(ref.current){
//             if(init) {
//                 document.addEventListener('click', onClick, options);
//             }
//         }
//
//         return () => {
//             if(ref.current && init) {
//                 document.removeEventListener('click', onClick, options);
//             }
//         };
//     },[init]);
//
//     useEffect(() => {
//         if(ref.current){
//             if(active) {
//                 setInit(true);
//             } else {
//                 setInit(false);
//             }
//         }
//     }, [ref, active, callback]);
// };

import {useEffect, RefObject, MouseEvent} from "react";

export default function useClickOutside(ref: RefObject<any>, active: boolean, callback: Function, options = true) {
    useEffect(() => {
        let observerRefValue: any = null;

        const onClick: any = (event: MouseEvent<EventTarget>) => {
            if (!observerRefValue || observerRefValue?.contains(event.target)) {
                return;
            }
            callback(event);
        };

        if(ref.current && active){
            observerRefValue = ref.current;
            document.addEventListener('click', onClick, options);
        };

        return () => {
            if(observerRefValue) {
                document.removeEventListener('click', onClick, options);
            }
        };
    },[ref, active, callback, options]);
};