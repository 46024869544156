import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import qs from 'query-string';
import { Link } from "react-router-dom";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import Back from '../../../component/Back';
import Icon from '../../../component/Icon';
import {useAppSelector} from "../../../hooks/redux";
import {STRUCTURE} from "../../../data/faq";
import {ICON_VARIANT} from "../../../assets/svg";
import {useTranslate} from "../../../hooks/useTranslate";
import {OPTIONS_SIZE_TEXT, ROUTE} from "../../../constants";
import {useUserSettings} from "../../../hooks/useUserSettings";
import tutorial from '../../../assets/tutorial/User_manual_Terminal.docx';
import tutorialEn from '../../../assets/tutorial/Sputnik_user_guide 2024.docx';
import css from '../FAQ.module.scss';
import {LANG} from "../../../types/IUser";

interface IFAQ{
    id?: number | null
}

interface TabProps {
    id: number,
    title: string
}

const TUTORIAL = {
    [LANG.RU]: tutorial,
    [LANG.EN]: tutorialEn,
    [LANG.ES]: tutorial,
    [LANG.CN]: tutorial,
    [LANG.EG]: tutorial,
    [LANG.FA]: tutorial
};

const FAQ: FC<IFAQ>  = ({id = null}) => {
    const { screen } = useAppSelector(state => state.screen);
    const { rtl, lang } = useAppSelector(state => state.auth);
    const location = useLocation();
    const translate = useTranslate();
    const { sizeBody } = useUserSettings();
    const TABS: TabProps[] = [
        {
            id: 1,
            title: translate.pages.onboard.manual
        },{
            id: 2,
            title: translate.pages.onboard.chapter
        }
    ];
    const [tab, setTab] = useState<TabProps>(TABS[0]);
    const [ bodySize, setBodySize ] = useState<string>(sizeBody);
    const index = id !== null ? STRUCTURE.findIndex(item => item.id === id) : null;
    const structure = index ? STRUCTURE[index] : null;
    const prevStructure = (index !== null && index > 0) ? STRUCTURE[index - 1] : null;
    const nextStructure = (index !== null && STRUCTURE.length > index) ? STRUCTURE[index + 1] : null;

    const onClickSmall = () => {
        const indexBody = OPTIONS_SIZE_TEXT.indexOf(bodySize) > 0 ?  OPTIONS_SIZE_TEXT.indexOf(bodySize) - 1 : 0;
        const newSizeBody = OPTIONS_SIZE_TEXT[indexBody];

        setBodySize(newSizeBody);
    };

    const onClickLarge = () => {
        const indexBody = OPTIONS_SIZE_TEXT.indexOf(bodySize) === OPTIONS_SIZE_TEXT.length - 1 ? OPTIONS_SIZE_TEXT.length - 1 : OPTIONS_SIZE_TEXT.indexOf(bodySize) + 1;
        const newSizeBody = OPTIONS_SIZE_TEXT[indexBody];

        setBodySize(newSizeBody);
    };

    const scrollTo = (target: string) => {
        const anchor = document.getElementById(target);
        if(anchor){
            anchor.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        const { chapter } = qs.parse(location.search);

        if(chapter){
            setTimeout(() =>{
                scrollTo(chapter);
            }, 500);
        }
    },[]);

    return(
        <>
            <div className={css.content}>
                <div className={css.back}><Back/></div>
                <div className={css.header}>
                    {structure
                        ? <div className={css.breadcrumbs}>
                            <Link className={css.breadcrumbsItem} to={ROUTE.FAQ}>
                                {translate.pages.onboard.manual}
                                <div className={css.breadcrumbsIcon}>
                                    <Icon name={rtl ? ICON_VARIANT.ARROW_LEFT : ICON_VARIANT.ARROW_RIGHT}/>
                                </div>
                            </Link>

                            <div className={css.breadcrumbsItem} >
                                {translate.pages.onboard.nav[structure.translate]}
                            </div>
                        </div>
                        : null
                    }
                    <div className={css.info}>
                        <div className={css.title} style={{fontSize: parseInt(bodySize) + 16 + 'px'}}>
                            {structure
                                ? translate.pages.onboard.nav[structure.translate]
                                : translate.pages.onboard.rules
                            }
                        </div>
                        <div className={css.size}>
                            <div className={css.smallIcon} onClick={onClickSmall}>
                                <Icon name={ICON_VARIANT.TEXT}/>
                            </div>
                            <div className={css.largeIcon} onClick={onClickLarge}>
                                <Icon name={ICON_VARIANT.TEXT}/>
                            </div>
                        </div>
                    </div>
                    <div className={css.download}>
                        <a className={css.downloadLink} download={true} href={TUTORIAL[lang]}>
                            {translate.pages.onboard.download}
                            <div className={css.downloadIcon}>
                                <Icon name={ICON_VARIANT.DOWNLOAD}/>
                            </div>
                        </a>
                    </div>
                </div>

                {structure
                    ? <>
                        <div className={css.body} style={{fontSize: bodySize}}>
                            {structure?.content[lang]}
                        </div>

                        <div className={cn(css.navigation, {[css.right]:!prevStructure})}>
                            {prevStructure
                                ? <div className={css.navigationButton}>
                                    <div className={css.navigationTitle}>
                                        {translate.pages.onboard.prev}
                                    </div>
                                    <Link className={css.navigationLink} to={ROUTE.FAQ + prevStructure.route}>
                                        {translate.pages.onboard.nav[prevStructure.translate]}
                                    </Link>
                                </div>
                                : null
                            }
                            {nextStructure
                                ? <div className={cn(css.navigationButton, {[css.right]: true})}>
                                    <div className={css.navigationTitle}>
                                        {translate.pages.onboard.next}
                                    </div>
                                    <Link className={css.navigationLink} to={ROUTE.FAQ + nextStructure.route}>
                                        {translate.pages.onboard.nav[nextStructure.translate]}
                                    </Link>
                                </div>
                                : null
                            }
                        </div>
                    </>
                    : <div className={css.intro}>
                        {STRUCTURE.map(item =>
                            <Link className={css.introLink} key={item.id} to={ROUTE.FAQ + item.route} style={{fontSize: bodySize}}>
                                {translate.pages.onboard.nav[item.translate]}
                            </Link>
                        )}
                    </div>
                }
            </div>
        </>
    )
};

export default FAQ