import React, { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { TooltipProfile } from '../Tooltip';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from '../Icon';
import Dropdown, { DropdownItem } from '../Dropdown';
import {ICON_VARIANT} from "../../assets/svg";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {LIST_VARIANT} from "../List/ListItem";
import css from './Title.module.scss';
import {DROPDOWN_PLACEMENT} from "../Dropdown/DropdownMenu";
import {useTranslate} from "../../hooks/useTranslate";

export interface ITitleMenu {
    title: string,
    icon?: ICON_VARIANT,
    active?: boolean
    type?: string | LIST_VARIANT
    callback: () => void
}


interface ITitle {
    title: string,
    backScreen?: SCREEN_VARIANT[] | [],
    listVariant?: LIST_VARIANT
    dropdown?: boolean,
    sticky?: boolean,
    isBreaking?: boolean | null
    menuIcon?: ICON_VARIANT,
    menu?: ITitleMenu[] | [],
    titleMenu?: ITitleMenu[] | [],
    icon?: ICON_VARIANT,
    onClickFlash?: () => void
}

const Title: FC<ITitle> = ({title, listVariant, backScreen = [], titleMenu = [], dropdown = false, sticky = false, isBreaking = null, menuIcon = ICON_VARIANT.SORT, menu = [], icon = ICON_VARIANT.FLASH, onClickFlash}) => {
    const {screen} = useAppSelector(state => state.screen);
    const navigate = useNavigate();
    const translate = useTranslate();

    const onBack = (e: MouseEvent) => {
        e.stopPropagation();
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else{
            navigate('/', { replace: true });
        }
    };

    return(
        <div className={cn(css.container, {[css.sticky]: sticky})}>
            <div className={css.inner}>
                <div className={css.left}>
                    {backScreen.includes(screen)
                        ? <div className={css.back} onClick={onBack}>
                            <Icon name={ICON_VARIANT.ARROW_BACK}/>
                        </div>
                        : null
                    }

                    <div className={css.text}>
                        {titleMenu.length
                            ? <Dropdown
                                width={'300px'}
                                header={
                                    <>
                                        {title}
                                        <div className={css.arrow}>
                                            <Icon name={ICON_VARIANT.ARROW_DOWN}/>
                                        </div>
                                    </>
                                }
                                offset={{
                                    offsetY: 36,
                                    offsetX: 20
                                }}
                            >
                                {titleMenu.map((item, i) =>
                                    <DropdownItem
                                        key={i}
                                        // icon={item.icon}
                                        // active={item.active}
                                        onClick={item.callback}>
                                        {item.title}
                                    </DropdownItem>
                                )}
                            </Dropdown>
                            : title
                        }
                    </div>
                </div>
                <div className={css.right}>
                    {isBreaking !== null && onClickFlash
                        ? <TooltipProfile
                            title={translate.tooltip.list.breaking}
                        >
                            <div className={css.menu}>
                                <div className={cn(css.icon, {[css.isBreaking]: isBreaking})} onClick={onClickFlash}>
                                    <Icon name={icon}/>
                                </div>
                            </div>
                        </TooltipProfile>
                        : null
                    }
                    {menu.length
                        ? <div className={css.menu}>
                                <Dropdown
                                    header={<Icon name={menuIcon}/>}
                                    placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
                                    width={'200px'}
                                    offset={{
                                        offsetY: 50,
                                        offsetX: -16
                                    }}
                                >
                                    {menu.map((item, i) =>
                                        <DropdownItem
                                            key={i}
                                            active={item.active || Boolean(listVariant && item.type && listVariant === item.type)}
                                            icon={item.icon}
                                            onClick={item.callback}>
                                            {item.title}
                                        </DropdownItem>
                                    )}
                                </Dropdown>
                            </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Title