import React, { FC, useState, useRef, ChangeEvent, MouseEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import moment from 'moment-jalaali'
import Checkbox from '../Input/Checkbox/Checkbox';
import Button from '../Button'
import {TooltipProfile } from '../Tooltip'
import Icon from '../Icon'
import Range, {OnChangeRange} from './Range';
import Calendar, {OnChangeCalendar} from './Calendar2';
import DatePickerHeader, {DATE_PICKER_LABEL, DatePickerLabel} from './DatePickerHeader';
import CalendarLabel from './CalendarLabel';
import {DATE_FORMAT, DATE_FORMAT_SHORT, getScrollParent, TIME_FORMAT, validDate} from "../../utils/utils";
import {useAppSelector} from "../../hooks/redux";
import {INPUT_PLACEMENT, INPUT_SIZE, INPUT_VARINAT, OnCheckboxChange} from "../../types/IInput";
import {COLORS_COUNT_DATEPICKER, COLORS_COUNT_DATEPICKER_SPUTNIK, PERIOD_DATEPICKER} from "../../constants";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useScroll from "../../hooks/useScroll";
import {ICON_VARIANT} from "../../assets/svg";
import {useCalendar, useTranslate} from "../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {LANG, THEME} from "../../types/IUser";
import '../../lang/locale/ru'
import '../../lang/locale/cn'
import '../../lang/locale/es'
import '../../lang/locale/ar'
import '../../lang/locale/fa'
import css from './Datepicker.module.scss';

export interface OnChangeDatePicker{
    startDate: Date | null,
    endDate: Date | null,
    startTime: string | null,
    endTime: string | null,
    label: DatePickerLabel | null
}

interface IDatePicker {
    header: string | React.ReactNode,
    startDate: Date | null,
    endDate?: Date | null,
    startTime?: string | null,
    endTime?: string | null,
    picker?: DATE_PICKER_LABEL[],
    label?: DatePickerLabel | null ,
    disabled?: boolean
    showTime?: boolean,
    sidStatistics?: string[],
    onChange: (dates: OnChangeDatePicker) => void
}

const DatePicker: FC<IDatePicker> = ({header, startDate, endDate = null, startTime = null, showTime = false, endTime = null, label = null, disabled = false, picker = [DATE_PICKER_LABEL.DAYS, DATE_PICKER_LABEL.TIME], sidStatistics = [], onChange}) => {
    const { windowY, windowX, screen } = useAppSelector(state => state.screen);
    const { lang, rtl, theme } = useAppSelector(state => state.auth);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isTime, setIsTime] = useState<boolean>(showTime || !picker.includes(DATE_PICKER_LABEL.DAYS));
    const [transition, setTransition] = useState<boolean>(false);
    const [calendar, setCalendar] = useState<OnChangeDatePicker>({
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        label: label
    });

    const [coordinate, setCoordinate] = useState<{x: number, y: number}>({x: 0, y: 0});
    const ref = useRef<HTMLDivElement | null>(null);
    const refDropdown = useRef<HTMLDivElement | null>(null);
    const parent = useRef<HTMLDivElement | null>(null);
    const translate = useTranslate();
    const { format, formatMobile } = useCalendar();
    const dateFormat = SCREEN_VARIANT.MOBILE ? formatMobile : format;
    const labels = theme === THEME.RIA ? COLORS_COUNT_DATEPICKER : COLORS_COUNT_DATEPICKER_SPUTNIK;

    const save = () => {
        onChange({
            startDate: calendar.startDate,
            endDate: calendar.endDate,
            startTime: calendar.startTime,
            endTime: calendar.endTime,
            label: calendar.label
        });
        setOpen(false);
    };

    const clickToggle = (e: MouseEvent<EventTarget>) => {
        e.stopPropagation();
        e.preventDefault();
        if(!disabled) {
            parent.current = getScrollParent(ref.current);
            setOpen(!isOpen);
        }
    };


    const handleChange = ({ startDate, endDate } : OnChangeCalendar) => {
        setCalendar({
            startDate: startDate,
            endDate: endDate,
            startTime: null,
            endTime: null,
            label: null
        });
    };

    const handleRange = ({startDate, endDate}: OnChangeRange) => {
        const start = (calendar.startDate && moment(calendar.startDate) || moment()).set({hour: startDate.hours, minute: startDate.minutes}),
            end = (calendar.startDate && moment(calendar.startDate) || moment()).set({hour: endDate.hours, minute: endDate.minutes});

        setCalendar({
            startDate: start.toDate(),
            endDate: end.toDate(),
            startTime: start.format(TIME_FORMAT),
            endTime: end.format(TIME_FORMAT),
            label: null
        });
    };

    const handleTag = (item: any) => {
        const interval =  5 * 60 * 1000, // 5 минут
            now = moment(Math.ceil(+moment() / interval) * interval),
            ago = moment(Math.ceil(+moment() / interval) * interval).subtract({
                hours: item.hours,
                days: item.days,
                months: item.months,
                years: item.years
            });

        setCalendar({
            startDate: now.diff(ago, 'days') > 0 ? ago.set({hour: 0, minute: 0}).toDate() : ago.toDate(),
            endDate: now.diff(ago, 'days') > 0 ? now.set({hour: 0, minute: 0}).toDate() : null,
            startTime: now.diff(ago, 'days') === 0 ? ago.format(TIME_FORMAT) : null,
            endTime: now.diff(ago, 'days') === 0 ? now.format(TIME_FORMAT) : null,
            label: item
        });

        if(picker.includes(DATE_PICKER_LABEL.DAYS) && picker.includes(DATE_PICKER_LABEL.TIME)){
            setIsTime(item.picker === DATE_PICKER_LABEL.TIME);
        }
    };

    const removeLabel = () => {
        setCalendar({
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            label: null
        });
    };

    useEffect(()=>{
        if(isOpen && ref.current && refDropdown.current){
            const { left, top, height, width } = ref.current.getBoundingClientRect();
            const dropdown = refDropdown.current.getBoundingClientRect();
            const coordinateLeft = (windowX < left + dropdown.width) ? windowX - dropdown.width : left;
            const coordinateLeftRtl = (left + width > dropdown.width) ? left + width - dropdown.width : 0;
            const coordinateTop = (windowY < top + dropdown.height) ? windowY - dropdown.height - 10 : top + height;

            setCoordinate({
                x: screen === SCREEN_VARIANT.MOBILE ? 0 : rtl ? coordinateLeftRtl : coordinateLeft,
                y: screen === SCREEN_VARIANT.MOBILE ? 0 : coordinateTop
            });
        }
        setTimeout(()=> {
            setTransition(isOpen);
        },10);
    },[isOpen]);


    useEffect(() => {
        if(!isOpen && (startDate !== calendar.startDate || endDate !== calendar.endDate || calendar.startTime !== startTime || calendar.endTime !==  endTime || calendar.label !== label)) {
            setCalendar({
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                label: label
            })
        }
    },[isOpen, startDate, endDate, startTime, endTime, label]);


    // useEffect(()=> {
    //     if(lang){
    //         moment.locale(LOCALE[lang]);
    //     }
    // },[lang]);

    useOnClickOutside(refDropdown, isOpen, () => setOpen(false), false);
    useScroll(parent, isOpen, () => setOpen(false));

    return(
        <div className={css.container} ref={ref}>
            <div className={css.head} onClick={clickToggle}>
                {header}
            </div>
            {isOpen
                ? ReactDOM.createPortal(<div
                    className={cn(css.dropdown,{[css.isOpen]: transition, [css.smHeight]: picker.length === 1})}
                    style={{
                        left: coordinate.x + 'px',
                        top: coordinate.y + 'px'
                    }}
                    onClick={e => e.stopPropagation()}
                    ref={refDropdown}>
                    <div className={css.close} onClick={clickToggle}>
                        <Icon name={ICON_VARIANT.CLOSE}/>
                    </div>
                    <div className={css.header}>
                        {<div className={css.title}>{translate.datepicker.title}</div>}
                        {picker.includes(DATE_PICKER_LABEL.DAYS)
                            ? <div className={css.fields}>
                                <DatePickerHeader
                                    title={translate.datepicker.period}
                                    placeholder={[moment().format(dateFormat), moment().format(dateFormat)]}
                                    value={[
                                        calendar.startDate ? moment(calendar.startDate).format(dateFormat) : '',
                                        calendar.endDate ? moment(calendar.endDate).format(dateFormat) : '',
                                    ]}
                                    mask={SCREEN_VARIANT.MOBILE ? '99.99.99' : '99.99.9999'}
                                    format={dateFormat}
                                    label={calendar.label && calendar.label.hours === 0 ? calendar.label : null}
                                    titleCheckbox={translate.datepicker.periodYear}
                                    valueCheckbox={calendar.label?.months === 12}
                                    removeLabel={removeLabel}
                                    size={INPUT_SIZE.SMALL}
                                    onChange={(values: [string, string]) => {
                                        setCalendar(
                                            {...calendar,
                                                ...{startDate: validDate(values[0], dateFormat) ? moment(values[0], dateFormat).toDate() : null,
                                                    endDate: validDate(values[1], dateFormat) ? moment(values[1], dateFormat).toDate() : null,
                                                    label: null,
                                                }
                                            })
                                    }}
                                    onChangeCheckbox={() => {
                                        if(calendar.label?.months === 12) {
                                            removeLabel();
                                        } else {
                                            handleTag(PERIOD_DATEPICKER.find(item => item.months === 12))
                                        }
                                    }}
                                />
                            </div>
                            : null
                        }
                        {picker.includes(DATE_PICKER_LABEL.TIME) && picker.includes(DATE_PICKER_LABEL.DAYS)
                            ? <div className={css.controls}>
                                <Checkbox
                                    variant={INPUT_VARINAT.BLUE}
                                    placement={INPUT_PLACEMENT.END}
                                    name={"all"}
                                    value={isTime}
                                    title={translate.datepicker.interval}
                                    onChange={({value}: OnCheckboxChange) => setIsTime(value)}/>
                            </div>
                            : null
                        }

                        {isTime
                            ? <div className={css.fields}>
                                <DatePickerHeader
                                    title={translate.datepicker.time}
                                    placeholder={['00:00', '23:59']}
                                    value={[
                                        calendar.startTime || '',
                                        calendar.endTime || '',
                                    ]}
                                    mask={'99:99'}
                                    format={TIME_FORMAT}
                                    label={calendar.label && calendar.label.hours > 0 ? calendar.label : null}
                                    titleCheckbox={translate.datepicker.periodDay}
                                    valueCheckbox={calendar.label?.hours === 24}
                                    removeLabel={removeLabel}
                                    size={INPUT_SIZE.SMALL}
                                    onChange={(values: [string, string]) => {
                                        const startTime = moment(values[0] || '00:00', TIME_FORMAT);
                                        const endTime = moment(values[1] || '23:59', TIME_FORMAT);
                                        const date = calendar.startDate ? moment(calendar.startDate, dateFormat) : moment();

                                        setCalendar({
                                            startDate: date.set({hour: startTime.get('hour'), minute: startTime.get('minute')}).toDate(),
                                            endDate: date.set({hour: endTime.get('hour'), minute: endTime.get('minute')}).toDate(),
                                            startTime: values[0],
                                            endTime: values[1],
                                            label: null
                                        });
                                    }}
                                    onChangeCheckbox={() => {
                                        if(calendar.label?.hours === 24) {
                                            removeLabel();
                                        } else {
                                            handleTag(PERIOD_DATEPICKER.find(item => item.hours === 24))
                                        }
                                    }}
                                />
                            </div>
                            : null
                        }
                    </div>
                    {
                        isTime
                        ? <div className={css.range}>
                            <div className={css.subtitle}>{translate.datepicker.selectTime}</div>
                            <div className={css.slide}>
                                <Range
                                    startDate={calendar.startTime || ''}
                                    endDate={calendar.endTime || ''}
                                    disabled={Boolean(calendar.label)}
                                    onChange={handleRange}
                                />
                            </div>
                            <div className={css.subtitle}>{translate.datepicker.showNewsPeriod}</div>
                            <CalendarLabel sort={picker} onClick={handleTag}/>
                            <div className={css.save}><Button onClick={save}>{translate.datepicker.applyFilter}</Button></div>
                        </div>
                        : <>
                            <div className={css.wrapper}>
                                <Calendar
                                    startDate={calendar.startDate || moment().set({hour: 0, minute: 0, second: 0}).subtract(7, 'days').toDate()}
                                    endDate={calendar.endDate}
                                    onChange={handleChange}
                                    sidStatistics={sidStatistics}
                                />
                            </div>
                            <div className={css.footer}>
                                <div className={css.tag}>
                                    {translate.datepicker.NewsCountDay}
                                    <TooltipProfile
                                        title={translate.tooltip.list.counter}
                                    />
                                </div>
                                <div className={css.info}>
                                    {labels.map(item =>
                                        <div className={css.item} key={item.id} style={{background: item.color}}>{item.title}</div>
                                    )}
                                </div>
                            </div>
                            <div className={css.save}><Button onClick={save}>{translate.datepicker.applyFilter}</Button></div>
                        </>
                    }
                </div>,  document.getElementById('root') as HTMLElement)
                : null
            }
        </div>
    )
};

export default DatePicker