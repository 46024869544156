import React, { FC, useState, MouseEvent, FormEvent, useRef, useEffect } from 'react';
import moment from 'moment'
import Modal from '../index';
import Spinner from '../../Spinner';
import Icon from '../../Icon';
import {telegramModalClose, getCodeTelegram} from "../../../store/reducers/ModalSlice";
import {useTranslate} from "../../../hooks/useTranslate";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ICON_VARIANT} from "../../../assets/svg";
import {copy} from "../../../utils/utils";
import {TELEGRAM_BOT} from "../../../constants";
import css from '../Modal.module.scss';

const COUNT = 300; // 5 минут

const TelegramModal: FC = () => {
    const { isOpen, isLoading, code } = useAppSelector(state => state.modal.telegram);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const [copied, setCopied] = useState(false);
    const ref = useRef<NodeJS.Timeout | null>(null);
    const [counter, setCounter] = useState(COUNT);

    const getCode = () => {
        dispatch(getCodeTelegram());
        setCounter(COUNT);
        ref.current = setInterval(() => {
            setCounter((t) => t - 1);
        }, 1000);
    };

    const onCopy = () => {
        copy(code as string);
        setCopied(true);
    };

    useEffect(() => {
        if (isOpen && !ref.current) {
            getCode();
        }
    },[isOpen]);

    useEffect(() => {
        if(counter === 0 && ref.current){
            clearInterval(ref.current);
        }
    },[counter]);


    return(
        <Modal
            isOpen={isOpen}
            close={() => dispatch(telegramModalClose())}
            title={translate.telegram.title}
        >
            <div className={css.content}>
                <div className={css.list}>
                    <div className={css.item}>
                        <div className={css.number}>1.</div>
                        <span>
                            <span dangerouslySetInnerHTML={{ __html: translate.telegram.part1 }}/>
                            {' '}
                            <a href={TELEGRAM_BOT} target="_blank">{TELEGRAM_BOT}</a>
                        </span>
                    </div>
                    <div className={css.item}>
                        <div className={css.number}>2.</div>
                        <span dangerouslySetInnerHTML={{ __html: translate.telegram.part2 }}/>
                    </div>
                    <div className={css.item}>
                        <div className={css.number}>3.</div>
                        <span dangerouslySetInnerHTML={{ __html: translate.telegram.part3 }}/>
                    </div>
                </div>
                <div className={css.telegram}>
                    {isLoading
                        ? <Spinner height={'90px'}/>
                        : <>
                            <div className={css.code}>
                                <div className={css.codeText}>
                                    {code}
                                </div>
                                <div className={css.codeCopy} onClick={onCopy}>
                                    {copied ? translate.telegram.copied : translate.telegram.copy}
                                    <Icon name={ICON_VARIANT.COPY}/>
                                </div>
                            </div>
                            {counter > 0
                                ? <div className={css.annotate}>
                                    <span dangerouslySetInnerHTML={{ __html: translate.telegram.annotate }}/>
                                    {' '}
                                    <span className={css.annotateBold}>
                                        {moment.utc(counter*1000).format('mm:ss')}
                                    </span>
                                </div>
                                : <div className={css.getCode} dangerouslySetInnerHTML={{ __html: translate.telegram.code }} onClick={getCode}/>
                            }
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
};

export default TelegramModal