import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";


interface ITour {
    run: boolean,
    stepIndex: number,
    variant: number
}


const initialState: ITour = {
    run: false,
    stepIndex: 0,
    variant: 1
};


export const tourSlice = createSlice({
    name: 'tourtSlice',
    initialState,
    reducers: {
        // alert(state: ITour, action: PayloadAction<{ title: string, description: string, button: string }>) {
        //     const {title, description, button} = action.payload;
        //
        // },
        setIndex(state: ITour, action: PayloadAction<number>){
            state.stepIndex = action.payload;
        },
        setRun(state: ITour, action: PayloadAction<boolean>){
            state.run = action.payload;
        },
        setTour(state: ITour, action: PayloadAction<number>){
            state.run = true;
            state.stepIndex = 0;
            state.variant = action.payload
        }
    },
    extraReducers: {

    }
});

export const { setIndex, setRun, setTour } = tourSlice.actions;
export default tourSlice.reducer;