import React, { FC, useEffect  } from 'react';
import cn from 'classnames';
import moment from 'moment-jalaali'
import Checkbox from '../../Input/Checkbox/Checkbox';
import {INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import {IHistory} from "../../../types/IHistory";
import css from './HistoryItem.module.scss';
import {useDateFormat} from "../../../hooks/useDateFormat";

interface Props {
    history: IHistory,
    value: boolean,
    onChange: (arg: OnCheckboxChange) => void
}

const HistoryItem: FC<Props> = ({history, value, onChange}) => {
    const { FORMAT_TIME_AND_DATE } = useDateFormat();
    const {article_id, article_title, format, date} = history;

    return(
        <div className={cn(css.item, {[css.value]: value})}>
            <div className={css.date}>{moment(date).format(FORMAT_TIME_AND_DATE)}</div>
            <div className={css.type}>{format}</div>
            <div className={css.title}>{article_title}</div>
            <div className={css.id}>{article_id}</div>
            <div className={css.controls}>
                <Checkbox variant={INPUT_VARINAT.BLUE} value={value} onChange={onChange}/>
            </div>
        </div>
    )
};

export default HistoryItem;