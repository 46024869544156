import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import cn from 'classnames';
import { useSearchParams } from "react-router-dom";
import Modal from '../index';
import Input from '../../Input/Input/Input';
import Button, {ButtonGroup, BUTTON_GROUP_ALIGN, BUTTON_VARIANT} from '../../Button';
import css from '../Login/Login.module.scss'
import {OnInputChange} from "../../../types/IInput";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setLoginRecoveryOpen} from "../../../store/reducers/ModalSlice";
import {loginRecovery} from "../../../store/reducers/AuthSlice";
import {useTranslate} from "../../../hooks/useTranslate";


const LoginRecovery: FC = () => {
    const { isOpen, isLoading } = useAppSelector(state => state.modal.loginRecovery);
    const [form, setForm] = useState({login: ''});
    const [error, setError] = useState('');
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const getToken = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
        } else {
            try {
                const token = await executeRecaptcha('load');
                return token;
            } catch (e) {
                getToken();
            }
        }
    };

    const onChange = ({name, value}: OnInputChange) => {
        setForm(prev => ({...prev, ...{[name]: value}}));
    };

    const onSubmit = async (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const token = await getToken();

        dispatch(loginRecovery({
            form: {...form, ...{'g-recaptcha-response': token}},
            success: {
                title: translate.auth.recovery,
                description: 'Вам на почту отправлено письмо с восстановлением пароля',
                button: translate.auth.close,
            }
        }))
    };

    return (
        <Modal
            isOpen={isOpen}
            close={() => dispatch(setLoginRecoveryOpen(false))}
        >
            <div className={css.tab}>
                <div className={cn(css.tabItem, css.active)}>
                    {translate.auth.recovery}
                </div>
                <div className={css.content}>
                    <form onSubmit={onSubmit}>
                        <Input name={'login'} value={form.login} title={translate.auth.email} onChange={onChange}/>
                        <button hidden/>
                        {error
                            ? <div className={css.error}>{error}</div>
                            : null
                        }
                    </form>
                    <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                        <Button onClick={onSubmit} disabled={!Boolean(form.login)}>{translate.auth.submit}</Button>
                    </ButtonGroup>
                </div>
            </div>
        </Modal>
    )

};

export default LoginRecovery