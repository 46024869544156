import React, { FC, useState } from 'react';
import Icon from '../../../Icon';
import cn from 'classnames';
import { TooltipProfile } from '../../../Tooltip';
import DropDown, { DropdownItem } from '../../../Dropdown';
import {ICON_VARIANT} from "../../../../assets/svg";
import {ROUTE, SHARE_URL} from "../../../../constants";
import {IArticle} from "../../../../types/IArticle";
import {useTranslate} from "../../../../hooks/useTranslate";
import {sendMailOpen} from "../../../../store/reducers/ModalSlice";
import {LOG_FORMAT, logHistory} from "../../../../store/reducers/HistorySlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {DROPDOWN_PLACEMENT} from "../../../Dropdown/DropdownMenu";
import css from '../ListItem.module.scss';
import { useUserPremium } from '../../../../hooks/useUserPremium';

interface Props {
    article: IArticle
}

const ListShare:FC<Props> = ({article}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const {run } = useAppSelector(state => state.tour);
    const domain = window.location.origin;
    const id = article.article_id;
    const url = domain + ROUTE.ARTICLE + id;
    const title = article.article_title?.replace(/<\/?[^>]+>/g, '').replace(/&nbsp;/gi, " ");
    const translate = useTranslate();
    const dispatch = useAppDispatch();
    const premium = useUserPremium();

    const onMail = () => {
        dispatch(sendMailOpen([id]));
    };

    return(
        <DropDown
            header={
                <TooltipProfile
                    title={translate.tooltip.list.share}
                >
                <div className={cn(css.icon, {[css.active]: isOpen})} data-share={'share'}><Icon name={ICON_VARIANT.SHARE}/></div>
                </TooltipProfile>
            }
            placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() =>{
                setOpen(false);
            }}
            width={'260px'}
        >
            {premium
                ? <DropdownItem
                    icon={ICON_VARIANT.MAIL}
                    onClick={onMail}
                >
                    {translate.list.sendMail}
                </DropdownItem>
                : null
            }
            <DropdownItem
                icon={ICON_VARIANT.TELEGRAM}
                onClick={() => {
                    dispatch(logHistory({article_id: [id], format: LOG_FORMAT.TELEGRAM}));
                    window.open(SHARE_URL.TELEGRAM + '?' + 'url=' + url + '&text=' + title);
                }}
            >
                {translate.list.shareTelegram}
            </DropdownItem>
            <DropdownItem
                icon={ICON_VARIANT.VK}
                onClick={() => {
                    dispatch(logHistory({article_id: [id], format: LOG_FORMAT.VK}));
                    window.open(SHARE_URL.VK + '?' + 'url=' + url + '&title=' + title)
                }}
            >
                {translate.list.shareVk}
            </DropdownItem>
            <DropdownItem
                icon={ICON_VARIANT.OK}
                onClick={() => {
                    dispatch(logHistory({article_id: [id], format: LOG_FORMAT.OK}));
                    window.open(SHARE_URL.OK + '?' + 'url=' + url + '&title=' + title)
                }}
            >
                {translate.list.shareOk}
            </DropdownItem>
        </DropDown>
    )
};

export default ListShare