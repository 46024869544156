import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import {IArticle} from "../../types/IArticle";
import {addListIds, LIST_NAME} from './LSlice';
import {IHistory} from "../../types/IHistory";

interface Istore {
    articles: {
        [article_id: number]:  IArticle
    },
    history: {
        [export_id: string]:  IHistory[]
    },
    sids: {
        [sid: string]: number[]
    }
}

const initialState: Istore = {
    articles: {},
    history: {},
    sids: {}
};


export const addStoreComet = createAsyncThunk(
    'store/addStoreComet',
    (articles: IArticle[], { getState, dispatch}) => {
        const state = getState() as any;
        const { lang } = state.auth;
        const { sid_list } = state.l[LIST_NAME.ALL_NEWS].filter;

        dispatch(addStoreArticles(articles));
        dispatch(addStoreSids({articles, sid_list}));
        dispatch(addListIds({articles, lang}));
    }
);

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        addStoreSids(state: Istore, action: PayloadAction<{articles:IArticle[], sid_list: string[]}>) {
            const { articles, sid_list } = action.payload;

            for(let i = 0; i < articles.length; i++){
                const article_id = articles[i].article_id;

                state.articles[article_id] = articles[i];

                const sids = articles[i].categories.map(item => item.list_sid);
                if(sids.length){
                    for(let j = 0; j < sids.length; j++){
                        const name = sids[j];


                        if(!sid_list.includes(name)) {
                            if (!state.sids[name]) {
                                state.sids[name] = []
                            }

                            state.sids[name] = state.sids[name].concat(article_id);
                        }
                    }
                }
            }
        },
        cleanStoreSids(state: Istore, action: PayloadAction<string[]>) {
            const sids = action.payload;

            for(let i = 0; i < sids.length; i++){
                const name = sids[i];
                delete state.sids[name]
            }
        },
        addStoreArticles(state: Istore, action: PayloadAction<IArticle[]>) {
            const articles = action.payload;

            for(let i = 0; i < articles.length; i++) {
                const article_id = articles[i].article_id;

                state.articles[article_id] = articles[i];
            }
        },
        addStoreHistory(state: Istore, action: PayloadAction<IHistory[]>) {
            const history = action.payload;
            for(let i = 0; i < history.length; i++){
                const export_id = history[i].export_id;
                const article_id = history[i].article_id;

                if(!state.history[export_id]){
                    state.history[export_id] = []
                }
                const index = state.history[export_id].findIndex(article => article.article_id === article_id);

                if(index > -1){
                    state.history[export_id][index] = history[i];
                }else {
                    state.history[export_id].push(history[i]);
                }
            }
        },
    },
});

export const { addStoreSids, cleanStoreSids, addStoreArticles, addStoreHistory } = storeSlice.actions;
export default storeSlice.reducer;