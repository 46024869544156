import React, { FC, useState, useEffect, useRef, forwardRef, FocusEvent, ChangeEvent, RefObject, MouseEvent } from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import {ICON_VARIANT} from "../../assets/svg";
import {OnInputChange} from "../../types/IInput";
import {useDebounce} from "../../hooks/useDebounce";
import {useTranslate} from "../../hooks/useTranslate";
import { setFocus, setShowFilter } from "../../store/reducers/HeaderSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import css from './Search.module.scss';
import {isRussian} from "../../utils/utils";
import {QUERY_LENGTH, QUERY_LENGTH_RU} from "../../constants";
import {DROPDOWN_PLACEMENT} from "../Dropdown/DropdownMenu";

interface ISearch {
    value: string
    onChange: (arg: OnInputChange) => void
}

const Search = forwardRef<HTMLDivElement, ISearch>(({value, onChange}, ref) => {
    const { focus, showFilter } = useAppSelector(state => state.header);
    const [search, setSearch] = useState<string>(value);
    const [error, setError] = useState<string>('');
    const fetch = useDebounce(onChange, 900);
    const translate = useTranslate();
    const dispatch = useAppDispatch();

    const onToggle = () => {
        dispatch(setShowFilter(!showFilter));
    };

    const onFocus = (e: FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        dispatch(setFocus(true));
        listeners(true);
    };

    const onClean = () => {
        setSearch('');

        fetch({
            name: 'query',
            value: ''
        })
    };

    const onBlur = () => {
        dispatch(setFocus(false));
    };

    const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const strArr = value.split(' ').filter(e => e);
        const newValue = strArr.slice(0, QUERY_LENGTH).join(' ').concat((value[value.length - 1] === ' ' || strArr.length > QUERY_LENGTH) ? ' ' : '');

        e.preventDefault();
        setSearch(newValue);
        setError(strArr.length > QUERY_LENGTH ? translate.pages.query.error : '');
        dispatch(setFocus(true));

        fetch({
            name: name,
            value: newValue
        })
    };

    const onDocument: any = (e: MouseEvent<EventTarget>) => {
        if (ref !== null && typeof ref !== 'function') {
            if(!ref?.current?.contains(e.target as Node)){
                onBlur();
                listeners(false);
            }
        }
    };

    const listeners = (active: boolean) => {
        if (active) {
            document.addEventListener('click', onDocument)
        } else{
            document.removeEventListener('click', onDocument)
        }
    };

    useEffect(() => {
        if(value !== search){
            setSearch(value);
        }
    },[value]);

    return (
        <div className={cn(css.container,{[css.hasError]: error})} ref={ref}>
            <div className={css.search}>
                <div className={css.icon}>
                    <Icon name={ICON_VARIANT.SEARCH}/>
                </div>
                <input
                    id={'search'}
                    className={css.field}
                    autoComplete="off"
                    value={search}
                    name={'query'}
                    onFocus={onFocus}
                    onChange={handlerChange}
                    type="text"
                    placeholder={translate.search.search}/>
                {error && translate.pages.query.alert
                    ? <div className={css.alert}>
                        <Tooltip
                            title={<span dangerouslySetInnerHTML={{__html: translate.pages.query.alert}}/>}
                            placement={DROPDOWN_PLACEMENT.RIGHT_BOTTOM}
                        >
                            <div className={css.alertIcon}>
                                <Icon name={ICON_VARIANT.WARNING}/>
                            </div>
                        </Tooltip>
                    </div>
                    : <div className={cn(css.clean, {[css.active]: search})} onClick={onClean}>
                        <Icon name={ICON_VARIANT.CLOSE}/>
                    </div>
                }
                <div className={cn(css.toggle, {[css.focus]: focus || showFilter})} onClick={onToggle}>
                    <Icon name={showFilter ? ICON_VARIANT.ARROW_UP : ICON_VARIANT.ARROW_DOWN}/>
                </div>
            </div>
            {showFilter && error
                ? <div className={css.error}>{error}</div>
                : null
            }
        </div>
    )
});

export default Search