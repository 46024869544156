import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import Checkbox from '../../Input/Checkbox/Checkbox';
import Icon from '../../Icon';
import { INPUT_VARINAT, OnCheckboxChange, OnInputChange} from "../../../types/IInput";
import css from './SendMail.module.scss';
import {IMailingList} from "../../../types/IUser";
import {ICON_VARIANT} from "../../../assets/svg";

const ListMail: FC<{data: IMailingList, active: boolean, onChange: (arg: OnCheckboxChange) => void}> = ({data, active, onChange}) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    return(
        <div className={css.listContainer}>
            <div className={css.listMailHeader}>
                <Checkbox
                    variant={INPUT_VARINAT.BLUE}
                    name={data.id}
                    value={active}
                    title={data.name}
                    onChange={onChange}/>
                <div className={css.listMailToggle} onClick={() => setOpen(!isOpen)}>
                    {data.value?.list.length} чел
                    <span className={css.listMailToggleIcon}>
                        <Icon name={isOpen ? ICON_VARIANT.ARROW_UP : ICON_VARIANT.ARROW_DOWN}/>
                    </span>
                </div>
            </div>
            {isOpen
                ? <div className={css.listMail}>
                    {data.value?.list.map(item =>
                        <div className={css.listMailItem} key={item}>
                            {item}
                        </div>
                    )}
                </div>
                : null
            }
        </div>
    )
};

export default ListMail;