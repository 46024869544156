import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from 'query-string';
import axios from "axios";
import {IArticle} from "../../types/IArticle";
import {TYPE_PREFERENCES, URL} from "../../constants";


interface IPrint {
    isLoading: boolean,
    articles: IArticle[]
}

const initialState: IPrint = {
    isLoading: false,
    articles: []
};

export const getPrintArticles = createAsyncThunk(
    'article/getArticle',
    async (ids: number[], { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await axios.post(URL.GET_ARTICLE, qs.stringify({
                type: TYPE_PREFERENCES.QUERY,
                id: ids,
                event: 'print'
            }, {arrayFormat: 'bracket'}), {withCredentials: true});

            return response?.data?.data?.paginationSolrArticles?.articles
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);


export const printSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getPrintArticles.pending.type]: (state: IPrint) => {
            state.isLoading = true;
            state.articles = [];
        },
        [getPrintArticles.fulfilled.type]: (state: IPrint, action: PayloadAction<IArticle[]>) => {
            const articles = action.payload;

            state.isLoading = false;
            state.articles = articles || [];
        },
    }
});

export const { } = printSlice.actions;
export default printSlice.reducer;