import React, { FC, MouseEvent } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Modal from '../index';
import Button, {ButtonGroup, BUTTON_VARIANT, BUTTON_GROUP_ALIGN} from '../../Button';
import { closeMobileModal, openDemoModal, openSubscribeModal} from "../../../store/reducers/AuthSlice";
import { setLoginOpen } from "../../../store/reducers/ModalSlice";
import {ICON_VARIANT} from "../../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useTranslate} from "../../../hooks/useTranslate";
import {ROUTE, SUBSCRIBE} from "../../../constants";

const MobileAuth: FC = () => {
    const { isOpenMobile, lang } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const location = useLocation();
    const navigate = useNavigate();

    const openLogin = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if(location.pathname !== ROUTE.INDEX){
            navigate(ROUTE.INDEX);
        }
        dispatch(closeMobileModal())
        dispatch(setLoginOpen(true))
    };

    const openDemo = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(closeMobileModal())
        dispatch(openDemoModal())
    };

    const openSubscribe = (e: MouseEvent<HTMLButtonElement>) => {
        const link = SUBSCRIBE[lang].link;

        e.preventDefault();
        window.open(link);
        // dispatch(closeMobileModal())
        // dispatch(openSubscribeModal())
    };

    return (
        <Modal
            isOpen={isOpenMobile}
            close={() => dispatch(closeMobileModal())}
            style={{
                overlay: {
                    padding:'0px',
                    textAlign: 'right',
                },
                content: {
                    borderRadius: '0px',
                    width: '300px',
                    padding: '32px 18px 12px 18px',
                    verticalAlign: 'top',
                    minHeight: '315px'
                }
            }}
        >
            <ButtonGroup align={BUTTON_GROUP_ALIGN.VERTICAL}>
                {/*<Button variant={BUTTON_VARIANT.LIGHT} onClick={openDemo}>{translate.auth.demo}</Button>*/}
                <Button onClick={openSubscribe}>{translate.auth.subscribe}</Button>
                <Button variant={BUTTON_VARIANT.LIGHT} onClick={openLogin} startIcon={ICON_VARIANT.USER}>{translate.auth.loginAndRegistration}</Button>
            </ButtonGroup>
        </Modal>
    )
};

export default MobileAuth