import {useState, useEffect, RefObject, UIEvent} from "react";

export default function useScroll(ref: RefObject<any>, active: boolean, callback: Function) {
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        const scroll: any = (event: UIEvent<HTMLElement>) => {
            callback(event);
        };

        if(ref.current){
            if(init) {
                ref.current.addEventListener('scroll', scroll);
            }
        }

        return () => {
            if(ref.current && init) {
                ref.current.removeEventListener('scroll', scroll);
            }
        };
    },[init]);

    useEffect(() => {
        if(ref.current){
            // ref.current.removeEventListener('scroll', scroll);

            if(active) {
                setInit(true);
            } else {
                setInit(false);
            }
        }
    }, [ref, active, callback]);
};