// import { useEffect, useState } from 'react'
//
// export function useDebounce<T>(value: T, delay?: number): T {
//     const [debouncedValue, setDebouncedValue] = useState<T>(value);
//
//     useEffect(() => {
//         const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
//
//         return () => {
//             clearTimeout(timer)
//         }
//     }, [value, delay]);
//
//     return debouncedValue
// }

import {useCallback, useRef} from 'react';

export function useDebounce(callback: Function, delay: number = 500) {
    const timer: { current: NodeJS.Timeout | null } = useRef(null);

    const debouncedCallback = useCallback((...args) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, [callback, delay]);

    return debouncedCallback;
};