const BANNER_HEADER = `
	<div id="adfox_16655623911347075"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_16655623911347075',
	            params: {
	                pp: 'cvru',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_HEADER_MOBILE = `
	<div id="adfox_167653497937941877"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_167653497937941877',
	            params: {
	                pp: 'ddjm',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS2 = `
	<div id="adfox_166556301507491957"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556301507491957',
	            params: {
	                pp: 'cvrw',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_SIDEBARE_GS3 = `
	<div id="adfox_168846016505745648"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_168846016505745648',
	            params: {
	                pp: 'dolu',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

const BANNER_INDEX = `
	<div id="adfox_166556290351385072"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556290351385072',
	            params: {
	                pp: 'cvrv',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;


const BANNER_FEED = `
	<div id="adfox_166556311844117650_bannerId"></div>
	<script>
	    window.yaContextCb.push(()=>{
	        Ya.adfoxCode.create({
	            ownerId: 249922,
	            containerId: 'adfox_166556311844117650_bannerId',
	            params: {
	                pp: 'cvrx',
	                ps: 'gasz',
	                p2: 'fluh',
	                puid1: '',
	                puid2: '',
	                puid3: '',
	                puid4: '',
	                puid5: '',
	                puid6: ''
	            }
	        })
	    })
	</script>
`;

export default {
	BANNER_HEADER,
	BANNER_HEADER_MOBILE,
	BANNER_SIDEBARE_GS2,
	BANNER_SIDEBARE_GS3,
	BANNER_INDEX,
	BANNER_FEED
}