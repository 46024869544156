import React, { FC, useState, MouseEvent, FormEvent } from 'react';
import Modal from '../index';
import Form from '../../../pages/settings/export/form/Form';
import {newsletterClose} from "../../../store/reducers/ModalSlice";
import {useTranslate} from "../../../hooks/useTranslate";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {IMailingList} from "../../../types/IUser";
import css from '../Modal.module.scss';

interface Props {
    callback?: (arg: IMailingList) => void
}

const Newsletter: FC<Props> = ({callback}) => {
    const { isOpen } = useAppSelector(state => state.modal.newsletter);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const createAndClose = (arg: IMailingList) => {
        dispatch(newsletterClose());
        callback && callback(arg);
    };

    return(
        <Modal
            isOpen={isOpen}
            close={() => dispatch(newsletterClose())}
            title={translate.pages.export.mailTitle}
        >
            <div className={css.content}>
                <Form
                    callback={createAndClose}
                />
            </div>
        </Modal>
    )
};

export default Newsletter