import React, { FC, useState, useRef, ChangeEvent } from 'react';
import {DATE_PICKER_LABEL, DatePickerLabel} from "../DatePickerHeader";
import {PERIOD_DATEPICKER} from "../../../constants";
import {useTranslate} from "../../../hooks/useTranslate";
import css from './CalendarLabel.module.scss'

interface Props {
    sort?: DATE_PICKER_LABEL[] | [],
    onClick: (item: DatePickerLabel) => void,
}

const CalendarLabel: FC<Props> = ({onClick, sort = [DATE_PICKER_LABEL.TIME, DATE_PICKER_LABEL.DAYS]}) => {
    const translate = useTranslate();
    const tags = translate.datepicker.tags;

    return(
        <div className={css.label}>
            {PERIOD_DATEPICKER.filter(item => sort.includes(item.picker)).map(item =>
                <div className={css.tag} key={item.id} onClick={() => onClick(item)}>
                    {tags[item.id]?.title}
                </div>
            )}
        </div>
    )
};

export default CalendarLabel;