import React, { FC, useEffect, useState, memo  } from 'react';
import HistoryBar from './HistoryBar';
import HistoryHeader from './HistoryHeader';
import HistoryItem from './HistoryItem';
import HistoryPagination from './HistoryPagination';
import HistoryFilter from './HistoryFilter';
import HistoryEmpty from './HistoryEmpty';
import Spinner from '../Spinner';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getHistory, setChecked, setCheckedIds, setParams } from "../../store/reducers/HistorySlice";
import {useGetHistory} from "../../hooks/useGetHistory";
import {IHistory} from "../../types/IHistory";
import {arrayCompareSomeCheckbox} from "../../utils/utils";
import {OnCheckboxChange} from "../../types/IInput";
import {SCREEN_VARIANT} from "../../types/IScreen";
import css from './History.module.scss';



const History: FC = () => {
    const {screen} = useAppSelector(state => state.screen);
    const {isLoading, exportIds, checked, onpage, total, filter} = useAppSelector(state => state.history);
    const [collapse, setCollapse] = useState<string[]>([]);
    // const history = useGetAndSortHistory(exportIds);
    const history = useGetHistory(exportIds);
    const dispatch = useAppDispatch();

    const onChange = (id: string, article_id: number) => {
        // dispatch(setChecked(id))
        dispatch(setChecked({
            id: id,
            article_id: article_id
        }))
    };

    const onChangeAll = ({id, article_ids, value}: {id: string, article_ids: number[], value: boolean} ) => {
        dispatch(setCheckedIds({id, article_ids, value}))
    };

    const onCollapse = (day: string) => {
        const state = collapse.includes(day) ? collapse.filter(e => e !== day) : collapse.concat(day);
        setCollapse(state);
    };

    const onPage = (offset: number) => {
        dispatch(setParams({
            offset: offset
        }));
        dispatch(getHistory());
    };

    const onLimit = (limit: number) => {
        dispatch(setParams({
            limit: limit
        }));
        dispatch(getHistory());
    };

    useEffect(() => {
        dispatch(getHistory());
    },[]);

    return(
        <div className={css.history}>
            <HistoryFilter/>
            {screen === SCREEN_VARIANT.DESKTOP ? <HistoryHeader/> : null}

            {isLoading
                ? <Spinner/>
                : <>
                    {history.map((pack: IHistory[]) => {
                        const id = pack?.[0]?.export_id;
                        const day = pack?.[0]?.date;
                        const article_ids = pack.map(item => item.article_id);
                        const valueCheckbox = arrayCompareSomeCheckbox(checked[id] || [], article_ids);
                        const count = article_ids.length;

                        return (
                            <div className={css.section} key={id}>
                                <HistoryBar
                                    id={id}
                                    day={day}
                                    collapse={!collapse.includes(id)}
                                    onCollapse={onCollapse}
                                    onChange={({value}: OnCheckboxChange) => onChangeAll({id, article_ids, value})}
                                    checked={valueCheckbox}
                                    count={count}/>

                                {!collapse.includes(id)
                                    ? pack.map((article: IHistory) =>
                                        <HistoryItem
                                            key={article.article_id}
                                            history={article}
                                            value={Boolean(checked[id]?.includes(article.article_id))}
                                            onChange={() => onChange(id, article.article_id)}/>
                                    )
                                    : null
                                }
                            </div>
                        )}
                    )}
                    {!total
                        ? <HistoryEmpty/>
                        : null
                    }
                    {total && onpage
                        ? <HistoryPagination
                            onPage={onPage}
                            onLimit={onLimit}
                            page={Math.ceil(filter.offset/filter.limit) || 1}
                            onpage={filter.limit}
                            total={total}/>
                        : null
                    }
                </>
            }
        </div>
    )
};

export default History;