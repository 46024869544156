import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Icon from '../Icon';
import {ICON_VARIANT} from "../../assets/svg";
import {useTranslate} from "../../hooks/useTranslate";
import {TELEGRAM} from "../../constants";
import css from './Telegram.module.scss';
import {useAppSelector} from "../../hooks/redux";
import { analyticsTelegramButton } from '../../utils/analytics';

const Telegram:FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const translate = useTranslate();
    const unix = moment().unix();
    const widget = TELEGRAM[lang].widget;
    const [url, setUrl] = useState<string>(widget + '?v=' + unix);

    const onTelegram = () => {
        analyticsTelegramButton()
    };

    useEffect(() => {
        const timer = setInterval(() => {
            const unix = moment().unix();
            const url = widget + '?v=' + unix;
            setUrl(url);
        }, 1000 * 60);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={css.telegram}>
            <div className={css.widget}>
                <iframe src={url} frameBorder="0"/>
            </div>
            <div className={css.buttons}>
                <a className={css.btn} href={TELEGRAM[lang].link} target="_blank" onClick={onTelegram}>
                    <span className={css.icon}>
                        <Icon name={ICON_VARIANT.TELEGRAM}/>
                    </span>
                    <span className={css.text}>
                        <span className={css.title}>{translate.telegram.verified}</span>
                        <span className={css.lead}>{translate.telegram.read}</span>
                    </span>
                </a>
                <a className={css.btn} href={TELEGRAM[lang].link_2} target="_blank" onClick={onTelegram}>
                    <span className={css.icon}>
                        <Icon name={ICON_VARIANT.TELEGRAM}/>
                    </span>
                    <span className={css.text}>
                        <span className={css.title}>{translate.telegram.verifiedEng}</span>
                        <span className={css.lead}>{translate.telegram.readEng}</span>
                    </span>
                </a>
            </div>
        </div>
    );
};

export default Telegram