import React, { FC, useEffect } from 'react';
import qs from 'query-string';
import moment from 'moment';
import Icon from '../Icon';
import { useLocation } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getPrintArticles} from "../../store/reducers/PrintSlice";
import { getMediaSrc, getMediaTitle, toArray} from "../../utils/utils";
import {useTranslate} from "../../hooks/useTranslate";
import {initialProfileExport} from "../../store/reducers/AuthSlice";
import {EXPORT_DISPLAY, LANG} from "../../types/IUser";
import {MEDIA_VARIANT} from "../../types/IMedia";
import {ICON_VARIANT} from "../../assets/svg";
import css from './Print.module.scss';
import {useDateFormat} from "../../hooks/useDateFormat";

const Print: FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const location =  useLocation();
    const params = qs.parse(location.search, {arrayFormat: 'comma'});
    const ids = toArray(params.ids).map((id: string) => parseInt(id)).filter(e => e);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const {FORMAT_TIME, FORMAT_DATE} = useDateFormat();
    const { isLoading, articles } = useAppSelector(state => state.print);
    const profile = useAppSelector(state => state.auth.profile_export);
    const settings = (profile.find(item => item.name === 'main') || initialProfileExport).value;
    const format = ((settings.showTime ? FORMAT_TIME : '') + ' ' + (settings.showDate ? FORMAT_DATE : '')).trim();


    useEffect(()=>{
        if(ids.length){
            dispatch(getPrintArticles(ids));
        }
    },[]);

    return(
        <>
            {articles.map((article, i) =>
                <div className={css.print}>
                    {settings.display === EXPORT_DISPLAY.STEP || i === 0
                        ? <div className={css.header}>
                            <div className={css.logo}>
                                {lang === LANG.RU
                                    ? <Icon name={ICON_VARIANT.LOGO_INVERSE}/>
                                    : <Icon name={ICON_VARIANT.LOGO_SPUTNIK_INVERSE}/>
                                }
                            </div>
                        </div>
                        : null
                    }
                    <div className={css.content}>
                        <div className={css.article}>
                            {format
                                ? <div className={css.date}>
                                    {moment(article.article_date).format(format)}
                                </div>
                                : null
                            }
                            <div className={css.title} style={{fontSize: settings.sizeTitle}}>
                                {article.article_title}
                            </div>
                            {settings.media && article.medias?.length
                                ? article.medias.map(media =>
                                    <div className={css.media}>
                                        <img
                                            src={getMediaSrc(media, [MEDIA_VARIANT.AR16x9, MEDIA_VARIANT.PHOTO], 1000).src}
                                            alt={getMediaTitle(media) || ''}
                                            title={getMediaTitle(media) || ''}/>
                                    </div>
                                )
                                : null
                            }
                            {settings.showKeywords
                                ? <div className={css.keywords}>
                                    {article.article_keywords}
                                </div>
                                : null
                            }
                            <div
                                className={css.body}
                                style={{fontSize: settings.sizeBody, lineHeight:settings.sizeLineHeight}}
                                dangerouslySetInnerHTML={{__html: article.article_body}}/>
                        </div>
                    </div>
                    {settings.display === EXPORT_DISPLAY.STEP || (articles.length === i + 1)
                        ? <div className={css.footer}>
                            <p dangerouslySetInnerHTML={{__html: translate.print.part1}}/>
                            <p dangerouslySetInnerHTML={{__html: translate.print.part2}}/>
                            {translate.print.part3 ? <p dangerouslySetInnerHTML={{__html: translate.print.part3}}/> : null}
                            <p dangerouslySetInnerHTML={{__html: translate.print.part4}}/>
                        </div>
                        : null
                    }
                </div>
            )}
        </>
    );

};

export default Print;