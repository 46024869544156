import React, { FC, useState, MouseEvent, FormEvent, useEffect, useRef } from 'react';
import cn from 'classnames';
import {TooltipProfile} from '../../../../component/Tooltip';
import Title from '../../../../component/Title/index';
import Button, {BUTTON_GROUP_ALIGN, ButtonGroup} from '../../../../component/Button/index';
import { Form, Row, Cell, DL, Title as FormTitle} from '../../../../component/Input/Form';
import Checkbox from '../../../../component/Input/Checkbox/Checkbox';
import RadioGroup from '../../../../component/Input/Radio/RadioGroup';
import Radio from '../../../../component/Input/Radio/Radio';
import Sizer from '../../../../component/Input/Sizer';
import {OPTIONS_SIZE_LINE_HEIGHT, OPTIONS_SIZE_TEXT, OPTIONS_SIZE_TITLE} from "../../../../constants";
import {INPUT_ALIGN, INPUT_VARINAT, OnCheckboxChange, OnRadioChange} from "../../../../types/IInput";
import {ISizerChange} from "../../../../component/Input/Sizer";
import {initialProfileExport, setProfileExport} from "../../../../store/reducers/AuthSlice";
import {useTranslate} from "../../../../hooks/useTranslate";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import export_variant_1 from '../../../../assets/images/export_variant_1.jpg';
import export_variant_2 from '../../../../assets/images/export_variant_2.jpg';
import {BUTTON_VARIANT} from "../../../../component/Button";
import {SCREEN_VARIANT} from "../../../../types/IScreen";
import {EXPORT_DISPLAY} from "../../../../types/IUser";
import css from '../Export.module.scss';
import {
    FORM_CELL_VARINAT, FORM_FORM_VARIANT,
    FORM_ROW_JUSTIFY, FORM_ROW_MARGIN,
    FORM_ROW_VARIANT,
    FORM_TITLE_VARINAT
} from "../../../../component/Input/Form";

const Export: FC = () => {
    const { profile_export, isLoading} = useAppSelector(state => state.auth);
    const profile = profile_export.find(item => item.name === 'main') || initialProfileExport;
    const [form, setForm] = useState(profile.value);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const onChange = ({name, value}: OnCheckboxChange | ISizerChange | OnRadioChange) =>{
        const newForm = {...form, ...{[name]: value}};

        setForm(newForm);
        dispatch(setProfileExport({
            name: profile.name,
            type: profile.type,
            value: newForm
        }))
    };


    // const submit = () => {
    //     dispatch(setProfileExport({
    //         name: settings.name,
    //         type: settings.type,
    //         value: form
    //     }))
    // };

    return (
        <>
            <Title title={translate.pages.export.title} sticky={true} backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}/>
            <Form variant={FORM_FORM_VARIANT.INNER}>
                <Row>
                    <Cell>
                        <FormTitle varinat={FORM_TITLE_VARINAT.H1}>
                            {translate.pages.export.display}
                            <TooltipProfile
                                isClick={true}
                                title={translate.tooltip.export.display}
                            />
                        </FormTitle>
                        <Row>
                            <RadioGroup name={'display'} value={form.display} onChange={onChange}>
                                <Radio variant={INPUT_VARINAT.BLUE} value={EXPORT_DISPLAY.LINE} title={translate.pages.export.line}/>
                                <Radio variant={INPUT_VARINAT.BLUE} value={EXPORT_DISPLAY.STEP} title={translate.pages.export.step}/>
                            </RadioGroup>
                        </Row>
                        <Row>
                            {form.display === 'line'
                                ? <img className={css.image} src={export_variant_1}/>
                                : <>
                                    <img className={css.image} src={export_variant_2}/>
                                    <img className={css.image} src={export_variant_2}/>
                                </>
                            }
                        </Row>
                    </Cell>
                    <Cell>
                        <FormTitle varinat={FORM_TITLE_VARINAT.H1}>
                            {translate.pages.export.structure}
                            <TooltipProfile
                                isClick={true}
                                title={translate.tooltip.export.structure}
                            />
                        </FormTitle>
                        <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                            <Checkbox
                                variant={INPUT_VARINAT.BLUE}
                                title={translate.pages.export.media}
                                name="media"
                                value={form.media}
                                onChange={onChange}/>
                        </Row>
                        <RadioGroup name={'mediaType'} disabled={!form.media} value={form.mediaType} onChange={onChange}>
                            <Row varinat={FORM_ROW_VARIANT.SUB_LIST} margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                                <Cell varinat={FORM_CELL_VARINAT.AUTO}><Radio variant={INPUT_VARINAT.BLUE} value={'all'} title={translate.pages.export.mediaAll}/></Cell>
                                <Cell varinat={FORM_CELL_VARINAT.AUTO}><Radio variant={INPUT_VARINAT.BLUE} value={'main'} title={translate.pages.export.mediaMain}/></Cell>
                            </Row>
                        </RadioGroup>
                        <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                            <Checkbox
                                variant={INPUT_VARINAT.BLUE}
                                title={translate.pages.export.showDate}
                                name="showDate"
                                value={form.showDate}
                                onChange={onChange}/>
                        </Row>
                        <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                            <Checkbox
                                variant={INPUT_VARINAT.BLUE}
                                title={translate.pages.export.showTime}
                                name="showTime"
                                value={form.showTime}
                                onChange={onChange}/>
                        </Row>
                        <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                            <Checkbox
                                variant={INPUT_VARINAT.BLUE}
                                title={translate.pages.export.showTitle}
                                name="showTitle"
                                value={form.showTitle}
                                onChange={onChange}/>
                        </Row>
                        <Row margin={FORM_ROW_MARGIN.MINI_MARGIN}>
                            <Checkbox
                                variant={INPUT_VARINAT.BLUE}
                                title={translate.pages.export.showKeywords}
                                name="showKeywords"
                                value={form.showKeywords}
                                onChange={onChange}/>
                        </Row>
                        <FormTitle varinat={FORM_TITLE_VARINAT.H1}>
                            {translate.pages.export.mediaSize}
                        </FormTitle>
                        <Row>
                            <RadioGroup name={'mediaSize'} value={form.mediaSize} onChange={onChange}>
                                <Radio variant={INPUT_VARINAT.BLUE} value={'small'} title={translate.pages.export.small}/>
                                <Radio variant={INPUT_VARINAT.BLUE} value={'medium'} title={translate.pages.export.medium}/>
                                <Radio variant={INPUT_VARINAT.BLUE} value={'large'} title={translate.pages.export.large}/>
                            </RadioGroup>
                        </Row>
                    </Cell>
                </Row>
                <Row>
                    <Cell>
                        <DL
                            title={translate.pages.export.sizeTitle}
                            tooltip={translate.tooltip.export.sizeTitle}
                        >
                            <Sizer value={form.sizeTitle} name={'sizeTitle'} options={OPTIONS_SIZE_TITLE} onChange={onChange}/>
                        </DL>
                        <DL
                            title={translate.pages.export.sizeBody}
                            tooltip={translate.tooltip.export.sizeBody}
                        >
                            <Sizer value={form.sizeBody} name={'sizeBody'} options={OPTIONS_SIZE_TEXT} onChange={onChange}/>
                        </DL>
                        <DL
                            title={translate.pages.export.sizeLineHeight}
                            tooltip={translate.tooltip.export.sizeLineHeight}
                        >
                            <Sizer value={form.sizeLineHeight} name={'sizeLineHeight'} options={OPTIONS_SIZE_LINE_HEIGHT} onChange={onChange}/>
                        </DL>
                        {/*<Row justify={FORM_ROW_JUSTIFY.END}>*/}
                            {/*<Button*/}
                                {/*height={'40px'}*/}
                                {/*onClick={submit}*/}
                                {/*isLoading={isLoading}>*/}
                                {/*{translate.pages.export.save}*/}
                            {/*</Button>*/}
                        {/*</Row>*/}
                    </Cell>
                    <Cell/>
                </Row>
            </Form>
        </>
    )
};

export default Export