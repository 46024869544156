import React, { FC, useEffect } from 'react';
import HistoryTable from '../../../component/History/index';
import Scrollbar from '../../../component/Scrollbar/index';
import Title from '../../../component/Title/index';
import {useTranslate} from "../../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import cssL from '../../../component/Layout/Layout.module.scss';

const History: FC = () => {
    const translate = useTranslate();

    return(
        <Scrollbar>
            <div className={cssL.stickyContent}>
                <Title title={translate.pages.history.title} sticky={true} backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}/>
                <HistoryTable/>
            </div>
        </Scrollbar>
    )
};

export default History;