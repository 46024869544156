import React, { FC, useEffect, memo } from 'react';
import InnerHTML from 'dangerously-set-html-content'
import {analyticsBanner} from "../../utils/analytics";
import {useGetBanner} from "../../hooks/useGetBanner";
import {getCookie, setCookie} from "../../utils/utils";

const Banner: FC = memo(() => {
    const banner = useGetBanner();

    const onClick = () => {
        analyticsBanner();
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', padding: '12px 6px'}}>
            <div style={{width: '300px', height: '600px'}} onClick={onClick}>
                <InnerHTML html={banner.BANNER_INDEX} />
            </div>
        </div>
    )
});

export default Banner