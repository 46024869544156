import React, { FC, useState, MouseEvent, FormEvent } from 'react';
import Modal from '../index';
import Button, {BUTTON_GROUP_ALIGN, BUTTON_VARIANT, ButtonGroup} from '../../../component/Button';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { logout, loginMultisession } from '../../../store/reducers/AuthSlice';
import { setMultiSessionOpen } from '../../../store/reducers/ModalSlice';
import { useTranslate } from "../../../hooks/useTranslate";
import css from '../Modal.module.scss';

const MultiSession: FC = () => {
    const { isOpen, isLoading } = useAppSelector(state => state.modal.multiSession);
    const { login, pwd } = useAppSelector(state => state.modal.login);
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const close = () => {
        dispatch(logout());
        dispatch(setMultiSessionOpen(false))
    };

    const auth = () => {
        dispatch(loginMultisession({login, pwd}));
        dispatch(setMultiSessionOpen(false));
    };

    return(
        <Modal
            isOpen={isOpen}
            close={close}
            title={translate.multiSession.title}
        >
            <div className={css.content}>
                <div className={css.text}>
                    {translate.multiSession.description}
                </div>
                <ButtonGroup align={BUTTON_GROUP_ALIGN.RIGHT}>
                    <Button variant={BUTTON_VARIANT.RED} onClick={auth} isLoading={isLoading}>{translate.multiSession.login}</Button>
                    <Button onClick={close}>{translate.multiSession.logout}</Button>
                </ButtonGroup>
            </div>
        </Modal>
    )
};

export default MultiSession;