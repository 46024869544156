import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";

interface IAlert {
    isOpen: boolean,
    title: string,
    description: string
    button: string
}


const initialState: IAlert = {
    isOpen: false,
    title: '',
    description: '',
    button: ''
};


export const alertSlice = createSlice({
    name: 'alertSlice',
    initialState,
    reducers: {
        alert(state: IAlert, action: PayloadAction<{ title: string, description: string, button: string }>) {
            const {title, description, button} = action.payload;

            state.isOpen = true;
            state.title = title;
            state.description = description;
            state.button = button;
        },
        closeAlert(state: IAlert) {
            state.isOpen = false;
            // state.title = '';
            // state.description = '';
        },
    },
    extraReducers: {

    }
});

export const { alert, closeAlert} = alertSlice.actions;
export default alertSlice.reducer;