import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import Input from '../Input/Input';
import {IInput, OnInputChange} from "../../../types/IInput";
import {useDebounce} from "../../../hooks/useDebounce";


interface Props extends IInput {
    delay?: number
}

const Fetch: FC<Props> = ({delay = 300, value, onChange, ...props}) => {
    const [state, setState] = useState<string>(value);
    const fetch = useDebounce(onChange, delay);

    const handlerChange = ({name, value}: OnInputChange) => {
        setState(value);
        fetch({name, value});
    };

    useEffect(() => {
        if(state !== value){
            setState(value)
        }
    },[value]);

    return (
        <Input
            {...props}
            value={state}
            onChange={handlerChange}
        />
    )
};

export default Fetch