import React, { FC, useState, MouseEvent, FormEvent, useEffect } from 'react';
import cn from 'classnames';
import Scrollbar from '../../../component/Scrollbar/index';
import Title from '../../../component/Title/index';
import Icon from '../../../component/Icon/index';
import {TooltipProfile} from '../../../component/Tooltip';
import { Form, Row, Cell, DL} from '../../../component/Input/Form';
import Sizer from '../../../component/Input/Sizer';
import Toggle from '../../../component/Input/Toggle';
import {INPUT_VARINAT, OnCheckboxChange} from "../../../types/IInput";
import {COLORS_FAVORITE, COLORS_TEXT, OPTIONS_SIZE_TEXT, OPTIONS_SIZE_TITLE} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ICON_VARIANT} from "../../../assets/svg/index";
import {initialProfileLenta, setProfileLenta} from "../../../store/reducers/AuthSlice";
import {ISizerChange} from "../../../component/Input/Sizer/index";
import {useTranslate} from "../../../hooks/useTranslate";
import {SCREEN_VARIANT} from "../../../types/IScreen";
import {useGetColor} from "../../../hooks/useGetColor";
import css from './Settings.module.scss';
import cssL from '../../../component/Layout/Layout.module.scss';
import {FORM_FORM_VARIANT} from "../../../component/Input/Form";

const Settings: FC = () => {
    const {profile_lenta, isLoading} = useAppSelector(state => state.auth);
    const profile = profile_lenta.find(item => item.name === 'main') || initialProfileLenta;
    const [form, setForm] = useState(profile.value);
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const color = useGetColor();

    const onChange = ({name, value}: OnCheckboxChange | ISizerChange) => {
        const newValue = {...form, ...{[name]: value}};

        setForm(newValue);
        dispatch(setProfileLenta({
            name: profile.name,
            type: profile.type,
            value: newValue
        }))
    };

    const onChangeParams = (params: object) => {
        const newValue = {...form, ...params};

        setForm(newValue);
        dispatch(setProfileLenta({
            name: profile.name,
            type: profile.type,
            value: newValue
        }))
    };

    return (
        <Scrollbar>
            <div className={cssL.stickyContent}>
                <Title title={translate.pages.lenta.title} sticky={true} backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}/>
                <Form variant={FORM_FORM_VARIANT.INNER}>
                    <Row>
                        <Cell>
                            {/*<DL*/}
                                {/*title={translate.tooltip.enable}*/}
                            {/*>*/}
                                {/*<Toggle*/}
                                    {/*variant={INPUT_VARINAT.LIGHT}*/}
                                    {/*name={'tooltip'}*/}
                                    {/*value={form.tooltip}*/}
                                    {/*onChange={onChange}/>*/}
                            {/*</DL>*/}
                            <DL
                                title={translate.pages.lenta.scrollFixed}
                                tooltip={translate.tooltip.lenta.scrollFixed}
                            >
                                <Toggle
                                    variant={INPUT_VARINAT.LIGHT}
                                    name={'scrollFixed'}
                                    value={form.scrollFixed}
                                    onChange={onChange}/>
                            </DL>
                            {translate.pages.lenta.hideSids
                                ? <DL
                                    title={translate.pages.lenta.hideSids}
                                    tooltip={translate.tooltip.lenta.hideSids}
                                >
                                    <Toggle
                                        variant={INPUT_VARINAT.LIGHT}
                                        name={'hideSids'}
                                        value={form.hideSids}
                                        onChange={onChange}/>
                                </DL>
                                : null
                            }
                            <DL
                                title={translate.pages.lenta.sizeTitle}
                                tooltip={translate.tooltip.lenta.sizeTitle}
                            >
                                <Sizer
                                    value={form.sizeTitle}
                                    name={'sizeTitle'}
                                    options={OPTIONS_SIZE_TITLE}
                                    onChange={onChange}/>
                            </DL>
                            <DL
                                title={translate.pages.lenta.sizeBody}
                                tooltip={translate.tooltip.lenta.sizeBody}
                            >
                                <Sizer
                                    value={form.sizeBody}
                                    name={'sizeBody'}
                                    options={OPTIONS_SIZE_TEXT}
                                    onChange={onChange}/>
                            </DL>
                            <div className={css.section}>
                                <div className={css.partition}>
                                    <div className={css.title}>
                                        {translate.pages.lenta.lightingQuery}
                                        <TooltipProfile
                                            isClick={true}
                                            title={translate.tooltip.lenta.lightingQuery}
                                        />
                                    </div>
                                    <div className={css.colors}>
                                        <div className={cn(css.colorItem, css.empty)}
                                             onClick={() => onChangeParams({lightingQuery: false, lightingQueryColor: '#fff'})}>
                                            {!form.lightingQuery
                                                ? <Icon name={ICON_VARIANT.CHECK} />
                                                : null
                                            }
                                        </div>
                                        {COLORS_TEXT.map((item, i) =>
                                            <div
                                                key={i}
                                                className={cn(css.colorItem, {[css.active]: form.lightingQuery})}
                                                style={{backgroundColor: item, borderColor: item}}
                                                onClick={() => onChangeParams({lightingQuery: true, lightingQueryColor: item})}>
                                                {(form.lightingQueryColor === item && form.lightingQuery)
                                                    ? <Icon name={ICON_VARIANT.CHECK} />
                                                    : null
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*<div className={css.section}>*/}
                                {/*<div className={css.partition}>*/}
                                    {/*<div className={css.title}>*/}
                                        {/*{translate.pages.lenta.lightingFavorite}*/}
                                        {/*<Tooltip*/}
                                            {/*isClick={true}*/}
                                            {/*title={translate.tooltip.lenta.lightingFavoriteColor}*/}
                                        {/*/>*/}
                                    {/*</div>*/}
                                    {/*<div className={css.colors}>*/}
                                        {/*<div className={cn(css.colorItem, css.empty)}*/}
                                             {/*onClick={() => onChangeParams({lightingFavorite: false, lightingFavoriteColor: '#fff'})}>*/}
                                            {/*{!form.lightingFavorite*/}
                                                {/*? <Icon name={ICON_VARIANT.CHECK} />*/}
                                                {/*: null*/}
                                            {/*}*/}
                                        {/*</div>*/}
                                        {/*{COLORS_FAVORITE.map((item, i) =>*/}
                                            {/*<div*/}
                                                {/*key={i}*/}
                                                {/*className={cn(css.colorItem, {[css.active]: form.lightingFavorite})}*/}
                                                {/*style={{backgroundColor: item, borderColor: item}}*/}
                                                {/*onClick={() => onChangeParams({lightingFavorite: true, lightingFavoriteColor: item})}>*/}
                                                {/*{(form.lightingFavoriteColor === item && form.lightingFavorite)*/}
                                                    {/*? <Icon name={ICON_VARIANT.CHECK}/>*/}
                                                    {/*: null*/}
                                                {/*}*/}
                                            {/*</div>*/}
                                        {/*)}*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </Cell>
                        <Cell/>
                    </Row>
                </Form>
            </div>
        </Scrollbar>
    )
};

export default Settings