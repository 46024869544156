import React, { FC } from 'react';
import SplitPane from 'react-split';
import {gutter} from "../../utils/utils";
import '../../assets/styles/split.scss';
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";

interface ISplit {
    sizes?: [number, number],
    tab?: number | null,
    onDrag?: (sizes: [number, number]) => void
    minSize?: [number, number]
}


const Split: FC<ISplit> = ({children, tab = null, sizes = [75, 25], minSize = [400, 324], onDrag}) => {
    const { screen } = useAppSelector(state => state.screen);
    const { rtl } = useAppSelector(state => state.auth);

    if(tab){
        return (
            <>{React.Children.toArray(children)[(tab - 1)]}</>
        )
    }

    if(screen !== SCREEN_VARIANT.DESKTOP) {
        return (
            <>{children}</>
        )
    }

    const onDragSplit = (sizes: any) => {
        onDrag && onDrag(rtl ? sizes.reverse() : sizes);
    };

    return (
        <SplitPane
            className="split"
            sizes={rtl ? sizes.reverse() : sizes}
            minSize={rtl ? minSize.reverse() : minSize}
            expandToMin={false}
            gutterSize={20}
            snapOffset={30}
            dragInterval={1}
            gutter={gutter}
            direction="horizontal"
            cursor="col-resize"
            onDrag={onDragSplit}
        >
            {rtl
                ? React.Children.toArray(children).reverse()
                : children
            }
        </SplitPane>
    )
};

export default Split