import React, { FC, useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import moment from 'moment'
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title as ChartTitle, Tooltip, Legend} from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from 'chartjs-plugin-zoom';
import Scrollbar from '../../component/Scrollbar';
import Dropdown, { DropDownHeader, DropdownItem} from '../../component/Dropdown';
import Title from '../../component/Title';
import Spinner from '../../component/Spinner';
import Fetch  from '../../component/Input/Fetch'
import Button, {BUTTON_VARIANT} from '../../component/Button';
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useTranslate} from "../../hooks/useTranslate";
import {useUserAllSids, useUserSids} from "../../hooks/useUserSids";
import {ISid} from "../../types/IUser";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getAnalytics, IAnalyticsFilter} from "../../store/reducers/AnalyticsSlice";
import {getDate, getGraph, getGraphSids, getNextGraphColor, getDateSubtractDays} from "../../utils/utils";
import css from './Analytics.module.scss';
import {INPUT_VARINAT, OnInputChange} from "../../types/IInput";
import {DROPDOWN_VARIANT} from "../../component/Dropdown/DropdownMenu";
import {DROPDOWN_HEADER_SIZE} from "../../component/Dropdown/DropdownHeader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin
);

const Analytics: FC = () => {
    const { isLoading, data } = useAppSelector(state => state.analytics);
    const [sid, setSid] = useState<{data: ISid, color: string}[]>([]);
    const [filter, setFilter] = useState<IAnalyticsFilter>({query: '', date_end: getDate(), date_start: getDateSubtractDays(30)});
    const translate = useTranslate();
    const feed = useUserSids();
    // const feed: ISid[] = useUserAllSids();
    const [count, setCount] = useState<number>(20);
    const dispatch = useAppDispatch();
    const dataGraph = getGraph(data, sid, feed, translate.pages.analytics.allSids);
    const sidsGraph = getGraphSids(data);
    const ref = useRef<any>(null);
    const allCount = dataGraph.datasets.map(item => item.data.reduce((a,b) => a+b, 0))?.reduce((a,b) => a+b, 0);

    const onChange = ({name, value}: OnInputChange) => {
        setFilter(state => ({...state, ...{[name]: value}}));
    };

    const onChangeDate = (days: number) => {
        setFilter(state => ({...state, ...{date_start: getDateSubtractDays(days)}}));
    };

    const onSid = (item: ISid) => {
        const newValue = sid.find(s => s.data.sid === item.sid)
            ? sid.filter(s => s.data.sid !== item.sid)
            : sid.concat({
                data: item,
                color: getNextGraphColor(sid[sid.length - 1]?.color)
            });

        setSid(newValue);
    };

    const onDownload = () => {
        const link = document.createElement('a');
        link.download = "chart.jpeg";
        link.href = ref?.current?.toBase64Image('image/png', 1);
        link.click();
    };


    const getValue = () => {
        const dateStart = moment(filter.date_start).format('DDMMYYYY');
        if(dateStart === moment(getDateSubtractDays(365)).format('DDMMYYYY')){
            return translate.pages.analytics.range.year
        } else if(dateStart === moment(getDateSubtractDays(90)).format('DDMMYYYY')){
            return translate.pages.analytics.range.quarter
        } else if(dateStart === moment(getDateSubtractDays(30)).format('DDMMYYYY')){
            return translate.pages.analytics.range.mounth
        } else {
            return translate.pages.analytics.range.week
        }
    };


    useEffect(()=> {
        dispatch(getAnalytics(filter))
    },[filter]);

    return(
        <div className={css.content}>
            <Scrollbar>
                <Title
                    title={translate.pages.analytics.title}
                    sticky={true}
                    backScreen={[SCREEN_VARIANT.MOBILE, SCREEN_VARIANT.TABLET, SCREEN_VARIANT.DESKTOP]}/>
                <div className={css.analytics}>
                    <div className={css.head}>
                        <div className={css.title}>{translate.pages.analytics.subTitle}</div>
                        <Button
                            disabled={isLoading}
                            height={'40px'}
                            fontSize={'16px'}
                            onClick={onDownload}>
                                {translate.pages.analytics.button}
                        </Button>
                    </div>
                    <div className={css.filter}>
                        <div className={css.filterSearch}>
                            <Fetch
                                value={filter.query}
                                name={'query'}
                                title={translate.search.search}
                                variant={INPUT_VARINAT.GREY}
                                onChange={onChange}
                            />
                        </div>
                        <div className={css.filterRange}>
                            <Dropdown
                                header={
                                    <DropDownHeader
                                        value={getValue()}
                                        title={translate.datepicker.period}
                                        size={DROPDOWN_HEADER_SIZE.LARGE}
                                    />
                                }
                                variant={DROPDOWN_VARIANT.WHITE}
                            >
                                <DropdownItem active={moment(filter.date_start).format('DDMMYYYY') === moment(getDateSubtractDays(365)).format('DDMMYYYY')} onClick={() => onChangeDate(365)}>{translate.pages.analytics.range.year}</DropdownItem>
                                <DropdownItem active={moment(filter.date_start).format('DDMMYYYY') === moment(getDateSubtractDays(90)).format('DDMMYYYY')} onClick={() => onChangeDate(90)}>{translate.pages.analytics.range.quarter}</DropdownItem>
                                <DropdownItem active={moment(filter.date_start).format('DDMMYYYY') === moment(getDateSubtractDays(30)).format('DDMMYYYY')} onClick={() => onChangeDate(30)}>{translate.pages.analytics.range.mounth}</DropdownItem>
                                <DropdownItem active={moment(filter.date_start).format('DDMMYYYY') === moment(getDateSubtractDays(7)).format('DDMMYYYY')} onClick={() => onChangeDate(7)}>{translate.pages.analytics.range.week}</DropdownItem>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={css.graph}>
                        {isLoading
                            ? <Spinner/>
                            : <Line
                                ref={ref}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: "top" as const
                                        },
                                        zoom: {
                                            limits: {
                                                x: {min: 0, max: 30, minRange: 2}
                                            },
                                            pan: {
                                                enabled: true,
                                                mode: 'x'
                                            },
                                            zoom: {
                                                wheel: {
                                                    enabled: true,
                                                    speed: 0.00000000001
                                                },
                                                drag: {
                                                    enabled: true
                                                },
                                                mode: 'x',
                                            }
                                        }
                                    },
                                    scales: {
                                        x: {
                                            display: true,
                                            title: {
                                                display: true
                                            },
                                            ticks: {
                                                // forces step size to be 50 units
                                                stepSize: 50
                                            }
                                        },
                                        y: {
                                            display: true,
                                            title: {
                                                display: true,
                                                text: translate.pages.analytics.count
                                            }
                                        }
                                    }
                                }}
                                data={dataGraph}
                            />
                        }
                    </div>
                    {!isLoading
                        ? <>
                            <div className={css.section}>
                                <div className={css.sectionTitle}>{translate.pages.analytics.count}: <span className={css.sectionCount}>{allCount}</span></div>
                            </div>
                            <div className={css.section}>
                                <div className={css.sectionTitle}>{translate.pages.analytics.lenta}</div>
                                <div className={css.tags}>
                                    <div className={css.tagItem}
                                         style={{background: !sid.length ? '#E1EFFF' : "#44579E", color: !sid.length ? '#545454' : "#fff"}}
                                         onClick={() => setSid([])}>
                                        {translate.pages.analytics.allSids}
                                    </div>
                                    {feed.map(item =>
                                        <div key={item.id}
                                            className={css.tagItem}
                                            style={{background: sid.find(s => s.data.id === item.id)?.color || "#44579E"}}
                                            onClick={() => onSid(item)}>
                                            {item.title}
                                        </div>
                                    )}
                                </div>
                                {count < feed.length
                                    ? <div>
                                        <div className={css.more}
                                            onClick={() => setCount(count + 20)}>{translate.pages.analytics.more}</div>
                                    </div>
                                    : null
                                }
                            </div>
                        </>
                        : null
                    }
                </div>
            </Scrollbar>
        </div>
    )
};

export default Analytics;