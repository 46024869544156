import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Icon from '../Icon';
import {ROUTE} from "../../constants";
import {ICON_VARIANT} from "../../assets/svg";
import css from './Back.module.scss'

interface Props {
    title?: string | null
}

const Back: FC<Props> = ({ title = null}) => {
    const navigate = useNavigate();

    return(
        <div className={css.back} onClick={() => window.history.length === 1 ? navigate(ROUTE.INDEX) : navigate(-1)}>
            <div className={css.icon}><Icon name={ICON_VARIANT.ARROW_BACK}/></div>
            {title}
        </div>
    )
}

export default Back;