import React, { FC, useRef, useState, MouseEvent, useEffect, KeyboardEvent } from 'react';
import cn from 'classnames';
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Search from '../Search';
import Filter from './Filter';
import Buttons from './HeaderButtons';
import Menu from './HeaderMenu';
import Icon from '../Icon';
import { ICON_VARIANT } from "../../assets/svg";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import { SCREEN_VARIANT } from "../../types/IScreen";
import { OnInputChange } from "../../types/IInput";
import {disabledScroll, enabledScroll, getSearchParams} from "../../utils/utils";
import {ROUTE} from "../../constants";
import {LIST_NAME, getList, setParams, resetAllFilters} from "../../store/reducers/LSlice";
import {useGetMainSid} from "../../hooks/useGetMainSid";
import {useUserPremium} from "../../hooks/useUserPremium";
import {GS_VARIANT, setOpenFilter} from "../../store/reducers/ScreenSlice";
import {LANG, THEME} from "../../types/IUser";
import { setFocus, setShowMenu, setShowFilter } from "../../store/reducers/HeaderSlice";
import css from './Header.module.scss';
import {useUserSids} from "../../hooks/useUserSids";


const Header: FC = () => {
    const { lang, theme, topline, queries } = useAppSelector(state => state.auth);
    const { focus, showFilter } = useAppSelector(state => state.header);
    const { screen, premiumScreen} = useAppSelector(state => state.screen);
    // const { query, sid_list } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]?.filter);
    const { filter } = useAppSelector(state => state.l[LIST_NAME.ALL_NEWS]);
    const refSearch = useRef<HTMLDivElement | null>(null);
    const refHeader = useRef<HTMLDivElement | null>(null);
    const feed = useUserSids();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const premium = useUserPremium();
    const sid = useGetMainSid();
    const query = queries.find(item => item.id === filter.id);
    const value = query ? query.value.query || '' : filter.query;

    const onChange = ({name, value}: OnInputChange) => {
        if(location.pathname !==  ROUTE.INDEX) {
            navigate(ROUTE.INDEX);
        }

        dispatch(setParams({
            name: LIST_NAME.ALL_NEWS,
            params: {
                query: value,
                sid_list: filter?.sid_list.length === 0 && premium ? [sid.sid] : filter?.sid_list,
                id: '',
                name: '',
                ids: []

            }
        }));
        dispatch(getList(LIST_NAME.ALL_NEWS));
        dispatch(setFocus(false));
    };

    const toggleScroll = (active: boolean) => {
        if(active) {
            if(document.body.getAttribute('scroll') !== 'false') {
                disabledScroll();
            }
        } else {
            enabledScroll();
        }
    };

    const onDocumentEsc = () => {
        document.addEventListener('keydown', (e: any) => {
            if (e.keyCode === 27) {
                dispatch(setOpenFilter(false));
                dispatch(setFocus(false));
                toggleScroll(false);
                ((document as any).getElementById("search") as any)?.blur();
            }
        });
    };

    const onClose = () => {
        dispatch(setShowMenu(false));
        dispatch(setShowFilter(false));
        // dispatch(setOpenFilter(false));
        toggleScroll(false);
    };

    const onClickLogo = (e: MouseEvent) => {
        e.preventDefault();
        dispatch(resetAllFilters());

        dispatch(setParams({
            name: LIST_NAME.ALL_NEWS,
            params: {
                sid_list: filter?.sid_list?.length ? filter?.sid_list : feed.map(item => item.sid)
            }
        }));

        if(location.pathname !==  ROUTE.INDEX){
            navigate(ROUTE.INDEX);
        } else {
            dispatch(getList(LIST_NAME.ALL_NEWS));
        }

        // if(location.pathname !==  ROUTE.INDEX){
        //     navigate(ROUTE.INDEX);
        // } else {
        //     if(!premium) {
        //         dispatch(getList(LIST_NAME.OPINION));
        //         dispatch(getList(LIST_NAME.TOP_NEWS));
        //     } else {
        //         // dispatch(setParams({
        //         //     name: LIST_NAME.ALL_NEWS,
        //         //     params: {
        //         //         sid_list: filter?.sid_list?.length ? filter?.sid_list : feed.map(item => item.sid)
        //         //     }
        //         // }));
        //         dispatch(getList(LIST_NAME.ALL_NEWS));
        //     }
        // }
    };

    useEffect(() => {
        onDocumentEsc();
    },[]);

    return (
        <div className={css.header} data-tour={'header'}>
            <div className={css.wrapper} ref={refHeader}>
                <Link
                    to={'/'}
                    className={cn(css.logo, {[css.hidden]: (focus || showFilter) && screen === SCREEN_VARIANT.MOBILE})}
                    onClick={onClickLogo}>
                        {theme === THEME.RIA
                            ? <Icon name={ICON_VARIANT.LOGO}/>
                            : <Icon name={ICON_VARIANT.LOGO_SPUTNIK}/>
                        }
                </Link>

                <Search
                    value={value}
                    ref={refSearch}
                    onChange={onChange}
                />

                {screen === SCREEN_VARIANT.DESKTOP
                    ? <Buttons/>
                    : null
                }

                <Menu/>

                <Filter refSearch={refSearch}/>
            </div>

            {showFilter || focus
                ? <div
                    className={cn(css.overlay, {[css.topline]: topline, [css.active]: showFilter || focus})}
                    onClick={onClose}/>
                : null
            }
        </div>
    );
};

export default Header