import React, { FC, useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content'
import {analyticsBannerGS1} from "../../utils/analytics";
import {useGetBanner} from "../../hooks/useGetBanner";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";

const BannerHeader: FC = () => {
    const { screen } = useAppSelector(state => state.screen);
    const banner = useGetBanner();

    const onClick = () => {
        analyticsBannerGS1();
    };

    return (
        <div style={{padding: '12px 6px'}} onClick={onClick}>
            {screen === SCREEN_VARIANT.DESKTOP
                ? <div style={{height: '120px'}}>
                    <InnerHTML html={banner.BANNER_HEADER} />
                </div>
                : <div style={{height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <InnerHTML html={banner.BANNER_HEADER_MOBILE} />
                </div>
            }
        </div>
    )
};

export default BannerHeader;