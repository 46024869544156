import React, { FC } from 'react';
import {SCREEN_VARIANT} from "../../types/IScreen";
import {useAppSelector} from "../../hooks/redux";

interface Props {
    screen: SCREEN_VARIANT[]
}

const Screen: FC<Props> = ({screen, children}) => {
    const windowScreen = useAppSelector(state => state.screen.screen);

    if(!screen.includes(windowScreen)){
        return null
    }

    return (
        <>{children}</>
    );
};

export default Screen;