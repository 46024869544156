import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from 'query-string';
import {URL} from "../../constants";
import {download} from "../../utils/utils";
import {LANG} from "../../types/IUser";


export enum EXPORT_FORMAT {
    PDF = 'pdf',
    // ZIP = 'zip',
    DOC = 'doc',
    NEWSML = 'newsml'
}

export enum EXPORT_FORMAT_ZIP {
    ZIP = 'zip'
}

interface IExportParams {
    ids: number[],
    format: EXPORT_FORMAT,
    lang: LANG,
    zipFiles: boolean
}

export const exportArticles = createAsyncThunk(
    'export/export',
    async (params: IExportParams, { rejectWithValue }) => {
        try {
            const {ids, format, lang, zipFiles} = params;
            if (ids.length) {

                download(
                    URL.EXPORT + '?' + qs.stringify({
                        article_id: ids,
                        lang: lang,
                        format: format,
                        zipFiles: zipFiles ? 1 : 0
                    }, {arrayFormat: 'bracket'}),
                    ids.join('_')
                );
            }
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);